import { NavLink, useMatches } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import { Paragraph } from "@frontend/lyng/typography";
import { ChevronRight } from "@frontend/lyng/assets/icons/12/outline";

export type Crumb = (data: unknown) => { name: string; href: string } | null;
type HandleWithCrumb = {
  crumb: Crumb;
};

function isHandleWithCrumb(handle: unknown): handle is { crumb: Crumb } {
  return (
    typeof handle === "object" &&
    !!handle &&
    "crumb" in handle &&
    !!handle.crumb
  );
}

export const Breadcrumbs = () => {
  const matches = useMatches();
  const { t } = useTranslate();
  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(isHandleWithCrumb(match.handle)))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => (match.handle as HandleWithCrumb).crumb(match.data));

  return (
    <nav className="flex p-4" aria-label={t("breadcrumbs").toString()}>
      <ol className="flex items-center gap-2">
        <li>
          <NavLink to="/">
            <Paragraph
              size="xs"
              className="cursor-pointer"
              type={crumbs.length === 0 ? "primary" : "secondary"}
            >
              {t("home")}
            </Paragraph>
          </NavLink>
        </li>
        {crumbs.map(
          (crumb, i) =>
            crumb && (
              <>
                <ChevronRight className="text-greyscale-600" />
                <NavLink to={crumb.href}>
                  <Paragraph
                    size="xs"
                    className="cursor-pointer"
                    type={i === crumbs.length - 1 ? "primary" : "secondary"}
                  >
                    {t(crumb.name) !== crumb.name
                      ? t(crumb.name).toString()
                      : crumb.name}
                  </Paragraph>
                </NavLink>
              </>
            ),
        )}
      </ol>
    </nav>
  );
};
