import { DateTime, Duration } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import {
  VisitLogsByOfficeIdQuery,
  useVisitLogsByOfficeIdQuery,
} from "../../../api/generated/graphql";
import { formatDurationToDecimals } from "@frontend/lyng/utils/dateUtils";
import { useDateFormatter } from "../../../utils/dateUtils";
import { useCareContext } from "../../../providers";
import { CSVExportColumnPickerModal, Table } from "../../../components/common";
import { Headline, HeadlineContainer, Link } from "@frontend/lyng/typography";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { useLocalStorage } from "../../../utils/hooks/useLocalStorage";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useTranslateCancelledReason } from "../../../utils/translationUtils";
import { OfficeFilter } from "../../../components/common/officeFilter/OfficeFilter";
import { DateRangePicker } from "@frontend/lyng/forms";
import { Button } from "@frontend/lyng/button/Button";

type Visitor = {
  firstName: string | null;
  lastName: string | null;
  id: string;
};

type VisitLogRow = VisitLogsByOfficeIdQuery["visitLogsByOfficeId"][number];

export function useDurationToDecimalsFormatter() {
  const {
    state: { viewer },
  } = useCareContext();
  return {
    durationInHours: (base: Duration | number) =>
      formatDurationToDecimals(base, viewer?.tenantSettings.decimalSeparator),
    durationInMinutes: (base: Duration | number) =>
      formatDurationToDecimals(
        base,
        viewer?.tenantSettings.decimalSeparator,
        true,
      ),
  };
}

export const VisitNotesReport = () => {
  const {
    state: { viewer, selectedTenant },
  } = useCareContext();
  const { t } = useTranslate();
  const { formatDate: formatFullDate, formatTime } = useDateFormatter();

  const { collator, nameOrderFn } = useSortingOptions();

  const [showCSVExportModal, setShowCSVExportModal] = useState(false);

  const [selectedOfficeId] = useLocalStorage<string | null>(
    `office-filter-${selectedTenant?.id}`,
    null,
  );
  const [officeId, setOfficeId] = useState<string | null>(selectedOfficeId);
  const [dateRange, setDateRange] = useState({
    start: DateTime.local().startOf("month"),
    end: DateTime.local().endOf("month"),
  });
  const [visibilityState, setVisibilityState] =
    useLocalStorage<VisibilityState>("visitNotesVisibilityState", {
      caregiverId: true,
      careRecipentId: true,
      visitTypeCode: true,
      clockInTime: true,
      visitNote: true,
    });

  const { data, error, loading } = useVisitLogsByOfficeIdQuery({
    fetchPolicy: "network-only",
    skip: dateRange.start > dateRange.end || !officeId,
    variables: {
      officeId: officeId ?? "",
      from: dateRange.start.startOf("day").toISO() ?? "",
      to: dateRange.end.endOf("day").toISO() ?? "",
    },
  });

  const getCaregiverName = useCallback(
    (visitor: Visitor | null) => {
      if (!visitor) return "-";
      return nameOrderFn(visitor);
    },
    [nameOrderFn],
  );

  const translateCancelledReason = useTranslateCancelledReason(t);

  const columnAccessor: Record<string, (row: VisitLogRow) => string> =
    useMemo(() => {
      return {
        careRecipient: (row) => nameOrderFn(row.careRecipient),
        caregiver: (row) => getCaregiverName(row.visitors[0] ?? null),
        visitDate: (row) => formatFullDate(row.start),
        actualStart: (row) => {
          const clockInTime = row.clockInTime
            ? formatTime(row.clockInTime)
            : "-";
          return `${clockInTime}`;
        },
        visitNote: (row) => row.visitNote ?? "",
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translateCancelledReason]);

  const headerAccessor: Record<string, string> = useMemo(() => {
    return {
      careRecipient: t("reports.visitLogs.careRecipient") ?? "",
      caregiver: t("reports.visitLogs.caregiver") ?? "",
      visitDate: t("reports.visitLogs.visitDate") ?? "",
      actualStart: t("reports.visitLogs.actualStart") ?? "",
      visitNote: t("reports.visitLogs.visitNote") ?? "",
      view: t("reports.visitLogs.view") ?? "",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCsvData = useCallback(
    (selectedColumns: string[]) => {
      if (!data?.visitLogsByOfficeId) {
        return [];
      }
      const header = selectedColumns.map(
        (columnId) => headerAccessor[columnId] ?? "",
      );
      const rows = data?.visitLogsByOfficeId.map(
        (report) =>
          selectedColumns.map((columnId) => columnAccessor[columnId](report)) ??
          "",
      );
      return [header, ...rows];
    },
    [columnAccessor, data?.visitLogsByOfficeId, headerAccessor],
  );

  const columns = useMemo<ColumnDef<VisitLogRow>[]>(() => {
    return [
      {
        id: "careRecipient",
        header: headerAccessor.careRecipient,
        accessorFn: columnAccessor.careRecipient,
        cell: (row) => (
          <Link
            size="xs"
            to={`/care-recipients/${row.row.original.careRecipient.id}`}
          >
            {row.getValue<string>()}
          </Link>
        ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "caregiver",
        header: headerAccessor.caregiver,
        accessorFn: columnAccessor.caregiver,
        cell: (row) =>
          row.row.original.visitors.length ? (
            <Link
              size="xs"
              to={`/caregivers/${row.row.original.visitors[0].id}`}
            >
              {row.getValue<string>()}
            </Link>
          ) : (
            row.getValue<string>()
          ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "visitDate",
        header: headerAccessor.visitDate,
        accessorFn: columnAccessor.visitDate,
        sortingFn: (a, b) =>
          DateTime.fromISO(a.original.start).diff(
            DateTime.fromISO(b.original.start),
          ).milliseconds,
      },
      {
        id: "actualStart",
        header: headerAccessor.actualStart,
        accessorFn: columnAccessor.actualStart,
        enableSorting: false,
      },
      {
        id: "visitNote",
        header: headerAccessor.visitNote,
        accessorFn: columnAccessor.visitNote,
        enableSorting: false,
      },
      {
        id: "view",
        header: headerAccessor.view,
        accessorFn: (row) => row.id,
        cell: (row) => (
          <Link
            size="s"
            to={`visits/${row.getValue<string>()}`}
            preventScrollReset
          >
            {t("view")}
          </Link>
        ),
        enableSorting: false,
        enableHiding: false,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="mb-8 p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("reports.visitNotes.title")}</Headline>
      </HeadlineContainer>

      <HeadlineContainer className="-mt-8 items-center">
        <OfficeFilter
          selectedOfficeId={officeId}
          setSelectedOfficeId={(officeId) => officeId && setOfficeId(officeId)}
          showAllOption={false}
        />
        <div className="flex items-center gap-4">
          <DateRangePicker
            dateSettings={viewer?.tenantSettings}
            aria-label="Select date range"
            name="dateRange"
            value={dateRange}
            onChange={(value) => value && setDateRange(value)}
          />

          <Button
            text={t("export") ?? ""}
            variant="primary"
            onClick={() => setShowCSVExportModal(true)}
          />
        </div>
      </HeadlineContainer>

      <Table
        columns={columns}
        data={data?.visitLogsByOfficeId ?? []}
        defaultSorting={[{ id: "visitDate", desc: true }]}
        columnVisibility={visibilityState}
        onColumnVisibilityChange={setVisibilityState}
        loading={loading}
      />

      <CSVExportColumnPickerModal
        show={showCSVExportModal}
        onClose={() => setShowCSVExportModal(false)}
        name="exportCsv"
        defaultSelectedColumns={visibilityState}
        columns={columns.reduce(
          (acc, column) => {
            if (column.enableHiding === false) return acc;
            if (!column.id) return acc;
            if (typeof column.header !== "string") return acc;
            return [...acc, { id: column.id, label: column.header }];
          },
          [] as { id: string; label: string }[],
        )}
        getData={getCsvData}
      />
    </div>
  );
};
