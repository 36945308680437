import { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import classNames from "classnames";
import { DalaWordmark } from "@frontend/lyng/assets/svg/DalaWordmark";

import {
  Home as HomeFilled,
  Schedule as ScheduleFilled,
  User as UserFilled,
  Users as UsersFilled,
  Reports as ReportsFilled,
} from "@frontend/lyng/assets/icons/16/filled";

import {
  Home as HomeOutline,
  Schedule as ScheduleOutline,
  User as UserOutline,
  Users as UsersOutline,
  Reports as ReportsOutline,
  ChevronLeft,
} from "@frontend/lyng/assets/icons/16/outline";

import { ChevronRight } from "@frontend/lyng/assets/icons/12/outline";
import { Hamburger } from "@frontend/lyng/assets/icons/24/outline";

import {
  Link,
  matchPath,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Label } from "@frontend/lyng/typography";
import { useTranslate } from "@tolgee/react";
import { useCareContext } from "../../../providers";
import { ResourceType, RoleType } from "@frontend/lyng/api/generated/graphql";
import { AnimatePresence, easeOut, motion } from "framer-motion";
import { Button } from "@frontend/lyng/button";

import { ProfileMenu } from "./ProfileMenu";
import { NotificationsList } from "@frontend/lyng/notifications";
import { Notification } from "@frontend/lyng/notifications/types";
import { match, P } from "ts-pattern";
import { NavActiveBeam } from "@frontend/lyng/assets/svg/NavActiveBeam";
import { TenantSwitcher } from "./TenantSwitcher";
import { useFeatureFlag } from "../../../providers/FeatureFlags";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { CommunicationsPanel } from "./CommunicationsButton";

type NavItemLink = {
  type: "link";
  name: string;
  href: string;
  icon?: React.ElementType;
  iconCurrent?: React.ElementType;
  hidden?: boolean;
  children?: {
    name: string;
    href: string;
    hidden?: boolean;
  }[];
};

type NavItemComponent = {
  type: "component";
  component: React.ReactNode;
  hidden?: boolean;
};

type NavItem = NavItemLink | NavItemComponent;

const ACCESS_LOGS_TENANT_IDS =
  import.meta.env.VITE_ACCESS_LOGS_TENANT_IDS ?? "";

const LinkItem = ({ item }: { item: NavItemLink }) => {
  const location = useLocation();

  const isActive = useMemo(
    () =>
      !!matchPath(
        { path: item.href, end: item.href === "/" },
        location.pathname,
      ),
    [item.href, location.pathname],
  );

  const [isOpen, setIsOpen] = useState(!!isActive && !!item.children);

  useEffect(() => {
    // Automatically open if a subItem is navigated to
    if (!!isActive && !!item.children) {
      setIsOpen(true);
    }
  }, [isActive, item.children]);

  if (item.hidden) {
    return null;
  }

  const iconColor = isActive ? "text-primary-600" : "text-greyscale-900";
  const Icon = isActive ? item.iconCurrent : item.icon;

  return (
    <li key={item.name} className="relative">
      {!item.children ? (
        <>
          <NavLink
            to={item.href}
            className={classNames(
              isActive ? "bg-primary-50" : "bg-white hover:bg-primary-0",
              "flex gap-x-3 rounded px-3 py-4 items-center focus:outline-none",
            )}
          >
            {Icon && <Icon aria-hidden="true" className={iconColor} />}

            <Label size="xs" className="cursor-pointer">
              {item.name}
            </Label>
          </NavLink>
          {isActive && !isOpen && (
            <NavActiveBeam className="absolute top-1/2 -right-1 -translate-y-1/2 z-[999]" />
          )}
        </>
      ) : (
        <>
          <button
            onClick={() => setIsOpen((prev) => !prev)}
            className={classNames(
              isActive && !isOpen
                ? "bg-primary-50"
                : "bg-white hover:bg-primary-0",
              "w-full flex items-center gap-x-3 rounded px-3 py-4 focus:outline-none",
            )}
          >
            {Icon && <Icon aria-hidden="true" className={iconColor} />}

            <Label size="xs" className="cursor-pointer">
              {item.name}
            </Label>
            <ChevronRight
              aria-hidden="true"
              className={classNames("text-greyscale-900 transition-transform", {
                "rotate-90": isOpen,
              })}
            />
          </button>

          <AnimatePresence>
            {isOpen && (
              <motion.ul
                initial={{ opacity: 0, y: -24 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -24, height: 0 }}
                transition={{ duration: 0.2, ease: easeOut }}
                className="origin-top"
              >
                {item.children.map((subItem) => {
                  const subIsActive = matchPath(
                    { path: subItem.href, end: true },
                    location.pathname,
                  );
                  if (subItem.hidden) {
                    return null;
                  }

                  return (
                    <li key={subItem.name} className="relative">
                      <NavLink
                        to={subItem.href}
                        className={classNames(
                          subIsActive
                            ? "bg-primary-50"
                            : "bg-white hover:bg-primary-0",
                          "block rounded pl-10 pr-5 py-3.5 focus:outline-none",
                        )}
                      >
                        <Label size="xs" className="cursor-pointer">
                          {subItem.name}
                        </Label>
                      </NavLink>
                      {subIsActive && (
                        <NavActiveBeam className="absolute top-1/2 -right-1 -translate-y-1/2 z-[999]" />
                      )}
                    </li>
                  );
                })}
              </motion.ul>
            )}
          </AnimatePresence>
        </>
      )}
    </li>
  );
};

const ItemGroup = ({ items }: { items: NavItem[] }) => {
  return (
    <ul className="px-1 py-4 space-y-0.5">
      {items.map((item) =>
        match(item)
          .with({ hidden: true }, () => null)
          .with({ type: "link" }, (item) => (
            <LinkItem key={item.name} item={item} />
          ))
          .with({ type: "component" }, (item) => item.component)
          .exhaustive(),
      )}
    </ul>
  );
};

const SidebarItems = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { viewer },
  } = useCareContext();

  const ffActivityTemplates = useFeatureFlag("Activities");
  const ff_messaging = useFeatureFlag("Messaging");

  const [secondaryMenuOpen, setSecondaryMenuOpen] = useState(
    location.pathname.startsWith("/settings"),
  );
  // Keep track of where the user was before navigating to settings
  const [storedRoute, setStoredRoute] = useState<string>("/");

  useEffect(() => {
    if (location.pathname.startsWith("/settings")) {
      setSecondaryMenuOpen(true);
    } else {
      setSecondaryMenuOpen(false);
      setStoredRoute(location.pathname);
    }
  }, [location.pathname]);

  const handleNotificationCTA = useCallback(
    (notification: Notification) => {
      // remove time from createdAt
      match([notification.ctaData.type, notification.digestSize])
        .with(["visit", 1], () => {
          navigate(
            `schedule/care-recipients/visits/${notification.ctaData.visitInstanceId}`,
          );
        })
        .with(["visit", P._], () => {
          navigate(
            `schedule/caregivers?filter=late&from=${notification.createdAt}&highlight=${notification.createdAt}`,
          );
        })
        .with(["none", P._], () => {
          return;
        })
        .exhaustive();
    },
    [navigate],
  );
  const { navItems1, navItems2, settingsNavItems } = useMemo(() => {
    const isTenantAdmin = viewer?.roles.some(
      ({ deactivateAt, roleType, resourceType }) =>
        !deactivateAt &&
        resourceType === ResourceType.Tenant &&
        roleType === RoleType.Admin,
    );

    const navItems1: NavItem[] = [
      {
        type: "link",
        name: t("sidebarItems.home"),
        href: "/",
        icon: HomeOutline,
        iconCurrent: HomeFilled,
      },
      {
        type: "link",
        name: t("sidebarItems.schedule"),
        href: "/schedule/care-recipients",
        icon: ScheduleOutline,
        iconCurrent: ScheduleFilled,
      },
      {
        type: "link",
        name: t("sidebarItems.careRecipients"),
        href: "/care-recipients",
        icon: UserOutline,
        iconCurrent: UserFilled,
      },
      {
        type: "link",
        name: t("sidebarItems.caregivers"),
        href: "/caregivers",
        icon: UsersOutline,
        iconCurrent: UsersFilled,
      },
      {
        type: "link",
        name: t("sidebarItems.reports"),
        href: "/reports",
        icon: ReportsOutline,
        iconCurrent: ReportsFilled,
        children: [
          {
            name: t("sidebarItems.caregiverWorkload"),
            href: "/reports/workload",
          },
          {
            name: t("sidebarItems.visitLogs"),
            href: "/reports/visit-logs",
          },
          {
            name: t("sidebarItems.visitNotes"),
            href: "/reports/visit-notes",
          },
          {
            name: t("sidebarItems.billing"),
            href: "/reports/billing",
            hidden:
              !isTenantAdmin || !viewer?.tenantSettings.enableVisitBilling,
          },
        ],
      },
    ];

    const navItems2: NavItem[] = [
      {
        type: "component",
        component: (
          <NotificationsList
            mode="office"
            dateSettings={viewer?.tenantSettings}
            clickHandler={handleNotificationCTA}
          />
        ),
      },
      {
        type: "component",
        component: <CommunicationsPanel />,
        hidden: !ff_messaging,
      },
    ];

    const settingsNavItems: NavItem[] = [
      {
        type: "link",
        name: t("sidebarItems.workspace"),
        href: "/settings/workspace",
        hidden: !isTenantAdmin,
      },
      {
        type: "link",
        name: t("sidebarItems.organization"),
        href: "/settings/organization",
      },
      {
        type: "link",
        name: t("sidebarItems.users"),
        href: "/settings/users",
      },
      {
        type: "link",
        name: t("sidebarItems.labels"),
        href: "/settings/labels",
      },
      {
        type: "link",
        name: t("sidebarItems.activities"),
        href: "/settings/activities",
        hidden: !ffActivityTemplates,
      },
      {
        type: "link",
        name: t("sidebarItems.billing"),
        href: "/settings/billing",
        hidden: !isTenantAdmin,
      },
      {
        type: "link",
        name: t("sidebarItems.accessLogs"),
        href: "/settings/access-logs",
        hidden: !ACCESS_LOGS_TENANT_IDS.split(",").includes(
          viewer?.tenantAccess.id,
        ),
      },
    ];

    return { navItems1, navItems2, settingsNavItems };
  }, [viewer, t, handleNotificationCTA, ffActivityTemplates, ff_messaging]);

  return (
    <div className="flex grow flex-col overflow-y-auto bg-white shadow-[0px_0px_6px_0px_rgba(0,0,0,0.10)]">
      <div className="shrink-0 px-4 py-5 border-b border-greyscale-200">
        <Link to="/">
          <DalaWordmark className="h-4 w-auto text-primary-600" />
        </Link>
      </div>
      <div className="pt-2 pb-1 px-2">
        <TenantSwitcher />
      </div>
      <nav className="flex flex-1 flex-col">
        {!secondaryMenuOpen ? (
          <ul key="main-nav" className="flex flex-1 flex-col divide-y">
            <li>
              <ItemGroup items={navItems1} />
            </li>
            <li>
              <ItemGroup items={navItems2} />
            </li>
          </ul>
        ) : (
          <>
            <Link
              to={storedRoute}
              className="mt-4 px-4 py-1.5 flex flex-row gap-4"
            >
              <ChevronLeft className="text-greyscale-900" />
              <Label size="xs" className="cursor-pointer">
                {t("tenantSwitcher.workspaceSettings")}
              </Label>
            </Link>
            <ul key="settings-nav" className="flex flex-1 flex-col divide-y">
              <li>
                <ItemGroup items={settingsNavItems} />
              </li>
            </ul>
          </>
        )}
        <div className="sticky bottom-0 z-10 border-t">
          <ProfileMenu />
        </div>
      </nav>
    </div>
  );
};

export const SidebarMenu = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (sidebarOpen) {
      document.body.classList.add("scroll-lock");
    } else {
      document.body.classList.remove("scroll-lock");
    }

    return () => {
      document.body.classList.remove("scroll-lock");
    };
  }, [sidebarOpen]);

  return (
    <>
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-50 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed h-screen inset-0 bg-greyscale-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex h-screen">
          <DialogPanel
            transition
            className="relative flex w-full h-screen max-w-xs flex-1 flex-col bg-white transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <div className="sticky top-0 z-40 mt-3 pt-5 pb-4 px-4 ">
              <Button
                variant="secondary"
                size="md"
                onClick={() => setSidebarOpen(false)}
                icon={Hamburger}
                iconPosition="only"
              />
            </div>

            <SidebarItems />
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-56 lg:flex-col">
        <SidebarItems />
      </div>

      <div className="sticky flex flex-row gap-2 top-0 z-10 mt-3 pt-5 pb-4 px-4 lg:hidden">
        <Button
          variant="secondary"
          size="md"
          onClick={() => setSidebarOpen(true)}
          icon={Hamburger}
          iconPosition="only"
        />
        <Breadcrumbs />
      </div>
    </>
  );
};
