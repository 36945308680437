import { DateTime } from "luxon";
import { useTranslate, UseTranslateResult } from "@tolgee/react";
import { match } from "ts-pattern";
import { EventInput } from "@fullcalendar/core";
import { ResourceInput } from "@fullcalendar/resource";
import {
  CareGiversQuery,
  useCareGiversQuery,
  useScheduleTimelineQuery,
  Frequency,
  Weekday,
  useCareRecipientsForSchedulePageQuery,
  CareRecipientsForSchedulePageQuery,
  useLabelsByOfficeIdsQuery,
} from "../../api/generated/graphql";
import {
  getAbsenceColor,
  getVisitColor,
  getVisitState,
} from "../../utils/visitUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSortingOptions } from "../../utils/hooks/useSortingOptions";
import { ListVisit } from "../../components/common/schedule-list/types";

type QueryVisit = {
  __typename: "Visit";
  id: string;
  start: string;
  durationMinutes: number;
  careRecipientId: string;
  visitorIds: string[];
  clockInTime: string | null;
  clockOutTime: string | null;
  cancelledAt: string | null;
  labelIds: string[];
  visitNoteSentiment: {
    mixed: number;
    negative: number;
    neutral: number;
    positive: number;
  } | null;
  officeId: string;
  recurrence: {
    __typename?: "Recurrence";
    rRule: {
      __typename?: "RRule";
      frequency: Frequency;
      interval: number;
      weekdays: Array<Weekday> | null;
    } | null;
  } | null;
};

type QueryAbsence = {
  __typename: "Absence";
  id: string;
  userId: string;
  allDay: boolean;
  start: string;
  end: string;
};

type QueryEvent = QueryVisit | QueryAbsence;

type NameOrderFn = ({
  firstName,
  lastName,
}: {
  firstName: string | null;
  lastName: string | null;
}) => string;

export type FilterTabs = "care-recipients" | "caregivers" | "labels";
export type AvailableFilters = "scheduled" | "none" | "open" | "late";
export type CalendarTabs = "timeline" | "list";

const eventsResponseToFullcalEvents = (
  events: QueryEvent[],
  tab: FilterTabs,
  getVisitTitle: (visit: QueryVisit) => string,
  t: UseTranslateResult["t"],
): EventInput[] => {
  return events.map((event) => {
    return match<QueryEvent, EventInput>(event)
      .with({ __typename: "Visit" }, (visit) => {
        const start = DateTime.fromISO(visit.start) ?? "";
        const end =
          start.plus({ minutes: visit.durationMinutes }).toISO() ?? "";

        const visitColor = getVisitColor(visit);
        const visitEvent = {
          id: visit.id,
          title: getVisitTitle(visit),
          start: visit.start,
          end: end,
          labels: visit.labelIds,
          color: visitColor.background,
          textColor: visitColor.text,
          eventType: "visit",
        };
        if (tab === "care-recipients") {
          return {
            ...visitEvent,
            resourceId: visit.careRecipientId,
          };
        } else if (tab === "labels") {
          return {
            ...visitEvent,
            resourceIds: visit.labelIds,
          };
        } else {
          return {
            ...visitEvent,
            resourceIds: visit.visitorIds,
          };
        }
      })
      .with({ __typename: "Absence" }, (absence) => {
        const start = absence.allDay
          ? DateTime.fromISO(absence.start).startOf("day").toISO() ?? ""
          : absence.start;

        const end = absence.allDay
          ? DateTime.fromISO(absence.end).endOf("day").toISO() ?? ""
          : absence.end;

        const color = getAbsenceColor();
        return {
          id: absence.id,
          start: start,
          end: end,
          resourceId: absence.userId,
          // allDay: absence.allDay,
          backgroundColor: color.background,
          text: color.text,
          title: t("absences.absent").toString(),
          display: "background",
          eventType: "absence",
        };
      })
      .exhaustive();
  }, []);
};

const userResponseToFullcalResources = (
  users: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    deactivatedAt: string | null;
  }[],
  nameOrderFn: NameOrderFn,
): ResourceInput[] => {
  return users.reduce<ResourceInput[]>((result, user) => {
    if (user.deactivatedAt) {
      return result;
    }
    return [
      ...result,
      {
        id: user.id,
        title: nameOrderFn(user),
      },
    ];
  }, []);
};

const labelsToFullcalResources = (
  labels: {
    id: string;
    name: string;
    inUse: boolean | null;
    officeId: string;
  }[],
  filteredOfficeId: string | null,
): ResourceInput[] => {
  return labels.reduce<ResourceInput[]>((result, label) => {
    if (
      !label.inUse ||
      (filteredOfficeId && label.officeId !== filteredOfficeId)
    ) {
      return result;
    }
    return [
      ...result,
      {
        id: label.id,
        title: label.name,
        officeId: label.officeId,
      },
    ];
  }, []);
};

const filterEventsResponse = (
  events: QueryEvent[] | undefined | null,
  filter: AvailableFilters,
  filteredOfficeId?: string | null,
) => {
  return (
    events?.filter((event) => {
      const correctOffice =
        (event.__typename !== "Absence" &&
          event.officeId === filteredOfficeId) ||
        !filteredOfficeId;

      return (
        correctOffice &&
        match({ filter, event })
          .with({ filter: "scheduled" }, { filter: "none" }, () => true)
          .with(
            { filter: "open", event: { __typename: "Visit" } },
            ({ event }) => getVisitState(event) === "open",
          )
          .with({ filter: "open" }, () => false)
          .with(
            { filter: "late", event: { __typename: "Visit" } },
            ({ event }) => {
              const state = getVisitState(event);
              return state === "late-clockout" || state === "late-clockin";
            },
          )
          .with({ filter: "late" }, () => false)
          .exhaustive()
      );
    }) ?? []
  );
};

export const useGetTimelineData = (
  tab: FilterTabs,
  dateRange: { from: DateTime; to: DateTime } | null,
  filter: AvailableFilters,
  officeIds: string[],
  filteredOfficeId: string | null,
  filteredLabelId: string | null,
) => {
  const { t } = useTranslate();
  const { nameOrderFn } = useSortingOptions();

  const [filteredResources, setFilteredResources] = useState<ResourceInput[]>(
    [],
  );

  const { data: eventsData, ...eventsResult } = useScheduleTimelineQuery({
    fetchPolicy: "cache-and-network",
    skip:
      (!dateRange && officeIds.length === 0) ||
      (dateRange ? dateRange.from > dateRange.to : false),
    variables: {
      from: dateRange?.from.toISO() ?? "",
      to: dateRange?.to.toISO() ?? "",
      officeIds: officeIds,
    },
  });

  const { data: careRecipientsData, ...careRecipientResult } =
    useCareRecipientsForSchedulePageQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        filter: { officeIds },
      },
    });

  const { data: caregiversData, ...caregiverResult } = useCareGiversQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: { officeIds },
    },
  });

  const { data: labelData, ...labelResult } = useLabelsByOfficeIdsQuery({
    fetchPolicy: "cache-and-network",
    variables: { officeIds: officeIds },
  });

  const careRecipientsMap: Map<
    string,
    CareRecipientsForSchedulePageQuery["careRecipients"][0]
  > = useMemo(() => {
    const m = new Map<
      string,
      CareRecipientsForSchedulePageQuery["careRecipients"][0]
    >();
    careRecipientsData?.careRecipients.forEach((cr) => m.set(cr.id, cr));
    return m;
  }, [careRecipientsData]);

  const caregiversMap: Map<string, CareGiversQuery["careGivers"][0]> =
    useMemo(() => {
      const m = new Map<string, CareGiversQuery["careGivers"][0]>();
      caregiversData?.careGivers.forEach((cg) => m.set(cg.id, cg));
      return m;
    }, [caregiversData]);

  const getVisitTitle = useCallback(
    (visit: QueryVisit): string => {
      if (tab === "care-recipients") {
        const visitor = visit.visitorIds?.length
          ? caregiversMap.get(visit.visitorIds[0])
          : undefined;
        return visitor ? nameOrderFn(visitor) : t("schedule.open");
      }
      if (tab === "caregivers" || tab === "labels") {
        const careRecipient = careRecipientsMap.get(visit.careRecipientId);
        return careRecipient
          ? nameOrderFn(careRecipient)
          : t("schedule.deleted");
      }
      return "";
    },
    [tab, nameOrderFn, t, careRecipientsMap, caregiversMap],
  );

  const error =
    careRecipientResult.error ||
    caregiverResult.error ||
    eventsResult.error ||
    labelResult.error;
  const resourcesLoading =
    careRecipientResult.loading ||
    careRecipientResult.loading ||
    labelResult.loading;
  const eventsLoading = eventsResult.loading;

  const [resources, events] = useMemo(() => {
    const resources = match<FilterTabs, ResourceInput[]>(tab)
      .with("care-recipients", () =>
        userResponseToFullcalResources(
          careRecipientsData?.careRecipients ?? [],
          nameOrderFn,
        ),
      )
      .with("caregivers", () =>
        userResponseToFullcalResources(
          caregiversData?.careGivers ?? [],
          nameOrderFn,
        ),
      )
      .with("labels", () =>
        labelsToFullcalResources(
          labelData?.labelsByOfficeIds ?? [],
          filteredOfficeId,
        ),
      )
      .exhaustive();

    const filteredEventsResponse = filterEventsResponse(
      eventsData?.eventsByOfficeIds,
      filter,
      filteredOfficeId,
    );

    const fullcalEvents = eventsResponseToFullcalEvents(
      filteredEventsResponse,
      tab,
      getVisitTitle,
      t,
    );

    return [resources, fullcalEvents];
  }, [
    tab,
    eventsData,
    filter,
    getVisitTitle,
    t,
    careRecipientsData,
    nameOrderFn,
    caregiversData,
    labelData,
    filteredOfficeId,
  ]);

  const listVisits = useMemo(() => {
    if (!eventsData) return [];
    const filteredEvents = filterEventsResponse(
      eventsData.eventsByOfficeIds,
      filter,
      filteredOfficeId,
    );
    return filteredEvents.reduce<ListVisit[]>((acc, curr) => {
      if (curr.__typename === "Visit") {
        const careRecipient = careRecipientsMap.get(curr.careRecipientId);
        if (!careRecipient) {
          return acc;
        }

        const currentDtStart = DateTime.fromISO(curr.start);

        // Listview should not include visits that start before selected period or start exactly on the to value
        if (
          dateRange &&
          (currentDtStart < dateRange.from || currentDtStart === dateRange.to)
        ) {
          return acc;
        }

        // Filter out visits that do not have the selected label
        if (filteredLabelId && !curr.labelIds.includes(filteredLabelId)) {
          return acc;
        }

        const visitors = curr.visitorIds
          .map((visitorId) => caregiversMap.get(visitorId))
          .filter(
            (visitor): visitor is CareGiversQuery["careGivers"][0] => !!visitor,
          );

        const selectedLabels =
          labelData?.labelsByOfficeIds.filter(
            (label) => curr.labelIds.includes(label.id) && label.inUse === true,
          ) ?? [];

        const newVisit: ListVisit = {
          id: curr.id,
          start: curr.start,
          durationMinutes: curr.durationMinutes,
          careRecipient: careRecipient,
          clockInTime: curr.clockInTime,
          clockOutTime: curr.clockOutTime,
          visitors: visitors,
          visitNoteSentiment: curr.visitNoteSentiment,
          visitorIds: visitors.map((visitor) => visitor.id),
          labels: selectedLabels,
          officeId: curr.officeId,
          cancelledAt: curr.cancelledAt,
          recurrence: curr.recurrence,
        };
        return [...acc, newVisit];
      }
      return acc;
    }, []);
  }, [
    eventsData,
    filter,
    filteredOfficeId,
    filteredLabelId,
    careRecipientsMap,
    dateRange,
    labelData?.labelsByOfficeIds,
    caregiversMap,
  ]);

  // Update resources based on which resource has events and currenctly selected filter
  // Skip if events are still loading to prevent flickering
  // Rerun when selected office changes
  useEffect(() => {
    if (eventsLoading || resourcesLoading) {
      return;
    }

    const resourceHasEvents = (resourceId: string | undefined): boolean =>
      !!resourceId &&
      events.some(
        (event) =>
          event.resourceId === resourceId ||
          event.resourceIds?.includes(resourceId),
      );

    // if there are no events and filters are active, we don't want to show empty resources
    if (filter !== "none") {
      const res = resources.filter((resource) => {
        return resourceHasEvents(resource.id);
      });
      return setFilteredResources(res);
    }

    // if filter is none only show resources with no events
    if (filter === "none") {
      const res = resources.filter((resource) => {
        return !resourceHasEvents(resource.id);
      });
      return setFilteredResources(res);
    }
  }, [resources, filter, events, eventsLoading, resourcesLoading]);

  const sortedListVisits = listVisits.sort(
    (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
  );

  const counter = {
    open:
      eventsData?.eventsByOfficeIds.filter(
        (e) =>
          e.__typename === "Visit" &&
          getVisitState(e) === "open" &&
          (!filteredOfficeId || filteredOfficeId === e.officeId),
      ).length ?? 0,
    late:
      eventsData?.eventsByOfficeIds.filter(
        (e) =>
          e.__typename === "Visit" &&
          getVisitState(e) === "late-clockin" &&
          (!filteredOfficeId || filteredOfficeId === e.officeId),
      ).length ?? 0,
    openLabels:
      eventsData?.eventsByOfficeIds.filter(
        (e) =>
          e.__typename === "Visit" &&
          getVisitState(e) === "open" &&
          e.labelIds.length &&
          (!filteredOfficeId || filteredOfficeId === e.officeId),
      ).length ?? 0,
    lateLabels:
      eventsData?.eventsByOfficeIds.filter(
        (e) =>
          e.__typename === "Visit" &&
          getVisitState(e) === "late-clockin" &&
          e.labelIds.length &&
          (!filteredOfficeId || filteredOfficeId === e.officeId),
      ).length ?? 0,
  };

  return {
    events,
    counter,
    sortedListVisits,
    resources: filteredResources,
    error,
    resourcesLoading,
    eventsLoading: eventsLoading || resourcesLoading,
    labels: labelData?.labelsByOfficeIds.filter((label) => label.inUse),
  };
};
