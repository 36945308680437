import { useEffect, useState } from "react";
import { useCareContext } from "../../providers";
import { usePrivateChannelCreateMutation } from "../../api/generated/graphql";
import { useNavigate } from "react-router";
import { Button } from "@frontend/lyng/button";
import { ChannelList, useChatContext } from "stream-chat-react";
import {
  ChatterList,
  CommsChannelList,
  CommsChannelPreview,
} from "@frontend/lyng/comms";
import { LoadingBounce } from "@frontend/lyng/loading";
import { Plus } from "@frontend/lyng/assets/icons/24/outline";
import { Chatter } from "@frontend/lyng/comms/ChatterList";
import classNames from "classnames";
import { useTranslate } from "@tolgee/react";

type ChatListProps = {
  modal?: boolean;
};

export const ChatList = ({ modal = true }: ChatListProps) => {
  const {
    state: { viewer },
  } = useCareContext();
  const { t } = useTranslate();

  const [show, setShow] = useState(false);
  const { channel, setActiveChannel } = useChatContext();
  const [privateChannelCreateMutation] = usePrivateChannelCreateMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!channel) return;

    navigate(`/chat/${channel.id}`);
    setActiveChannel(undefined);
  }, [channel, navigate, setActiveChannel]);

  if (!viewer) {
    return (
      <div className="flex justify-center dark:bg-greyscale-900 m-4">
        <LoadingBounce size="m" />
      </div>
    );
  }

  const onChatterClick = async (chatter: Chatter) => {
    const { data } = await privateChannelCreateMutation({
      variables: { input: { member: chatter.id } },
    });

    if (data?.privateChannelCreate) {
      navigate(`/chat/${data.privateChannelCreate.id}`);
    }
  };

  const filters = { members: { $in: [viewer?.id] }, type: "messaging" };
  const options = { presence: true, state: true };

  return (
    <>
      <div
        className={classNames({
          "absolute top-0 right-6 mt-4 z-20": modal,
          "flex flex-auto w-full justify-end p-6 sticky top-0 bg-primary-0 border-b":
            !modal,
        })}
      >
        {!modal && (
          <div className="flex-grow flex sticky top-0">
            <h1 className="text-2xl font-bold">{t("comms.title")}</h1>
          </div>
        )}
        <Button
          variant="primary"
          size="sm"
          className="rounded-2xl"
          onClick={() => setShow(true)}
          icon={Plus}
          iconPosition="only"
        />
      </div>
      <ChannelList
        List={CommsChannelList}
        Preview={CommsChannelPreview}
        filters={filters}
        options={options}
        setActiveChannelOnMount={false}
      />
      <ChatterList
        show={show}
        setShow={setShow}
        onChatterClick={onChatterClick}
      />
    </>
  );
};
