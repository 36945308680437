import { useCallback, useMemo } from "react";
import {
  useActivitiesTemplatesQuery,
  ActivitiesTemplatesQuery,
  ActivityGroupsQuery,
  useActivityGroupsQuery,
} from "../../../api/generated/graphql";
import { Table } from "../../../components/common";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslate } from "@tolgee/react";
import {
  Headline,
  HeadlineContainer,
  Paragraph,
} from "@frontend/lyng/typography";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@frontend/lyng/button";
import classNames from "classnames";
import { Plus } from "@frontend/lyng/assets/icons/24/outline";
import { ActivitiesGroupCard } from "./ActivitiesGroupCard";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { stringToCamelCaseString } from "../../../utils/formUtils";
import Empty from "@frontend/lyng/assets/svg/empty";

type ActivityTemplatesRow =
  ActivitiesTemplatesQuery["activityTemplates"][number];

type ActivityGroupRow = ActivityGroupsQuery["activityGroups"][number];

export const Activities = () => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { collator } = useSortingOptions();
  const { activityGroupId } = useParams();

  const selectedGroupId = activityGroupId ? activityGroupId : "all";

  const {
    data: templates,
    loading: templatesLoading,
    error: templateError,
  } = useActivitiesTemplatesQuery();
  const { data: activityGroups } = useActivityGroupsQuery();

  const activityGroupsWithAll = useMemo(() => {
    const allGroup: ActivityGroupRow = {
      id: "all",
      title: t("activityTemplates.all"),
      activityTemplates: templates?.activityTemplates ?? [],
      __typename: "ActivityGroup",
    };

    const groups = activityGroups?.activityGroups ?? [];
    return [allGroup, ...groups];
  }, [activityGroups?.activityGroups, templates?.activityTemplates, t]);

  const openGroupModalForm = useCallback(
    (activityGroup?: Partial<ActivityGroupRow> | undefined) => {
      if (activityGroup) {
        navigate(`./groups/${activityGroup.id}`, {
          preventScrollReset: true,
        });
      } else {
        navigate("./groups/new", {
          preventScrollReset: true,
        });
      }
    },
    [navigate],
  );

  const openActivityTemplateSlideOver = useCallback(
    (
      activityTemplate?: Partial<ActivityTemplatesRow>,
      activityGroupId?: string,
    ) => {
      if (activityTemplate) {
        navigate(`./templates/${activityTemplate.id}`, {
          preventScrollReset: true,
        });
      } else {
        navigate("./templates/new", {
          preventScrollReset: true,
          state: { groupId: activityGroupId },
        });
      }
    },
    [navigate],
  );

  const selectedGroup = useMemo(() => {
    return activityGroupsWithAll.find((group) => group.id === selectedGroupId);
  }, [activityGroupsWithAll, selectedGroupId]);

  const columns = useMemo<ColumnDef<ActivityTemplatesRow>[]>(() => {
    return [
      {
        id: "title",
        header: t("title") ?? "",
        accessorFn: (row) => row.title,
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "type",
        header: t("type") ?? "",
        accessorFn: (row) =>
          row?.activityType?.custom
            ? row.activityType.title
            : t(
                `activityType.${stringToCamelCaseString(row.activityType?.title ?? "")}`,
              ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "division",
        header: t("division") ?? "",
        cell: (row) => {
          const units = row.row.original.organizationUnits
            .filter((unit) => unit.__typename === "Division")
            .map((unit) => unit.name);

          return (
            <ul>
              {units.map((unit, index) => (
                <li key={`${index}-${row.row.id}`}>{unit}</li>
              ))}
            </ul>
          );
        },
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "region",
        header: t("region") ?? "",
        cell: (row) => {
          const units = row.row.original.organizationUnits
            .filter((unit) => unit.__typename === "Region")
            .map((unit) => unit.name);

          return (
            <ul>
              {units.map((unit, index) => (
                <li key={`${index}-${row.row.id}`}>{unit}</li>
              ))}
            </ul>
          );
        },
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "office",
        header: t("office") ?? "",
        cell: (row) => {
          const units = row.row.original.organizationUnits
            .filter((unit) => unit.__typename === "Office")
            .map((unit) => unit.name);

          return (
            <ul>
              {units.map((unit, index) => (
                <li key={`${index}-${row.row.id}`}>{unit}</li>
              ))}
            </ul>
          );
        },
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "edit",
        header: "",
        accessorFn: (row) => row.id,
        enableSorting: false,
        cell: (row) => (
          <div className="flex justify-end">
            <Button
              className="ml-auto"
              type="button"
              variant="tertiary"
              text={t("edit")}
              onClick={() => openActivityTemplateSlideOver(row.row.original)}
            />
          </div>
        ),
      },
    ];
  }, [collator, openActivityTemplateSlideOver, t]);

  if (templateError) {
    return <p>Error: {templateError.message}</p>;
  }

  return (
    <div className={classNames("p-5 md:p-0", "h-full")}>
      <HeadlineContainer>
        <Headline className="pt-2 pb-2" size="l">
          {t("activityTemplates.title")}
        </Headline>
        {selectedGroup?.activityTemplates.length !== 0 && (
          <Button
            className="ml-auto"
            variant="primary"
            text={t("activityTemplates.addTemplate")}
            onClick={() => openActivityTemplateSlideOver()}
          />
        )}
      </HeadlineContainer>
      <div className="flex">
        <div className="flex flex-col mr-4">
          <ActivitiesGroupCard
            activityGroups={activityGroupsWithAll}
            onEditGroup={openGroupModalForm}
            selectedGroupId={selectedGroupId ?? ""}
          />
          <Button
            className=" self-start mt-4"
            iconPosition="left"
            icon={Plus}
            text={t("activityTemplates.addGroup")}
            variant="tertiary"
            onClick={() => openGroupModalForm()}
          />
        </div>
        <div className="flex flex-1 flex-col">
          {selectedGroup?.activityTemplates.length !== 0 ? (
            <Table
              loading={templatesLoading}
              data={selectedGroup?.activityTemplates ?? []}
              columns={columns}
              topAlign={true}
              defaultSorting={[{ id: "title", desc: false }]}
            />
          ) : (
            <div className="flex flex-col items-center gap-10 mt-14">
              <Empty.F />
              <Paragraph size="m">
                {t("activityTemplates.noActivityTemplates")}
              </Paragraph>
              <Button
                variant="primary"
                text={t("activityTemplates.addTemplate")}
                onClick={() =>
                  openActivityTemplateSlideOver(undefined, selectedGroupId)
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
