import { useVisitTypeCreateMutation } from "../../../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { FormOutput, VisitTypeForm } from "./visitTypeForm";
import { toast } from "react-hot-toast";
import { errorToToastMessage } from "../../../../utils/toastUtils";
import { SlideOver } from "@frontend/lyng/slideOver";

export const NewVisitTypeSlideOver = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [visitTypeCreate] = useVisitTypeCreateMutation();

  const handleSubmit = (values: FormOutput) => {
    const promise = visitTypeCreate({
      variables: {
        input: {
          title: values.title,
          code: values.code,
          billingType: values.billingType,
          baseRate: values.baseRate,
          basePeriodStart: values.basePeriodStart,

          eveningRate: values.eveningPeriod?.rate,
          eveningPeriodStart: values.eveningPeriod?.start,

          nightRate: values.nightPeriod?.rate,
          nightPeriodStart: values.nightPeriod?.start,
        },
      },
      refetchQueries: ["VisitTypes"],
    });

    return toast.promise(promise, {
      loading: t("visitTypes.creatingVisitType"),
      success: () => t("visitTypes.visitTypeCreated"),
      error: errorToToastMessage,
    });
  };

  return (
    <SlideOver.WithIntercomHider onClose={() => navigate("..")} show={true}>
      <SlideOver.Title>{t("visitTypes.visitType").toString()}</SlideOver.Title>
      <SlideOver.DetailSection>
        <VisitTypeForm
          onSubmit={(values) => handleSubmit(values).then(() => navigate(".."))}
        />
      </SlideOver.DetailSection>
    </SlideOver.WithIntercomHider>
  );
};
