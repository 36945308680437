import { useEffect, useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EventClickArg } from "@fullcalendar/core";
import { ScheduleTimeline } from "../../components/common/schedule-timeline/ScheduleTimeline";
import {
  useGetTimelineData,
  AvailableFilters,
  CalendarTabs,
  FilterTabs,
} from "./hooks";
import { useCareContext } from "../../providers/CareProvider";
import { match, P } from "ts-pattern";
import { Empty } from "@frontend/lyng/assets/svg";
import { ScheduleList } from "../../components/common/schedule-list/ScheduleList";
import { DateTime } from "luxon";
import { Label } from "@frontend/lyng/typography/label/Label";
import { NumberPill } from "@frontend/lyng/pill/NumberPill";
import classNames from "classnames";
import { StickyFooter } from "../../components/common/stickyFooter/StickyFooter";
import { Button } from "@frontend/lyng/button/Button";
import { OfficeFilter } from "../../components/common/officeFilter/OfficeFilter";
import { ListBox } from "@frontend/lyng/forms/listBox/ListBox";
import { CaregiverAvailabilityBulkSelect } from "./CaregiverAvailabilityBulkSelect";
import { Paragraph } from "@frontend/lyng/typography";
import { useSortingOptions } from "../../utils/hooks/useSortingOptions";
import { AvailabilityForLabel } from "../../api/generated/graphql";
import { BulkUpdateModal } from "./BulkUpdateModal";
import { CareGiverAvailability } from "./types";
import { DateRangePicker } from "@frontend/lyng/forms";
import { useIntercom } from "react-use-intercom";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import { Tabs } from "@frontend/lyng/tabs/Tabs";

type Range = {
  start: DateTime;
  end: DateTime;
};

const TimelineExplanation = () => {
  const { t } = useTranslate();
  const square = (color: string) => {
    return <div className={classNames(color, "rounded w-4 h-4 flex")} />;
  };
  return (
    <div className="hidden sm:flex gap-2">
      {square("bg-secondary-300")}
      <Label size="xxs">{t("filters.happeningNow")}</Label>
      {square("bg-primary-200")}
      <Label size="xxs">{t("filters.scheduled")}</Label>
      {square("bg-critical-400")}
      <Label size="xxs">{t("filters.late")}</Label>
      {square("bg-accent-400")}
      <Label size="xxs">{t("filters.open")}</Label>
    </div>
  );
};

export const SchedulePage = () => {
  const {
    state: { selectedTenant },
  } = useCareContext();
  const { t } = useTranslate();
  const intercom = useIntercom();
  const navigate = useNavigate();
  const { tab } = useParams();
  const { collator } = useSortingOptions();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    state: { viewer },
  } = useCareContext();
  const timezone = viewer?.tenantSettings.timezone ?? "UTC";

  const from = searchParams.has("from") ? searchParams.get("from") : null;
  const to = searchParams.has("to") ? searchParams.get("to") : null;

  const [dateRange, setDateRange] = useState<{ from: DateTime; to: DateTime }>(
    () => {
      return {
        from: from ? DateTime.fromISO(from) : DateTime.now().startOf("day"),
        to: to ? DateTime.fromISO(to) : DateTime.now().endOf("day"),
      };
    },
  );

  // Update URL with new date dateRange.
  // This is done in a useEffect because fullcalendar can update this while the page is rendering
  useEffect(() => {
    if (
      dateRange &&
      (!from || dateRange.from !== DateTime.fromISO(from)) &&
      (!to || dateRange.to !== DateTime.fromISO(to))
    ) {
      setSearchParams(
        (prev) => ({
          ...Object.fromEntries(prev.entries()),
          from:
            dateRange.from.toISODate({
              format: "basic",
            }) ?? "",
          to:
            dateRange.to.toISODate({
              format: "basic",
            }) ?? "",
        }),
        { preventScrollReset: true },
      );
    }
  }, [dateRange, setSearchParams, from, to]);

  const filter = searchParams.has("filter") ? searchParams.get("filter") : null;
  const highlight = searchParams.has("highlight")
    ? searchParams.get("highlight")
    : null;

  const calendarView: CalendarTabs | null = match<
    string | null,
    CalendarTabs | null
  >(searchParams.get("view"))
    .with("calendar", () => "timeline")
    .with("list", () => "list")
    .otherwise(() => null);
  const isListView = calendarView === "list";

  const tabView: FilterTabs = tab === null ? "caregivers" : (tab as FilterTabs);
  const tabFilter: AvailableFilters =
    filter === null ? "scheduled" : (filter as AvailableFilters);

  const officeIds = useMemo(() => {
    return viewer?.tenantAccess.offices.map((office) => office.id) ?? [];
  }, [viewer]);

  // Used in office filter listbox
  const [selectedOfficeId, setSelectedOfficeId] = useLocalStorage<
    string | null
  >(`office-filter-${selectedTenant?.id}`, null);

  const [selectedCaregiver, setSelectedCaregiver] =
    useState<CareGiverAvailability | null>(null);
  // Used in label filter listbox
  const allLabelsOption = { id: "all", name: t("allLabels"), officeId: null };
  const [selectedLabel, setSelectedLabel] = useState<{
    id: string;
    name: string;
    officeId: string | null;
  } | null>(null);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);

  const {
    resources,
    events,
    counter,
    sortedListVisits,
    error,
    resourcesLoading,
    eventsLoading,
    labels,
  } = useGetTimelineData(
    tabView,
    dateRange,
    tabFilter,
    officeIds,
    selectedOfficeId,
    selectedLabel?.id ?? null,
  );

  const futureVisitsCount = useMemo(() => {
    const now = DateTime.now().toISO();
    return sortedListVisits.filter((visit) => visit.start > now).length;
  }, [sortedListVisits]);

  // Hide intercom launcher when bulk update footer is shown
  useEffect(() => {
    if (!!selectedLabel && futureVisitsCount > 0 && isListView) {
      intercom.update({ hideDefaultLauncher: true });
    } else {
      intercom.update({ hideDefaultLauncher: false });
    }
  }, [futureVisitsCount, intercom, isListView, selectedLabel]);

  const getVisitLink = (visitId: string) => {
    return genURL(`schedule/${tab}/visits/${visitId}`, tabFilter, calendarView);
  };

  if (error) {
    return <p>Error : {error.message}</p>;
  }

  const genURL = (
    base: string,
    filter: string,
    calendarView: string | null,
  ) => {
    let url = `/${base}?filter=${filter}`;
    if (calendarView) {
      url += `&view=${calendarView}`;
    }

    url += `&from=${from}&to=${to}`;

    return url;
  };

  const getFilteredLabels = (
    labels: {
      id: string;
      name: string;
      inUse: boolean | null;
      officeId: string;
    }[],
    filteredOfficeId: string | null,
  ) => {
    return labels.filter(
      (label) =>
        label.inUse &&
        (!filteredOfficeId || label.officeId === filteredOfficeId),
    );
  };

  const handleEventClick = (e: EventClickArg) => {
    const eventId = e.event.id;
    if (e.event.extendedProps.eventType === "visit") {
      // push current url to history
      navigate(
        genURL(`schedule/${tab}/visits/${eventId}`, tabFilter, calendarView),
        { preventScrollReset: true },
      );
    }
    if (e.event.extendedProps.eventType === "absence") {
      navigate(
        genURL(`schedule/${tab}/absence/${eventId}`, tabFilter, calendarView),
        { preventScrollReset: true },
      );
    }
  };

  const tabFilters = [
    {
      id: "scheduled",
      label: t("filters.scheduled"),
      emptyStateLabel: t("filtersEmptyState.scheduled"),
      emptyLabelState: t("labelEmptyState.scheduled"),
    },
    ...(tabView !== "caregivers" || isListView
      ? [
          {
            id: "open",
            label: t("filters.open"),
            emptyStateLabel: t("filtersEmptyState.open"),
            emptyLabelState: t("labelEmptyState.open"),
            icon: () => (
              <NumberPill
                count={
                  tabView === "labels" && !isListView
                    ? counter.openLabels
                    : counter.open
                }
                color="yellow"
              />
            ),
          },
        ]
      : []),
    ...(!isListView
      ? [
          {
            id: "late",
            label: t("filters.late"),
            emptyStateLabel: t("filtersEmptyState.late"),
            icon: () => (
              <NumberPill
                count={tabView === "labels" ? counter.lateLabels : counter.late}
                color="red"
              />
            ),
          },
          {
            id: "none",
            label: t("filters.none"),
            emptyStateLabel: t("filtersEmptyState.none"),
          },
        ]
      : []),
  ];

  const calendarFilters = [
    {
      id: "timeline",
      label: t("schedule.timeline"),
    },
    {
      id: "list",
      label: t("schedule.list"),
    },
  ];

  const timelineFilters =
    viewer?.tenantSettings.enableLabels && labels?.length
      ? [
          {
            id: "care-recipients",
            label: t("schedule.careRecipients"),
          },
          {
            id: "caregivers",
            label: t("schedule.caregivers"),
          },
          {
            id: "labels",
            label: t("labels.labels"),
          },
        ]
      : [
          {
            id: "care-recipients",
            label: t("schedule.careRecipients"),
          },
          {
            id: "caregivers",
            label: t("schedule.caregivers"),
          },
        ];

  const handleTabClick = (tab: string) => {
    const newTabFilter = match([tab, tabFilter])
      .with(["caregivers", "open"], () => "scheduled")
      .otherwise(() => tabFilter);
    navigate(genURL(`schedule/${tab}`, newTabFilter, calendarView), {
      replace: true,
    });
  };

  const handleCalendarViewChange = (calendarView: string) => {
    const newTabFilter = match({ calendarView: calendarView, tabFilter, tab })
      .with({ calendarView: "list", tabFilter: "late" }, () => "scheduled")
      .with({ calendarView: "list", tabFilter: "none" }, () => "scheduled")
      .with(
        { calendarView: "calendar", tabFilter: "open", tab: "caregivers" },
        () => "scheduled",
      )
      .otherwise(() => tabFilter);
    navigate(genURL(`schedule/${tab}`, newTabFilter, calendarView), {
      replace: true,
    });
  };

  const dateRangePickerValue = {
    start: dateRange.from,
    end: dateRange.to.minus({ second: 1 }),
  };

  const handleDateRangePicker = (value: Range | undefined) => {
    if (value && value.start.isValid && value.end.isValid) {
      setDateRange({
        from: value.start,
        to: value.end.endOf("day"),
      });
    }
  };

  const getLabelCount = () => {
    // Count of labels for event in currently selected date range
    const totalLabels = labels?.length ?? 0;
    const labelCount = sortedListVisits.filter(
      (visit) => visit.labels.length !== 0,
    ).length;
    return match(labelCount)
      .with(0, () => "none")
      .with(totalLabels, () => "all")
      .otherwise(() => "some");
  };

  const getResources = () => {
    const labelCount = getLabelCount();
    return match([tabView, labelCount, tabFilter])
      .with(["labels", "none", P.not("none")], () => [])
      .with(["labels", "all", "none"], () => [])
      .otherwise(() => resources);
  };

  const shouldShowEmptyState = () => {
    const labelCount = getLabelCount();
    const noListVisits = sortedListVisits?.length === 0;
    const noEvents = events.length === 0;

    return match({
      tabFilter,
      isListView,
      tabView,
      labelCount,
      noListVisits,
      eventsLoading,
      noEvents,
    })
      .with({ eventsLoading: false, noEvents: true }, () => true)
      .with(
        { tabView: "labels", labelCount: "none", tabFilter: P.not("none") },
        () => true,
      )
      .with(
        { eventsLoading: false, isListView: true, noListVisits: true },
        () => true,
      )
      .with(
        { filteredLabels: true, labels: true, noListVisits: true },
        () => true,
      )
      .with({ tabFilter: "none", labelCount: "all" }, () => true)
      .otherwise(() => false);
  };

  const filteredLabels = getFilteredLabels(labels ?? [], selectedOfficeId);

  const sortedLabels = [...(filteredLabels ?? [])].sort((a, b) =>
    collator.compare(a.name, b.name),
  );

  const labelOptions = [
    allLabelsOption,
    ...sortedLabels.map((label) => ({
      id: label.id,
      name: label.name,
      officeId: label.officeId,
    })),
  ];

  const dateRangeFromNow = (from: DateTime, to: DateTime) => {
    const now = DateTime.now();

    if (from < now) {
      const nextUpcomingVisit = sortedListVisits.find(
        (visit) => DateTime.fromISO(visit.start) > now && !visit.cancelledAt,
      );

      if (nextUpcomingVisit) {
        return {
          from: DateTime.fromISO(nextUpcomingVisit.start),
          to: dateRange.to,
        };
      }
    }

    if (to < now) {
      return null;
    }
    return dateRange;
  };

  return (
    <>
      <div className="flex items-baseline gap-8 flex-wrap">
        <h1 className="mb-5 text-3xl font-semibold">{t("schedule.title")}</h1>
        {!isListView && <TimelineExplanation />}
      </div>
      <div className="flex gap-2 flex-wrap">
        {officeIds.length > 1 && (
          <OfficeFilter
            selectedOfficeId={selectedOfficeId}
            setSelectedOfficeId={(officeId) => {
              setSelectedOfficeId(officeId);
              setSelectedLabel(null);
            }}
          />
        )}
        {viewer?.tenantSettings.enableLabels && (
          <div className="mb-4 w-fit">
            <Tabs
              tabs={calendarFilters}
              currentTab={calendarView ?? "timeline"}
              onChange={handleCalendarViewChange}
            />
          </div>
        )}
        {!isListView && (
          <div className="mb-4 w-fit">
            <Tabs
              tabs={timelineFilters}
              currentTab={tabView}
              onChange={handleTabClick}
            />
          </div>
        )}

        <div className="mb-4 w-fit">
          <Tabs
            tabs={tabFilters}
            currentTab={tabFilter}
            onChange={(tabFilter) => {
              if (
                tabFilter === "scheduled" ||
                tabFilter === "open" ||
                tabFilter === "late" ||
                tabFilter === "none"
              ) {
                navigate(genURL(`schedule/${tab}`, tabFilter, calendarView), {
                  replace: true,
                });
              }
            }}
          />
        </div>
        {isListView && labels?.length && viewer?.tenantSettings.enableLabels ? (
          <div className="mb-4 w-fit">
            <ListBox
              options={labelOptions}
              value={selectedLabel ?? allLabelsOption}
              getOptionLabel={(label) => label.name}
              getOptionKey={(label) => label.id}
              onChange={(newValue) => {
                setSelectedLabel(newValue?.id === "all" ? null : newValue);
                setSelectedCaregiver(null);
              }}
            />
          </div>
        ) : null}
        {isListView && (
          <div className="mb-4 w-fit">
            <DateRangePicker
              dateSettings={viewer?.tenantSettings}
              aria-label="Date range picker"
              value={dateRangePickerValue}
              onChange={(value) => {
                handleDateRangePicker(value);
              }}
            />
          </div>
        )}
      </div>
      {isListView ? (
        <div>
          <ScheduleList
            listVisits={sortedListVisits}
            loading={eventsLoading}
            visitClick={getVisitLink}
          />
        </div>
      ) : (
        <div className="rounded bg-white px-3 pt-3 shadow sm:rounded-2xl">
          <ScheduleTimeline
            timezone={timezone}
            resources={getResources()}
            events={events}
            datesSet={({ startStr, endStr }) => {
              // Update URL with new date dateRange
              if (startStr !== from || endStr !== to) {
                setDateRange({
                  from: DateTime.fromISO(startStr),
                  to: DateTime.fromISO(endStr),
                });
              }
            }}
            eventClick={handleEventClick}
            resourceLink={
              tabView !== "labels"
                ? (resource) => `/${tabView}/${resource.id}`
                : undefined
            }
            highlight={highlight ?? undefined}
            initialDate={
              dateRange.from.toISO({ suppressMilliseconds: true }) ?? undefined
            }
            resourceLoading={resourcesLoading}
            eventsLoading={eventsLoading}
          />
        </div>
      )}
      {shouldShowEmptyState() &&
        tabFilters
          .filter((tab) => tab.id === tabFilter)
          .map((tab) => (
            <div
              key={tab.id}
              className="flex flex-col justify-center items-center font-semibold text-xl gap-4 h-full"
            >
              <Empty.C1 />
              <Paragraph size="m" className="mt-6">
                {isListView ? tab.emptyLabelState : tab.emptyStateLabel}
              </Paragraph>
              {isListView && (
                <Button
                  onClick={() => {
                    if (!selectedLabel) {
                      setSelectedOfficeId(null);
                    }
                    setSelectedLabel(null);
                  }}
                  text={t("seeAllVisits")}
                  variant="tertiary"
                />
              )}
            </div>
          ))}

      <BulkUpdateModal
        visitAmount={futureVisitsCount}
        show={showBulkUpdateModal}
        noCaregiver={!selectedCaregiver}
        conflict={
          selectedCaregiver?.availability === AvailabilityForLabel.Conflict
        }
        onSave={() => setShowBulkUpdateModal(false)}
        onCancel={() => setShowBulkUpdateModal(false)}
        dateRange={dateRangeFromNow(dateRange?.from ?? "", dateRange?.to ?? "")}
        selectedLabelId={selectedLabel?.id ?? ""}
        selectedCaregiverId={selectedCaregiver?.visitor.id ?? ""}
      />

      <StickyFooter
        show={!!selectedLabel && futureVisitsCount > 0 && isListView}
      >
        {futureVisitsCount > 1
          ? t("schedule.changeCaregiversPlural", {
              number: futureVisitsCount,
            })
          : t("schedule.changeCaregivers")}
        <CaregiverAvailabilityBulkSelect
          onChange={(caregiver) => setSelectedCaregiver(caregiver)}
          value={selectedCaregiver}
          labelId={selectedLabel?.id ?? ""}
          from={dateRange.from}
          to={dateRange.to}
        />
        <Button
          variant="primary"
          text={t("schedule.changeAll")}
          onClick={() => setShowBulkUpdateModal(true)}
        />
      </StickyFooter>
    </>
  );
};
