import { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { UseTranslateResult, useTranslate } from "@tolgee/react";
import { DateTime } from "luxon";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Absence } from "../../api/generated/graphql";
import { Form, Checkbox } from "../../components/formfields";
import {
  AbsenceReasonSelect,
  caregiverAbsenceReasonSchema,
  careRecipientAbsenceReasonSchema,
} from "../../components/formfields";
import {
  getCareRecipientAbsenceReasonOption,
  getCaregiverAbsenceReasonOption,
} from "../../components/formfields/absenceReasonSelect/absenceReasonSelect";
import {
  DatePicker,
  FormGroup,
  TimePicker,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { useCareContext } from "../../providers";
import { Button } from "@frontend/lyng/button/Button";

const getSchema = (isCareGiver: boolean) => {
  return z
    .object({
      start: z.custom<DateTime>((value) => DateTime.isDateTime(value), {
        message: "absences.validation.startDateInvalid",
      }),
      end: z
        .custom<DateTime>((value) => DateTime.isDateTime(value), {
          message: "absences.validation.endDateInvalid",
        })
        .optional(),
      startTime: z.object(timeSchemaShape),
      endTime: z.object(timeSchemaShape),
      allDay: z.boolean(),
      reason: isCareGiver
        ? caregiverAbsenceReasonSchema.nullable()
        : careRecipientAbsenceReasonSchema.nullable(),
    })
    .refine(({ allDay, end }) => !allDay || end, {
      message: "absences.validation.endDateMissing",
      path: ["end"],
    })
    .refine(({ reason }) => reason !== null, {
      message: "absences.validation.reasonMissing",
      path: ["reason"],
    })
    .refine(
      ({ startTime, endTime }) => {
        const dtStart = DateTime.fromObject(startTime);
        const dtEnd = DateTime.fromObject(endTime);
        return dtEnd >= dtStart;
      },
      {
        message: "absences.validation.endNotBeforeStartDate",
        path: ["endTime"],
      },
    )
    .refine(
      ({ allDay, startTime, endTime }) => {
        const dtStart = startTime && DateTime.fromObject(startTime);
        let dtEnd = endTime && DateTime.fromObject(endTime);
        if (dtStart && dtEnd && dtEnd < dtStart) {
          dtEnd = dtEnd.plus({ days: 1 });
        }
        const duration =
          dtStart && dtEnd && dtEnd.diff(dtStart, "minutes").minutes;
        return allDay || (duration && duration >= 60);
      },
      {
        message: "absences.validation.noShortAbsence",
        path: ["endTime"],
      },
    );
};

export const getDefault = (
  isCaregiver: boolean,
  absence: Absence | undefined,
  t: UseTranslateResult["t"],
): Partial<FormInput> => {
  if (!absence)
    return {
      allDay: true,
      start: DateTime.now(),
      end: DateTime.now(),
      startTime: (({ hour = 0, minute = 0 }) => ({ hour, minute }))(
        DateTime.now().toObject(),
      ),
      endTime: (({ hour = 0, minute = 0 }) => ({ hour, minute }))(
        DateTime.now().plus({ hours: 1 }).toObject(),
      ),
      reason: null,
    };
  const reason = isCaregiver
    ? getCaregiverAbsenceReasonOption(absence?.reason, t)
    : getCareRecipientAbsenceReasonOption(absence?.reason, t);
  const allDay = absence.allDay;
  const start = DateTime.fromISO(absence.start);
  const end = allDay ? DateTime.fromISO(absence.end) : undefined;

  const timeStart = DateTime.fromISO(absence.start);
  const timeEnd = DateTime.fromISO(absence.end);
  const times = {
    start: (({ hour = 0, minute = 0 }) => ({ hour, minute }))(
      timeStart.toObject(),
    ),
    end: (({ hour = 0, minute = 0 }) => ({ hour, minute }))(timeEnd.toObject()),
  };

  return {
    allDay,
    start,
    end,
    startTime: times?.start,
    endTime: times?.end,
    reason: reason,
  };
};

export type FormInput = z.infer<ReturnType<typeof getSchema>>;

type Props = {
  absence?: Absence;
  onClose: () => void;
  onSubmit: (values: FormInput) => void;
  onDelete?: (id: string) => void;
  isCareGiver: boolean;
};

const AbsenceForm = ({
  absence,
  onClose,
  onSubmit,
  onDelete,
  isCareGiver,
}: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();
  const schema = getSchema(isCareGiver);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: getDefault(isCareGiver, absence, t),
  });

  const allDay = watch("allDay");
  const start = watch("start");
  const end = watch("end");

  const prevStart = useRef(start);
  const prevEnd = useRef(end);

  useEffect(() => {
    if (end && prevStart.current !== start) {
      if (start > end) {
        setValue("end", start, { shouldValidate: true });
      }
      prevStart.current = start;
    }

    if (end && prevEnd.current !== end) {
      if (end < start) {
        setValue("start", end, { shouldValidate: true });
      }
      prevEnd.current = end;
    }
  }, [start, end, setValue]);

  const handleClose = () => onClose();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label={t("absences.startDate")} labelFor="start">
        <Controller
          control={control}
          name="start"
          render={({ field }) => (
            <DatePicker
              dateSettings={viewer?.tenantSettings}
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              isInvalid={!!errors.start}
              aria-label={t("absences.startDate").toString()}
              errorMessage={errors.start && t(errors.start.message ?? "")}
            />
          )}
        />
      </FormGroup>

      <div className={allDay ? "block" : "hidden"}>
        <FormGroup label={t("absences.endDate")} labelFor="end">
          <Controller
            control={control}
            name="end"
            render={({ field }) => (
              <DatePicker
                dateSettings={viewer?.tenantSettings}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                minValue={start}
                isInvalid={!!errors.end}
                aria-label={t("absences.endDate").toString()}
                errorMessage={errors.end && t(errors.end.message ?? "")}
              />
            )}
          />
        </FormGroup>
      </div>

      <div className={allDay ? "hidden" : "block"}>
        <FormGroup label={t("visitDetailsForm.timeStart")} labelFor="startTime">
          <Controller
            control={control}
            name="startTime"
            render={({ field }) => (
              <TimePicker
                dateSettings={viewer?.tenantSettings}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                invalid={!!errors.startTime}
                errorMessage={
                  errors.startTime && t(errors.startTime?.message ?? "")
                }
              />
            )}
          />
        </FormGroup>
      </div>
      <div className={allDay ? "hidden" : "block"}>
        <FormGroup label={t("visitDetailsForm.timeEnd")} labelFor="endTime">
          <Controller
            control={control}
            name="endTime"
            render={({ field }) => (
              <TimePicker
                dateSettings={viewer?.tenantSettings}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                invalid={!!errors.endTime}
                errorMessage={
                  errors.endTime && t(errors.endTime?.message ?? "")
                }
              />
            )}
          />
        </FormGroup>
      </div>

      <FormGroup label={t("absences.allDay")} labelFor="allDay">
        <Checkbox
          {...register("allDay")}
          id="allDay"
          type="checkbox"
          ariaLabel={t("absences.allDay").toString() + " checkbox"}
        />
      </FormGroup>
      <FormGroup label={t("absences.reason")} labelFor="absenceReason">
        <Controller
          control={control}
          name="reason"
          render={({ field }) => (
            <AbsenceReasonSelect
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              isCareGiver={isCareGiver}
              errorMessage={errors.reason && t(errors.reason?.message ?? "")}
            />
          )}
        />
      </FormGroup>

      <Form.Footer className="border-t p-2">
        {absence && !!onDelete && (
          <Button
            className="mr-auto"
            variant="critical"
            text={t("delete").toString()}
            onClick={() => onDelete(absence.id)}
          />
        )}
        <Button
          variant="secondary"
          text={t("cancel").toString()}
          onClick={handleClose}
        />
        <Button variant="primary" text={t("save").toString()} type="submit" />
      </Form.Footer>
    </form>
  );
};

export default AbsenceForm;
