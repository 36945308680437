import { useForm, FormProvider } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ActivityGroupByIdQuery } from "../../../api/generated/graphql";
import { useNavigate } from "react-router-dom";
import { FormGroup, Input } from "@frontend/lyng/forms";
import { SlideOver } from "@frontend/lyng/slideOver";
import { Form } from "../../../components/formfields";
import { Button } from "@frontend/lyng/button";
import { useEffect } from "react";

const validationSchema = z.object({
  title: z.string().min(1, { message: "activityTemplate.titleRequired" }),
});

export type FormInput = z.infer<typeof validationSchema>;

type Props = {
  activityGroup?: ActivityGroupByIdQuery["activityGroupById"];
  onSubmit: (data: FormInput) => Promise<void>;
  onDelete: (id: string) => void;
};

export const ActivityGroupForm = ({
  activityGroup,
  onSubmit,
  onDelete,
}: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const methods = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      title: activityGroup?.title ?? "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = methods;

  useEffect(() => {
    if (activityGroup) {
      reset({
        title: activityGroup.title,
      });
    }
  }, [activityGroup, reset]);

  const submitHandler = async (values: FormInput) => {
    await onSubmit(values);
  };

  return (
    <FormProvider {...methods}>
      <div className="h-full">
        <form onSubmit={handleSubmit(submitHandler)} className="h-full">
          <SlideOver.DetailSection>
            <FormGroup label={t("title")} labelFor="title">
              <Input
                {...register("title")}
                placeholder={t("placeholder.title")}
                type="text"
                errorMessage={errors.title?.message && t(errors.title.message)}
              />
            </FormGroup>
          </SlideOver.DetailSection>

          <Form.StickyFooter>
            {!activityGroup ? (
              <Button
                className="ml-auto"
                variant="primary"
                type="submit"
                text={t("save")}
              />
            ) : (
              <>
                <Button
                  className="mr-auto"
                  variant="critical"
                  text={t("activityTemplates.deleteGroup")}
                  onClick={() => onDelete(activityGroup.id)}
                />

                <Button
                  className="mr-2"
                  variant="secondary"
                  onClick={() => navigate("..")}
                  text={t("cancel")}
                  disabled={isSubmitting}
                />
                <Button variant="primary" type="submit" text={t("save")} />
              </>
            )}
          </Form.StickyFooter>
        </form>
      </div>
    </FormProvider>
  );
};
