import { useTranslate } from "@tolgee/react";
import NotFoundBackgroundDesktop from "../../assets/svg/notFoundBackground.svg?react";
import { Headline, Paragraph } from "@frontend/lyng/typography";
import { useNavigate } from "react-router-dom";
import { Button } from "@frontend/lyng/button/Button";

const NotFound = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  return (
    <>
      <div className="sm:overflow-auto w-full h-full relative content-center">
        <NotFoundBackgroundDesktop className="hidden sm:hidden md:block absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4" />

        <div className="flex flex-col items-center font-poppins">
          <Headline className="mb-6" size="xxl">
            {t("notFound.title")}
          </Headline>
          <Paragraph className="mb-8 text-center" size="m">
            {" "}
            {t("notFound.description")}
          </Paragraph>
          <Button
            size="lg"
            variant="primary"
            className="hidden sm:hidden md:block flex-1 "
            text={t("notFound.goHome").toString()}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <Button
        size="lg"
        variant="primary"
        className="md:hidden flex-1 mb-16"
        text={t("notFound.goHome").toString()}
        onClick={() => navigate("/")}
      />
    </>
  );
};

export default NotFound;
