import { NameOrder, Viewer } from "../api/generated/graphql";
import { Chat as ChatProvider } from "stream-chat-react";
import { StreamVideo, StreamVideoClient } from "@stream-io/video-react-sdk";
import { StreamChat } from "stream-chat";
import { useEffect, useState } from "react";
import { useSortingOptions } from "../utils/hooks/useSortingOptions";
import { useCommsContext } from "./CommsProvider";

// for dev
const apiKey = "vfmvsd635hgf";

type ChatWrapperProps = {
  children: React.ReactNode;
  loadingView: JSX.Element;
  nameOrder: NameOrder;
  country: string;
  darkMode: boolean;
  viewer: Pick<Viewer, "id" | "chatToken" | "firstName" | "lastName">;
};

export const ChatWrapper = (props: ChatWrapperProps) => {
  const { children, loadingView: Splash, viewer } = props;
  const { updateUnreadCount } = useCommsContext();
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [videoClient, setVideoClient] = useState<StreamVideoClient | null>(
    null,
  );
  const { nameOrderFn } = useSortingOptions({
    nameOrder: props.nameOrder,
    country: props.country,
  });

  useEffect(() => {
    const initChatClient = async (
      viewer?: Pick<Viewer, "id" | "chatToken" | "firstName" | "lastName">,
    ) => {
      if (!viewer) return;

      const client = new StreamChat(apiKey);
      const videoClient = new StreamVideoClient(apiKey);

      const chatUser = await client.connectUser(
        {
          id: viewer.id,
          name: nameOrderFn(viewer),
          image: "https://i.imgur.com/fR9Jz14.png",
        },
        viewer.chatToken,
      );
      videoClient.connectUser(
        {
          id: viewer.id,
          name: nameOrderFn(viewer),
          image: "https://i.imgur.com/fR9Jz14.png",
        },
        viewer.chatToken,
      );

      setChatClient(client);
      setVideoClient(videoClient);

      if (chatUser) {
        updateUnreadCount(chatUser.me?.total_unread_count ?? 0);
      }
    };

    if (!chatClient || !videoClient) {
      initChatClient(viewer);
    }
  }, [props, chatClient, videoClient, nameOrderFn, viewer, updateUnreadCount]);

  useEffect(() => {
    return () => {
      if (chatClient && videoClient) {
        chatClient.disconnectUser();
        videoClient.disconnectUser();
      }
    };
  }, [chatClient, videoClient]);

  if (!chatClient || !videoClient) {
    return Splash;
  }

  chatClient.on((event) => {
    if (event.total_unread_count !== undefined) {
      updateUnreadCount(event.total_unread_count);
    }
  });

  return (
    <StreamVideo client={videoClient}>
      <ChatProvider
        client={chatClient}
        theme={props.darkMode ? "str-chat__theme-dark" : ""}
      >
        {children}
      </ChatProvider>
    </StreamVideo>
  );
};
