import { useTranslate } from "@tolgee/react";
import Skeleton from "react-loading-skeleton";
import { relationshipTypeToString } from "../../../utils/translationUtils";
import { Card } from "../../../components/common";
import { RelationshipType } from "../../../api/generated/graphql";
import { Button } from "@frontend/lyng/button/Button";
import { Plus } from "@frontend/lyng/assets/icons/16/outline";

type Contact = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  email: string | null;
  relationshipType: RelationshipType;
};
type Props = {
  contacts: Contact[];
  primary: Contact | null;
  loading?: boolean;
  onCreateContact: () => void;
  onShowAllContacts: () => void;
};

const ContactCard = ({
  contacts = [],
  primary,
  loading,
  onCreateContact,
  onShowAllContacts,
}: Props) => {
  const { t } = useTranslate();

  const renderNoContacts = () => <p>{t("contacts.noContacs")}</p>;
  const renderNoPrimaryContact = () => <p>{t("contacts.noPrimaryContact")}</p>;

  const renderPrimary = () => {
    if (!primary) return;
    return (
      <>
        <h3 className="font-medium">
          {`${primary.firstName} ${primary.lastName} (${t(
            relationshipTypeToString(primary.relationshipType),
          )})`}
        </h3>
        <p className="mb-1.5 text-xs font-medium text-gray-700">
          {t("primaryContact")}
        </p>

        <a href={`mailto:${primary.email}`} className="text-sm">
          {primary.email}
        </a>
        <a href={`tel:${primary.phone}`} className="text-sm text-gray-700">
          {primary.phone}
        </a>
      </>
    );
  };

  return (
    <Card>
      <h4 className="mb-3 text-xs font-medium uppercase text-gray-700">
        {t("careRecipientDetails.contacts")}
      </h4>

      <div className="flex flex-col gap-1.5">
        {loading ? (
          <Skeleton count={4} />
        ) : contacts.length && primary ? (
          renderPrimary()
        ) : contacts.length && !primary ? (
          renderNoPrimaryContact()
        ) : (
          renderNoContacts()
        )}
      </div>

      <div className="mt-6 flex gap-2 flex-wrap">
        <Button
          className="flex-1"
          variant="secondary"
          onClick={onShowAllContacts}
          text={t("careRecipientDetails.seeAll").toString()}
          disabled={loading}
          size="sm"
        />
        <Button
          variant="primary"
          onClick={onCreateContact}
          text={t("careRecipientDetails.addContact").toString()}
          disabled={loading}
          size="sm"
          iconPosition="left"
          icon={Plus}
        />
      </div>
    </Card>
  );
};

export default ContactCard;
