import { gql } from "@apollo/client";

export const careRecipientById = gql`
  fragment Carelog on Carelog {
    log
    author {
      firstName
      lastName
    }
    createdAt
    id
  }
  query CareRecipientById($id: ID!) {
    careRecipientById(id: $id) {
      id
      firstName
      lastName
      birthDate
      deactivatedAt
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
        homeInformation
      }
      gender
      contacts {
        id
        firstName
        lastName
        relationshipType
        email
        phone
        isPrimary
        isPayer
        hasPowerOfAttorney
      }
      primaryContact {
        id
        firstName
        lastName
        relationshipType
        email
        phone
        isPrimary
        isPayer
        hasPowerOfAttorney
      }
      phone
      homePhone
      email
      careRecipientRoles {
        birthDate
        ssn
        deactivatedAt
        office {
          id
          name
        }
      }
      carePlan {
        fields {
          ... on RadioField {
            __typename
            id
            name
            availableValues
            required
            description
            value
          }
          ... on CheckboxField {
            __typename
            id
            name
            availableValues
            description
            required
            values
          }
          ... on TextField {
            __typename
            id
            name
            required
            description
            value
          }
          ... on TextArea {
            __typename
            id
            name
            required
            value
            description
          }
          ... on DateField {
            __typename
            id
            name
            required
            value
            description
          }
        }
      }
      files {
        name
        url
      }
      carelogs(limit: 10) {
        ...Carelog
      }
    }
  }
`;
