import classNames from "classnames";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

type StatsCardProps = {
  headerText: string;
  stat: string;
  change?: { value: string; type: "increase" | "decrease" };
  link: { href: string; text: string };
  icon: React.ElementType;
  loading?: boolean;
};
const StatsCard = (props: StatsCardProps) => {
  return (
    <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div className="absolute rounded-md bg-primary-500 p-3">
          <props.icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          {props.headerText}
        </p>
      </dt>
      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
        {props.loading ? (
          <Skeleton containerClassName="flex-1" className="h-7 !w-12 pb-1" />
        ) : (
          <>
            <p className="text-2xl font-semibold text-gray-900">{props.stat}</p>
            {props.change && (
              <p
                className={classNames(
                  props.change.type === "increase"
                    ? "text-green-600"
                    : "text-red-600",
                  "ml-2 flex items-baseline text-sm font-semibold",
                )}
              >
                {props.change.type === "increase" ? (
                  <ArrowUpIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}
                <span className="sr-only">
                  {" "}
                  {props.change.type === "increase"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by{" "}
                </span>
                {props.change.value}
              </p>
            )}
          </>
        )}
        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
          <div className="text-sm">
            <Link
              to={props.link.href}
              className="font-medium"
              aria-label={props.link.text}
            >
              {props.link.text}
            </Link>
          </div>
        </div>
      </dd>
    </div>
  );
};

const Root = ({ children }: { children: React.ReactNode }) => {
  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3  ">
      {children}
    </dl>
  );
};

const Stats = Object.assign(Root, { Card: StatsCard });

export default Stats;
