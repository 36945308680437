import { PropsWithChildren } from "react";
import { ChannelListMessengerProps, LoadingChannels } from "stream-chat-react";

/**
 * A preview list of channels, allowing you to select the channel you want to open
 */
export const CommsChannelList = (
  props: PropsWithChildren<ChannelListMessengerProps>,
) => {
  const { children, loading, LoadingIndicator = LoadingChannels } = props;

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="dark:bg-greyscale-900 bg-transparent h-fit">{children}</div>
  );
};
