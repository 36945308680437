import { SearchProvider, UsersSearchTable } from "../../../components/common";
import { useFeatureFlag } from "../../../providers/FeatureFlags";
import UsersOld from "./UsersOld";
import { Headline, HeadlineContainer } from "@frontend/lyng/typography";
import { useTranslate } from "@tolgee/react";
import { Button } from "@frontend/lyng/button";
import { useCallback, useMemo } from "react";
import { MenuProps, useMenu } from "react-instantsearch";
import { SearchBox } from "../../../components/common";
import { useNavigate } from "react-router-dom";
import { NumberPill } from "@frontend/lyng/pill";
import { Tabs } from "@frontend/lyng/tabs";
import { useCareContext } from "../../../providers";

const roleTypeOrder: Record<string, number> = {
  CAREGIVER: 0,
  CARE_RECIPIENT: 1,
  CARE_TEAM_MEMBER: 2,
  ADMIN: 3,
  SCHEDULING_MANAGER: 4,
  SUCCESS_MANAGER: 5,
  CONTACT: 6,
  NO_ROLE: 7,
};
const sortRoleTypes: MenuProps["sortBy"] = (a, b) =>
  roleTypeOrder[a.name] - roleTypeOrder[b.name];
const RoleTypeFilter = () => {
  const { t } = useTranslate();

  const { items, refine } = useMenu({
    attribute: "role_types",
    sortBy: sortRoleTypes,
  });

  const translations: Record<string, string> = useMemo(() => {
    return {
      NO_ROLE: t("users.noRole"),
      CAREGIVER: t("users.caregivers"),
      CARE_RECIPIENT: t("users.careRecipients"),
      CARE_TEAM_MEMBER: t("users.careTeamMembers"),
      CONTACT: t("Contacts"),
    };
  }, [t]);

  const tabs = items.map((item) => ({
    id: item.value,
    label: translations[item.value] ?? "",
    icon:
      item.value === "NO_ROLE"
        ? () => <NumberPill count={item.count} color="yellow" />
        : null,
  }));

  const allTab = {
    id: "",
    label: t("users.all"),
  };

  const currentTab = items.find((item) => item.isRefined)?.value ?? "";

  return (
    <Tabs tabs={[allTab, ...tabs]} currentTab={currentTab} onChange={refine} />
  );
};

const UsersNew = () => {
  const {
    state: { searchKeys },
  } = useCareContext();
  const { t } = useTranslate();
  const navigate = useNavigate();

  const openModalForm = useCallback(
    (userId?: string | undefined) => {
      navigate(`./${userId ?? "new"}`, {
        preventScrollReset: true,
      });
    },
    [navigate],
  );

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("users.title")}</Headline>
        <Button
          className="ml-auto"
          variant="primary"
          text={t("users.addUser").toString()}
          onClick={() => openModalForm()}
        />
      </HeadlineContainer>
      <SearchProvider
        apiKey={searchKeys?.usersKey.key ?? ""}
        indexName="users"
        additionalSearchParameters={{
          query_by: "first_name,last_name,email,pid",
          sort_by: "first_name_sortable:asc",
          facet_by: "role_types",
          perPage: 50,
        }}
      >
        <div className="px-8 pb-4 flex flex-row gap-2">
          <SearchBox placeholder={t("search")} />
          <RoleTypeFilter />
        </div>
        <UsersSearchTable
          columnVisibility={{
            name: true,
            email: true,
            roles: true,
            phone: true,
            lastLogin: true,
            edit: true,
          }}
          onEditClick={openModalForm}
          emptyMessage={t("users.noUsersFound")}
        />
      </SearchProvider>
    </div>
  );
};

export const Users = () => {
  const usersIndexFF = useFeatureFlag("UsersSearchIndex");

  return usersIndexFF ? <UsersNew /> : <UsersOld />;
};
