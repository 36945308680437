import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";
import {
  OrganizationDocument,
  useOfficeCreateMutation,
  useOfficeUpdateMutation,
  ViewerDocument,
} from "../../../api/generated/graphql";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { useMemo } from "react";
import { Button } from "@frontend/lyng/button/Button";
import { Select } from "@frontend/lyng/forms/select/Select";
import { FormGroup, Input } from "@frontend/lyng/forms";
import { SlideOver } from "@frontend/lyng/slideOver";
import { Form } from "../../../components/formfields";

const updateValidationSchema = z.object({
  name: z.string().min(1),
});

type FormInput = z.infer<typeof updateValidationSchema>;
export type UpdateOffice = FormInput;

export type UpdateProps = {
  id: string;
  office: UpdateOffice;
  onClose: () => void;
};

export const OrganizationOfficeUpdateFormSlideOver = ({
  id,
  office,
  onClose,
}: UpdateProps) => {
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isLoading },
  } = useForm<FormInput>({
    resolver: zodResolver(updateValidationSchema),
    defaultValues: {
      name: office.name,
    },
  });

  const [officeUpdateMutation] = useOfficeUpdateMutation({
    refetchQueries: [OrganizationDocument],
  });
  const onSubmit: SubmitHandler<FormInput> = (value) => {
    const updatePromise = officeUpdateMutation({
      variables: {
        id: id,
        input: {
          name: value.name,
        },
      },
    }).then(() => onClose());

    toast.promise(updatePromise, {
      loading: t("office.updating"),
      success: t("office.updated"),
      error: (error) => errorToToastMessage(error),
    });
  };

  return (
    <SlideOver onClose={onClose} show={true}>
      <SlideOver.Title>
        {`${t("update").toString()} ${t("office.label").toString()}`}
      </SlideOver.Title>
      <SlideOver.DetailSection>
        <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={t("office.table.name")} labelFor="region.name">
            <Input
              {...register("name")}
              id="region.name"
              type="text"
              placeholder={t("office.table.name")}
            />
          </FormGroup>
          <Form.Footer className="border-t p-2">
            <Button
              variant="secondary"
              text={t("cancel").toString()}
              onClick={() => {
                onClose();
              }}
            />
            <Button
              variant="primary"
              text={t("save").toString()}
              type="submit"
              disabled={!isValid || !isDirty || isLoading}
            />
          </Form.Footer>
        </form>
      </SlideOver.DetailSection>
    </SlideOver>
  );
};

const createValidationSchema = z.object({
  name: z.string().min(1),
  region: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .required(),
});

type CreateFormInput = z.infer<typeof createValidationSchema>;

export type CreateProps = {
  availableRegions: { id: string; name: string }[];
  onClose: () => void;
};

export const OrganizationOfficeCreateFormModal = ({
  availableRegions,
  onClose,
}: CreateProps) => {
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isLoading },
  } = useForm<CreateFormInput>({
    resolver: zodResolver(createValidationSchema),
  });

  const [officeCreateMutation] = useOfficeCreateMutation({
    refetchQueries: [OrganizationDocument, ViewerDocument],
  });
  const onSubmit: SubmitHandler<CreateFormInput> = (value) => {
    const updatePromise = officeCreateMutation({
      variables: {
        input: {
          name: value.name,
          regionId: value.region.value,
        },
      },
    }).then(() => onClose());

    toast.promise(updatePromise, {
      loading: t("office.creating"),
      success: t("office.created"),
      error: (error) => errorToToastMessage(error),
    });
  };

  const options = useMemo(() => {
    return availableRegions.map((region) => ({
      value: region.id,
      label: region.name,
    }));
  }, [availableRegions]);

  return (
    <SlideOver onClose={onClose} show={true}>
      <SlideOver.Title>
        {`${t("create").toString()} ${t("office.label").toString()}`}
      </SlideOver.Title>
      <SlideOver.DetailSection>
        <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={t("office.table.name")} labelFor="office.name">
            <Input
              {...register("name")}
              id="office.name"
              type="text"
              placeholder={t("office.table.name")}
            />
          </FormGroup>
          <FormGroup
            label={t("office.table.region")}
            labelFor="office.region.select"
          >
            <Controller
              control={control}
              name={"region"}
              render={({ field }) => (
                <Select
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  id="office.region.select"
                  placeholder={t("office.selectRegionPlaceholder")}
                  options={options}
                  isMulti={false}
                  isClearable={false}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  styles={{
                    input: (base) => ({
                      ...base,
                      "input:focus": {
                        boxShadow: "none",
                      },
                    }),
                  }}
                />
              )}
            />
          </FormGroup>

          <Form.Footer className="border-t p-2">
            <Button
              variant="secondary"
              text={t("cancel").toString()}
              onClick={() => {
                onClose();
              }}
            />
            <Button
              variant="primary"
              text={t("save").toString()}
              type="submit"
              disabled={!isValid || isLoading}
            />
          </Form.Footer>
        </form>
      </SlideOver.DetailSection>
    </SlideOver>
  );
};
