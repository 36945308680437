import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";

import {
  ContactForCareRecipient,
  useContactDeactivateMutation,
  useContactReactivateMutation,
} from "../../api/generated/graphql";
import { errorToToastMessage } from "../../utils/toastUtils";

import ContactInfo from "./components/contactInfo";
import DeactivateModal from "./components/deactivate";

type Props = {
  contacts: ContactForCareRecipient[];
  onClose: () => void;
};
const AllContacts = ({ contacts }: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const params = useParams();
  const [deactivateId, setDeactivateId] = useState("");
  const [contactDeactivateMutation] = useContactDeactivateMutation({
    refetchQueries: ["ContactsByCareRecipientId"],
  });
  const [contactReactivateMutation] = useContactReactivateMutation({
    refetchQueries: ["ContactsByCareRecipientId"],
  });

  const handleDeactivate = (contact: ContactForCareRecipient) =>
    setDeactivateId(contact.id);
  const handleEdit = (contact: ContactForCareRecipient) => {
    navigate(`./${contact.id}`, { state: { contact } });
  };

  const deactivateContact = () => {
    const promise = contactDeactivateMutation({
      variables: {
        contactId: deactivateId,
        careRecipientId: params?.careRecipientId ?? "",
      },
    }).then((data) => {
      setDeactivateId("");
      return data.data;
    });

    toast.promise(promise, {
      loading: t("contacts.deactivating"),
      success: () => t("contacts.deactivated"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const reactivateContact = (contact: ContactForCareRecipient) => {
    const promise = contactReactivateMutation({
      variables: {
        contactId: contact.id,
        careRecipientId: params?.careRecipientId ?? "",
      },
    }).then((data) => {
      return data.data;
    });

    toast.promise(promise, {
      loading: t("contacts.reactivating"),
      success: () => t("contacts.reactivated"),
      error: (err) => errorToToastMessage(err),
    });
  };

  return (
    <>
      <ul className="flex flex-col gap-6">
        {contacts.map((contact) => {
          const options = contact.deactivatedAt
            ? [
                {
                  id: contact.id,
                  label: t("reactivate"),
                  onClick: reactivateContact,
                },
              ]
            : [
                { id: contact.id, label: t("edit"), onClick: handleEdit },
                {
                  id: contact.id,
                  label: t("deactivate"),
                  onClick: handleDeactivate,
                },
              ];
          return (
            <ContactInfo key={contact.id} contact={contact} options={options} />
          );
        })}
      </ul>
      <DeactivateModal
        show={!!deactivateId}
        onClose={() => setDeactivateId("")}
        onAccept={deactivateContact}
      />
    </>
  );
};

export default AllContacts;
