import { Search, Close } from "../assets/icons/24/outline";
import classNames from "classnames";
import { useCallback, useRef } from "react";
import useKeyboardShortcut from "use-keyboard-shortcut";

type SearchInputProps = {
  inputValue: string;
  setInputValue: (value: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const SearchInput = ({
  inputValue,
  setInputValue,
  ...props
}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const isMac = /Mac OS X/.test(navigator.userAgent);
  const keysSystem = isMac ? ["Meta", "F"] : ["Control", "F"];
  const shortcutCallback = useCallback(() => {
    inputRef.current?.focus();
  }, []);
  useKeyboardShortcut(keysSystem, shortcutCallback, { overrideSystem: true });

  return (
    <div className="relative ">
      <input
        {...props}
        ref={inputRef}
        autoFocus
        className={classNames(
          "h-12 w-full rounded-xl border px-4 py-2.5 transition-colors placeholder-greyscale-900 placeholder:font-inclusive text-greyscale-900 text-lg dark:text-greyscale-100 dark:placeholder-greyscale-300",
          "border-greyscale-200 hover:border-greyscale-400 focus:border-primary-600 dark:border-greyscale-700 dark:hover:border-greyscale-500 dark:focus:border-primary-600",
          "bg-transparent hover:bg-greyscale-0 focus:bg-greyscale-0 dark:bg-greyscale-800 dark:hover:bg-greyscale-900 dark:focus:bg-greyscale-900",
        )}
        type="text"
        onChange={(event) => setInputValue(event.currentTarget.value)}
        value={inputValue}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        {inputValue !== "" ? (
          <button type="button" onClick={() => setInputValue("")}>
            <Close className="text-greyscale-600 dark:text-greyscale-500" />
          </button>
        ) : (
          <Search className="text-greyscale-600 dark:text-greyscale-500" />
        )}
      </div>
    </div>
  );
};
