import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Link, useParams } from "react-router-dom";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useCareContext } from "../../providers";
import {
  CareGiverByIdQuery,
  CareGiverByIdQueryVariables,
  RoleType,
  useCareGiverByIdQuery,
} from "../../api/generated/graphql";
import { caregiverById } from "../../api/operations/CaregiverById";
import {
  Caregiver,
  CaregiverFormSlideOver,
} from "../caregiverList/CaregiverFormSlideOver";
import {
  CardColumn,
  ColumnCard,
  ThreeColumnLayout,
} from "../../components/common";
import { CaregiverSchedule } from "./caregiverSchedule/CaregiverSchedule";
import { DeactivatedCaregiver } from "./components/deactivatedCaregiver";
import { LoadingBounce } from "@frontend/lyng/loading";
import {
  HomePhone,
  Office,
  Phone,
} from "@frontend/lyng/assets/icons/16/filled";
import { Paragraph } from "@frontend/lyng/typography/paragraph/Paragraph";
import { Button } from "@frontend/lyng/button/Button";

export const caregiverDetailsLoader = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string | undefined,
): Promise<CareGiverByIdQuery> => {
  if (!id) {
    return Promise.reject("id is required");
  }

  const res = await client.query<
    CareGiverByIdQuery,
    CareGiverByIdQueryVariables
  >({
    query: caregiverById,
    variables: {
      id,
    },
  });

  return res.data;
};

export const CaregiverDetails = () => {
  const { t } = useTranslate();
  const { caregiverId } = useParams();

  const [caregiverFormModal, setCaregiverFormModal] = useState<Partial<
    Caregiver & { id: string }
  > | null>(null);

  const {
    state: { viewer },
  } = useCareContext();

  // We need to have the caregiver data on the route loader so we can set the breadcrumbs.
  // We are getting the cached data on the component to be able to update the page when the
  // caregiver info changes.
  const { data, loading, error } = useCareGiverByIdQuery({
    fetchPolicy: "cache-only",
    variables: {
      id: caregiverId ?? "",
    },
  });

  const caregiver = data?.careGiverById;
  const viewerOffices = viewer?.tenantAccess.offices
    .filter((officeAccess) =>
      officeAccess.roles.some((role) => role.roleType === RoleType.Admin),
    )
    .map((officeAccess) => officeAccess.id);
  const caregiverRole = caregiver?.caregiverRoles.find(
    (role) =>
      role.deactivatedAt !== null && viewerOffices?.includes(role.office.id),
  );

  if (loading) return <LoadingBounce />;
  if (error) return <p>{error.message}</p>;
  if (!caregiver) return <p>No caregiver data</p>;

  if (caregiver.deactivatedAt) {
    return (
      <DeactivatedCaregiver
        id={caregiver.id}
        officeId={caregiverRole?.office.id ?? ""}
        name={`${caregiver.firstName} ${caregiver.lastName}`}
        deactivatedAt={caregiver.deactivatedAt}
      />
    );
  }

  const careGiverOffices = caregiver.caregiverRoles
    .filter((role) => !role.deactivatedAt)
    .map((role) => role.office.name);

  return (
    <>
      <ThreeColumnLayout>
        <CardColumn>
          {/* Start left column area */}
          <ColumnCard>
            <h3 className="mb-4 text-base font-semibold">{`${caregiver.firstName} ${caregiver.lastName}`}</h3>
            {careGiverOffices.length && (
              <Paragraph
                size="xs"
                className="flex items-center gap-2 text-sm font-medium"
              >
                <Office className="stroke-primary-800" />
                {careGiverOffices.join(", ")}
              </Paragraph>
            )}
            {caregiver.phone && (
              <div className="mb-4 flex items-center gap-2">
                <Phone className="h-4 stroke-primary-800" />

                <Link
                  className="text-sm font-medium"
                  to={`tel:${caregiver.homePhone}`}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {`${t("phone")}: ${caregiver.phone}`}
                </Link>
              </div>
            )}
            {caregiver.homePhone && (
              <div className="mt-4 flex items-center gap-2">
                <HomePhone className="text-primary-800" />
                <Link
                  className="text-sm font-medium"
                  to={`tel:${caregiver.homePhone}`}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {caregiver.homePhone}
                </Link>
              </div>
            )}
            <div className="mt-6">
              <Button
                className="w-full"
                variant="secondary"
                onClick={() => {
                  setCaregiverFormModal({
                    firstName: caregiver.firstName || "",
                    lastName: caregiver.lastName || "",
                    email: caregiver.email || "",
                    phone: caregiver.phone || "",
                    homePhone: caregiver.homePhone || null,
                    birthDate: caregiver.birthDate,
                    pid: caregiver.pid,
                    hireDate: caregiver.hireDate,
                    deactivatedAt: caregiver.deactivatedAt,
                    id: caregiver.id,
                    officeId: caregiver?.caregiverRoles[0].office.id,
                  });
                }}
                text={t("editProfile").toString()}
              />
            </div>
          </ColumnCard>
          {/* End left column area */}
        </CardColumn>

        {/* Start main area*/}
        <CaregiverSchedule caregiverId={caregiver.id} />
        {/* End main area */}
      </ThreeColumnLayout>
      {caregiverFormModal && (
        <CaregiverFormSlideOver
          caregiver={caregiverFormModal}
          onClose={() => {
            setCaregiverFormModal(null);
          }}
        />
      )}
    </>
  );
};
