"use client";

import { MemberRequest, StreamVideoClient } from "@stream-io/video-react-sdk";
import { createContext, useCallback, useContext, useState } from "react";
import { Channel, StreamChat } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react";
import { v4 as uuid } from "uuid";

type CommsState = {
  callId: string | undefined;
  unreadCount: number;
  channelsByCategories: Map<string, Array<Channel<DefaultStreamChatGenerics>>>;
  changeChannel: (
    client: StreamChat,
    name: string,
    category: string,
    userIds: string[],
  ) => void;
  createCall: (
    client: StreamVideoClient,
    channelName: string,
    userIds: string[],
  ) => Promise<void>;
  setCall: (callId: string | undefined) => void;
  updateUnreadCount: (unreadCount: number) => void;
};

const initialValue: CommsState = {
  callId: undefined,
  unreadCount: 0,
  channelsByCategories: new Map(),
  changeChannel: () => {
    console.log("changeChannel");
  },
  createCall: async () => {
    console.log("createCall");
  },
  setCall: () => {
    console.log("setCall");
  },
  updateUnreadCount: () => {
    console.log("updateUnreadCount");
  },
};

const CommsProvider = createContext<CommsState>(initialValue);

export const CommsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [myState, setMyState] = useState<CommsState>(initialValue);

  const createCall = useCallback(
    async (
      client: StreamVideoClient,
      channelName: string,
      userIds: string[],
    ) => {
      const callId = uuid();
      const audioCall = client.call("default", callId);
      const audioChannelMembers: MemberRequest[] = userIds.map((userId) => {
        return {
          user_id: userId,
        };
      });
      try {
        const createdAudioCall = await audioCall.create({
          data: {
            custom: {
              // serverId: server?.id,
              callName: channelName,
            },
            members: audioChannelMembers,
          },
        });
        console.log(
          `[DiscordContext] Created Call with id: ${createdAudioCall.call.id}`,
        );
        setMyState((myState) => {
          return { ...myState, callId: createdAudioCall.call.id };
        });
      } catch (err) {
        console.log(err);
      }
    },
    [],
  );

  const changeChannel = useCallback(
    async (
      client: StreamChat,
      name: string,
      category: string,
      userIds: string[],
    ) => {
      if (client.userID) {
        const channel = client.channel("messaging", {
          name: name,
          members: userIds,
          data: {
            category: category,
          },
        });
        try {
          await channel.create();
        } catch (err) {
          console.log(err);
        }
      }
    },
    [],
  );

  const setCall = useCallback(
    (callId: string | undefined) => {
      setMyState((myState) => {
        return { ...myState, callId };
      });
    },
    [setMyState],
  );

  const updateUnreadCount = useCallback(
    (unreadCount: number) => {
      setMyState((myState) => {
        return { ...myState, unreadCount };
      });
    },
    [setMyState],
  );

  const store: CommsState = {
    callId: myState.callId,
    unreadCount: myState.unreadCount,
    channelsByCategories: myState.channelsByCategories,
    changeChannel,
    createCall,
    setCall,
    updateUnreadCount,
  };

  return (
    <CommsProvider.Provider value={store}>{children}</CommsProvider.Provider>
  );
};

export const useCommsContext = () => useContext(CommsProvider);
