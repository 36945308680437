import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Label } from "../typography";
import { Button } from "../button";
import { ChevronDown, ChevronRight } from "../assets/icons/12/outline";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  children: ReactNode;
  className?: string;
  testId?: string;
};

type TitleProps = {
  children: ReactNode;
  className?: string;
};

type RowsProps = {
  children: ReactNode;
  className?: string;
  link?: string;
};

type CollapsibleContainterProps = {
  children: ReactNode;
  className?: string;
  title: string;
  onClick?: () => void;
};

const Title = ({ children, className }: TitleProps) => {
  return (
    <div
      className={classNames(className, "rounded-t-2xl w-full bg-greyscale-100")}
    >
      {children}
    </div>
  );
};

const Rows = ({ children, className }: RowsProps) => {
  return (
    <div
      className={classNames(className, "flex flex-row w-full")}
      data-testid={"cardlist-rows"}
      role="row"
    >
      {children}
    </div>
  );
};

const Container = ({ children }: { children: ReactNode }) => {
  return <div className="divide-y py-2 px-3">{children}</div>;
};

const CollapsibleContainter = ({
  children,
  title,
  className,
}: CollapsibleContainterProps) => {
  const [show, setShow] = useState(true);
  return (
    <div className="first:rounded-2xl overflow-hidden">
      <div className="flex bg-greyscale-100 items-center">
        <Button
          icon={show ? ChevronDown : ChevronRight}
          iconPosition="only"
          variant="secondary"
          onClick={() => setShow(!show)}
          className="!ring-0"
        />
        <Label size="xs">{title}</Label>
      </div>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className={classNames(className, "divide-y py-2 px-3")}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const Root = ({ children, className }: Props) => {
  return (
    <div
      className={classNames(className, "rounded-2xl")}
      data-testid={"cardlist-root"}
    >
      <div>{children}</div>
    </div>
  );
};

export const CardList = Object.assign(Root, {
  Title: Title,
  Rows: Rows,
  CollapsibleContainter: CollapsibleContainter,
  Container: Container,
});
