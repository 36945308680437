import { gql } from "@apollo/client";

export const tenantSettings = gql`
  query TenantSettings {
    viewer {
      tenantSettings {
        country
        timezone
        dateFormat
        showSsn
        language
        timeFormat
        nameOrder
        currency
        distanceUnit
        decimalSeparator
      }
    }
  }
`;
