import { useCallback, useState } from "react";
import { match } from "ts-pattern";
import { Frequency, Weekday } from "../../../api/generated/graphql";
import {
  datedayToWeekday,
  dateTimeToZonedDateTime,
  weekStartingFromDay,
  weekDayInitial,
} from "@frontend/lyng/utils/dateUtils";
import { useTranslate } from "@tolgee/react";
import { AnimatePresence, motion } from "framer-motion";
import { CheckboxButton } from "../checkbox-button/CheckboxButton";
import { useCareContext } from "../../../providers/CareProvider";
import { useLocale } from "@react-aria/i18n";
import { DateTime } from "luxon";
import { startOfWeek } from "@internationalized/date";
import { Paragraph } from "@frontend/lyng/typography";
import {
  getPremadeSelections,
  getSelectValueAndDescription,
} from "../../../utils/visitUtils";
import { getHumanReadableRecurrence } from "../../../utils/visitUtils";
import { Button } from "@frontend/lyng/button/Button";
import { Input } from "@frontend/lyng/forms";
import Modal from "@frontend/lyng/modal/Modal";

export type Value = {
  frequency: Frequency;
  weekdays: Weekday[] | null;
  interval: number | null;
  weekStart: Weekday;
};

type CustomModal = {
  frequency: Frequency;
  weekdays: Weekday[];
  interval: number;
};

export type Props = {
  name?: string;
  value: Value | null;
  baseDate: Date | null;
  onChange: (value: Value | null) => void;
  onBlur: () => void;
  editView?: boolean;
};

// Possible selection in dropdown
type Selections =
  | ""
  | "DAILY"
  | "WEEKLY"
  | "EVERY_WEEKDAY"
  | "MONTHLY"
  | "SELECTED_CUSTOM"
  | "NEW_CUSTOM";

export const VisitRecurrencePicker = ({
  name,
  value,
  onChange,
  baseDate,
  onBlur,
  editView = true,
}: Props) => {
  const [customModal, setCustomModal] = useState<CustomModal | null>(null);
  const { t } = useTranslate();

  const {
    state: { viewer },
  } = useCareContext();
  const { locale } = useLocale();
  const timezone = viewer?.tenantSettings.timezone ?? "UTC";
  const now = DateTime.now().setZone(timezone);
  const weekStartDate = startOfWeek(dateTimeToZonedDateTime(now), locale);
  const weekStart = datedayToWeekday(weekStartDate.toDate().getDay());

  const [selectValue, maybeCustomDescription] = getSelectValueAndDescription(
    value,
    t,
  );

  const handleSelectFrequency = useCallback(
    (val: string, baseDate: Date | null) => {
      const [newValue, isCustom] = match<Selections, [Value | null, boolean]>(
        val as Selections,
      )
        .with("", () => [null, false])

        .with("DAILY", () => [
          {
            frequency: Frequency.Daily,
            weekdays: null,
            interval: null,
            weekStart,
          },
          false,
        ])
        .with("WEEKLY", () => [
          {
            frequency: Frequency.Weekly,
            weekdays: null,
            interval: null,
            weekStart,
          },
          false,
        ])
        .with("EVERY_WEEKDAY", () => [
          {
            frequency: Frequency.Weekly,
            weekdays: [
              Weekday.Monday,
              Weekday.Tuesday,
              Weekday.Wednesday,
              Weekday.Thursday,
              Weekday.Friday,
            ],
            interval: null,
            weekStart,
          },
          false,
        ])
        .with("MONTHLY", () => [
          {
            frequency: Frequency.Monthly,
            weekdays: null,
            interval: null,
            weekStart,
          },
          false,
        ])
        .with("SELECTED_CUSTOM", () => [value, false])
        .with("NEW_CUSTOM", () => [null, true])
        .otherwise(() => [null, false]);

      if (isCustom) {
        const day = baseDate ? [datedayToWeekday(baseDate.getDay())] : [];
        setCustomModal({
          frequency: value?.frequency || Frequency.Weekly,
          weekdays: value?.weekdays || day,
          interval: value?.interval || 1,
        });
        return;
      }

      onChange(newValue);
    },
    [onChange, value, weekStart],
  );

  const handleCancelCustom = () => {
    setCustomModal(null);
  };

  const handleConfirmCustom = useCallback(
    (custom: CustomModal) => {
      onChange({
        frequency: custom.frequency,
        weekdays: custom.frequency !== Frequency.Daily ? custom.weekdays : null,
        interval: custom.interval,
        weekStart,
      });
      setCustomModal(null);
    },
    [onChange, weekStart],
  );

  const premadeSelections = getPremadeSelections(baseDate, t);

  const dayCheckboxes: { label: string; value: Weekday }[] =
    weekStartingFromDay(weekStartDate.toDate()).map((weekday) => ({
      value: weekday,
      label: weekDayInitial(weekday, t),
    }));

  if (!editView) {
    return (
      <Paragraph size="m" className="flex h-12 items-center px-5">
        {getHumanReadableRecurrence(value, baseDate, t)}
      </Paragraph>
    );
  }

  return (
    <>
      <select
        aria-label="recurrence frequency"
        name={name}
        className={
          "block h-12 w-full max-w-lg rounded-xl border border-gray-300 px-4 text-lg transition-colors focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs"
        }
        value={selectValue}
        disabled={!editView}
        onChange={(e) => handleSelectFrequency(e.target.value, baseDate)}
        onBlur={onBlur}
      >
        {Object.entries(premadeSelections).map(([key, value]) =>
          key !== "SELECTED_CUSTOM" || selectValue === "SELECTED_CUSTOM" ? (
            <option key={key} value={key}>
              {selectValue === key && maybeCustomDescription
                ? maybeCustomDescription
                : value.description}
            </option>
          ) : null,
        )}
      </select>
      <Modal show={!!customModal} onClose={handleCancelCustom}>
        <Modal.Title>{t("visitRecurrencePicker.customRecurrence")}</Modal.Title>

        <div className="my-4 flex flex-col">
          <div className="flex flex-row items-center">
            <p className="text-base">
              {t("visitRecurrencePicker.repeatEvery")}
            </p>
            <Input
              name="interval"
              type="number"
              className="ml-2 w-20"
              value={customModal?.interval}
              min={1}
              onChange={(e) =>
                Promise.resolve(
                  setCustomModal((prev) => {
                    if (!prev) return prev;
                    return {
                      ...prev,
                      interval: parseInt(e.target.value),
                    };
                  }),
                )
              }
              onBlur={() => Promise.resolve()}
            />
            <select
              name="frequency"
              className="ml-2 block w-48 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm"
              value={customModal?.frequency}
              onChange={(e) => {
                setCustomModal((prev) => {
                  if (!prev) return prev;
                  return {
                    ...prev,
                    frequency: e.target.value as Frequency,
                  };
                });
              }}
            >
              <option value="DAILY">
                {customModal?.interval && customModal.interval > 1
                  ? t("visitRecurrencePicker.intervalDays")
                  : t("visitRecurrencePicker.intervalDay")}
              </option>
              <option value="WEEKLY">
                {customModal?.interval && customModal.interval > 1
                  ? t("visitRecurrencePicker.intervalWeeks")
                  : t("visitRecurrencePicker.intervalWeek")}
              </option>
              <option value="MONTHLY">
                {customModal?.interval && customModal.interval > 1
                  ? t("visitRecurrencePicker.intervalMonths")
                  : t("visitRecurrencePicker.intervalMonth")}
              </option>
            </select>
          </div>

          <AnimatePresence initial={false}>
            {customModal?.frequency === Frequency.Weekly && (
              <motion.div
                key="weekdays"
                initial={{
                  height: 0,
                  overflow: "hidden",
                }}
                animate={{
                  height: "auto",
                  overflow: "hidden",
                  transitionEnd: {
                    overflow: "visible",
                  },
                }}
                exit={{
                  height: 0,
                  overflow: "hidden",
                }}
              >
                <p className="mt-4 text-base">
                  {t("visitRecurrencePicker.repeatOn")}
                </p>
                <div className="mt-3 flex flex-row flex-wrap gap-2">
                  {dayCheckboxes.map((weekday) => (
                    <CheckboxButton
                      id={`weekdays-${weekday.value}`}
                      key={weekday.value}
                      label={weekday.label}
                      checked={!!customModal?.weekdays.includes(weekday.value)}
                      value={weekday.value.toString()}
                      onChange={(e) =>
                        Promise.resolve(
                          setCustomModal((prev) => {
                            if (!prev) return prev;
                            if (e.target.checked) {
                              return {
                                ...prev,
                                weekdays: [
                                  ...prev.weekdays,
                                  e.target.value as Weekday,
                                ],
                              };
                            }
                            return {
                              ...prev,
                              weekdays: prev.weekdays.filter(
                                (w) => w.toString() !== e.target.value,
                              ),
                            };
                          }),
                        )
                      }
                    />
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <Modal.Footer>
          <Button
            variant="primary"
            text={t("ok").toString()}
            className="col-start-2"
            onClick={() => customModal && handleConfirmCustom(customModal)}
            disabled={
              !!customModal &&
              (customModal.weekdays.length === 0 ||
                customModal.interval < 1 ||
                isNaN(customModal.interval))
            }
          />
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            className="col-start-1"
            onClick={handleCancelCustom}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
