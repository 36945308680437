import { Avatar } from "../avatar/Avatar";
import { useSortingOptions } from "../utils/hooks/useSortingOptions";
import { Chatter } from "./ChatterList";

type ChatterListCardProps = {
  chatter: Chatter;
};

export const ChatterListCard = ({ chatter }: ChatterListCardProps) => {
  const { nameOrderFn } = useSortingOptions();

  return (
    <div className="border-b dark:border-greyscale-700 flex flex-row px-2 py-4 mt-2 items-center dark:text-greyscale-100 text-sm font-poppins">
      <Avatar
        firstName={chatter.firstName ?? undefined}
        lastName={chatter.lastName ?? undefined}
      />
      <div className="flex flex-col ml-4">{nameOrderFn(chatter)}</div>
    </div>
  );
};
