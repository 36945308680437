import classNames from "classnames";
import { forwardRef } from "react";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormRegisterReturn,
  useController,
} from "react-hook-form";

type Props = {
  id?: string;
  value?: string;
  label: string;
  invalid?: boolean;
  checked?: boolean;
};

export const Radio = forwardRef<
  HTMLInputElement,
  Props & UseFormRegisterReturn<string>
>(function Radio(
  { name, onChange, onBlur, value, label, invalid, checked },
  ref,
) {
  return (
    <label className="flex items-center gap-x-3">
      <input
        className={classNames("text-primary-600 focus:ring-primary-600", {
          "border-red-300": invalid,
        })}
        ref={ref}
        name={name}
        type="radio"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        checked={checked}
      />
      <p className="ml-3 text-sm font-medium text-gray-700">{label}</p>
    </label>
  );
});

type RadiosProps = {
  options: { key: string; label: string; value: string }[];
  invalid?: boolean;
};

export function Radios<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: UseControllerProps<TFieldValues, TName> & RadiosProps) {
  const { field } = useController(props);

  return (
    <>
      {props.options.map((option) => (
        <Radio
          id={`${field.name}-${option.key}`}
          key={option.key}
          name={field.name}
          onChange={(e) => {
            field.onChange(e.target.value);
            return Promise.resolve();
          }}
          onBlur={() => Promise.resolve(field.onBlur())}
          label={option.label}
          value={option.value}
          checked={option.value === field.value}
          invalid={props.invalid}
        />
      ))}
    </>
  );
}
