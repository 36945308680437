import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";
import { Form } from "../../../components/formfields";
import { errorToToastMessage } from "../../../utils/toastUtils";
import {
  OrganizationDocument,
  useOrganizationUpdateMutation,
} from "../../../api/generated/graphql";
import { Button } from "@frontend/lyng/button/Button";
import { FormGroup, Input } from "@frontend/lyng/forms";
import { SlideOver } from "@frontend/lyng/slideOver";

const validationSchema = z.object({
  name: z.string().min(1),
});

type FormInput = z.infer<typeof validationSchema>;
export type Organization = FormInput;

type Props = {
  organization: Organization;
  onClose: () => void;
};

export const OrganizationFormSlideOver = ({ organization, onClose }: Props) => {
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isLoading },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: organization.name,
    },
  });

  const [organizationUpdateMutation] = useOrganizationUpdateMutation({
    refetchQueries: [OrganizationDocument],
  });
  const onSubmit: SubmitHandler<FormInput> = (value: FormInput) => {
    const updatePromise = organizationUpdateMutation({
      variables: {
        input: {
          name: value.name,
        },
      },
    }).then(() => {
      onClose();
    });

    toast.promise(updatePromise, {
      loading: t("organization.updating"),
      success: t("organization.updated"),
      error: (error) => errorToToastMessage(error),
    });
  };

  return (
    <SlideOver onClose={onClose} show={true}>
      <SlideOver.Title>
        {`${t("edit").toString()} ${t("organization.label").toString()}`}
      </SlideOver.Title>
      <SlideOver.DetailSection>
        <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup
            label={t("organization.name")}
            labelFor="organization.name"
          >
            <Input {...register("name")} id="organization.name" type="text" />
          </FormGroup>
          <Form.Footer className="border-t p-2">
            <Button
              variant="secondary"
              text={t("cancel").toString()}
              onClick={() => {
                onClose();
              }}
            />
            <Button
              variant="primary"
              text={t("save").toString()}
              type="submit"
              disabled={!isValid || !isDirty || isLoading}
            />
          </Form.Footer>
        </form>
      </SlideOver.DetailSection>
    </SlideOver>
  );
};
