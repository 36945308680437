import {
  CancelledReason,
  ExpenseAdminInput,
  MileageAdminInput,
  VisitByIdQuery,
  useVisitCancelMutation,
  useVisitLogUpdateMutation,
  useVisitReactivateMutation,
} from "../../../api/generated/graphql";
import MapBox, { Marker } from "../../../components/common/map-box/MapBox";
import {
  CaregiverSelect,
  CurrencyInput,
  DistanceInput,
  Form,
  FormSection,
  FormTable,
  LabelsSelect,
  TextAreaWide,
  ValidationMessage,
  VisitTypeSelect,
  caregiverSelectOptionSchema,
} from "../../../components/formfields";
import { Paragraph, Headline, Label, Link } from "@frontend/lyng/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatDuration } from "@frontend/lyng/utils/dateUtils";
import { errorToToastMessage } from "../../../utils/toastUtils";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { VisitLogHistory } from "./VisitLogHistory";
import { valueAsNumberOpt } from "../../../utils/formUtils";
import { useCareContext } from "../../../providers/CareProvider";
import { CancelVisitModal } from "../cancelVisitModal/CancelVisitModal";
import { CancelledVisitCard } from "../cancelledVisitCard/CancelledVisitCard";
import { visitLogUpdated } from "../../../typewriter/segment";
import { Button } from "@frontend/lyng/button/Button";
import { SentimentPill } from "@frontend/lyng/pill/sentimentPill";
import {
  DatePicker,
  Input,
  TimePicker,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { ActivitiesTable } from "../../../components/common";

const getDuration = (dtStart: DateTime, dtEnd: DateTime): number => {
  if (dtEnd < dtStart) {
    dtEnd = dtEnd.plus({ days: 1 });
  }

  return dtEnd.diff(dtStart, "minutes").minutes;
};

const validationSchema = z
  .object({
    careRecipient: z.string(),
    scheduledDate: z.custom<DateTime>((value) => DateTime.isDateTime(value)),
    scheduledStartTime: z.object(timeSchemaShape, {
      invalid_type_error: "visitLog.validation.timeStart",
      required_error: "visitLog.validation.timeStart",
    }),
    scheduledEndTime: z.object(timeSchemaShape, {
      invalid_type_error: "visitLog.validation.timeEnd",
      required_error: "visitLog.validation.timeEnd",
    }),
    clockInTime: z.object(timeSchemaShape).nullable(),
    clockOutTime: z.object(timeSchemaShape).nullable(),
    careGiver: caregiverSelectOptionSchema.nullable(),
    visitNote: z.string(),
    //
    // Internal field for form validation
    isCancelled: z.boolean(),
    cancelledReason: z.nativeEnum(CancelledReason).nullable(),
    cancelledOnDate: z
      .custom<DateTime>(
        (value) => DateTime.isDateTime(value),
        "Invalid date  format.",
      )
      .nullable(),

    labelIds: z.array(z.string()).optional(),
    visitType: z
      .object({
        id: z.string(),
      })
      .nullable(),
    mileage: z
      .object({
        drivenToLocationBilled: z.number().min(0).nullish(),
        drivenToLocationReported: z.number().min(0).nullish(),
        drivenToLocationReimbursed: z.number().min(0).nullish(),
        drivenForCareRecipientBilled: z.number().min(0).nullish(),
        drivenForCareRecipientReported: z.number().min(0).nullish(),
        drivenForCareRecipientReimbursed: z.number().min(0).nullish(),
      })
      .nullish(),
    expenses: z
      .object({
        description: z.string().nullish(),
        reported: z.number().min(0).nullish(),
        billed: z.number().min(0).nullish(),
        reimbursed: z.number().min(0).nullish(),
      })
      .nullish(),
  })
  .refine(
    ({ scheduledDate, scheduledStartTime, clockInTime }) =>
      scheduledDate.set(scheduledStartTime) < DateTime.now() || clockInTime,
    {
      message: "visitLog.validation.scheduledDateInPast",
      path: ["scheduledDate"],
    },
  )
  .refine(({ clockInTime, clockOutTime }) => !clockOutTime || !!clockInTime, {
    message: "visitLog.validation.clockOutRequiresClockIn",
    path: ["clockOutTime"],
  })
  .refine(
    ({ expenses }) =>
      expenses?.description ||
      ((expenses?.billed === null || expenses?.billed === undefined) &&
        (expenses?.reported === null || expenses?.reported === undefined) &&
        (expenses?.reimbursed === null || expenses?.reimbursed === undefined)),
    {
      message: "visitLog.validation.expensesDescriptionRequired",
      path: ["expenses", "description"],
    },
  );
type FormInput = z.infer<typeof validationSchema>;

const defaultValues = (
  visitData: VisitByIdQuery["visitById"] | null,
): FormInput => {
  if (!visitData) {
    return {
      careRecipient: "",
      careGiver: null,
      scheduledDate: DateTime.now(),
      scheduledStartTime: {
        hour: DateTime.now().hour,
        minute: DateTime.now().minute,
      },
      scheduledEndTime: {
        hour: DateTime.now().hour,
        minute: DateTime.now().minute,
      },
      clockInTime: null,
      clockOutTime: null,
      visitNote: "",
      mileage: null,
      expenses: null,
      isCancelled: false,
      cancelledReason: null,
      cancelledOnDate: null,
      visitType: null,
      labelIds: undefined,
    };
  }

  return {
    careRecipient: `${visitData.careRecipient?.firstName || ""} ${
      visitData.careRecipient?.lastName || ""
    }`,
    careGiver: visitData.visitors?.[0] ?? null,
    scheduledDate: DateTime.fromISO(visitData.start),
    scheduledStartTime: {
      hour: DateTime.fromISO(visitData.start).hour,
      minute: DateTime.fromISO(visitData.start).minute,
    },
    scheduledEndTime: {
      hour: DateTime.fromISO(visitData.start).plus({
        minutes: visitData.durationMinutes,
      }).hour,
      minute: DateTime.fromISO(visitData.start).plus({
        minutes: visitData.durationMinutes,
      }).minute,
    },
    clockInTime: visitData.clockInTime
      ? {
          hour: DateTime.fromISO(visitData.clockInTime).hour,
          minute: DateTime.fromISO(visitData.clockInTime).minute,
        }
      : null,
    clockOutTime: visitData.clockOutTime
      ? {
          hour: DateTime.fromISO(visitData.clockOutTime).hour,
          minute: DateTime.fromISO(visitData.clockOutTime).minute,
        }
      : null,
    visitNote: visitData.visitNote ?? "",
    mileage: visitData.mileage && {
      drivenToLocationReported: visitData.mileage.drivenToLocationReported
        ? parseFloat(visitData.mileage.drivenToLocationReported)
        : null,
      drivenToLocationReimbursed: visitData.mileage.drivenToLocationReimbursed
        ? parseFloat(visitData.mileage.drivenToLocationReimbursed)
        : null,
      drivenToLocationBilled: visitData.mileage.drivenToLocationBilled
        ? parseFloat(visitData.mileage.drivenToLocationBilled)
        : null,
      drivenForCareRecipientReported: visitData.mileage
        .drivenForCareRecipientReported
        ? parseFloat(visitData.mileage.drivenForCareRecipientReported)
        : null,
      drivenForCareRecipientReimbursed: visitData.mileage
        .drivenForCareRecipientReimbursed
        ? parseFloat(visitData.mileage.drivenForCareRecipientReimbursed)
        : null,
      drivenForCareRecipientBilled: visitData.mileage
        .drivenForCareRecipientBilled
        ? parseFloat(visitData.mileage.drivenForCareRecipientBilled)
        : null,
    },
    expenses:
      visitData.expenses && visitData.expenses.length
        ? {
            description: visitData.expenses[0].description ?? "",
            billed: visitData.expenses[0].billedAmount
              ? parseFloat(visitData.expenses[0].billedAmount)
              : null,
            reported: visitData.expenses[0].reportedAmount
              ? parseFloat(visitData.expenses[0].reportedAmount)
              : null,
            reimbursed: visitData.expenses[0].reimbursedAmount
              ? parseFloat(visitData.expenses[0].reimbursedAmount)
              : null,
          }
        : null,
    isCancelled: !!visitData.cancelledAt,
    cancelledReason: visitData.cancelledReason ?? null,
    cancelledOnDate: visitData.cancelledAt
      ? DateTime.fromISO(visitData.cancelledAt)
      : null,
    visitType: visitData.visitType ?? null,
    labelIds: visitData.labelIds ?? null,
  };
};

type Props = {
  visitData: NonNullable<VisitByIdQuery["visitById"]>;
};
export const VisitLogDetails = ({ visitData }: Props) => {
  const {
    state: { viewer },
  } = useCareContext();
  const { t } = useTranslate();

  const [editView, setEditView] = useState(false);
  const [showCancelVisitModal, setShowCancelVisitModal] = useState(false);

  const formMethods = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: defaultValues(visitData),
  });
  const {
    control,
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  useEffect(() => {
    reset(defaultValues(visitData));
  }, [visitData, reset]);

  const mutationRefetchQueries = [
    "VisitById",
    "VisitLogsByOfficeId",
    "EventsByCareRecipientId",
    "EventsByCaregiverId",
    "CareRecipientsTimeline",
  ];
  const [visitLogUpdateMutation, { loading: updateLoading }] =
    useVisitLogUpdateMutation({
      refetchQueries: mutationRefetchQueries,
    });
  const [visitCancelMutation, { loading: cancelLoading }] =
    useVisitCancelMutation({
      refetchQueries: mutationRefetchQueries,
    });
  const [visitReactivateMutation, { loading: reactivateLoading }] =
    useVisitReactivateMutation({
      refetchQueries: mutationRefetchQueries,
    });
  const mutationLoading = updateLoading || cancelLoading || reactivateLoading;

  const onSubmit: SubmitHandler<FormInput> = async (values: FormInput) => {
    if (visitData.cancelledAt) {
      return handleCancelVisit({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cancelledOnDate: values.cancelledOnDate!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        reason: values.cancelledReason!,
      }).then(() => {
        setEditView(false);
      });
    } else {
      return handleUpdateVisitLog(values);
    }
  };
  const handleUpdateVisitLog = (values: FormInput) => {
    const dtStart = values.scheduledDate.set(values.scheduledStartTime);
    let dtEnd = values.scheduledDate.set(values.scheduledEndTime);
    if (dtStart > dtEnd) {
      dtEnd = dtEnd.plus({ days: 1 });
    }

    const dtClockIn = values.clockInTime
      ? values.scheduledDate.set(values.clockInTime)
      : null;
    let dtClockOut =
      values.clockInTime && values.clockOutTime
        ? values.scheduledDate.set(values.clockOutTime)
        : null;
    if (dtClockIn && dtClockOut && dtClockIn > dtClockOut) {
      dtClockOut = dtClockOut.plus({ days: 1 });
    }

    const mileage: MileageAdminInput | null =
      values.mileage &&
      (values.mileage.drivenToLocationBilled ||
        values.mileage.drivenToLocationReimbursed ||
        values.mileage.drivenToLocationReported ||
        values.mileage.drivenForCareRecipientBilled ||
        values.mileage.drivenForCareRecipientReimbursed ||
        values.mileage.drivenForCareRecipientReported)
        ? {
            drivenToLocationBilled:
              values.mileage.drivenToLocationBilled?.toString(),
            drivenToLocationReimbursed:
              values.mileage.drivenToLocationReimbursed?.toString(),
            drivenToLocationReported:
              values.mileage.drivenToLocationReported?.toString(),
            drivenForCareRecipientBilled:
              values.mileage.drivenForCareRecipientBilled?.toString(),
            drivenForCareRecipientReimbursed:
              values.mileage.drivenForCareRecipientReimbursed?.toString(),
            drivenForCareRecipientReported:
              values.mileage.drivenForCareRecipientReported?.toString(),
          }
        : null;

    const expenses: ExpenseAdminInput[] =
      values.expenses &&
      (values.expenses.billed ||
        values.expenses.reported ||
        values.expenses.reimbursed)
        ? [
            {
              description: values.expenses.description ?? "",
              billedAmount: values.expenses.billed?.toString(),
              reportedAmount: values.expenses.reported?.toString(),
              reimbursedAmount: values.expenses.reimbursed?.toString(),
            },
          ]
        : [];

    const promise = visitLogUpdateMutation({
      variables: {
        visitInstanceId: visitData.id,
        input: {
          visitorIds: values.careGiver ? [values.careGiver.id] : [],
          startDate: dtStart.toISO() ?? "",
          durationMinutes: dtEnd.diff(dtStart, "minutes").minutes,
          clockIn: dtClockIn?.toISO(),
          clockOut: dtClockOut?.toISO(),
          visitNote: values.visitNote || null,
          visitTypeId: values.visitType?.id ?? null,
          labelIds: values.labelIds ?? null,
          mileage,
          expenses,
        },
      },
    }).then(() => {
      setEditView(false);
      visitLogUpdated({
        care_recipient_id: visitData.careRecipientId,
      });
    });

    return toast.promise(promise, {
      loading: t("visitLog.updating"),
      success: t("visitLog.updateSuccess"),
      error: (err) => errorToToastMessage(err),
    });
  };
  const handleCancelVisit = (values: {
    cancelledOnDate: DateTime;
    reason: CancelledReason;
  }) => {
    const promise = visitCancelMutation({
      variables: {
        visitInstanceId: visitData.id,
        cancelledAt: values.cancelledOnDate.toUTC().toISO() ?? "",
        cancelledReason: values.reason,
      },
    });

    return toast.promise(promise, {
      loading: t("visitDetails.cancellingVisit"),
      success: t("visitDetails.successCancel"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleReactivateVisit = (visitInstanceId: string) => {
    const promise = visitReactivateMutation({
      variables: {
        visitInstanceId,
      },
    });

    return toast.promise(promise, {
      loading: t("visitDetails.reactivatingVisit"),
      success: t("visitDetails.successReactivate"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const watchVisitNote = watch("visitNote");
  const watchStartDate = watch("scheduledDate");
  const watchStartTime = watch("scheduledStartTime");
  const watchEndTime = watch("scheduledEndTime");
  const watchClockInTime = watch("clockInTime");
  const watchClockOutTime = watch("clockOutTime");

  const activities = visitData.activities;
  const startTimeDT = watchStartTime && DateTime.fromObject(watchStartTime);
  const endTimeDt = watchEndTime && DateTime.fromObject(watchEndTime);
  const clockInTimeDT =
    watchClockInTime && DateTime.fromObject(watchClockInTime);
  const clockOutTimeDT =
    watchClockInTime &&
    watchClockOutTime &&
    DateTime.fromObject(watchClockOutTime);

  const locations = useMemo<Marker[]>(() => {
    const l: Marker[] = [];
    if (visitData.clockInLocation) {
      l.push({
        lat: visitData.clockInLocation.lat,
        lng: visitData.clockInLocation.lng,
        title: t("visitLog.markerClockIn"),
      });
    }

    if (visitData.clockOutLocation) {
      l.push({
        lat: visitData.clockOutLocation.lat,
        lng: visitData.clockOutLocation.lng,
        title: t("visitLog.markerClockOut"),
      });
    }
    return l;
  }, [t, visitData.clockInLocation, visitData.clockOutLocation]);

  const copyScheduledTime = () => {
    formMethods.setValue("clockInTime", watchStartTime);
    formMethods.setValue("clockOutTime", watchEndTime);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {visitData.cancelledAt && <CancelledVisitCard editView={editView} />}
          <FormSection>
            <FormSection.Label>{t("careRecipient")}</FormSection.Label>
            <div className="flex h-12 items-center px-5">
              <Link
                size="m"
                to={`/care-recipients/${visitData.careRecipient?.id}`}
              >{`${visitData.careRecipient?.firstName} ${visitData.careRecipient?.lastName}`}</Link>
            </div>

            <FormSection.Label id="careGiver-label" htmlFor="careGiver">
              {t("caregiver")}
            </FormSection.Label>
            <Controller
              control={control}
              name="careGiver"
              render={({ field }) => (
                <CaregiverSelect
                  aria-labelledby="careGiver-label"
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  editView={editView && !visitData.cancelledAt}
                  officeIdFilter={visitData.officeId}
                />
              )}
            />

            <FormSection.Label htmlFor="scheduledDate" id="scheduledDate-label">
              {t("date")}
            </FormSection.Label>
            <div>
              <Controller
                control={control}
                name="scheduledDate"
                render={({ field }) => (
                  <DatePicker
                    dateSettings={viewer?.tenantSettings}
                    id="scheduledDate"
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    isInvalid={!!errors.scheduledDate}
                    aria-labelledby="scheduledDate-label"
                    editView={editView && !visitData.cancelledAt}
                  />
                )}
              />
              {errors.scheduledDate && (
                <ValidationMessage dataTestId="scheduledDate-error">
                  {t(errors.scheduledDate?.message ?? "")}
                </ValidationMessage>
              )}
            </div>

            {viewer?.tenantSettings.enableLabels && (
              <>
                <FormSection.Label htmlFor="labels">
                  {t("visitDetailsForm.label")}
                </FormSection.Label>
                <Controller
                  control={control}
                  name="labelIds"
                  render={({ field }) => (
                    <LabelsSelect
                      value={field.value ?? []}
                      onChange={field.onChange}
                      officeId={visitData.officeId}
                      displayView={!editView || !!visitData.cancelledAt}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </>
            )}

            {viewer?.tenantSettings.enableVisitBilling && (
              <>
                <FormSection.Label htmlFor="visitType">
                  {t("visitDetailsForm.visitType")}
                </FormSection.Label>
                <Controller
                  control={control}
                  name="visitType"
                  render={({ field }) => (
                    <VisitTypeSelect
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      activeOn={watchStartDate?.toISO() ?? null}
                      editView={editView && !visitData.cancelledAt}
                    />
                  )}
                />
              </>
            )}
          </FormSection>
          {(watchVisitNote || (editView && !visitData.cancelledAt)) && (
            <>
              <div className="flex items-center gap-6 px-7 pb-2 pt-4">
                <Headline size="m">{t("visitLog.visitNote")}</Headline>
                {visitData.visitNoteSentiment && (
                  <SentimentPill sentiment={visitData.visitNoteSentiment} />
                )}{" "}
              </div>
              <div className="px-4 pb-8">
                <label
                  id="visitNote-label"
                  htmlFor="visitNote"
                  className="sr-only"
                >
                  {t("visitLog.visitNote")}
                </label>
                <div className="w-full rounded-2xl px-3 py-3 shadow">
                  <TextAreaWide
                    control={control}
                    name="visitNote"
                    id="visitNote"
                    editView={editView && !visitData.cancelledAt}
                    aria-labelledby="visitNote-label"
                  />
                </div>
              </div>
            </>
          )}
          <div className="px-4 pb-8 pt-6">
            <FormTable.Header gridCols="grid-cols-4">
              <Headline size="m" className="px-5">
                {t("visitLog.time")}
              </Headline>
              <Label size="m" className="justify-self-end px-5">
                {t("visitLog.start")}
              </Label>
              <Label size="m" className="justify-self-end px-5">
                {t("visitLog.end")}
              </Label>
              <Label size="m" className="justify-self-end px-5">
                {t("duration")}
              </Label>
            </FormTable.Header>

            <FormTable.Body>
              <FormTable.Row gridCols="grid-cols-4">
                <FormTable.Cell>
                  <Label size="m" className="mx-5">
                    {t("visitLog.scheduled")}
                  </Label>
                </FormTable.Cell>
                <FormTable.Cell justifyEnd borderRight>
                  <label htmlFor="scheduledStartTime" className="sr-only">
                    {t("visitLog.start")} - {t("visitLog.scheduled")}
                  </label>
                  <Controller
                    control={control}
                    name="scheduledStartTime"
                    render={({ field }) => (
                      <TimePicker
                        dateSettings={viewer?.tenantSettings}
                        className="grow"
                        id="scheduledStartTime"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        invalid={!!errors.scheduledStartTime}
                        alignRight
                        editView={editView && !visitData.cancelledAt}
                      />
                    )}
                  />
                </FormTable.Cell>
                <FormTable.Cell justifyEnd>
                  <label htmlFor="scheduledEndTime" className="sr-only">
                    {t("visitLog.end")} - {t("visitLog.scheduled")}
                  </label>
                  <Controller
                    control={control}
                    name="scheduledEndTime"
                    render={({ field }) => (
                      <TimePicker
                        dateSettings={viewer?.tenantSettings}
                        className="grow"
                        id="scheduledEndTime"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        invalid={!!errors.scheduledEndTime}
                        alignRight
                        editView={editView && !visitData.cancelledAt}
                      />
                    )}
                  />
                </FormTable.Cell>
                <FormTable.Cell justifyEnd>
                  <Paragraph size="m" className="mx-5 justify-self-end">
                    {startTimeDT && endTimeDt
                      ? formatDuration(getDuration(startTimeDT, endTimeDt), t)
                      : "-"}
                  </Paragraph>
                </FormTable.Cell>
              </FormTable.Row>
              <FormTable.Row gridCols="grid-cols-4">
                <FormTable.Cell>
                  <Label size="m" className="mx-5">
                    {t("visitLog.logged")}
                  </Label>
                </FormTable.Cell>
                <FormTable.Cell justifyEnd borderRight>
                  <label htmlFor="clockInTime" className="sr-only">
                    {t("visitLog.start")} - {t("visitLog.logged")}
                  </label>
                  <Controller
                    control={control}
                    name="clockInTime"
                    render={({ field }) => (
                      <TimePicker
                        dateSettings={viewer?.tenantSettings}
                        className="grow"
                        id="clockInTime"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        invalid={!!errors.clockInTime}
                        alignRight
                        editView={editView && !visitData.cancelledAt}
                      />
                    )}
                  />
                </FormTable.Cell>
                <FormTable.Cell justifyEnd>
                  <label htmlFor="clockOutTime" className="sr-only">
                    {t("visitLog.end")} - {t("visitLog.logged")}
                  </label>
                  <Controller
                    control={control}
                    name="clockOutTime"
                    render={({ field }) => (
                      <TimePicker
                        dateSettings={viewer?.tenantSettings}
                        className="grow"
                        id="clockOutTime"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        invalid={!!errors.clockOutTime}
                        alignRight
                        editView={editView && !visitData.cancelledAt}
                      />
                    )}
                  />
                </FormTable.Cell>
                <FormTable.Cell justifyEnd>
                  <Paragraph size="m" className="mx-5">
                    {clockInTimeDT && clockOutTimeDT
                      ? formatDuration(
                          getDuration(clockInTimeDT, clockOutTimeDT),
                          t,
                        )
                      : "-"}
                  </Paragraph>
                </FormTable.Cell>
              </FormTable.Row>
              {editView && !visitData.cancelledAt && (
                <FormTable.Row gridCols="grid-cols-4">
                  <Button
                    type="button"
                    variant="tertiary"
                    onClick={() => copyScheduledTime()}
                    className="col-span-4"
                    text={t("visitDetails.copyScheduledTime")}
                  />
                </FormTable.Row>
              )}
            </FormTable.Body>
          </div>

          {viewer?.tenantSettings.enableMileageExpense && (
            <>
              <div className="px-4 pb-8 pt-6">
                <FormTable.Header gridCols="grid-cols-4">
                  <Headline size="m" className="mx-5">
                    {t("visitLog.mileage")}
                  </Headline>
                  <Label size="m" className="mx-5 justify-self-end">
                    {t("visitLog.logged")}
                  </Label>
                  <Label size="m" className="mx-5 justify-self-end">
                    {t("visitLog.reimbursed")}
                  </Label>
                  <Label size="m" className="mx-5 justify-self-end">
                    {t("visitLog.billed")}
                  </Label>
                </FormTable.Header>

                <FormTable.Body>
                  <FormTable.Row gridCols="grid-cols-4">
                    <FormTable.Cell>
                      <Label size="m" className="mx-5">
                        {t("visitLog.mileageToAndFrom")}
                      </Label>
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd borderRight>
                      <DistanceInput
                        {...register("mileage.drivenToLocationReported", {
                          setValueAs: valueAsNumberOpt,
                        })}
                        placeholder="-"
                        invalid={!!errors.mileage?.drivenToLocationReported}
                        editView={editView && !visitData.cancelledAt}
                        alignRight
                        aria-label={`${t("visitLog.mileageToAndFrom")} - ${t(
                          "visitLog.logged",
                        )}`}
                      />
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd>
                      <DistanceInput
                        {...register("mileage.drivenToLocationReimbursed", {
                          setValueAs: valueAsNumberOpt,
                        })}
                        placeholder="-"
                        invalid={!!errors.mileage?.drivenToLocationReimbursed}
                        editView={editView && !visitData.cancelledAt}
                        aria-label={`${t("visitLog.mileageToAndFrom")} - ${t(
                          "visitLog.reimbursed",
                        )}`}
                        alignRight
                      />
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd>
                      <DistanceInput
                        {...register("mileage.drivenToLocationBilled", {
                          setValueAs: valueAsNumberOpt,
                        })}
                        placeholder="-"
                        invalid={!!errors.mileage?.drivenToLocationBilled}
                        editView={editView && !visitData.cancelledAt}
                        aria-label={`${t("visitLog.mileageToAndFrom")} - ${t(
                          "visitLog.billed",
                        )}`}
                        alignRight
                      />
                    </FormTable.Cell>
                  </FormTable.Row>

                  <FormTable.Row gridCols="grid-cols-4">
                    <FormTable.Cell>
                      <Label size="m" className="mx-5">
                        {t("visitLog.mileageForCareRecipient")}
                      </Label>
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd borderRight>
                      <DistanceInput
                        {...register("mileage.drivenForCareRecipientReported", {
                          setValueAs: valueAsNumberOpt,
                        })}
                        placeholder="-"
                        invalid={
                          !!errors.mileage?.drivenForCareRecipientReported
                        }
                        editView={editView && !visitData.cancelledAt}
                        aria-label={`${t(
                          "visitLog.mileageForCareRecipient",
                        )} - ${t("visitLog.logged")}`}
                        alignRight
                      />
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd>
                      <DistanceInput
                        {...register(
                          "mileage.drivenForCareRecipientReimbursed",
                          {
                            setValueAs: valueAsNumberOpt,
                          },
                        )}
                        placeholder="-"
                        invalid={
                          !!errors.mileage?.drivenForCareRecipientReimbursed
                        }
                        editView={editView && !visitData.cancelledAt}
                        aria-label={`${t(
                          "visitLog.mileageForCareRecipient",
                        )} - ${t("visitLog.reimbursed")}`}
                        alignRight
                      />
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd>
                      <DistanceInput
                        {...register("mileage.drivenForCareRecipientBilled", {
                          setValueAs: valueAsNumberOpt,
                        })}
                        placeholder="-"
                        invalid={!!errors.mileage?.drivenForCareRecipientBilled}
                        editView={editView && !visitData.cancelledAt}
                        aria-label={`${t(
                          "visitLog.mileageForCareRecipient",
                        )} - ${t("visitLog.billed")}`}
                        alignRight
                      />
                    </FormTable.Cell>
                  </FormTable.Row>
                </FormTable.Body>
              </div>

              <div className="px-4 pb-8 pt-6">
                <FormTable.Header gridCols="grid-cols-4">
                  <Headline size="m" className="px-5">
                    {t("visitLog.expenses")}
                  </Headline>
                  <Label size="m" className="justify-self-end px-5">
                    {t("visitLog.logged")}
                  </Label>
                  <Label size="m" className="justify-self-end px-5">
                    {t("visitLog.reimbursed")}
                  </Label>
                  <Label size="m" className="justify-self-end px-5">
                    {t("visitLog.billed")}
                  </Label>
                </FormTable.Header>

                <FormTable.Body>
                  <FormTable.Row gridCols="grid-cols-4">
                    <FormTable.Cell borderRight>
                      <Input
                        className={classNames("pr-1", {
                          "!bg-greyscale-0": editView,
                        })}
                        {...register("expenses.description")}
                        type="text"
                        placeholder={
                          t("visitLog.expenseDescriptionPlaceholder") ?? ""
                        }
                        errorMessage={errors.expenses?.description?.message}
                        displayView={!editView && !visitData.cancelledAt}
                        aria-label={`${t("visitLog.expenses")} - ${t(
                          "visitLog.description",
                        )}`}
                      />
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd borderRight>
                      <CurrencyInput
                        control={control}
                        name="expenses.reported"
                        editView={editView && !visitData.cancelledAt}
                        placeholder="-"
                        alignRight
                        aria-label={`${t("visitLog.expenses")} - ${t(
                          "visitLog.logged",
                        )}`}
                      />
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd>
                      <CurrencyInput
                        name="expenses.reimbursed"
                        control={control}
                        placeholder="-"
                        editView={editView && !visitData.cancelledAt}
                        alignRight
                        aria-label={`${t("visitLog.expenses")} - ${t(
                          "visitLog.reimbursed",
                        )}`}
                      />
                    </FormTable.Cell>
                    <FormTable.Cell justifyEnd>
                      <CurrencyInput
                        control={control}
                        name="expenses.billed"
                        placeholder="-"
                        editView={editView && !visitData.cancelledAt}
                        alignRight
                        aria-label={`${t("visitLog.expenses")} - ${t(
                          "visitLog.billed",
                        )}`}
                      />
                    </FormTable.Cell>
                  </FormTable.Row>
                </FormTable.Body>
              </div>
            </>
          )}

          {activities.length > 0 && (
            <div className="px-4">
              <Headline size="m" className="px-3 pb-4 pt-6">
                {t("visitLog.visitActivities")}
              </Headline>

              <ActivitiesTable
                activities={activities}
                loading={mutationLoading}
                insideSlideOver={true}
                isVisitLog={true}
              />
            </div>
          )}

          {locations.length > 0 && (
            <div>
              {/* TODO: reusable section header */}
              <Headline size="m" className="px-7 pb-4 pt-6">
                {t("visitLog.visitVerification")}
              </Headline>
              <div className="px-4 pb-8">
                <MapBox center={locations[0]} markers={locations} />
              </div>
            </div>
          )}

          {visitData.history && <VisitLogHistory history={visitData.history} />}

          <Form.StickyFooter>
            <div className="ml-auto flex justify-end gap-3">
              {!editView ? (
                <>
                  <Button
                    variant="secondary"
                    text={t(
                      visitData.cancelledAt
                        ? "visitDetails.reactivateVisit"
                        : "visitDetails.cancelVisit",
                    ).toString()}
                    onClick={() =>
                      visitData.cancelledAt
                        ? handleReactivateVisit(visitData.id)
                        : setShowCancelVisitModal(true)
                    }
                    disabled={mutationLoading}
                  />

                  <Button
                    key="edit-btn" // key is needed to prevent onSubmit from triggering
                    type="button"
                    className="ml-auto"
                    variant="primary"
                    text={t("edit") ?? ""}
                    onClick={() => setEditView(true)}
                  />
                </>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    text={t("discardChanges") ?? ""}
                    onClick={() => {
                      reset();
                      setEditView(false);
                    }}
                    disabled={mutationLoading}
                  />
                  <Button
                    key="save-btn"
                    className="ml-auto"
                    variant="primary"
                    text={t("save") ?? ""}
                    type="submit"
                    disabled={mutationLoading}
                    loading={isSubmitting}
                  />
                </>
              )}
            </div>
          </Form.StickyFooter>
        </form>
      </FormProvider>
      <CancelVisitModal
        show={showCancelVisitModal}
        onCancel={() => {
          setShowCancelVisitModal(false);
        }}
        onConfirm={(values) => {
          handleCancelVisit({
            cancelledOnDate: values.cancelledAt,
            reason: values.reason,
          }).then(() => {
            setShowCancelVisitModal(false);
          });
        }}
      />
    </>
  );
};
