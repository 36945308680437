import { useId } from "react";

export const NavActiveBeam = ({ className }: { className: string }) => {
  // the linear gradient id must be unique on the page
  const uniqueId = useId();

  return (
    <svg
      className={className}
      width="1"
      height="88"
      viewBox="0 0 1 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1" height="88" fill={`url(#${uniqueId}_paint0_linear)`} />
      <defs>
        <linearGradient
          id={`${uniqueId}_paint0_linear`}
          x1="0"
          y1="0"
          x2="0"
          y2="88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F33D6" stopOpacity="0" />
          <stop offset="0.488866" stopColor="#4F33D6" />
          <stop offset="1" stopColor="#4F33D6" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
