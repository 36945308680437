import {
  UsersDocument,
  UsersQuery,
  useUserCaregiverRoleAddMutation,
  useUserCareRecipientRoleAddMutation,
  useUserCareTeamMemberRoleAddMutation,
  useUserCreateMutation,
  useUserDeleteMutation,
  useUserRoleDeactivateMutation,
  useUserRoleReactivateMutation,
  useUserUpdateMutation,
} from "../../../api/generated/graphql";

export const useUserMutationsWithCache = () => {
  const [userCreateMutation, { loading: createLoading }] =
    useUserCreateMutation({
      update: (cache, { data }) => {
        if (!data || !data.userCreate) return;
        const existingUsers = cache.readQuery<UsersQuery>({
          query: UsersDocument,
        });
        if (existingUsers?.users) {
          cache.writeQuery<UsersQuery>({
            query: UsersDocument,
            data: {
              users: [...existingUsers.users, data.userCreate],
            },
          });
        }
      },
    });

  const [userUpdateMutation, { loading: updateLoading }] =
    useUserUpdateMutation();

  const [userRoleReactivate] = useUserRoleReactivateMutation();

  const [userRoleDeactivate] = useUserRoleDeactivateMutation();

  const [addCaregiverRole] = useUserCaregiverRoleAddMutation();

  const [addCareRecipientRole] = useUserCareRecipientRoleAddMutation();

  const [addCareTeamMemberRole] = useUserCareTeamMemberRoleAddMutation();

  const [userDelete] = useUserDeleteMutation({
    update: (cache, { data }, { variables }) => {
      if (!data || !data.userDelete || !variables) return;
      const existingUsers = cache.readQuery<UsersQuery>({
        query: UsersDocument,
      });
      if (existingUsers?.users) {
        cache.writeQuery<UsersQuery>({
          query: UsersDocument,
          data: {
            users: existingUsers.users.filter(
              (user) => user.id !== variables.userId,
            ),
          },
        });
      }
    },
  });

  return {
    userCreateMutation,
    userUpdateMutation,
    updateLoading,
    createLoading,
    userRoleReactivate,
    userRoleDeactivate,
    addCaregiverRole,
    addCareRecipientRole,
    addCareTeamMemberRole,
    userDelete,
  };
};
