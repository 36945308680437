import classNames from "classnames";

import { ChevronLeft } from "@frontend/lyng/assets/icons/24/outline";
import { Message } from "@frontend/lyng/assets/icons/16/outline";
import { Label } from "@frontend/lyng/typography";
import { NumberPill } from "@frontend/lyng/pill";
import { useCommsContext } from "@frontend/lyng/comms";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { useTranslate } from "@tolgee/react";
import { Fragment } from "react/jsx-runtime";
import { ChatList } from "../../../pages/chat/ChatList";
import { NavActiveBeam } from "@frontend/lyng/assets/svg/NavActiveBeam";
import { useLocation } from "react-router";

const CommunicationsButton = ({
  open,
  onCommsPage,
}: {
  open: boolean;
  onCommsPage: boolean;
}) => {
  const { t } = useTranslate();
  const { unreadCount } = useCommsContext();

  return (
    <div className="relative">
      <PopoverButton
        className={classNames(
          open || onCommsPage ? "bg-primary-50" : "bg-white hover:bg-primary-0",
          "w-full flex gap-x-3 rounded px-3 py-4 items-center",
        )}
      >
        <Message aria-hidden="true" className="text-greyscale-900" />

        <Label size="xs" className="cursor-pointer">
          {t("comms.comms")}
        </Label>
        {unreadCount > 0 && (
          <NumberPill count={unreadCount} color="redSubdued" />
        )}
      </PopoverButton>
      {onCommsPage && (
        <NavActiveBeam className="absolute top-1/2 -right-1 -translate-y-1/2 z-[999]" />
      )}
    </div>
  );
};

export const CommunicationsPanel = () => {
  const location = useLocation();
  const { t } = useTranslate();

  const onCommsPage = location.pathname.includes("/chat");

  return (
    <Popover>
      {({ open }) => (
        <>
          <CommunicationsButton open={open} onCommsPage={onCommsPage} />
          {!onCommsPage && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="fixed h-full lg:h-full inset-0 lg:left-[416px] top-0 z-40 lg:w-96 lg:-translate-x-1/2 lg:max-w-3xl bg-primary-0 dark:bg-greyscale-900 lg:shadow-right">
                <div className="dark:text-white flex mt-6 lg:mt-0 ml-4">
                  <PopoverButton className="focus:outline-none lg:hidden">
                    <div className="h-8 w-8 rounded-lg">
                      <ChevronLeft className="m-1" />
                    </div>
                  </PopoverButton>
                  <div className="text-2xl font-semibold ml-4 mt-4 mb-4">
                    {t("notifications.notifications")}
                  </div>
                </div>
                <div className="bg-primary-0 dark:bg-greyscale-900 px-1 py-4">
                  <ChatList />
                </div>
              </PopoverPanel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
};
