import { getTimeZones } from "@vvo/tzdb";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { Duration } from "luxon";
import { Select } from "@frontend/lyng/forms/select/Select";

type Props = {
  id?: string;
  value: string | null;
  name?: string | undefined;
  onChange: (value: string | null) => void;
  onBlur: () => void;
  disabled?: boolean;
  invalid?: boolean;
  "aria-labelledby"?: string;
};

export const TimezoneSelect = ({
  id,
  value,
  name,
  onChange,
  onBlur,
  disabled,
  invalid,
  "aria-labelledby": ariaLabelledBy,
}: Props) => {
  const { t } = useTranslate();

  const timezones = useMemo(() => {
    return getTimeZones({ includeUtc: true });
  }, []);

  const selectedOption = useMemo(() => {
    if (!value) {
      return null;
    }
    const timezoneByName = timezones.find(
      (timezone) => timezone.name === value,
    );
    return (
      timezoneByName ??
      timezones.find(
        (timezone) => timezone.name === value || timezone.group.includes(value),
      )
    );
  }, [value, timezones]);

  return (
    <div className="max-w-lg" data-testid="timezone-select">
      <Select
        id={id}
        name={name}
        aria-label={t("placeholder.chooseTimezone").toString()}
        placeholder={t("placeholder.chooseTimezone")}
        options={timezones}
        value={selectedOption}
        isMulti={false}
        isClearable={true}
        onChange={(value) => onChange(value?.name || null)}
        onBlur={onBlur}
        isDisabled={disabled}
        filterOption={(option, rawInput) => {
          return (
            option.data.name.toLowerCase().includes(rawInput.toLowerCase()) ||
            option.data.group
              .join(" ")
              .toLowerCase()
              .includes(rawInput.toLowerCase()) ||
            option.data.mainCities.join(" ").toLowerCase().includes(rawInput)
          );
        }}
        getOptionLabel={(option) =>
          `${option.mainCities.join(", ")} (UTC ${Duration.fromObject({
            minutes: option.currentTimeOffsetInMinutes,
          }).toFormat("h:mm")})`
        }
        getOptionValue={(option) => option.name}
        aria-labelledby={ariaLabelledBy}
      />
    </div>
  );
};
