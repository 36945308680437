import { ListBox } from "@frontend/lyng/forms/listBox/ListBox";
import { SearchInput } from "@frontend/lyng/search/SearchInput";
import { ArrowLeft, ArrowRight } from "@frontend/lyng/assets/icons/24/outline";
import { Button } from "@frontend/lyng/button";
import { useTranslate } from "@tolgee/react";
import classNames from "classnames";
import { useMemo, useState } from "react";
import {
  InstantSearch,
  useMenu,
  UseMenuProps,
  usePagination,
  UsePaginationProps,
  useSearchBox,
  UseSearchBoxProps,
} from "react-instantsearch";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import {
  paginationClicked,
  searchPerformed,
} from "../../../typewriter/segment";
import { debounce } from "../../../utils/debounce";

type AdditionalSearchParameters = NonNullable<
  ConstructorParameters<
    typeof TypesenseInstantsearchAdapter
  >[0]["additionalSearchParameters"]
>;

type SearchProviderProps = {
  apiKey: string;
  indexName: string;
  additionalSearchParameters: AdditionalSearchParameters;
  children: React.ReactNode;
};
export const SearchProvider = ({
  apiKey,
  indexName,
  additionalSearchParameters,
  children,
}: SearchProviderProps) => {
  const searchClient = useMemo(() => {
    const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
      server: {
        apiKey,
        nodes: [
          {
            host: import.meta.env.VITE_SEARCH_DOMAIN,
            port: import.meta.env.VITE_SEARCH_PORT,
            protocol: import.meta.env.VITE_SEARCH_PROTOCOL,
          },
        ],
        cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
      },

      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  query_by is required.
      additionalSearchParameters,
    });
    return typesenseInstantsearchAdapter.searchClient;
  }, [apiKey, additionalSearchParameters]);

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      {children}
    </InstantSearch>
  );
};

const PaginationItem = ({
  label: number,
  isCurrentPage,
  onClick,
}: {
  label: number;
  isCurrentPage: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "inline-flex items-center border-t-2 px-4 py-4 text-sm font-medium",
        {
          "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
            !isCurrentPage,
          "border-primary-500 text-primary-600": isCurrentPage,
        },
      )}
    >
      {number}
    </button>
  );
};

const PaginationEllipsis = () => {
  return (
    <span className="inline-flex items-center border-t-2 border-transparent px-4 py-4 text-sm font-medium text-gray-500">
      ...
    </span>
  );
};

export const Pagination = ({ padding = 2, ...props }: UsePaginationProps) => {
  const { t } = useTranslate();
  const { pages, currentRefinement, nbPages, isFirstPage, isLastPage, refine } =
    usePagination({ ...props, padding });
  const firstPageIndex = 0;
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const lastPageIndex = nbPages - 1;

  if (nbPages <= 1) {
    return null;
  }

  const handlePagination = (page: number, type: "next" | "prev" | "number") => {
    window.scrollTo(0, 0);
    paginationClicked({
      page,
      previous: type === "prev",
      next: type === "next",
    });
    refine(page);
  };

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4">
      <div className="-mt-px flex w-0 flex-1">
        {!isFirstPage && (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handlePagination(previousPageIndex, "prev")}
            text={t("pagination.previous")}
            icon={ArrowLeft}
            iconPosition="left"
          />
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages[0] !== firstPageIndex && (
          <>
            <PaginationItem
              label={firstPageIndex + 1}
              isCurrentPage={false}
              onClick={() => handlePagination(firstPageIndex, "number")}
            />
            <PaginationEllipsis />
          </>
        )}
        {pages.map((page) => {
          const isCurrentPage = page === currentRefinement;
          return (
            <PaginationItem
              key={page}
              label={page + 1}
              isCurrentPage={isCurrentPage}
              onClick={() => handlePagination(page, "number")}
            />
          );
        })}
        {pages[pages.length - 1] < lastPageIndex && (
          <>
            <PaginationEllipsis />
            <PaginationItem
              label={lastPageIndex + 1}
              isCurrentPage={false}
              onClick={() => handlePagination(lastPageIndex, "number")}
            />
          </>
        )}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {!isLastPage && (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handlePagination(nextPageIndex, "next")}
            text={t("pagination.next")}
            icon={ArrowRight}
            iconPosition="right"
          />
        )}
      </div>
    </nav>
  );
};

// debounce the analytics event so it doesn't fire on every keystroke
const debouncedSearchPerformed = debounce(searchPerformed, 800);

export const SearchBox = (
  props: UseSearchBoxProps & { placeholder?: string },
) => {
  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);

  function setQuery(newQuery: string) {
    if (newQuery !== "") {
      debouncedSearchPerformed({ query_length: newQuery.length });
    }

    setInputValue(newQuery);
    refine(newQuery);
  }

  return (
    <SearchInput inputValue={inputValue} setInputValue={setQuery} {...props} />
  );
};

export const SearchListBox = (
  props: Omit<UseMenuProps, "limit" | "showMoreLimit" | "showMore"> & {
    allItemsLabel: string;
  },
) => {
  const { items, refine } = useMenu({ ...props, limit: 999 });

  const allItemsOption = { value: "", label: props.allItemsLabel };
  const selected = items.find((item) => item.isRefined) || allItemsOption;

  // const showAll = { value: "", label: "All" };
  return (
    <ListBox
      options={[allItemsOption, ...items]}
      value={selected}
      getOptionLabel={(item) => item.label}
      getOptionKey={(item) => item.value}
      onChange={(newValue) => {
        refine(newValue?.value ?? "");
      }}
    />
  );
};
