import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@tolgee/react";

import { useEventsByCaregiverIdLazyQuery } from "../../../api/generated/graphql";
import { Schedule, VisitInstance } from "../../../components/common";
import { getEndOfWeek, getStartOfWeek } from "@frontend/lyng/utils/dateUtils";
import useGetHolidays from "../../../utils/hooks/useGetHolidays";

import { TopActions } from "../../../components/common";
import { useCareContext } from "../../../providers/CareProvider";
import { DateTime } from "luxon";
import {
  visitCreationStarted,
  VisitCreationStartedSource,
} from "../../../typewriter/segment";

type Props = {
  caregiverId: string;
};
export const CaregiverSchedule = ({ caregiverId }: Props) => {
  const {
    state: { viewer },
  } = useCareContext();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const timezone = viewer?.tenantSettings.timezone ?? "UTC";

  // Current calendar view range
  const [dateRange, setDateRange] = useState<{
    from: string;
    to: string;
  } | null>(null);

  const [getEvents, { data, error, loading }] = useEventsByCaregiverIdLazyQuery(
    {
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (dateRange) {
      getEvents({
        variables: {
          caregiverId: caregiverId,
          from: dateRange.from,
          to: dateRange.to,
        },
      });
    }
  }, [dateRange, getEvents, caregiverId]);

  const { holidays, loading: holidaysLoading } = useGetHolidays(
    dateRange?.from ?? getStartOfWeek(),
    dateRange?.to ?? getEndOfWeek(),
    // TODO: use office settings for caregiver
    viewer?.tenantSettings.country,
  );

  const handleNewVisit = (path: string, source?: "calendar" | "button") => {
    navigate(`visits/new/${path}`);
    visitCreationStarted({
      care_recipient_id: caregiverId,
      source:
        source === "button"
          ? VisitCreationStartedSource.Button
          : VisitCreationStartedSource.Calendar,
    });
  };

  const getTitle = (visit: VisitInstance) => {
    return `${visit.careRecipient?.firstName ?? ""} ${visit.careRecipient?.lastName ?? ""}`.trim();
  };

  const topActions: TopActions = {
    header: t("caregiverDetails.visits"),
    actions: [
      {
        text: t("absences.register"),
        onClick: () => navigate("absence"),
        variant: "secondary",
      },
      {
        text: `+ ${t("careRecipientDetails.addVisits")}`,
        onClick: () =>
          handleNewVisit(
            `${DateTime.now()
              .plus({ hour: 1 })
              .set({ minute: 0, second: 0, millisecond: 0 })
              .toISO()}`,
            "button",
          ),
        variant: "primary",
      },
    ],
  };

  const absenceClick = (id: string) => {
    navigate(`absence/${id}`);
  };

  if (error) return <p>Error : {error.message}</p>;

  return (
    <Schedule
      events={data?.eventsByCaregiverId ?? []}
      getTitle={getTitle}
      holidays={holidays}
      loading={loading || holidaysLoading}
      editable={false}
      datesSet={({ startStr, endStr }) => {
        setDateRange({ from: startStr, to: endStr });
      }}
      dateClick={(e) => {
        if (DateTime.now() <= DateTime.fromISO(e.dateStr)) {
          handleNewVisit(e.dateStr);
        }
      }}
      select={(e) => {
        handleNewVisit(`${e.startStr}--${e.endStr}`);
      }}
      eventClick={(e) => {
        navigate(`visits/${e.event.id}`, { preventScrollReset: true });
      }}
      topActions={topActions}
      absenceClick={absenceClick}
      timezone={timezone}
    />
  );
};
