import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { Checkmark, Critical } from "../../assets/icons/16/outline";
import { ChevronDown } from "../../assets/icons/24/filled";
import { ChevronUp } from "../../assets/icons/24/filled";
import { Button } from "../../button";
import { ErrorMessage } from "../errorMessage/ErrorMessage";

type Props<Option> = {
  options: NonNullable<Option>[];
  value: Option | null;
  getOptionLabel: (opt: NonNullable<Option>) => string;
  getOptionKey: (opt: NonNullable<Option>) => string;
  onChange: (value: Option | null) => void;
  errorMessage?: string;
};

function ListBox<Option>({
  options,
  value,
  getOptionLabel,
  getOptionKey,
  onChange,
  errorMessage,
}: Props<Option>) {
  const label = value ? getOptionLabel(value) : "";
  const innerValue = value ? getOptionKey(value) : null;
  const selected =
    options.find((option) => getOptionKey(option) === innerValue) ?? null;

  return (
    <div className="flex flex-col gap-3">
      <div className="relative">
        <Listbox
          value={selected}
          onChange={(value) =>
            onChange(!value || value === selected ? null : value)
          }
        >
          {({ open }) => (
            <div className="relative">
              <ListboxButton
                as={Button}
                size="md"
                variant="secondary"
                text={label}
                icon={open ? ChevronUp : ChevronDown}
                iconPosition="right"
              />
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-96 overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
                {options.map((option) => (
                  <ListboxOption
                    key={getOptionKey(option)}
                    value={option}
                    className={({ focus, selected }) =>
                      `group relative cursor-default select-none py-3 pl-3 m-1 rounded-lg ${
                        focus && !selected ? "bg-primary-50 " : null
                      } ${selected ? "bg-primary-100" : null}`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={"block truncate "}>
                          {getOptionLabel(option)}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-black">
                            <Checkmark aria-hidden="true" className="h-5 w-5" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          )}
        </Listbox>
        {errorMessage && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <Critical className="text-critical-800 dark:text-critical-400" />
          </div>
        )}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}

export { ListBox };
