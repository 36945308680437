import { FormOutput, VisitTypeForm } from "./visitTypeForm";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useVisitTypeByIdQuery,
  useVisitTypeUpdateMutation,
} from "../../../../api/generated/graphql";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../../utils/toastUtils";
import { LoadingBounce } from "@frontend/lyng/loading";
import { SlideOver } from "@frontend/lyng/slideOver";

export const EditVisitTypeSlideOver = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { visitTypeId } = useParams();

  const [visitTypeUpdate] = useVisitTypeUpdateMutation();
  const { data, loading, error } = useVisitTypeByIdQuery({
    skip: !visitTypeId,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: visitTypeId!,
    },
  });

  const handleSubmit = (values: FormOutput) => {
    const promise = visitTypeUpdate({
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: visitTypeId!,
        input: {
          title: values.title,
          code: values.code,
          billingType: values.billingType,
          baseRate: values.baseRate,
          basePeriodStart: values.basePeriodStart,

          eveningRate: values.eveningPeriod?.rate,
          eveningPeriodStart: values.eveningPeriod?.start,

          nightRate: values.nightPeriod?.rate,
          nightPeriodStart: values.nightPeriod?.start,
        },
      },
      refetchQueries: ["VisitTypes"],
    });

    return toast.promise(promise, {
      loading: t("visitTypes.updatingVisitType"),
      success: () => t("visitTypes.visitTypeUpdated"),
      error: errorToToastMessage,
    });
  };

  return (
    <SlideOver.WithIntercomHider show={true} onClose={() => navigate("..")}>
      <SlideOver.Title>{t("visitTypes.visitType").toString()}</SlideOver.Title>
      <SlideOver.DetailSection>
        {!data && loading && (
          <div className="flex h-full items-center justify-center">
            <LoadingBounce />
          </div>
        )}
        {error && <p>{error.message}</p>}
        {data && (
          <VisitTypeForm
            visitType={data.visitTypeById}
            onSubmit={(values) =>
              handleSubmit(values).then(() => navigate(".."))
            }
          />
        )}
      </SlideOver.DetailSection>
    </SlideOver.WithIntercomHider>
  );
};
