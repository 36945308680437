import { z } from "zod";
import {
  NameOrder,
  useCareGiversForSelectQuery,
} from "../../../api/generated/graphql";
import { useTranslate } from "@tolgee/react";
import { Paragraph, Link } from "@frontend/lyng/typography";
import { AriaAttributes, useMemo } from "react";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { Select } from "@frontend/lyng/forms/select/Select";

export const optionSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
});
export type Option = z.infer<typeof optionSchema>;

type Props = {
  value: Option | null;
  name?: string | undefined;
  editView?: boolean;
  onChange: (value: Option | null) => void;
  onBlur: () => void;
  officeIdFilter: string;
} & AriaAttributes;

export const CaregiverSelect = ({
  value,
  name,
  editView = true,
  onChange,
  onBlur,
  officeIdFilter,
  ...props
}: Props) => {
  const { t } = useTranslate();
  const { nameOrder, collator } = useSortingOptions();
  const { data, loading, error } = useCareGiversForSelectQuery({
    variables: {
      filter: { officeIds: [officeIdFilter] },
    },
  });

  const getName = (name: Option, nameOrder: NameOrder): string | null => {
    if (!name.firstName || !name.lastName) return null;
    return nameOrder === "FIRST_LAST"
      ? name.firstName.toLowerCase()
      : name.lastName.toLocaleLowerCase();
  };

  const caregiversOptions = useMemo(() => {
    let cg: {
      __typename?: "CareGiver";
      id: string;
      firstName: string | null;
      lastName: string | null;
    }[] = [];

    if (data?.careGivers) {
      const caregivers = [...data.careGivers].sort((a, b) => {
        const nameA = getName(a, nameOrder);
        const nameB = getName(b, nameOrder);

        if (nameA && nameB) {
          return collator.compare(nameA, nameB);
        }
        if (nameA === null) return 1;
        if (nameB === null) return -1;
        return 0;
      });
      cg = caregivers;
    }
    return cg;
  }, [collator, data?.careGivers, nameOrder]);

  if (!editView) {
    return (
      <div className="flex h-12 items-center px-5">
        {value ? (
          <Link size="m" to={`/caregivers/${value.id}`}>
            {`${value.firstName} ${value.lastName}`}
          </Link>
        ) : (
          <Paragraph size="m">-</Paragraph>
        )}
      </div>
    );
  }

  return (
    <div data-testid="caregiver-dropdown" className="sm:max-w-xs max-w-lg">
      <Select
        {...props}
        name={name}
        placeholder={t("placeholder.chooseCaregiver")}
        options={caregiversOptions}
        isLoading={loading}
        value={value}
        isMulti={false}
        isClearable={true}
        onChange={onChange}
        onBlur={onBlur}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionValue={(option) => option.id}
        errorMessage={error && error.message}
      />
    </div>
  );
};
