import { PopoverButton } from "@headlessui/react";
import { Bell } from "../assets/icons/16/outline";
import { capUnseen } from "./utils";

type Props = {
  unseen: number;
};

export const MobileNotificationsButton = ({ unseen }: Props) => {
  return (
    <PopoverButton className="focus:outline-none">
      {unseen > 0 && (
        <div className="ml-7 mt-1 w-4 h-4 bg-red-500 rounded-full flex items-center justify-center text-white text-xs font-semibold absolute">
          {capUnseen(unseen)}
        </div>
      )}
      <Bell className="h-12 w-12 p-3 rounded-xl border border-greyscale-700 text-white" />
    </PopoverButton>
  );
};
