import { useTranslate } from "@tolgee/react";
import { match } from "ts-pattern";
import { PillColors } from "./types";
import { useTranslateSentiment } from "../utils/translationUtils";
import { Sentiment } from "../api/generated/graphql";
import { SmallPill } from "./smallPill";

type sentimentKey = "neutral" | "mixed" | "positive" | "negative";

const getDisplaySentiment = (
  sentiment: Sentiment | undefined,
): sentimentKey | null => {
  if (!sentiment) {
    return null;
  }
  const keyValuePairs: { key: sentimentKey; value: number }[] = [
    { key: "neutral", value: sentiment.neutral },
    { key: "mixed", value: sentiment.mixed },
    { key: "positive", value: sentiment.positive },
    { key: "negative", value: sentiment.negative },
  ];
  return keyValuePairs.reduce((prev, curr) =>
    curr.value > prev.value ? curr : prev,
  ).key;
};

export const SentimentPill = ({
  sentiment,
}: {
  sentiment: Sentiment | undefined;
}) => {
  const { t } = useTranslate();
  const translateSentiment = useTranslateSentiment(t);

  const displaySentiment = getDisplaySentiment(sentiment);

  if (!displaySentiment) {
    return null;
  }

  const color = match<sentimentKey, PillColors>(displaySentiment)
    .with("neutral", () => "blue")
    .with("mixed", () => "gray")
    .with("positive", () => "green")
    .with("negative", () => "red")
    .exhaustive();

  // TODO change to medium pill when that has been created
  return (
    <SmallPill color={color} text={translateSentiment(displaySentiment)} />
  );
};
