import { useMemo, useState } from "react";
import { Configure } from "react-instantsearch";
import { useCareContext } from "../../providers";
import { useTranslate } from "@tolgee/react";
import { Headline, HeadlineContainer } from "@frontend/lyng/typography";
import { SearchBox, SearchProvider } from "../../components/common";
import { Button } from "@frontend/lyng/button";
import { Tabs } from "@frontend/lyng/tabs";
import { OfficeFilter } from "../../components/common/officeFilter/OfficeFilter";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import {
  CareRecipient as CareRecipientFormInput,
  CareRecipientFormSlideOver,
} from "./CareRecipientFormSlideOver";
import { UsersSearchTable } from "../../components/common";

type RoleActiveTab = "active" | "deactivated" | "all";

export const CareRecipients = () => {
  const {
    state: { viewer, selectedTenant, searchKeys },
  } = useCareContext();
  const { t } = useTranslate();
  const [careRecipientFormModal, setCareRecipientFormModal] =
    useState<Partial<CareRecipientFormInput> | null>(null);

  const isSsnEnabled = viewer?.tenantSettings.showSsn;

  const [selectedOfficeId, setSelectedOfficeId] = useLocalStorage<
    string | null
  >(`office-filter-${selectedTenant?.id}`, null);
  const [roleActiveTab, setRoleActiveTab] = useState<RoleActiveTab>("active");
  const roleActiveTabs: { id: RoleActiveTab; label: string }[] = useMemo(
    () => [
      {
        id: "active",
        label: t("careRecipients.active"),
      },
      {
        id: "deactivated",
        label: t("careRecipients.deactivated"),
      },
      {
        id: "all",
        label: t("careRecipients.all"),
      },
    ],
    [t],
  );

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("careRecipients.title")}</Headline>
        <Button
          variant="primary"
          text={t("careRecipients.add").toString()}
          onClick={() => {
            setCareRecipientFormModal({});
          }}
        />
      </HeadlineContainer>

      <SearchProvider
        apiKey={searchKeys?.usersKey.key ?? ""}
        indexName="users"
        additionalSearchParameters={{
          query_by: "first_name,last_name,email,pid",
          sort_by: "first_name_sortable:asc",
          facet_by: "role_types",
          perPage: 50,
        }}
      >
        <Configure
          filters={
            "role_types:CARE_RECIPIENT" +
            (selectedOfficeId
              ? `&& care_recipient_roles.office_id:${selectedOfficeId}`
              : "") +
            (roleActiveTab === "active"
              ? "&& care_recipient_state:ACTIVE"
              : roleActiveTab === "deactivated"
                ? "&& care_recipient_state:DEACTIVATED"
                : "")
          }
        />
        <div className="px-8 pb-4 flex flex-row gap-2">
          <SearchBox placeholder={t("search")} />
          <OfficeFilter
            selectedOfficeId={selectedOfficeId}
            setSelectedOfficeId={(officeId) => {
              setSelectedOfficeId(officeId);
            }}
            showAllOption={true}
          />
          <Tabs
            tabs={roleActiveTabs}
            currentTab={roleActiveTab}
            onChange={setRoleActiveTab}
          />
        </div>
        <UsersSearchTable
          columnVisibility={{
            name: true,
            pid: !!isSsnEnabled,
            address: true,
            careRecipientOffices: true,
          }}
          emptyMessage={t("careRecipients.noCareRecipientsFound")}
        />
      </SearchProvider>

      {careRecipientFormModal && (
        <CareRecipientFormSlideOver
          careRecipient={careRecipientFormModal}
          onClose={() => setCareRecipientFormModal(null)}
        />
      )}
    </div>
  );
};
