import { useCallback, useMemo, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ActivityTemplateByIdQuery,
  ActivityType,
  useActivityTypesQuery,
  useActivityGroupsQuery,
  Priority,
  ActivitySchedule,
} from "../../../api/generated/graphql";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DatePicker,
  FormGroup,
  Input,
  Select,
  TextArea,
  TimePicker,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { SlideOver } from "@frontend/lyng/slideOver";
import { Form, FormSection } from "../../../components/formfields";
import { Button } from "@frontend/lyng/button";
import { NestedOrganizationUnitSelector } from "./NestedOrganizationUnitSelector";
import { stringToCamelCaseString } from "../../../utils/formUtils";
import { ScheduleSelect } from "../../../components/common/activity-form/scheduleSelect";
import { scheduleSchema } from "../../../components/common/activity-form/types";
import { match } from "ts-pattern";
import { DateTime } from "luxon";
import { Paragraph } from "@frontend/lyng/typography";
import { useCareContext } from "../../../providers";

const validationSchema = z.object({
  title: z.string().min(1, { message: "activityTemplate.titleRequired" }),
  description: z
    .string()
    .min(1, { message: "activityTemplates.descriptionRequired" }),
  type: z.string().nullable(),
  schedule: scheduleSchema.nullable(),
  timeOfDayStart: z.object(timeSchemaShape).nullable(),
  organizationUnits: z.array(z.string()),
  priority: z.object({
    label: z.string(),
    value: z.nativeEnum(Priority),
  }),
  activityGroups: z.string().array(),
});

const getScheduleOption = (schedule: ActivitySchedule | null) => {
  const scheduleOption = match(schedule)
    .with({ __typename: "ActivityScheduleOneOff" }, (oneOff) => ({
      oneOff: {
        date: DateTime.fromISO(oneOff.date),
      },
    }))
    .with({ __typename: "ActivityScheduleEveryNDays" }, (everyNDays) => ({
      everyNDays,
    }))
    .with({ __typename: "ActivityScheduleEveryVisit" }, () => ({
      everyVisit: { everyDay: true },
    }))
    .with({ __typename: undefined }, () => null)
    .with(null, () => null)
    .exhaustive();
  return scheduleOption;
};

export type FormInput = z.infer<typeof validationSchema>;

type Props = {
  activityTemplate?: ActivityTemplateByIdQuery["activityTemplateById"];
  onSubmit: (data: FormInput) => Promise<void>;
  onDelete: (id: string) => void;
};

export const ActivityForm = ({
  activityTemplate,
  onSubmit,
  onDelete,
}: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const { groupId } = location.state || {};

  const { data: activityTypesData } = useActivityTypesQuery();
  const { data: activityGroupsData } = useActivityGroupsQuery();

  interface OptionType {
    value: string;
    label: string;
  }

  type PriorityOption = {
    label: string;
    value: Priority;
  };

  const {
    state: { viewer },
  } = useCareContext();

  const priorityLabels = {
    [Priority.High]: "activityDetailsModal.priorities.high",
    [Priority.Medium]: "activityDetailsModal.priorities.medium",
    [Priority.Low]: "activityDetailsModal.priorities.low",
    [Priority.Optional]: "activityDetailsModal.priorities.optional",
  };

  const getPriorityOption = (priority: Priority | null) => {
    if (!priority) return null;

    const key = priorityLabels[priority.toUpperCase() as Priority];
    if (!key) return null;

    return { label: key, value: priority };
  };

  const priorityOptions: PriorityOption[] = Object.values(Priority).map(
    (priority) => ({
      label: priorityLabels[priority],
      value: priority,
    }),
  );

  const translateActivityType = useCallback(
    (activityType: ActivityType) => {
      return activityType.custom
        ? activityType.title
        : t(`activityType.${stringToCamelCaseString(activityType.title)}`);
    },
    [t],
  );

  const activityGroupOptions: OptionType[] = useMemo(() => {
    return (
      activityGroupsData?.activityGroups.map((group) => ({
        value: group.id,
        label: group.title,
      })) ?? []
    );
  }, [activityGroupsData]);

  const activityTypeOptions: OptionType[] = useMemo(() => {
    return (
      activityTypesData?.activityTypes.map((type) => ({
        value: type.id,
        label: translateActivityType(type),
      })) ?? []
    );
  }, [activityTypesData, translateActivityType]);

  const methods = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      title: activityTemplate?.title ?? "",
      description: activityTemplate?.description ?? "",
      type: activityTemplate?.activityType?.title ?? null,
      timeOfDayStart: activityTemplate?.timeOfDayStart
        ? (({ hour, minute }) => ({ hour, minute }))(
            DateTime.fromFormat(
              activityTemplate.timeOfDayStart,
              "HH:mm",
            ).toObject(),
          )
        : null,
      priority:
        getPriorityOption(activityTemplate?.priority ?? null) || undefined,
      schedule: getScheduleOption(activityTemplate?.schedule ?? null),
      organizationUnits:
        activityTemplate?.organizationUnits?.map((unit) => unit.id || "") ?? [],
      activityGroups:
        activityTemplate?.groups.map((group) => group.id) ??
        (groupId && groupId !== "all" ? [groupId] : []),
    },
  });

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const [dayString, setDayString] = useState(t("activityForm.days"));
  const watchSchedule = watch("schedule");

  return (
    <FormProvider {...methods}>
      <div className="h-full">
        <form onSubmit={handleSubmit(onSubmit)} className="h-full">
          <SlideOver.DetailSection>
            <FormGroup label={t("title")} labelFor="title">
              <Input
                {...register("title")}
                placeholder={t("placeholder.title")}
                type="text"
                errorMessage={errors.title?.message && t(errors.title.message)}
              />
            </FormGroup>
            <FormGroup label={t("description")} labelFor="description">
              <TextArea
                control={control}
                name="description"
                placeholder={t("placeholder.description")}
                errorMessage={
                  errors.description?.message &&
                  t(errors.description?.message ?? "")
                }
              />
            </FormGroup>
            <FormGroup label={t("activities.time")} labelFor="timeOfDayStart">
              <Controller
                control={control}
                name={"timeOfDayStart"}
                render={({ field }) => (
                  <TimePicker
                    dateSettings={viewer?.tenantSettings}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
            </FormGroup>
            <FormGroup label={t("activityForm.schedule")} labelFor="schedule">
              <Controller
                control={control}
                name={"schedule"}
                render={({ field }) => (
                  <ScheduleSelect
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                  />
                )}
              />
            </FormGroup>
            {watchSchedule?.oneOff && (
              <FormGroup label={t("activityForm.date")} labelFor="oneOff">
                <Controller
                  control={control}
                  name="schedule.oneOff.date"
                  render={({ field }) => (
                    <DatePicker
                      dateSettings={viewer?.tenantSettings}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      aria-label="scheduled date"
                    />
                  )}
                />
              </FormGroup>
            )}

            {watchSchedule?.everyNDays && (
              <FormGroup
                label={t("activityForm.repeatsEvery")}
                labelFor="interval"
              >
                <div className="flex flex-row">
                  <Input
                    type="number"
                    min="0"
                    {...register("schedule.everyNDays.days", {
                      valueAsNumber: true,
                    })}
                    placeholder={t("placeholder.number")}
                    className="my-1.5"
                    errorMessage={errors.schedule?.everyNDays?.message}
                    onChange={async (event) => {
                      const daysValue = event.target.value;
                      <FormSection.Label htmlFor="priority">
                        {t("priority")}
                      </FormSection.Label>;
                      if (daysValue > 1) {
                        setDayString(t("activityForm.days"));
                      } else {
                        setDayString(t("activityForm.day"));
                      }
                      return;
                    }}
                  />
                  <Paragraph size="m" className="p-4">
                    {dayString}
                  </Paragraph>
                </div>
              </FormGroup>
            )}

            <FormGroup label={t("priority")} labelFor="type">
              <Controller
                control={control}
                name={"priority"}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    options={priorityOptions}
                    getOptionLabel={(option) => t(option.label)}
                  />
                )}
              />
            </FormGroup>
            <FormGroup label={t("type")} labelFor="type">
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Select
                    options={activityTypeOptions}
                    name={field.name}
                    isMulti={false}
                    placeholder={t("placeholder.selectActivityType")}
                    onChange={(selectedOption) => {
                      field.onChange(
                        selectedOption ? selectedOption.value : null,
                      );
                    }}
                    onBlur={field.onBlur}
                    value={
                      activityTypeOptions.find(
                        (option) => option.value === field.value,
                      ) || null
                    }
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    errorMessage={
                      errors.type?.message && t(errors.type.message)
                    }
                  />
                )}
              />
            </FormGroup>

            <FormGroup label={t("activityGroups")} labelFor="activityGroups">
              <Controller
                control={control}
                name="activityGroups"
                render={({ field }) => (
                  <Select
                    options={activityGroupOptions}
                    name={field.name}
                    isMulti={true}
                    placeholder={t("placeholder.selectActivityGroup")}
                    onChange={(selectedOptions) => {
                      field.onChange(
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [],
                      );
                    }}
                    onBlur={field.onBlur}
                    value={activityGroupOptions.filter((option) =>
                      field.value.includes(option.value),
                    )}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    errorMessage={
                      errors.activityGroups?.message &&
                      t(errors.activityGroups.message)
                    }
                  />
                )}
              />
            </FormGroup>
          </SlideOver.DetailSection>

          <SlideOver.SectionHeader title={t("organizationUnits")} />

          <SlideOver.CardSection>
            <NestedOrganizationUnitSelector />
          </SlideOver.CardSection>

          <Form.StickyFooter>
            {!activityTemplate ? (
              <Button
                className="ml-auto"
                variant="primary"
                type="submit"
                text={t("save")}
              />
            ) : (
              <>
                <Button
                  className="mr-auto"
                  variant="critical"
                  text={t("activityTemplate.deleteTemplate")}
                  onClick={() => onDelete(activityTemplate.id)}
                />
                <Button
                  className="mr-2"
                  variant="secondary"
                  onClick={() => navigate("..")}
                  text={t("cancel")}
                  disabled={isSubmitting}
                />
                <Button variant="primary" type="submit" text={t("save")} />
              </>
            )}
          </Form.StickyFooter>
        </form>
      </div>
    </FormProvider>
  );
};
