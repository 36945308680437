import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { SlideOver } from "@frontend/lyng/slideOver";
import Modal from "@frontend/lyng/modal/Modal";
import { Button } from "@frontend/lyng/button";
import { Paragraph } from "@frontend/lyng/typography";
import { LoadingBounce } from "@frontend/lyng/loading";
import { errorToToastMessage } from "../../../utils/toastUtils";
import {
  useActivityGroupCreateMutation,
  useActivityGroupByIdQuery,
  useActivityGroupUpdateMutation,
  ActivityGroupUpdateMutation,
  ActivityGroupCreateMutation,
  useActivityGroupDeleteMutation,
} from "../../../api/generated/graphql";
import { FetchResult } from "@apollo/client";
import { ActivityGroupForm, FormInput } from "./ActivityGroupForm";
import { useState } from "react";

export const ActivityGroupSlideOver = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const handleClose = () => navigate("..");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const { activityGroupId } = useParams();

  const [ActivityGroupCreate] = useActivityGroupCreateMutation();
  const [ActivityGroupUpdate] = useActivityGroupUpdateMutation();
  const [ActivityGroupDelete] = useActivityGroupDeleteMutation();

  const { data, loading, error } = useActivityGroupByIdQuery({
    skip: activityGroupId === "new",
    fetchPolicy: "cache-and-network",
    variables: { id: activityGroupId ?? "" },
  });

  const activityGroup =
    activityGroupId !== "new" ? data?.activityGroupById : undefined;

  const handleDelete = (id: string) => {
    setSelectedGroupId(id);
    setShowDeleteModal(true);
  };

  const onConfirm = () => {
    if (!selectedGroupId) return;

    const promise = ActivityGroupDelete({
      variables: { id: selectedGroupId ?? "" },
      refetchQueries: ["ActivityGroups"],
    }).then(() => {
      handleClose();
      setShowDeleteModal(false);
    });

    toast.promise(promise, {
      loading: t("activityGroup.deleting"),
      success: t("activityGroup.successDelete"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const onCancel = () => {
    setShowDeleteModal(false);
  };

  const handleSubmit = (values: FormInput) => {
    let promise: Promise<
      FetchResult<ActivityGroupUpdateMutation | ActivityGroupCreateMutation>
    >;

    if (activityGroup) {
      promise = ActivityGroupUpdate({
        variables: {
          id: activityGroupId ?? "",
          input: {
            title: values.title ?? "",
            activityTemplateIds: activityGroup?.activityTemplates?.map(
              (template) => template.id,
            ),
          },
        },
        refetchQueries: ["ActivityGroups"],
      });
    } else {
      promise = ActivityGroupCreate({
        variables: {
          input: {
            title: values.title ?? "",
            activityTemplateIds: [],
          },
        },
        refetchQueries: ["ActivityGroups"],
      });
    }

    return toast.promise<
      FetchResult<ActivityGroupUpdateMutation | ActivityGroupCreateMutation>
    >(promise, {
      loading: activityGroup
        ? t("activityGroup.updatingGroup")
        : t("activityGroup.creatingGroup"),
      success: () =>
        activityGroup
          ? t("activityGroup.groupUpdated")
          : t("activityGroup.groupCreated"),
      error: errorToToastMessage,
    });
  };

  return (
    <SlideOver.WithIntercomHider show={true} onClose={() => navigate("..")}>
      <SlideOver.Title>
        {activityGroupId
          ? t("activityTemplates.editGroup")
          : t("activityTemplates.addGroup")}
      </SlideOver.Title>
      {!data && loading ? (
        <div className="flex h-full items-center justify-center">
          <LoadingBounce className="text-primary-600" />
        </div>
      ) : (
        <ActivityGroupForm
          activityGroup={activityGroup}
          onSubmit={(values) => handleSubmit(values).then(() => navigate(".."))}
          onDelete={(id) => handleDelete(id)}
        />
      )}
      {error && <p>{error.message}</p>}

      <Modal show={showDeleteModal} onClose={onCancel}>
        <Modal.Title>{t("activityTemplates.deleteGroup")}</Modal.Title>
        <Paragraph size="m">
          {t("activityTemplates.deleteDescription", {
            groupName: activityGroup?.title,
          })}
        </Paragraph>
        <Modal.Footer>
          <Button
            variant="critical"
            text={t("activityTemplates.deleteGroup")}
            className="col-start-2"
            onClick={onConfirm}
          />
          <Button
            variant="secondary"
            text={t("cancel")}
            className="col-start-1"
            onClick={onCancel}
            disabled={loading}
          />
        </Modal.Footer>
      </Modal>
    </SlideOver.WithIntercomHider>
  );
};
