import classNames from "classnames";
import { HTMLInputTypeAttribute, forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { Critical } from "../../assets/icons/24/filled";
import { ErrorMessage } from "../errorMessage/ErrorMessage";

type Props = {
  id?: string;
  className?: string;
  type: HTMLInputTypeAttribute;
  value?: string | number | undefined;
  placeholder?: string;
  displayView?: boolean;
  errorMessage?: string | undefined;
  errorMessageIcon?: boolean;
  autoComplete?: string;
  onClick?: () => void;
};

export const Input = forwardRef<
  HTMLInputElement,
  Props & UseFormRegisterReturn
>(function Input(
  {
    type,
    onChange,
    onBlur,
    name,
    className,
    id,
    value,
    disabled,
    displayView = false,
    errorMessage,
    errorMessageIcon = false,
    autoComplete,
    placeholder,
    onClick,
    min,
    ...props
  },
  ref,
) {
  return (
    <div className="flex flex-col gap-3">
      <div className="relative ">
        <input
          {...props}
          ref={ref}
          id={id}
          className={classNames(
            className,
            "h-12 w-full rounded-xl border px-4 py-2.5 transition-colors placeholder-greyscale-600 placeholder:font-inclusive text-greyscale-900 text-lg dark:text-greyscale-100 dark:placeholder-greyscale-300",
            {
              "border-greyscale-200 hover:border-greyscale-400 focus:border-primary-600 dark:border-greyscale-700 dark:hover:border-greyscale-500 dark:focus:border-primary-600":
                !errorMessage && !displayView,
              "border-critical-600 pr-10 focus:border-primary-600":
                errorMessage && !displayView,
              "border-transparent bg-transparent placeholder-transparent hover:border-transparent":
                displayView,
              "bg-transparent hover:bg-greyscale-0 focus:bg-greyscale-0 dark:bg-greyscale-800 dark:hover:bg-greyscale-900 dark:focus:bg-greyscale-900":
                !displayView,
            },
          )}
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          min={min}
          value={value}
          disabled={disabled || displayView}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onClick={onClick}
          aria-invalid={errorMessage ? "true" : "false"}
        />
        {errorMessage && errorMessageIcon && !displayView && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <Critical className="text-critical-800 dark:text-critical-400" />
          </div>
        )}
      </div>
      {errorMessage && !displayView && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </div>
  );
});
