import classNames from "classnames";
import { ListItemsItem, Variant } from "./types";
import { Paragraph } from "../typography";
import { Checkmark as Checkmark16 } from "../assets/icons/16/outline";
import { NumberPill } from "../pill";

type ListItemProps<IsMulti extends boolean> = {
  id: string;
  name?: string;
  item: ListItemsItem;
  type: Variant<IsMulti>;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
function ListItem<IsMulti extends boolean>({
  id,
  name,
  item,
  type,
  checked,
  onChange,
}: ListItemProps<IsMulti>) {
  const { value, label } = item;
  return (
    <label
      htmlFor={id}
      className={classNames(
        "group px-4 py-2.5 cursor-pointer rounded-lg hover:bg-primary-50 focus:bg-primary-50 focus:outline-none",
        "has-[:checked]:bg-primary-100",
      )}
    >
      <div className="flex flex-row gap-3 items-center">
        <input
          id={id}
          name={name}
          type="checkbox"
          className="hidden peer"
          checked={checked}
          onChange={onChange}
          value={value}
        />

        {type === "checkbox" && (
          <div
            className={classNames(
              "flex items-center justify-center border h-7 w-7 rounded-md border-greyscale-700 -my-0.5",
              "peer-checked:border-primary-700 peer-checked:bg-primary-700",
              "hover:bg-greyscale-200 hover:peer-checked:bg-primary-500 ",
              "dark:border-greyscale-700 dark:hover:bg-greyscale-700",
              "dark:peer-checked:border-primary-600 dark:peer-checked:bg-primary-600",
            )}
          >
            <Checkmark16
              className={classNames(
                "relative group-has-[:checked]:block hidden pointer-events-none",
                "h-6 min-w-6 text-greyscale-0 peer-disabled:text-greyscale-500",
              )}
            />
          </div>
        )}

        {item.icon && item.icon}

        <Paragraph size="m" className="text-nowrap">
          {label}
        </Paragraph>

        {item.counter && (
          <NumberPill className="ml-2" color="yellow" count={item.counter} />
        )}

        <div className="flex gap-4 ml-auto">
          {item.subtext && (
            <Paragraph size="xxs" type="secondary">
              {item.subtext}
            </Paragraph>
          )}

          {type === "checkmark" && (
            <Checkmark16 className="group-has-[:checked]:block hidden pointer-events-none text-greyscale-900" />
          )}
        </div>
      </div>
    </label>
  );
}

type OnChangeValue<IsMulti extends boolean> = IsMulti extends true
  ? string[]
  : string | null;
type Props<IsMulti extends boolean = false> = {
  name?: string;
  type: Variant<IsMulti>;
  items: ListItemsItem[];
  value: IsMulti extends true ? string[] : string | null;
  onChange: (value: OnChangeValue<IsMulti>) => void;
  multi: IsMulti;
};
export function ListItems<IsMulti extends boolean = false>({
  name,
  items,
  type,
  value,
  onChange,
  multi,
}: Props<IsMulti>) {
  return (
    <div className="flex flex-col gap-1">
      {items.map((item) => (
        <ListItem
          key={item.value}
          id={item.value}
          name={name}
          type={type}
          item={item}
          checked={!!value?.includes(item.value)}
          onChange={(e) => {
            if (multi === true) {
              const copy = new Set<string>(value);
              if (e.target.checked) {
                copy.add(item.value);
              } else {
                copy.delete(item.value);
              }
              onChange(Array.from(copy) as OnChangeValue<IsMulti>);
            } else {
              onChange(
                (e.target.checked
                  ? item.value
                  : null) as OnChangeValue<IsMulti>,
              );
            }
          }}
        />
      ))}
    </div>
  );
}
