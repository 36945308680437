import { useCallback, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ResourceType,
  RoleType,
  UserByIdQuery,
} from "../../../api/generated/graphql";
import { BlockerFunction, useBlocker, useNavigate } from "react-router-dom";
import { Label, Link, Paragraph } from "@frontend/lyng/typography";
import { Edit, Plus, Close } from "@frontend/lyng/assets/icons/16/outline";
import { FormGroup, Input } from "@frontend/lyng/forms";
import { SlideOver } from "@frontend/lyng/slideOver";
import { Form, FormTable } from "../../../components/formfields";
import { Button } from "@frontend/lyng/button";
import { AnimatePresence, motion } from "framer-motion";
import { RolesForm, RolesInput } from "./RolesForm";
import {
  CareTeamMemberRoleTypeToRoleType,
  mapToResourceType,
  translateResourceType,
  translateRoleType,
} from "./utils";
import Modal from "@frontend/lyng/modal/Modal";
import { useCareContext } from "../../../providers";

const validationSchema = z
  .object({
    id: z.string().optional(),
    firstName: z.string().min(1, { message: "users.firstNameRequired" }),
    lastName: z.string().min(1, { message: "users.lastNameRequired" }),
    phone: z.string().nullable(),
    email: z.string().optional(),
    homePhone: z.string().nullable(),
    birthDate: z.string().nullable(),
    pid: z.string().nullable(),
    careRecipientRoles: z.array(
      z.object({
        role: z.object({ value: z.nativeEnum(RoleType), label: z.string() }),
        office: z.object({ id: z.string(), name: z.string() }),
        status: z.boolean(),
      }),
    ),
    careGiverRoles: z.array(
      z.object({
        role: z.object({ value: z.nativeEnum(RoleType), label: z.string() }),
        office: z.object({ id: z.string(), name: z.string() }),
        status: z.boolean(),
      }),
    ),
    contactRoles: z.array(
      z.object({
        role: z.object({ value: z.nativeEnum(RoleType), label: z.string() }),
      }),
    ),
    careTeamRoles: z.array(
      z
        .object({
          role: z.object({ value: z.nativeEnum(RoleType), label: z.string() }),
          organizationUnit: z
            .object({
              label: z.string(),
              id: z.string(),
              type: z.nativeEnum(ResourceType),
            })
            .optional(),
          status: z.boolean(),
        })
        .refine(
          (data) =>
            data.role &&
            data.organizationUnit?.id &&
            data.organizationUnit?.type,
          {
            message:
              "Role, Organization Unit ID, and Organization Unit Type are required for care team roles.",
            path: ["careTeamRoles"],
          },
        ),
    ),
  })
  .refine(
    (data) => {
      const hasRoles =
        data.careGiverRoles.length > 0 || data.careTeamRoles.length > 0;

      if (hasRoles && !data.email) {
        return false;
      }
      return true;
    },
    {
      message: "users.emailRequired",
      path: ["email"],
    },
  );

export type FormInput = z.infer<typeof validationSchema>;

type User = UserByIdQuery["userById"];
type RoleWithIndex = RolesInput & { index: number };

type Props = {
  user?: User;
  onSubmit: (data: FormInput) => Promise<void>;
  setModalTitle: (title: string) => void;
  isUserSubmitting: boolean;
  onDeleteUser: () => void;
};

export const UserForm = ({
  user,
  onSubmit,
  setModalTitle,
  onDeleteUser,
  isUserSubmitting,
}: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { viewer } = useCareContext().state;
  const [showAddNewRolesForm, setShowAddNewRolesForm] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState<RoleWithIndex | null>(null);
  const [newlyAddedRoles, setNewlyAddedRoles] = useState<number[]>([]);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const canEditSelf = user?.isTenantOwner && viewer?.id === user.id;

  useEffect(() => {
    if (showAddNewRolesForm && roleToEdit) {
      setModalTitle(t("users.editRole"));
    } else if (showAddNewRolesForm) {
      setModalTitle(t("users.addRole"));
    } else if (user?.isTenantOwner) {
      setModalTitle(t("users.ViewUser"));
    } else {
      setModalTitle(user ? t("users.editUser") : t("users.addUser"));
    }
  }, [showAddNewRolesForm, user, t, setModalTitle, roleToEdit]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: {
      errors,
      isDirty,
      dirtyFields,
      isSubmitting,
      isSubmitSuccessful,
    },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      id: user?.id,
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      phone: user?.phone,
      homePhone: user?.homePhone ?? null,
      birthDate: user?.birthDate ?? null,
      pid: user?.pid ?? null,
      email: user?.email ?? "",
      careRecipientRoles:
        user?.careRecipientRoles?.map((role) => ({
          role: { value: RoleType.CareRecipient, label: t("careRecipient") },
          office: { id: role.office.id, name: role.office.name },
          status: !role.deactivatedAt,
        })) || [],
      careGiverRoles:
        user?.caregiverRoles?.map((role) => ({
          role: { value: RoleType.Caregiver, label: t("caregiver") },
          office: { id: role.office.id, name: role.office.name },
          status: !role.deactivatedAt,
        })) || [],
      contactRoles:
        user?.caregiverRoles?.map(() => ({
          role: { value: RoleType.Contact, label: t("contact") },
        })) || [],
      careTeamRoles:
        user?.careTeamMemberRoles?.map((role) => ({
          role: {
            value: CareTeamMemberRoleTypeToRoleType(role.roleType),
            label: translateRoleType(role.roleType, t),
          },
          organizationUnit: {
            type: mapToResourceType(role.organizationUnit.__typename ?? ""),
            label: role.organizationUnit.name,
            id: role.organizationUnit.id || "",
          },
          status: !role.deactivatedAt,
        })) || [],
    },
  });

  const {
    fields: careRecipientFields,
    append: appendCareRecipient,
    update: updateCareRecipient,
    remove: removeCareRecipient,
  } = useFieldArray({
    control,
    name: "careRecipientRoles",
  });

  const {
    fields: careGiverFields,
    append: appendCareGiver,
    update: updateCareGiver,
    remove: removeCareGiver,
  } = useFieldArray({
    control,
    name: "careGiverRoles",
  });

  const {
    fields: careTeamFields,
    update: updateCareTeam,
    append: appendCareTeam,
    remove: removeCareTeam,
  } = useFieldArray({
    control,
    name: "careTeamRoles",
  });

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isDirty &&
      !isSubmitSuccessful &&
      currentLocation.pathname !== nextLocation.pathname,
    [isDirty, isSubmitSuccessful],
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked" && isSubmitSuccessful) {
      blocker.proceed?.();
    }
  }, [blocker, isSubmitSuccessful]);

  const handleRoleSubmit = (roleData: RolesInput) => {
    if (roleToEdit && typeof roleToEdit.index === "number") {
      const roleType = roleData.role.value;

      if (roleType === RoleType.CareRecipient) {
        updateCareRecipient(roleToEdit.index, {
          ...roleData,
          office: roleData.office ?? { id: "", name: "" },
        });
      } else if (roleType === RoleType.Caregiver) {
        updateCareGiver(roleToEdit.index, {
          ...roleData,
          office: roleData.office ?? { id: "", name: "" },
        });
      } else {
        updateCareTeam(roleToEdit.index, {
          ...roleData,
          organizationUnit: roleData.organizationUnit ?? {
            id: "",
            type: ResourceType.Tenant,
            label: "",
          },
        });
      }
    } else {
      if (roleData.role.value === RoleType.CareRecipient) {
        appendCareRecipient({
          ...roleData,
          office: roleData.office ?? { id: "", name: "" },
        });
        setNewlyAddedRoles([...newlyAddedRoles, careRecipientFields.length]);
      } else if (roleData.role.value === RoleType.Caregiver) {
        appendCareGiver({
          ...roleData,
          office: roleData.office ?? { id: "", name: "" },
        });
        setNewlyAddedRoles([...newlyAddedRoles, careGiverFields.length]);
      } else {
        appendCareTeam({
          ...roleData,
          organizationUnit: roleData.organizationUnit,
        });
        setNewlyAddedRoles([...newlyAddedRoles, careTeamFields.length]);
      }
    }
    setRoleToEdit(null);
    setShowAddNewRolesForm(false);
  };

  const getDeactivatedCaregiversCount = useCallback(() => {
    return (
      dirtyFields.careGiverRoles?.filter((cg, index) => {
        const caregiverStatusChanged = cg.status;
        const isNewlyAdded = newlyAddedRoles.includes(index);
        const isActiveCaregiver = careGiverFields[index].status;
        const deactivatedConfirmed =
          !isNewlyAdded && !isActiveCaregiver && caregiverStatusChanged;
        return deactivatedConfirmed;
      }).length ?? 0
    );
  }, [careGiverFields, dirtyFields.careGiverRoles, newlyAddedRoles]);

  const getDeactivatedCareRecipientsCount = useCallback(() => {
    return (
      dirtyFields.careRecipientRoles?.filter((cr, index) => {
        const careRecipientStatusChanged = cr.status;
        const isNewlyAdded = newlyAddedRoles.includes(index);
        const isActiveCareRecipient = careRecipientFields[index].status;
        const deactivatedConfirmed =
          !isNewlyAdded && !isActiveCareRecipient && careRecipientStatusChanged;
        return deactivatedConfirmed;
      }).length ?? 0
    );
  }, [careRecipientFields, dirtyFields.careRecipientRoles, newlyAddedRoles]);

  const submitHandler = async (values: FormInput) => {
    if (
      getDeactivatedCareRecipientsCount() + getDeactivatedCaregiversCount() >=
      1
    ) {
      setShowDeactivateModal(true);
    } else {
      await onSubmit(values);
    }
  };

  const renderRole = (
    label: string,
    details: React.ReactNode,
    status: string,
    roleData: RolesInput,
    index: number,
    removeRole?: (index: number) => void,
    dirtyField?: Partial<{
      status?: boolean;
      role?: { value?: boolean; label?: boolean };
      office?: { id?: boolean; name?: boolean };
    }>,
  ) => {
    const isNewlyAdded = newlyAddedRoles.includes(index);

    return (
      <div
        className="pb-2"
        key={roleData.organizationUnit?.id || roleData.office?.id}
      >
        <FormTable.Body
          className={`${roleData.status ? "" : "!bg-greyscale-100"}`}
        >
          <FormTable.Row gridCols="grid-cols-3">
            <FormTable.Cell className="pl-7">
              <Label size="s">{label}</Label>
            </FormTable.Cell>
            <FormTable.Cell>
              <Paragraph size="s">{details}</Paragraph>
            </FormTable.Cell>
            <FormTable.Cell className="justify-end">
              <Paragraph size="m">
                {status} {dirtyField?.status ? t("users.unsaved") : ""}
              </Paragraph>
              <span
                className={`h-2 w-2 rounded-full ${
                  roleData.status ? "bg-secondary-400" : "bg-greyscale-300"
                } self-center ml-3 mr-4`}
              />{" "}
              <>
                {(!user?.isTenantOwner || canEditSelf) &&
                roleData.role.value !== RoleType.Contact ? (
                  <Button
                    iconPosition="only"
                    icon={Edit}
                    variant="tertiary"
                    onClick={() => {
                      setRoleToEdit({ ...roleData, index });
                      setShowAddNewRolesForm(true);
                    }}
                  />
                ) : undefined}
                {isNewlyAdded && removeRole && (
                  <Button
                    iconPosition="only"
                    icon={Close}
                    variant="tertiary"
                    onClick={() => removeRole(index)}
                  />
                )}
              </>
            </FormTable.Cell>
          </FormTable.Row>
        </FormTable.Body>
      </div>
    );
  };

  const renderRoles = (user: User | undefined) => {
    if (!user) return null;

    return (
      <>
        {careRecipientFields.map((role, index) =>
          renderRole(
            t("careRecipient"),
            `${t("users.location")}: ${role.office?.name || ""}`,
            role.status ? t("active") : t("inactive"),
            role,
            index,
            removeCareRecipient,
            dirtyFields.careRecipientRoles?.[index],
          ),
        )}
        {careGiverFields.map((role, index) =>
          renderRole(
            t("caregiver"),
            `${t("users.location")}: ${role.office?.name || ""}`,
            role.status ? t("active") : t("inactive"),
            role,
            index,
            removeCareGiver,
            dirtyFields.careGiverRoles?.[index],
          ),
        )}
        {careTeamFields.map((role, index) =>
          renderRole(
            role.role?.label || "",
            `${role.organizationUnit?.type ? translateResourceType(role.organizationUnit.type, t) : "Unknown Type"}: ${role.organizationUnit?.label}`,
            role.status ? t("active") : t("inactive"),
            role,
            index,
            removeCareTeam,
            dirtyFields.careTeamRoles?.[index],
          ),
        )}
        {user.contactRoles?.map((role) =>
          renderRole(
            t("users.contact"),
            <>
              {t("users.contact")}:{" "}
              <Link size="s" to={`/care-recipients/${role.careRecipientId}`}>
                {role.careRecipient.firstName} {role.careRecipient.lastName}
              </Link>
            </>,
            t("active"),
            {
              role: { value: RoleType.Contact, label: t("contact") },
              status: true,
            },
            -1,
          ),
        )}
      </>
    );
  };

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit(submitHandler)} className="h-full">
        {user?.isTenantOwner && !canEditSelf && (
          <SlideOver.CardSection>
            <div className="rounded-2xl shadow px-8 py-6">
              <Paragraph size="m">{t("users.noPermissionToEdit")}</Paragraph>
            </div>
          </SlideOver.CardSection>
        )}
        {!showAddNewRolesForm && (
          <SlideOver.DetailSection>
            <FormGroup label={t("firstName")} labelFor="firstName">
              <Input
                {...register("firstName")}
                placeholder={t("placeholder.firstName")}
                type="text"
                displayView={user?.isTenantOwner && !canEditSelf}
                errorMessage={
                  errors.firstName?.message && t(errors.firstName.message)
                }
              />
            </FormGroup>
            <FormGroup label={t("lastName")} labelFor="lastName">
              <Input
                {...register("lastName")}
                placeholder={t("placeholder.lastName")}
                type="text"
                displayView={user?.isTenantOwner && !canEditSelf}
                errorMessage={
                  errors.lastName?.message && t(errors.lastName?.message ?? "")
                }
              />
            </FormGroup>
            <FormGroup label={t("email")} labelFor="email">
              <Input
                {...register("email")}
                placeholder={t("placeholder.email")}
                type="text"
                errorMessage={
                  errors.email?.message && t(errors.email?.message ?? "")
                }
                disabled={!!user?.email}
                displayView={
                  !!user?.email || (user?.isTenantOwner && !canEditSelf)
                }
              />
            </FormGroup>

            <FormGroup label={t("phone")} labelFor="phone">
              <Input
                {...register("phone")}
                placeholder={t("placeholder.phone")}
                type="text"
                displayView={user?.isTenantOwner && !canEditSelf}
                errorMessage={errors.phone?.message}
              />
            </FormGroup>

            <FormGroup label={t("homePhone")} labelFor="homePhone">
              <Input
                {...register("homePhone")}
                placeholder={t("placeholder.homePhone")}
                type="text"
                displayView={user?.isTenantOwner && !canEditSelf}
                errorMessage={errors.homePhone?.message}
              />
            </FormGroup>
          </SlideOver.DetailSection>
        )}

        {user && !showAddNewRolesForm && (
          <>
            <SlideOver.SectionHeader title={t("users.roles")} />
            <SlideOver.CardSection>{renderRoles(user)}</SlideOver.CardSection>
            {(!user?.isTenantOwner || canEditSelf) && (
              <SlideOver.ButtonSection align="left">
                <Button
                  iconPosition="left"
                  icon={Plus}
                  text={t("users.addRole")}
                  variant="tertiary"
                  onClick={() => setShowAddNewRolesForm(true)}
                />
              </SlideOver.ButtonSection>
            )}
          </>
        )}

        {!showAddNewRolesForm && (
          <Form.StickyFooter>
            {!user ? (
              <Button
                className="ml-auto"
                variant="primary"
                type="submit"
                text={t("users.addUser")}
                loading={isUserSubmitting}
              />
            ) : (
              (!user?.isTenantOwner || canEditSelf) && (
                <>
                  <Button
                    className="mr-auto"
                    variant="critical"
                    text={t("users.deleteUser")}
                    onClick={onDeleteUser}
                  />
                  {isDirty && (
                    <Paragraph size="s" className="content-center pr-2">
                      {t("users.unsavedChanges")}
                    </Paragraph>
                  )}
                  <Button
                    className="mr-2"
                    variant="secondary"
                    onClick={() => navigate("..")}
                    text={t("cancel")}
                    disabled={isSubmitting}
                  />
                  <Button
                    variant="primary"
                    type="submit"
                    text={t("save")}
                    loading={isUserSubmitting}
                  />
                </>
              )
            )}
          </Form.StickyFooter>
        )}

        <Modal show={blocker.state === "blocked" && !isSubmitting}>
          <Modal.Title>{t("users.discardChanges")}</Modal.Title>
          <div className="flex justify-center">
            <Paragraph size="m">{t("users.discardText")}</Paragraph>
          </div>
          <Modal.Footer>
            <Button
              variant="secondary"
              text={t("users.continueEditing")}
              onClick={() => blocker.reset?.()}
            />
            <Button
              variant="primary"
              text={t("users.discardChanges")}
              onClick={() => {
                blocker.proceed?.();
              }}
            />
          </Modal.Footer>
        </Modal>

        <Modal show={showDeactivateModal}>
          <Modal.Title>
            {getDeactivatedCareRecipientsCount() +
              getDeactivatedCaregiversCount() >
            1
              ? t("users.deactivateRoles")
              : t("users.deactivateRole")}
          </Modal.Title>
          <div className="flex flex-col gap-2">
            {getDeactivatedCareRecipientsCount() >= 1 && (
              <Modal.Note variant="info">
                {getDeactivatedCareRecipientsCount() > 1
                  ? t("users.deactivateCRs")
                  : t("users.deactivateCR")}
              </Modal.Note>
            )}
            {getDeactivatedCaregiversCount() >= 1 && (
              <Modal.Note variant="info">
                {getDeactivatedCaregiversCount() > 1
                  ? t("users.deactivateCGs")
                  : t("users.deactivateCG")}
              </Modal.Note>
            )}
          </div>
          <Modal.Footer>
            <Button
              variant="secondary"
              text={t("users.cancel")}
              onClick={() => setShowDeactivateModal(false)}
            />
            <Button
              variant="critical"
              text={t("users.deactivate")}
              onClick={async () => {
                const values = getValues();
                await onSubmit(values).then(() =>
                  setShowDeactivateModal(false),
                );
              }}
              loading={isUserSubmitting}
            />
          </Modal.Footer>
        </Modal>

        <AnimatePresence initial={false}>
          {showAddNewRolesForm && (
            <motion.div
              key="roles-form"
              initial={{ y: "100%" }}
              animate={{
                y: 0,
                transition: { type: "spring", stiffness: 100, damping: 20 },
              }}
            >
              <RolesForm
                user={user}
                onRoleSubmit={handleRoleSubmit}
                roleToEdit={roleToEdit}
                setShowAddNewRolesForm={() => {
                  setRoleToEdit(null);
                  setShowAddNewRolesForm(false);
                }}
                isNewlyAdded={newlyAddedRoles.includes(roleToEdit?.index || 0)}
                careGiverRoles={careGiverFields}
                careRecipientRoles={careRecipientFields}
                careTeamRoles={careTeamFields}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </div>
  );
};
