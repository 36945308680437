import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { SlideOver } from "@frontend/lyng/slideOver";
import Modal from "@frontend/lyng/modal/Modal";
import { Button } from "@frontend/lyng/button";
import { Paragraph } from "@frontend/lyng/typography";
import { LoadingBounce } from "@frontend/lyng/loading";
import { ActivityForm, FormInput } from "./ActivityForm";
import { errorToToastMessage } from "../../../utils/toastUtils";
import {
  useActivityTemplateCreateMutation,
  useActivityTemplateByIdQuery,
  useActivityTemplateDeleteMutation,
  useActivityTemplateUpdateMutation,
  ActivityTemplateUpdateMutation,
  ActivityTemplateCreateMutation,
  ActivitiesTemplatesDocument,
} from "../../../api/generated/graphql";
import { FetchResult } from "@apollo/client";
import { DateTime } from "luxon";

export const ActivityTemplateSlideOver = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const handleClose = () => navigate("..");

  const { activityId } = useParams();

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  );

  const [ActivityTemplateCreate] = useActivityTemplateCreateMutation();
  const [ActivityTemplateUpdate] = useActivityTemplateUpdateMutation();
  const [ActivityTemplateDelete] = useActivityTemplateDeleteMutation();

  const { data, loading, error } = useActivityTemplateByIdQuery({
    skip: activityId === "new",
    fetchPolicy: "cache-and-network",
    variables: { id: activityId ?? "" },
  });

  const activityTemplate =
    activityId !== "new" ? data?.activityTemplateById : undefined;

  const handleDelete = (id: string) => {
    setSelectedTemplateId(id);
  };

  const onConfirmDelete = () => {
    if (!selectedTemplateId) return;

    const promise = ActivityTemplateDelete({
      variables: { id: selectedTemplateId ?? "" },
      refetchQueries: [{ query: ActivitiesTemplatesDocument }],
    }).then(() => {
      handleClose();
      setSelectedTemplateId(null);
    });

    toast.promise(promise, {
      loading: t("labels.deleting"),
      success: t("labels.successDelete"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const onCancelDelete = () => {
    setSelectedTemplateId(null);
  };

  const handleSubmit = (values: FormInput) => {
    let promise: Promise<
      FetchResult<
        ActivityTemplateUpdateMutation | ActivityTemplateCreateMutation
      >
    >;

    if (activityTemplate) {
      promise = ActivityTemplateUpdate({
        variables: {
          id: activityId ?? "",
          input: {
            title: values.title ?? "",
            priority: values.priority.value,
            schedule: {
              ...(values.schedule?.everyVisit?.everyDay && {
                everyVisit: {
                  everyDay: values.schedule?.everyVisit?.everyDay,
                },
              }),
              ...(values.schedule?.everyNDays?.days && {
                everyNDays: {
                  days: values.schedule?.everyNDays?.days ?? 0,
                },
              }),
              ...(values.schedule?.oneOff?.date && {
                oneOff: {
                  date:
                    values.schedule?.oneOff?.date?.toFormat("yyyy-MM-dd") ?? "",
                },
              }),
            },
            timeOfDayStart: values.timeOfDayStart
              ? DateTime.fromObject(values.timeOfDayStart).toFormat("HH:mm")
              : null,
            description: values.description,
            activityTypeId: values.type,
            activityGroupIds: values.activityGroups,
            resourceIds: values.organizationUnits?.map((id) => id) ?? [],
          },
        },
        refetchQueries: [{ query: ActivitiesTemplatesDocument }],
      });
    } else {
      promise = ActivityTemplateCreate({
        variables: {
          input: {
            title: values.title ?? "",
            description: values.description,
            schedule: {
              ...(values.schedule?.everyVisit?.everyDay && {
                everyVisit: {
                  everyDay: values.schedule?.everyVisit?.everyDay,
                },
              }),
              ...(values.schedule?.everyNDays?.days && {
                everyNDays: {
                  days: values.schedule?.everyNDays?.days ?? 0,
                },
              }),
              ...(values.schedule?.oneOff?.date && {
                oneOff: {
                  date:
                    values.schedule?.oneOff?.date?.toFormat("yyyy-MM-dd") ?? "",
                },
              }),
            },
            priority: values.priority.value,
            activityTypeId: values.type,
            activityGroupIds: values.activityGroups,
            resourceIds: values.organizationUnits?.map((id) => id) ?? [],
          },
        },
        refetchQueries: [{ query: ActivitiesTemplatesDocument }],
      });
    }

    return toast.promise<
      FetchResult<
        ActivityTemplateUpdateMutation | ActivityTemplateCreateMutation
      >
    >(promise, {
      loading: activityTemplate
        ? t("activityTemplate.updatingTemplate")
        : t("activityTemplate.creatingTemplate"),
      success: () =>
        activityTemplate
          ? t("activityTemplate.templateUpdated")
          : t("activityTemplate.templateCreated"),
      error: errorToToastMessage,
    });
  };

  if (loading) {
    return <LoadingBounce size="m" />;
  }

  return (
    <SlideOver.WithIntercomHider
      show={true}
      onClose={() => navigate("..", { preventScrollReset: true })}
    >
      <SlideOver.Title>
        {activityId
          ? t("activityTemplates.editActivityTemplate")
          : t("activityTemplates.addActivityTemplate")}
      </SlideOver.Title>
      {!data && loading && (
        <div className="flex h-full items-center justify-center">
          <LoadingBounce className="text-primary-600" />
        </div>
      )}
      {error && <p>{error.message}</p>}
      <ActivityForm
        activityTemplate={activityTemplate}
        onSubmit={(values) => handleSubmit(values).then(() => navigate(".."))}
        onDelete={(id) => handleDelete(id)}
      />
      <Modal show={selectedTemplateId != null} onClose={onCancelDelete}>
        <Modal.Title>
          {t("activityTemplates.deleteActivityTemplate")}
        </Modal.Title>
        <Paragraph size="m">
          {t("activityTemplates.deleteDescription", {
            groupName: activityTemplate?.title,
          })}
        </Paragraph>
        <Modal.Footer>
          <Button
            variant="critical"
            text={t("activityTemplate.deleteTemplate")}
            className="col-start-2"
            onClick={onConfirmDelete}
          />
          <Button
            variant="secondary"
            text={t("cancel")}
            className="col-start-1"
            onClick={onCancelDelete}
            disabled={loading}
          />
        </Modal.Footer>
      </Modal>
    </SlideOver.WithIntercomHider>
  );
};
