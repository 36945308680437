import classNames from "classnames";
import { Notification } from "./types";
import { NumberPill } from "../pill";

type NotificationMessageProps = {
  notification: Notification;
  onClick: (notification: Notification) => void;
  mode: "showUnreadCount" | "hideUnreadCount";
};

export const NotificationMessage = ({
  notification,
  onClick,
  mode,
}: NotificationMessageProps) => {
  const { title, message, type, read, seen, digestSize } = notification;
  return (
    <button
      className={classNames(
        "flex flex-row w-full px-4 pt-3 pb-4 rounded-xl shadow-md active:shadow-none cursor-pointer hover:bg-greyscale-50 active:bg-transparent",
        // dark mode
        "dark:bg-greyscale-800 dark:hover:bg-greyscale-700 dark:active:bg-transparent",
        { "bg-primary-0 shadow-none": read },
        { "bg-white shadow-md": !read },
      )}
      onClick={() => onClick(notification)}
    >
      <div className="space-x-2 mt-2">
        {mode === "showUnreadCount" && (
          <NumberPill
            count={digestSize}
            color={type !== "open-visit" ? "redSubdued" : "yellow"}
            className="mr-3 text-xs ml-0"
          />
        )}
      </div>
      <div className="flex flex-col flex-grow gap-1">
        <div className="flex flex-row items-center justify-between ">
          <p
            className={classNames(
              "text-base text-greyscale-900 dark:text-greyscale-100 ",
              { "font-medium dark:text-greyscale-100": !read },
              { "dark:text-greyscale-300": read },
            )}
          >
            {title}
          </p>
          {!seen && <div className="w-2 h-2 bg-red-500 rounded-full" />}
        </div>
        <p className="font-inclusive font-normal text-greyscale-700 dark:text-greyscale-300 text-left">
          {message}
        </p>
      </div>
    </button>
  );
};
