/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from "react";
import { DateTime } from "luxon";
import { useTranslate } from "@tolgee/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useVisitByIdQuery } from "../../api/generated/graphql";

import { SlideOver } from "@frontend/lyng/slideOver";

import { VisitDetails } from "./VisitDetails";
import { VisitLogDetails } from "./visitLog/VisitLogDetails";
import { LoadingBounce } from "@frontend/lyng/loading";

enum Slides {
  details = 1,
  logs = 2,
}

interface VisitDetailsContainerProps {
  visitId?: string;
  onClose?: () => void;
}

export const VisitDetailsContainer: React.FC<VisitDetailsContainerProps> = ({
  visitId,
  onClose,
}) => {
  const { visitInstanceId: visitInstanceIdParam } = useParams<{
    visitInstanceId?: string;
  }>();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const visitInstanceId = useMemo(
    () => visitId || visitInstanceIdParam,
    [visitId, visitInstanceIdParam],
  );

  const {
    data: visitData,
    loading,
    error,
  } = useVisitByIdQuery({
    variables: { visitInstanceId: visitInstanceId! },
  });

  const slide = useMemo<Slides>(() => {
    if (!visitData?.visitById) return Slides.details;

    if (
      DateTime.fromISO(visitData.visitById.start) >= DateTime.now() &&
      !visitData?.visitById.clockInTime
    ) {
      return Slides.details;
    }

    return Slides.logs;
  }, [visitData]);

  const handleClose = () =>
    visitId
      ? onClose && onClose()
      : navigate(".." + location.search, { preventScrollReset: true });

  const title = useMemo(() => {
    if (slide === Slides.logs) return t("visitLog.visitLog");
    return t("visitDetails.updateVisit");
  }, [slide, t]);

  const renderSlide = () => {
    if (!visitData?.visitById)
      return (
        <SlideOver.EmptyState text={t("visitDetails.visitNotFound") ?? ""} />
      );

    if (slide === Slides.logs) {
      return <VisitLogDetails visitData={visitData.visitById} />;
    }

    return <VisitDetails visitData={visitData.visitById} />;
  };

  return (
    <SlideOver.WithIntercomHider show onClose={handleClose}>
      <SlideOver.Title
        pill={
          visitData?.visitById?.cancelledAt
            ? { color: "gray", text: t("cancelled") }
            : undefined
        }
      >
        {title}
      </SlideOver.Title>
      {loading && (
        <div className="flex justify-center items-center w-full h-80">
          <LoadingBounce />
        </div>
      )}
      {error && <p>Error : {error.message}</p>}
      {visitData && renderSlide()}
    </SlideOver.WithIntercomHider>
  );
};
