// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

export interface LoginCompleted {
  usingAD: boolean;
  [property: string]: any;
}

export interface LoginFailed {
  usingAD: boolean;
  [property: string]: any;
}

export interface LoginStarted {
  usingAD: boolean;
  [property: string]: any;
}

export interface NotificationClicked {
  /**
   * Number of aggregated events. One for a single or number of aggregated events when we have
   * multiple groupd
   */
  Count?: number;
  /**
   * Something like late start or late end
   */
  Type?: string;
  [property: string]: any;
}

export interface NotificationsAllMarkedRead {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

/**
 * When a user turns on browser push notification.
 */
export interface PageViewed {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface PaginationClicked {
  next?: boolean;
  page?: number;
  previous?: boolean;
  [property: string]: any;
}

export interface PushNotificationClicked {
  Count?: number;
  Type?: string;
  [property: string]: any;
}

export interface PushNotificationsEnabled {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface SearchPerformed {
  query_length?: number;
  [property: string]: any;
}

/**
 * When final message is displayed
 */
export interface SignupCompleted {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

export interface SignupFormSubmitted {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

/**
 * User picks a signup plan
 */
export interface SignupPlanPicked {
  plan: Plan;
  [property: string]: any;
}

export enum Plan {
  Enterprise = "enterprise",
  Family = "family",
}

/**
 * Lands on signup.dala.care
 */
export interface SignupStarted {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null;
  [property: string]: any;
}

/**
 * A new user is successfully created
 */
export interface UserCreated {
  /**
   * Care team members have different roles
   */
  role?: UserCreatedRole;
  /**
   * Which type of user was created
   */
  type: Type;
  /**
   * ID of user created
   */
  user_id: string;
  [property: string]: any;
}

/**
 * Care team members have different roles
 */
export enum UserCreatedRole {
  Administrator = "Administrator",
  SchedulingManager = "Scheduling manager",
  SuccessManager = "Success manager",
}

/**
 * Which type of user was created
 *
 * Which type of user was updated
 */
export enum Type {
  CareRecipient = "Care recipient",
  Caregiver = "Caregiver",
  Contact = "Contact",
  TeamMember = "Team member",
}

/**
 * A user is successfully updated
 */
export interface UserUpdated {
  /**
   * Care team member have different roles
   */
  role?: UserUpdatedRole;
  /**
   * Which type of user was updated
   */
  type?: Type;
  /**
   * ID of the user that was updated
   */
  user_id: string;
  [property: string]: any;
}

/**
 * Care team member have different roles
 */
export enum UserUpdatedRole {
  Administrator = "Administrator",
  SchedulingManager = "Scheduling manager",
  SuccessManager = "Success Manager",
}

/**
 * Visit successfully cancelled
 */
export interface VisitCancelled {
  /**
   * Reason for cancellation
   */
  reason: string;
  [property: string]: any;
}

/**
 * A new visit is successfully created
 */
export interface VisitCreated {
  care_recipient_id?: string;
  /**
   * Is a caregiver assigned
   */
  has_caregiver: boolean;
  /**
   * What type of recurrence
   */
  recurrence: VisitCreatedRecurrence;
  [property: string]: any;
}

/**
 * What type of recurrence
 */
export enum VisitCreatedRecurrence {
  Custom = "custom",
  Daily = "daily",
  DoesNotRepeat = "does not repeat",
  EveryWeekday = "every weekday",
  Monthly = "monthly",
  Weekly = "weekly",
}

/**
 * New visit dialog prompted
 */
export interface VisitCreationStarted {
  care_recipient_id: string;
  /**
   * How did it started
   */
  source?: VisitCreationStartedSource;
  [property: string]: any;
}

/**
 * How did it started
 */
export enum VisitCreationStartedSource {
  Button = "Button",
  Calendar = "Calendar",
}

/**
 * Visit successfully deleted
 */
export interface VisitDeleted {
  care_recipient_id: string;
  /**
   * just this or all following visits?
   */
  recurrence: VisitDeletedRecurrence;
  [property: string]: any;
}

/**
 * just this or all following visits?
 */
export enum VisitDeletedRecurrence {
  Following = "following",
  This = "this",
}

/**
 * Visit log successfully created
 */
export interface VisitLogCreated {
  care_recipient_id: string;
  [property: string]: any;
}

/**
 * Visit log successfully updated
 */
export interface VisitLogUpdated {
  care_recipient_id: string;
  [property: string]: any;
}

/**
 * Visit successfully updated
 */
export interface VisitUpdated {
  care_recipient_id: string;
  /**
   * just this or all following visits?
   */
  recurrence?: VisitDeletedRecurrence;
  /**
   * Where was is updated from
   */
  source?: VisitUpdatedSource;
  [property: string]: any;
}

/**
 * Where was is updated from
 */
export enum VisitUpdatedSource {
  Calendar = "calendar",
  Form = "form",
}

/**
 * User submits the workspace name
 */
export interface WorkspaceNameSubmitted {
  /**
   * Workspace name
   */
  Workspace: string;
  [property: string]: any;
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from "ajv";

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
  AnalyticsSnippet,
  Analytics,
  AnalyticsBrowser,
  Options,
} from "@segment/analytics-next";

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser;

/** The callback exposed by analytics.js. */
export type Callback = () => void;

export type ViolationHandler = (
  message: Record<string, any>,
  violations: ErrorObject[],
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations,
) => {
  const msg = JSON.stringify(
    {
      type: "Typewriter JSON Schema Validation Error",
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        "Tracking Plan spec.",
      errors: violations,
    },
    undefined,
    2,
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ allErrors: true, verbose: true });

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: "typescript",
        version: "9.2.0",
      },
    },
  };
}

/**
 * Fires a 'LoginCompleted' track call.
 *
 * @param LoginCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginCompleted(
  props: LoginCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Login_Completed",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      usingAD: { $id: "/properties/usingAD", description: "", type: "boolean" },
    },
    required: ["usingAD"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Login Completed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'LoginFailed' track call.
 *
 * @param LoginFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginFailed(
  props: LoginFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Login_Failed",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      usingAD: { $id: "/properties/usingAD", description: "", type: "boolean" },
    },
    required: ["usingAD"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Login Failed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'LoginStarted' track call.
 *
 * @param LoginStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginStarted(
  props: LoginStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Login_Started",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      usingAD: { $id: "/properties/usingAD", description: "", type: "boolean" },
    },
    required: ["usingAD"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Login Started",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'NotificationClicked' track call.
 *
 * @param NotificationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function notificationClicked(
  props: NotificationClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Notification_Clicked",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      Count: {
        $id: "/properties/Count",
        description:
          "Number of aggregated events. One for a single or number of aggregated events when we have multiple groupd",
        type: "integer",
      },
      Type: {
        $id: "/properties/Type",
        description: "Something like late start or late end",
        type: "string",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Notification Clicked",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'NotificationsAllMarkedRead' track call.
 *
 * @param NotificationsAllMarkedRead props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function notificationsAllMarkedRead(
  props: NotificationsAllMarkedRead,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Notifications_All_Marked_Read",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Notifications All Marked Read",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'PageViewed' track call.
 *
 * @param PageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageViewed(
  props: PageViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Page_Viewed",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "When a user turns on browser push notification.",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Page Viewed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'PaginationClicked' track call.
 *
 * @param PaginationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paginationClicked(
  props: PaginationClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Pagination_Clicked",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      next: { $id: "/properties/next", description: "", type: "boolean" },
      page: { $id: "/properties/page", description: "", type: "number" },
      previous: {
        $id: "/properties/previous",
        description: "",
        type: "boolean",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Pagination Clicked",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'PushNotificationClicked' track call.
 *
 * @param PushNotificationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pushNotificationClicked(
  props: PushNotificationClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Push_Notification_Clicked",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      Count: { $id: "/properties/Count", description: "", type: "integer" },
      Type: { $id: "/properties/Type", description: "", type: "string" },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Push Notification Clicked",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'PushNotificationsEnabled' track call.
 *
 * @param PushNotificationsEnabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pushNotificationsEnabled(
  props: PushNotificationsEnabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Push_Notifications_Enabled",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Push Notifications Enabled",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'SearchPerformed' track call.
 *
 * @param SearchPerformed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function searchPerformed(
  props: SearchPerformed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Search_Performed",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      query_length: {
        $id: "/properties/query_length",
        description: "",
        type: "number",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Search Performed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'SignupCompleted' track call.
 *
 * @param SignupCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupCompleted(
  props: SignupCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Signup_Completed",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "When final message is displayed",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Signup Completed",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'SignupFormSubmitted' track call.
 *
 * @param SignupFormSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupFormSubmitted(
  props: SignupFormSubmitted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Signup_Form_Submitted",
    $schema: "http://json-schema.org/draft-07/schema#",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Signup Form Submitted",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'SignupPlanPicked' track call.
 *
 * @param SignupPlanPicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupPlanPicked(
  props: SignupPlanPicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Signup_Plan_Picked",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "User picks a signup plan",
    properties: {
      plan: {
        $id: "/properties/plan",
        description: "",
        enum: ["enterprise", "family"],
        type: "string",
      },
    },
    required: ["plan"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Signup Plan Picked",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'SignupStarted' track call.
 *
 * @param SignupStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signupStarted(
  props: SignupStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Signup_Started",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Lands on signup.dala.care",
    properties: {
      _: {
        type: "null",
        $id: "/properties/_",
        description:
          "This property is added as a workaround to generate type for events without properties",
      },
    },
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Signup Started",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'UserCreated' track call.
 *
 * @param UserCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userCreated(
  props: UserCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "User_Created",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "A new user is successfully created",
    properties: {
      role: {
        $id: "/properties/role",
        description: "Care team members have different roles",
        enum: ["Administrator", "Scheduling manager", "Success manager"],
        type: "string",
      },
      type: {
        $id: "/properties/type",
        description: "Which type of user was created",
        enum: ["Care recipient", "Caregiver", "Contact", "Team member"],
        type: "string",
      },
      user_id: {
        $id: "/properties/user_id",
        description: "ID of user created",
        type: "string",
      },
    },
    required: ["type", "user_id"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "User Created",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'UserUpdated' track call.
 *
 * @param UserUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userUpdated(
  props: UserUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "User_Updated",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "A user is successfully updated",
    properties: {
      role: {
        $id: "/properties/role",
        description: "Care team member have different roles",
        enum: ["Administrator", "Scheduling manager", "Success Manager"],
        type: "string",
      },
      type: {
        $id: "/properties/type",
        description: "Which type of user was updated",
        enum: ["Care recipient", "Caregiver", "Contact", "Team member"],
        type: "string",
      },
      user_id: {
        $id: "/properties/user_id",
        description: "ID of the user that was updated",
        type: "string",
      },
    },
    required: ["user_id"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "User Updated",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'VisitCancelled' track call.
 *
 * @param VisitCancelled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function visitCancelled(
  props: VisitCancelled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Visit_Cancelled",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Visit successfully cancelled",
    properties: {
      reason: {
        $id: "/properties/reason",
        description: "Reason for cancellation",
        type: "string",
      },
    },
    required: ["reason"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Visit Cancelled",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'VisitCreated' track call.
 *
 * @param VisitCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function visitCreated(
  props: VisitCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Visit_Created",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "A new visit is successfully created",
    properties: {
      care_recipient_id: {
        $id: "/properties/care_recipient_id",
        description: "",
        type: "string",
      },
      has_caregiver: {
        $id: "/properties/has_caregiver",
        description: "Is a caregiver assigned",
        type: "boolean",
      },
      recurrence: {
        $id: "/properties/recurrence",
        description: "What type of recurrence",
        enum: [
          "custom",
          "daily",
          "does not repeat",
          "every weekday",
          "monthly",
          "weekly",
        ],
        type: "string",
      },
    },
    required: ["has_caregiver", "recurrence"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Visit Created",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'VisitCreationStarted' track call.
 *
 * @param VisitCreationStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function visitCreationStarted(
  props: VisitCreationStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Visit_Creation_Started",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "New visit dialog prompted",
    properties: {
      care_recipient_id: {
        $id: "/properties/care_recipient_id",
        description: "",
        type: "string",
      },
      source: {
        $id: "/properties/source",
        description: "How did it started",
        enum: ["Button", "Calendar"],
        type: "string",
      },
    },
    required: ["care_recipient_id"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Visit Creation Started",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'VisitDeleted' track call.
 *
 * @param VisitDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function visitDeleted(
  props: VisitDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Visit_Deleted",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Visit successfully deleted",
    properties: {
      care_recipient_id: {
        $id: "/properties/care_recipient_id",
        description: "",
        type: "string",
      },
      recurrence: {
        $id: "/properties/recurrence",
        description: "just this or all following visits?",
        enum: ["following", "this"],
        type: "string",
      },
    },
    required: ["recurrence", "care_recipient_id"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Visit Deleted",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'VisitLogCreated' track call.
 *
 * @param VisitLogCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function visitLogCreated(
  props: VisitLogCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Visit_Log_Created",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Visit log successfully created",
    properties: {
      care_recipient_id: {
        $id: "/properties/care_recipient_id",
        description: "",
        type: "string",
      },
    },
    required: ["care_recipient_id"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Visit Log Created",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'VisitLogUpdated' track call.
 *
 * @param VisitLogUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function visitLogUpdated(
  props: VisitLogUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Visit_Log_Updated",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Visit log successfully updated",
    properties: {
      care_recipient_id: {
        $id: "/properties/care_recipient_id",
        description: "",
        type: "string",
      },
    },
    required: ["care_recipient_id"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Visit Log Updated",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'VisitUpdated' track call.
 *
 * @param VisitUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function visitUpdated(
  props: VisitUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Visit_Updated",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "Visit successfully updated",
    properties: {
      care_recipient_id: {
        $id: "/properties/care_recipient_id",
        description: "",
        type: "string",
      },
      recurrence: {
        $id: "/properties/recurrence",
        description: "just this or all following visits?",
        enum: ["following", "this"],
        type: "string",
      },
      source: {
        $id: "/properties/source",
        description: "Where was is updated from",
        enum: ["calendar", "form"],
        type: "string",
      },
    },
    required: ["care_recipient_id"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Visit Updated",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}
/**
 * Fires a 'WorkspaceNameSubmitted' track call.
 *
 * @param WorkspaceNameSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function workspaceNameSubmitted(
  props: WorkspaceNameSubmitted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: "Workspace_Name_Submitted",
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "User submits the workspace name",
    properties: {
      Workspace: {
        $id: "/properties/Workspace",
        description: "Workspace name",
        type: "string",
      },
    },
    required: ["Workspace"],
    type: "object",
  };
  validateAgainstSchema(props, schema);

  const a = analytics();
  if (a) {
    a.track(
      "Workspace Name Submitted",
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: "typescript",
            version: "",
          },
        },
      },
      callback,
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'LoginCompleted' track call.
   *
   * @param LoginCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginCompleted,
  /**
   * Fires a 'LoginFailed' track call.
   *
   * @param LoginFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginFailed,
  /**
   * Fires a 'LoginStarted' track call.
   *
   * @param LoginStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginStarted,
  /**
   * Fires a 'NotificationClicked' track call.
   *
   * @param NotificationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notificationClicked,
  /**
   * Fires a 'NotificationsAllMarkedRead' track call.
   *
   * @param NotificationsAllMarkedRead props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notificationsAllMarkedRead,
  /**
   * Fires a 'PageViewed' track call.
   *
   * @param PageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pageViewed,
  /**
   * Fires a 'PaginationClicked' track call.
   *
   * @param PaginationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paginationClicked,
  /**
   * Fires a 'PushNotificationClicked' track call.
   *
   * @param PushNotificationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pushNotificationClicked,
  /**
   * Fires a 'PushNotificationsEnabled' track call.
   *
   * @param PushNotificationsEnabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pushNotificationsEnabled,
  /**
   * Fires a 'SearchPerformed' track call.
   *
   * @param SearchPerformed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  searchPerformed,
  /**
   * Fires a 'SignupCompleted' track call.
   *
   * @param SignupCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signupCompleted,
  /**
   * Fires a 'SignupFormSubmitted' track call.
   *
   * @param SignupFormSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signupFormSubmitted,
  /**
   * Fires a 'SignupPlanPicked' track call.
   *
   * @param SignupPlanPicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signupPlanPicked,
  /**
   * Fires a 'SignupStarted' track call.
   *
   * @param SignupStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signupStarted,
  /**
   * Fires a 'UserCreated' track call.
   *
   * @param UserCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userCreated,
  /**
   * Fires a 'UserUpdated' track call.
   *
   * @param UserUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userUpdated,
  /**
   * Fires a 'VisitCancelled' track call.
   *
   * @param VisitCancelled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  visitCancelled,
  /**
   * Fires a 'VisitCreated' track call.
   *
   * @param VisitCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  visitCreated,
  /**
   * Fires a 'VisitCreationStarted' track call.
   *
   * @param VisitCreationStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  visitCreationStarted,
  /**
   * Fires a 'VisitDeleted' track call.
   *
   * @param VisitDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  visitDeleted,
  /**
   * Fires a 'VisitLogCreated' track call.
   *
   * @param VisitLogCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  visitLogCreated,
  /**
   * Fires a 'VisitLogUpdated' track call.
   *
   * @param VisitLogUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  visitLogUpdated,
  /**
   * Fires a 'VisitUpdated' track call.
   *
   * @param VisitUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  visitUpdated,
  /**
   * Fires a 'WorkspaceNameSubmitted' track call.
   *
   * @param WorkspaceNameSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  workspaceNameSubmitted,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === "string" && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
      const a = analytics();
      if (a) {
        a.track(
          "Unknown Analytics Call Fired",
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});
