import { Checkmark as Checkmark16 } from "../assets/icons/16/outline";
import { Checkmark as Checkmark24 } from "../assets/icons/24/outline";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { match } from "ts-pattern";

const LoadingSpinner = () => (
  <div className="h-16 w-16 animate-spin rounded-full border-8 border-solid border-primary-700 border-t-transparent" />
);

type Size = "s" | "m";
type Props = {
  className?: string;
  done?: boolean;
  size?: Size;
};
const LoadingBounce = ({ className, done = false, size = "s" }: Props) => {
  const ballClass = "bg-current mr-1 rounded-full inline-block";
  const [wrapperSize, ballSize, iconWrapperSize, iconSize] = match<
    Size,
    [string, string, string, "16" | "24"]
  >(size)
    .with("s", () => ["h-6", "h-3 w-3", "h-6 w-6", "16"])
    .with("m", () => ["h-10", "h-4 w-4", "h-10 w-10", "24"])
    .exhaustive();
  return (
    <div
      className={classNames(
        className,
        "inline-flex items-center gap-0.5",
        wrapperSize,
      )}
      aria-label={done ? "Done" : "Loading"}
      role="progressbar"
      aria-busy={!done}
    >
      <AnimatePresence initial={false} mode="popLayout">
        {done ? (
          <motion.div
            key="checkmark"
            className={classNames(
              "bg-gradient-primary-100 flex items-center justify-center rounded-full",
              iconWrapperSize,
            )}
            initial={{ opacity: 0, translateY: "50%" }}
            animate={{ opacity: 1, translateY: "0%" }}
            exit={{ opacity: 0, translateY: "50%" }}
          >
            {iconSize === "16" ? (
              <Checkmark16 className="text-primary-600" />
            ) : (
              <Checkmark24 className="text-primary-600" />
            )}
          </motion.div>
        ) : (
          <motion.div
            key="balls"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              className={classNames(
                ballClass,
                ballSize,
                "animate-[scale-bounce_1.4s_infinite_-0.32s_ease-in-out_both]",
              )}
            />
            <div
              className={classNames(
                ballClass,
                ballSize,
                "animate-[scale-bounce_1.4s_infinite_-0.16s_ease-in-out_both]",
              )}
            />
            <div
              className={classNames(
                ballClass,
                ballSize,
                "animate-[scale-bounce_1.4s_infinite_ease-in-out_both]",
              )}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { LoadingSpinner, LoadingBounce };
