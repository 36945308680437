import { gql } from "@apollo/client";

export const caregiverById = gql`
  query CareGiverById($id: ID!) {
    careGiverById(id: $id) {
      id
      firstName
      lastName
      phone
      homePhone
      birthDate
      pid
      email
      hireDate
      deactivatedAt
      caregiverRoles {
        office {
          id
          name
        }
        deactivatedAt
      }
    }
  }
`;
