import { useMemo } from "react";
import { z } from "zod";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useCareRecipientsQuery } from "../../../api/generated/graphql";
import { Select } from "@frontend/lyng/forms/select/Select";
import { useCareContext } from "../../../providers";

export const optionSchema = z.object(
  {
    id: z.string(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
  },
  {
    required_error: "visitDetailsForm.validation.careRecipientRequired",
    invalid_type_error: "visitDetailsForm.validation.careRecipientRequired",
  },
);
export type Option = z.infer<typeof optionSchema>;

type Props = {
  value: Option | null;
  name?: string | undefined;
  onChange: (value: Option | null) => void;
  onBlur: () => void;
  officeId: string;
  errorMessage: string;
  displayView?: boolean;
};

export const VisitCareRecipientSelect = ({
  value,
  name,
  onChange,
  onBlur,
  errorMessage,
  officeId,
  displayView,
}: Props) => {
  const { nameOrder, nameOrderFn, collator } = useSortingOptions();

  const {
    state: { viewer },
  } = useCareContext();

  const { data: careRecipients, error } = useCareRecipientsQuery({
    skip: !viewer,
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        officeIds: officeId
          ? [officeId]
          : viewer?.tenantAccess.offices.map((office) => office.id) ?? [],
      },
    },
  });

  const careRecipientOptions = useMemo(() => {
    if (!careRecipients || !careRecipients.careRecipients || !nameOrder) {
      return [];
    }

    return [...careRecipients.careRecipients]
      .filter(
        (cr) => !cr.careRecipientRoles.some((role) => !!role.deactivatedAt),
      )
      .sort((a, b) => {
        const nameA = nameOrderFn(a);
        const nameB = nameOrderFn(b);
        if (nameA !== null && nameB !== null) {
          return collator.compare(nameA, nameB);
        } else return 0;
      })
      .map((cr) => {
        return {
          id: cr.id,
          firstName: cr.firstName ?? "",
          lastName: cr.lastName ?? "",
        };
      });
  }, [careRecipients, collator, nameOrderFn, nameOrder]);

  if (error) <p>{error.message}</p>;

  return (
    <div data-testid="caregiver-selector" className="sm:max-w-xs max-w-lg">
      <Select
        name={name}
        options={careRecipientOptions}
        getOptionLabel={(option) => nameOrderFn(option)}
        getOptionValue={(option) => option.id}
        value={value?.id === "" ? null : value}
        displayView={displayView}
        onChange={(option) => onChange(option)}
        onBlur={onBlur}
        isMulti={false}
        errorMessage={errorMessage}
        aria-label="Care Recipient"
      />
    </div>
  );
};
