import {
  BillingType,
  VisitTypeByIdQuery,
} from "../../../../api/generated/graphql";
import { PlusIcon } from "../../../../assets/svg/PlusIcon";
import { TrashIcon } from "../../../../assets/svg/TrashIcon";
import {
  Form,
  FormSection,
  FormTable,
  Radios,
  ValidationMessage,
} from "../../../../components/formfields";
import { CurrencyInput } from "../../../../components/formfields/currencyInput/CurrencyInput";
import { Headline, Label, Paragraph } from "@frontend/lyng/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDateFormatter } from "../../../../utils/dateUtils";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { Control, Controller, UseFormRegister, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { P, match } from "ts-pattern";
import { z } from "zod";
import { useTranslateBillingType } from "../../../../utils/translationUtils";
import {
  Input,
  TimeInput,
  TimePicker,
  parseTime,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { useCareContext } from "../../../../providers";
import { Button } from "@frontend/lyng/button/Button";

// Returns the end time of the period that comes before the next period that has value
// If p2 has value, returns p2
// If neither period has value, returns null
const getPeriodEnd = (
  p2Start: TimeInput | null | undefined,
  p3Start: TimeInput | null | undefined,
): TimeInput | null => {
  return match([p2Start, p3Start])
    .with([P.nullish, P.nullish], () => null)
    .with([P.not(P.nullish), P._], ([p2Start]) => p2Start)
    .with([P._, P.not(P.nullish)], ([, p3Start]) => p3Start)
    .exhaustive();
};

type BillingTableRow = {
  type: "billingTableRow";
  billingType: string;
  startFieldName:
    | "basePeriodStart"
    | "eveningPeriod.start"
    | "nightPeriod.start"
    | null;
  startInvalid?: boolean;
  endValue: string;
  rateFieldName: "baseRate" | "eveningPeriod.rate" | "nightPeriod.rate";
  rateInvalid: boolean;
  onRemoveRow?: () => void;
};
type BillingTableAddRow = {
  type: "billingTableAddRow";
  addRowText: string;
  onAddRow: () => void;
};
const BillingTableRow = ({
  row,
  control,
}: {
  row: BillingTableRow | BillingTableAddRow;

  control: Control<FormInput>;
  register: UseFormRegister<FormInput>;
}) => {
  const {
    state: { viewer },
  } = useCareContext();

  return (
    <FormTable.Row gridCols="grid-cols-[1fr,1fr,1fr,1fr,40px]">
      {row.type === "billingTableRow" ? (
        <>
          <FormTable.Cell>
            <Label size="m" className="mx-5">
              {row.billingType}
            </Label>
          </FormTable.Cell>
          <FormTable.Cell justifyEnd>
            {row.startFieldName ? (
              <Controller
                control={control}
                name={row.startFieldName}
                render={({ field }) => (
                  <TimePicker
                    dateSettings={viewer?.tenantSettings}
                    className="grow"
                    name={field.name}
                    value={field.value ?? null}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    alignRight
                    invalid={!!row.startInvalid}
                    aria-label={`${row.billingType} start`}
                  />
                )}
              />
            ) : (
              <Paragraph
                size="m"
                className="mx-5"
                data-testid={`${row.billingType}-empty-start`}
              >
                -
              </Paragraph>
            )}
          </FormTable.Cell>
          <FormTable.Cell justifyEnd>
            <Paragraph
              size="m"
              className="mx-5"
              data-testid={`${row.billingType}-end-value`}
            >
              {row.endValue}
            </Paragraph>
          </FormTable.Cell>
          <FormTable.Cell>
            <div className="border-l border-l-primary-200 px-1 py-0.5">
              <CurrencyInput
                control={control}
                name={row.rateFieldName}
                id="row.rateFieldName"
                placeholder="0.00"
                aria-label={`${row.billingType} rate`}
              />
            </div>
          </FormTable.Cell>
          <FormTable.Cell>
            {row.onRemoveRow && (
              <Button
                variant="critical"
                size="sm"
                icon={TrashIcon}
                iconPosition="only"
                onClick={row.onRemoveRow}
                ariaLabel={`Remove ${row.billingType} rate`}
                className=""
              />
            )}
          </FormTable.Cell>
        </>
      ) : (
        <div className="col-span-5">
          <Button
            variant="tertiary"
            text={row.addRowText}
            onClick={row.onAddRow}
            icon={PlusIcon}
            iconPosition="left"
          />
        </div>
      )}
    </FormTable.Row>
  );
};

const validationSchema = z
  .object({
    title: z.string().min(1),
    code: z.string().min(1),
    billingType: z.nativeEnum(BillingType),

    baseRate: z.number().min(0),
    basePeriodStart: z.object(timeSchemaShape).nullish(),

    eveningPeriod: z
      .object({
        start: z.object(timeSchemaShape),
        // end: z.object(timeSchemaShape),
        rate: z.number().min(0),
      })
      .optional(),

    nightPeriod: z
      .object({
        start: z.object(timeSchemaShape),
        // end: z.object(timeSchemaShape),
        rate: z.number().min(0),
      })
      .optional(),
  })
  .refine(
    (data) =>
      data.basePeriodStart || (!data.eveningPeriod && !data.nightPeriod),
    {
      message:
        "Base period start is required when evening or night period is set",
      path: ["basePeriodStart"],
    },
  )
  .superRefine((data, ctx) => {
    if (data.eveningPeriod?.start && data.nightPeriod?.start) {
      if (!data.basePeriodStart) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Base period start is required when evening or night period is set",
          path: ["basePeriodStart"],
        });
        return;
      }
      const baseDT = DateTime.fromObject(data.basePeriodStart);
      let eveningDT = DateTime.fromObject(data.eveningPeriod.start);
      let nightDT = DateTime.fromObject(data.nightPeriod.start);

      if (eveningDT < baseDT) {
        eveningDT = eveningDT.plus({ days: 1 });
      }
      if (nightDT < baseDT) {
        nightDT = nightDT.plus({ days: 1 });
      }

      if (!(baseDT < eveningDT && eveningDT < nightDT)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Evening period must be after base period",
          path: ["eveningPeriod", "start"],
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Night period must be after evening period",
          path: ["nightPeriod", "start"],
        });
      }
    }
  });

type FormInput = z.infer<typeof validationSchema>;
export type FormOutput = {
  title: string;
  billingType: BillingType;
  code: string;
  baseRate: string;
  basePeriodStart?: string | null;

  eveningPeriod: {
    start: string;
    rate: string;
  } | null;
  nightPeriod: {
    start: string;
    rate: string;
  } | null;
};

type Props = {
  visitType?: VisitTypeByIdQuery["visitTypeById"];
  onSubmit: (data: FormOutput) => Promise<void>;
};
export const VisitTypeForm = ({ visitType, onSubmit }: Props) => {
  const { t } = useTranslate();
  const { formatTime } = useDateFormatter();
  const translateBillingType = useTranslateBillingType(t);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      title: visitType?.title,
      code: visitType?.code,
      billingType: visitType?.billingType,

      baseRate: visitType?.baseRate
        ? parseFloat(visitType?.baseRate)
        : undefined,
      basePeriodStart: visitType?.basePeriodStart
        ? parseTime(visitType?.basePeriodStart)
        : undefined,

      eveningPeriod:
        visitType?.eveningPeriodStart && visitType.eveningRate
          ? {
              start: parseTime(visitType?.eveningPeriodStart) ?? undefined,
              rate: parseFloat(visitType?.eveningRate),
            }
          : undefined,

      nightPeriod:
        visitType?.nightPeriodStart && visitType.nightRate
          ? {
              start: parseTime(visitType?.nightPeriodStart) ?? undefined,
              rate: parseFloat(visitType?.nightRate),
            }
          : undefined,
    },
  });

  const submitHandler = async (data: FormInput) => {
    const eveningPeriod = data.eveningPeriod && {
      start: data.eveningPeriod.start,
      rate: data.eveningPeriod.rate,
    };
    const nightPeriod = data.nightPeriod && {
      start: data.nightPeriod.start,
      rate: data.nightPeriod.rate,
    };

    const output: FormOutput = {
      title: data.title,
      code: data.code,
      billingType: data.billingType,
      baseRate: data.baseRate.toString(),
      basePeriodStart: data.basePeriodStart
        ? DateTime.fromObject(data.basePeriodStart).toFormat("HH:mm")
        : null,

      eveningPeriod: eveningPeriod
        ? {
            rate: eveningPeriod.rate.toString(),
            start: DateTime.fromObject(eveningPeriod.start).toFormat("HH:mm"),
          }
        : null,
      nightPeriod: nightPeriod
        ? {
            rate: nightPeriod.rate.toString(),
            start: DateTime.fromObject(nightPeriod.start).toFormat("HH:mm"),
          }
        : null,
    };

    await onSubmit(output);
  };

  const watchBasePeriodStart = watch("basePeriodStart");
  const watchEveningPeriod = watch("eveningPeriod");
  const watchNightPeriod = watch("nightPeriod");

  const basePeriodEnd = getPeriodEnd(
    watchEveningPeriod?.start,
    watchNightPeriod?.start,
  );

  const eveningPeriodEnd = getPeriodEnd(
    watchNightPeriod?.start,
    watchBasePeriodStart,
  );

  const nightPeriodEnd = getPeriodEnd(
    watchBasePeriodStart,
    null, // base period is required when evening or night period is set
  );

  const formatPeriodEnd = useCallback(
    (period: TimeInput | null | undefined) =>
      period
        ? formatTime(DateTime.fromObject(period).minus({ minute: 1 }))
        : "-",
    [formatTime],
  );

  const billingTable: (BillingTableRow | BillingTableAddRow)[] = [
    {
      type: "billingTableRow",
      billingType: t("visitTypes.base"),
      startFieldName:
        watchEveningPeriod || watchNightPeriod ? "basePeriodStart" : null,
      startInvalid: !!errors.basePeriodStart,
      endValue: formatPeriodEnd(basePeriodEnd),
      rateFieldName: "baseRate",
      rateInvalid: !!errors.baseRate,
    },
    watchEveningPeriod
      ? {
          type: "billingTableRow",
          billingType: t("visitTypes.evening"),
          startFieldName: "eveningPeriod.start",
          startInvalid: !!errors.eveningPeriod?.start,
          endValue: formatPeriodEnd(eveningPeriodEnd),
          rateFieldName: "eveningPeriod.rate",
          rateInvalid: !!errors.eveningPeriod?.rate,
          onRemoveRow: () => {
            setValue("eveningPeriod", undefined);
          },
        }
      : {
          type: "billingTableAddRow",
          addRowText: t("visitTypes.addEveningRate"),
          onAddRow: () => {
            setValue("eveningPeriod", {
              start: { hour: 18, minute: 0 },
              rate: 0,
            });
          },
        },
    watchNightPeriod
      ? {
          type: "billingTableRow",
          billingType: t("visitTypes.night"),
          startFieldName: "nightPeriod.start",
          startInvalid: !!errors.nightPeriod?.start,
          endValue: formatPeriodEnd(nightPeriodEnd),
          rateFieldName: "nightPeriod.rate",
          rateInvalid: !!errors.nightPeriod?.rate,
          onRemoveRow: () => {
            setValue("nightPeriod", undefined);
          },
        }
      : {
          type: "billingTableAddRow",
          addRowText: t("visitTypes.addNightRate"),
          onAddRow: () => {
            setValue("nightPeriod", {
              start: { hour: 23, minute: 0 },
              rate: 0,
            });
          },
        },
  ];

  return (
    // TODO: hack to remove slideover padding, remove when new slideover is implemented
    <div className="-mx-4 -my-6 h-full sm:-mx-6">
      <form onSubmit={handleSubmit(submitHandler)} className="h-full">
        <FormSection>
          <FormSection.Label htmlFor="title">
            {t("visitTypes.title")}
          </FormSection.Label>
          <div className="sm:max-w-xs max-w-lg">
            <Input
              {...register("title")}
              id="title"
              type="text"
              errorMessage={errors.title?.message}
            />
          </div>
          <FormSection.Label htmlFor="code">
            {t("visitTypes.code")}
          </FormSection.Label>
          <div className="sm:max-w-xs max-w-lg">
            <Input
              {...register("code")}
              id="code"
              type="text"
              errorMessage={errors.code?.message}
            />
          </div>

          <FormSection.Label htmlFor="billingType">
            {t("visitTypes.billingType")}
          </FormSection.Label>
          <div className="mt-6 space-y-6 sm:mt-0">
            <Radios
              control={control}
              name="billingType"
              options={[BillingType.Hourly, BillingType.Fixed].map(
                (option) => ({
                  key: option,
                  value: option,
                  label: translateBillingType(option),
                }),
              )}
              invalid={!!errors.billingType}
            />
            {errors.billingType && (
              <ValidationMessage className="!mt-0">
                {errors.billingType.message}
              </ValidationMessage>
            )}
          </div>
        </FormSection>

        <div className="px-4 pb-8 pt-6">
          <FormTable.Header gridCols="grid-cols-[1fr,1fr,1fr,1fr,40px]">
            <Headline size="m" className="px-5">
              {t("visitTypes.billing")}
            </Headline>
            <Label size="m" className="justify-self-end px-5">
              {t("visitTypes.from")}
            </Label>
            <Label size="m" className="justify-self-end px-5">
              {t("visitTypes.to")}
            </Label>
            <Label size="m" className="px-5">
              {t("visitTypes.rate")}
            </Label>
            <div />
          </FormTable.Header>
          <FormTable.Body>
            {billingTable.map((row, i) => (
              <BillingTableRow
                key={i}
                row={row}
                control={control}
                register={register}
              />
            ))}
          </FormTable.Body>
          {errors.baseRate || errors.eveningPeriod || errors.nightPeriod ? (
            <div className="mt-2 flex flex-col gap-2">
              {[
                { e: errors.baseRate, key: "baseRate" },
                { e: errors.basePeriodStart, key: "basePeriodStart" },
                { e: errors.eveningPeriod?.start, key: "eveningPeriod.start" },
                { e: errors.eveningPeriod?.rate, key: "eveningPeriod.rate" },
                { e: errors.nightPeriod?.start, key: "nightPeriod.start" },
                { e: errors.nightPeriod?.rate, key: "nightPeriod.rate" },
              ].map(
                (error) =>
                  error.e && (
                    <ValidationMessage key={error.key}>
                      {error.e.message}
                    </ValidationMessage>
                  ),
              )}
            </div>
          ) : null}
        </div>
        <Form.StickyFooter>
          <Button
            className="ml-auto"
            variant="primary"
            text={t("save") ?? ""}
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </Form.StickyFooter>
      </form>
    </div>
  );
};
