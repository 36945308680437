import { useMemo, useState } from "react";
import {
  AvailableUsersForChatQuery,
  TenantSettings,
  useAvailableUsersForChatQuery,
} from "../api/generated/graphql";
import Modal from "../modal/Modal";
import { useSortingOptions } from "../utils/hooks/useSortingOptions";
import { ChatterListCard } from "./ChatterListCard";
import { SearchInput } from "../search/SearchInput";
import { LoadingBounce } from "../loading";

type ChatterListProps = {
  show: boolean;
  setShow: (value: boolean) => void;
  onChatterClick: (chatter: Chatter) => void;
  tenantSettings?: TenantSettings;
};

export type Chatter = AvailableUsersForChatQuery["users"][0];

export const ChatterList = ({
  show,
  setShow,
  tenantSettings,
  onChatterClick,
}: ChatterListProps) => {
  const { data, error } = useAvailableUsersForChatQuery();
  const { nameOrderFn, collator } = useSortingOptions(tenantSettings);
  const [search, setSearch] = useState("");

  const sortedUsers = useMemo(() => {
    if (!data?.users) return [];

    return [...data.users].sort((a, b) => {
      const nameA = nameOrderFn(a);
      const nameB = nameOrderFn(b);
      if (nameA !== null && nameB !== null) {
        return collator.compare(nameA, nameB);
      } else return 0;
    });
  }, [data, nameOrderFn, collator]);

  const filteredUsers = useMemo(() => {
    if (!search) return sortedUsers;

    return sortedUsers?.filter((user) => {
      const name = nameOrderFn(user);
      if (name) {
        return name.toLowerCase().includes(search.toLowerCase());
      } else {
        return false;
      }
    });
  }, [search, sortedUsers, nameOrderFn]);

  if (error) {
    console.error(error);
  }

  if (!data) {
    return <LoadingBounce />;
  }

  return (
    <Modal
      show={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <SearchInput setInputValue={setSearch} inputValue={search} />
      <div className="text-sm text-gray-500 h-96 overflow-y-auto flex flex-col">
        {filteredUsers?.map((user) => (
          <button key={user.id} onClick={() => onChatterClick(user)}>
            <ChatterListCard key={user.id} chatter={user} />
          </button>
        ))}
      </div>
    </Modal>
  );
};
