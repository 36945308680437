import { Label, Paragraph } from "@frontend/lyng/typography";
import { ActivityGroupsQuery } from "../../../api/generated/graphql";
import { Edit } from "@frontend/lyng/assets/icons/16/outline";
import { Button } from "@frontend/lyng/button";
import classNames from "classnames";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";

type ActivityGroupRow = ActivityGroupsQuery["activityGroups"][number];

type Props = {
  activityGroups?: ActivityGroupsQuery["activityGroups"];
  onEditGroup?: (group: ActivityGroupRow) => void;
  selectedGroupId?: string;
};

export const ActivitiesGroupCard = ({
  activityGroups,
  onEditGroup,

  selectedGroupId,
}: Props) => {
  const { collator } = useSortingOptions();
  const { t } = useTranslate();

  const sortedActivityGroups = activityGroups?.sort((a, b) => {
    if (a.id === "all") return -1;
    if (b.id === "all") return 1;
    return collator.compare(a.title, b.title);
  });

  return (
    <div className="flex flex-col w-80">
      {sortedActivityGroups?.map((group, index) => {
        const isBeforeSelected =
          sortedActivityGroups[index + 1]?.id === selectedGroupId;

        return (
          <Link
            to={`/settings/activities/${group.id}`}
            key={group.id}
            className={classNames(
              "p-2 bg-grayscale-200 border-b justify-start items-start inline-flex cursor-pointer",
              group.id === selectedGroupId && "bg-primary-100 rounded-2xl",
              isBeforeSelected && "border-b-0",
            )}
          >
            <div className="grow shrink basis-0 p-3 flex-col justify-start items-start gap-1 inline-flex">
              <Label size="s">{group.title}</Label>
              <Paragraph size="xs">
                {t("activitiesTable.activitiesTemplatesNumber", {
                  number: group.activityTemplates.length,
                })}
              </Paragraph>
            </div>
            {group.id !== "all" && (
              <Button
                className="mt-2 mr-1"
                variant="tertiary"
                size="sm"
                iconPosition="only"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEditGroup?.(group);
                }}
                icon={Edit}
              />
            )}
          </Link>
        );
      })}
    </div>
  );
};
