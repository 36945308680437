import {
  Frequency,
  Priority,
  VisitByIdQuery,
  useActivitiesByCareRecipientIdQuery,
  useActivityCreateAndScheduleMutation,
} from "../../api/generated/graphql";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { P, match } from "ts-pattern";
import { VisitDetailForm, VisitInstanceDetails } from "./VisitDetailsForm";
import {
  ActivityForm,
  ActivityFormInput,
} from "../../components/common/activity-form/ActivityForm";
import { DateTime } from "luxon";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../utils/toastUtils";
import { Button } from "@frontend/lyng/button";
import { Plus } from "@frontend/lyng/assets/icons/16/outline";
import { Activity } from "../../types";
import { ActivitiesTable } from "../../components/common";
import { Headline } from "@frontend/lyng/typography";

const visitInstanceFromQuery = (
  visitInstance: NonNullable<VisitByIdQuery["visitById"]>,
): VisitInstanceDetails => {
  const rRule = visitInstance.recurrence?.rRule;
  const recurrences: VisitInstanceDetails["recurrences"] = match(rRule)
    .with(P.nullish, () => null)
    .with({ frequency: Frequency.Daily }, (rrule) => ({
      ...rrule,
      frequency: Frequency.Daily,
    }))
    .with({ frequency: Frequency.Weekly }, (rrule) => ({
      ...rrule,
      frequency: Frequency.Weekly,
    }))
    .with({ frequency: Frequency.Monthly }, (rrule) => ({
      ...rrule,
      frequency: Frequency.Monthly,
    }))
    .exhaustive();

  return {
    ...visitInstance,
    officeId: visitInstance.officeId,
    recurrences,
    recurrenceEnd: visitInstance.recurrence?.end ?? null,
    exceptionId: visitInstance.exceptionId,
    residentialCare: visitInstance.visitDefinition.residentialCare,
    visitType: visitInstance.visitType,
    labelIds: visitInstance.labelIds,
  };
};

type Props = {
  visitData: NonNullable<VisitByIdQuery["visitById"]>;
};
export const VisitDetails = ({ visitData }: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [showActivityForm, setShowActivityForm] = useState(false);

  const [createAndScheduleActivity, { loading: createActivityLoading }] =
    useActivityCreateAndScheduleMutation({
      refetchQueries: ["ActivitiesByCareRecipientId", "VisitById"],
    });

  const {
    data: careRecipientActivities,
    loading: careRecipientActivitiesLoading,
    called: careRecipientActivitiesCalled,
  } = useActivitiesByCareRecipientIdQuery({
    variables: { careRecipientId: visitData.careRecipient?.id ?? "" },
  });

  const filterUnassignedActivities = (
    visitActivities: Activity[],
    allActivities: Activity[],
  ): Activity[] => {
    return allActivities.filter((activity) => {
      return !visitActivities.some((vt) => vt.id === activity.id);
    });
  };

  const handleCreateActivity = async (values: ActivityFormInput) => {
    if (!visitData.visitDefinitionId) {
      return Promise.resolve();
    }
    const activityCreatePromise = createAndScheduleActivity({
      variables: {
        visitDefinitionId: visitData.visitDefinitionId,
        input: {
          ...values,
          schedule: undefined,
          priority: values.priority.value,
          careRecipientId: visitData.careRecipientId,
          timeOfDayStart: values.timeOfDayStart
            ? DateTime.fromObject(values.timeOfDayStart).toFormat("HH:mm")
            : null,
        },
      },
    }).then((data) => {
      setShowActivityForm(false);
      return data;
    });

    await toast.promise(activityCreatePromise, {
      loading: t("activityDetailsModal.creating"),
      success: (data) =>
        t("saved", {
          name: data.data?.activityCreateAndSchedule.title ?? t("activity"),
        }),
      error: (err) => errorToToastMessage(err),
    });
  };

  return (
    <div className="space-y-12">
      <AnimatePresence initial={false}>
        {!showActivityForm && (
          <motion.div
            key="visit-detail-form"
            initial={{
              height: 0,
              overflow: "hidden",
            }}
            animate={{
              height: "auto",
              overflow: "hidden",
              transitionEnd: {
                overflow: "visible",
              },
            }}
            exit={{
              height: 0,
              overflow: "hidden",
            }}
          >
            <VisitDetailForm
              visit={visitInstanceFromQuery(visitData)}
              onClose={() => navigate("..")}
            />

            {visitData.activities.length ||
            careRecipientActivities?.activitiesByCareRecipientId.length ? (
              <div className="p-4">
                <Headline size="m" className="pl-6 p-3">
                  {t("activitiesTable.activities")}
                </Headline>
                <ActivitiesTable
                  activities={visitData.activities}
                  loading={
                    careRecipientActivitiesCalled &&
                    careRecipientActivitiesLoading
                  }
                  isVisitRecurring={!!visitData.recurrence?.rRule}
                  insideSlideOver={true}
                  visitDefinitionId={visitData.visitDefinitionId}
                  unScheduledActivities={filterUnassignedActivities(
                    visitData.activities,
                    careRecipientActivities?.activitiesByCareRecipientId ?? [],
                  )}
                />
              </div>
            ) : undefined}

            <Button
              text={t("careRecipientDetails.addActivity")}
              size="md"
              variant="tertiary"
              iconPosition="left"
              icon={Plus}
              onClick={() => setShowActivityForm(true)}
              className="my-4"
            />
          </motion.div>
        )}
      </AnimatePresence>

      {showActivityForm && (
        <div key="visit-detail-form">
          <h3 className="ml-8 pb-3 text-lg font-semibold">
            {t("visitDetails.newActivity")}
          </h3>
          <div className="pl-8">
            <ActivityForm
              activity={{
                careRecipientId: visitData.careRecipientId,
                title: "",
                description: "",
                timeOfDayStart: null,
                priority: Priority.Optional,
                schedule: null,
              }}
              onCancel={() => setShowActivityForm(false)}
              onSubmit={handleCreateActivity}
              loading={createActivityLoading}
              enableSchedule={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};
