/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime, Duration } from "luxon";
import { ZonedDateTime } from "@internationalized/date";
import { DateFormat, Months, TimeFormat, Weekday } from "../types/Dates";
import { P, match } from "ts-pattern";
import { useMemo } from "react";
import { useTranslate, UseTranslateResult } from "@tolgee/react";
import { DecimalSeparator, TenantSettings } from "../api/generated/graphql";

export const dateTimeToZonedDateTime = (date: DateTime): ZonedDateTime => {
  return new ZonedDateTime(
    date.year,
    date.month,
    date.day,
    date.zoneName ?? "",
    date.offset,
    date.hour,
    date.minute,
    date.second,
    date.millisecond,
  );
};

export const shortDays = (day: Weekday, t: UseTranslateResult["t"]) => {
  switch (day) {
    case Weekday.Sunday:
      return t("shortWeekdays.sunday");
    case Weekday.Monday:
      return t("shortWeekdays.monday");
    case Weekday.Tuesday:
      return t("shortWeekdays.tuesday");
    case Weekday.Wednesday:
      return t("shortWeekdays.wednesday");
    case Weekday.Thursday:
      return t("shortWeekdays.thursday");
    case Weekday.Friday:
      return t("shortWeekdays.friday");
    case Weekday.Saturday:
      return t("shortWeekdays.saturday");
  }
};

export const shortMonths = (month: Months, t: UseTranslateResult["t"]) => {
  switch (month) {
    case Months.January:
      return t("shortMonths.january");
    case Months.February:
      return t("shortMonths.february");
    case Months.March:
      return t("shortMonths.march");
    case Months.April:
      return t("shortMonths.april");
    case Months.May:
      return t("shortMonths.may");
    case Months.June:
      return t("shortMonths.june");
    case Months.July:
      return t("shortMonths.july");
    case Months.August:
      return t("shortMonths.august");
    case Months.September:
      return t("shortMonths.september");
    case Months.October:
      return t("shortMonths.october");
    case Months.November:
      return t("shortMonths.november");
    case Months.December:
      return t("shortMonths.december");
  }
};

export const longDays = (day: Weekday, t: UseTranslateResult["t"]) => {
  switch (day) {
    case Weekday.Sunday:
      return t("weekdays.sunday");
    case Weekday.Monday:
      return t("weekdays.monday");
    case Weekday.Tuesday:
      return t("weekdays.tuesday");
    case Weekday.Wednesday:
      return t("weekdays.wednesday");
    case Weekday.Thursday:
      return t("weekdays.thursday");
    case Weekday.Friday:
      return t("weekdays.friday");
    case Weekday.Saturday:
      return t("weekdays.saturday");
  }
};

export const longMonths = (month: Months, t: UseTranslateResult["t"]) => {
  switch (month) {
    case Months.January:
      return t("months.january");
    case Months.February:
      return t("months.february");
    case Months.March:
      return t("months.march");
    case Months.April:
      return t("months.april");
    case Months.May:
      return t("months.may");
    case Months.June:
      return t("months.june");
    case Months.July:
      return t("months.july");
    case Months.August:
      return t("months.august");
    case Months.September:
      return t("months.september");
    case Months.October:
      return t("months.october");
    case Months.November:
      return t("months.november");
    case Months.December:
      return t("months.december");
  }
};

export const weekDayInitial = (day: Weekday, t: UseTranslateResult["t"]) => {
  switch (day) {
    case Weekday.Sunday:
      return t("weekdayInitials.sunday");
    case Weekday.Monday:
      return t("weekdayInitials.monday");
    case Weekday.Tuesday:
      return t("weekdayInitials.tuesday");
    case Weekday.Wednesday:
      return t("weekdayInitials.wednesday");
    case Weekday.Thursday:
      return t("weekdayInitials.thursday");
    case Weekday.Friday:
      return t("weekdayInitials.friday");
    case Weekday.Saturday:
      return t("weekdayInitials.saturday");
  }
};

export const getShortDayFromDate = (
  date: Date | DateTime,
  t: UseTranslateResult["t"],
) => {
  const dateDT = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  return shortDays(datedayToWeekday(dateDT.weekday), t);
};

export const getLongDayFromDate = (date: Date, t: UseTranslateResult["t"]) => {
  return longDays(datedayToWeekday(date.getDay()), t);
};

export const getDayMonthYear = (
  date: Date | DateTime,
  t: UseTranslateResult["t"],
  dateSettings: DateSettings,
) => {
  const dateDT = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  const month = dateMonthToMonth(dateDT.month);
  if (dateSettings?.dateFormat === DateFormat.DdMmYyyy) {
    return `${dateDT.day}. ${shortMonths(month, t)}, ${dateDT.year}`;
  } else {
    return `${shortMonths(month, t)} ${dateDT.day}, ${dateDT.year}`;
  }
};

export const getOrdinalSuffix = (num: number): string => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = num % 100;
  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
};

export const datedayToWeekday = (day: number): Weekday => {
  switch (day) {
    case 0:
      return Weekday.Sunday;
    case 1:
      return Weekday.Monday;
    case 2:
      return Weekday.Tuesday;
    case 3:
      return Weekday.Wednesday;
    case 4:
      return Weekday.Thursday;
    case 5:
      return Weekday.Friday;
    case 6:
      return Weekday.Saturday;
  }
  // Should never happen
  return Weekday.Sunday;
};

export const dateMonthToMonth = (month: number): Months => {
  switch (month) {
    case 1:
      return Months.January;
    case 2:
      return Months.February;
    case 3:
      return Months.March;
    case 4:
      return Months.April;
    case 5:
      return Months.May;
    case 6:
      return Months.June;
    case 7:
      return Months.July;
    case 8:
      return Months.August;
    case 9:
      return Months.September;
    case 10:
      return Months.October;
    case 11:
      return Months.November;
    case 12:
      return Months.December;
  }
  // Should never happen
  return Months.December;
};

export const weekStartingFromDay = (day: Date): Weekday[] => {
  let dayNumber = day.getDay();

  const days = [];
  for (let i = 0; i < 7; i++) {
    days.push(dayNumber);
    dayNumber++;
    dayNumber = dayNumber % 7;
  }
  return days.map((day) => datedayToWeekday(day));
};

const weekdayOrder = Object.values(Weekday);

/**
 * Returns a human readable string given an array of weekdays. Returns undefined if
 * array is empty
 **/
export const weekdaysArrayToString = (
  weekdays: Weekday[],
  t: UseTranslateResult["t"],
): string | undefined => {
  const sortedWeekdays = weekdays
    .slice()
    .sort((a, b) => weekdayOrder.indexOf(a) - weekdayOrder.indexOf(b));
  if (weekdays.length === 0) {
    return undefined;
  }
  if (weekdays.length === 1) {
    return t(`visitRecurrencePicker.weeklyOnDays.${sortedWeekdays[0]}`);
  }

  return sortedWeekdays.map((x) => shortDays(x, t)).join(", ");
};

export function getStartOfDay(): string {
  return DateTime.local().startOf("day").toISO() ?? "";
}
export function getEndOfDay(): string {
  return DateTime.local().endOf("day").toISO() ?? "";
}
export function getStartOfWeek(): string {
  return DateTime.local().startOf("week").toISO() ?? "";
}
export function getEndOfWeek(): string {
  return DateTime.local().endOf("week").toISO() ?? "";
}
export function getStartOfMonth(): string {
  return DateTime.local().startOf("month").toISO() ?? "";
}
export function getEndOfMonth(): string {
  return DateTime.local().endOf("month").toISO() ?? "";
}

export function getGreetingTime(t: UseTranslateResult["t"]): string {
  const currentHour = DateTime.local().hour;
  if (currentHour < 12) {
    return t("greetingTime.morning");
  }
  if (currentHour < 18) {
    return t("greetingTime.afternoon");
  }
  return t("greetingTime.evening");
}
/**
 * Get shift duration in hours and minutes
 * @param shiftStart string date for shift start
 * @param shiftEnd string date for shift end
 * @param abbreviate boolean whether to abbreviate hours and minutes, defaults to true
 * @returns number of hours and minutes for shift as a number
 */
export function getDurationInHoursAndMintues(
  shiftStart: string | DateTime,
  shiftEnd: string | DateTime,
  t: UseTranslateResult["t"],
  abbreviate = true,
): string {
  const start = DateTime.isDateTime(shiftStart)
    ? shiftStart
    : DateTime.fromISO(shiftStart);
  const end = DateTime.isDateTime(shiftEnd)
    ? shiftEnd
    : DateTime.fromISO(shiftEnd);
  const diffTime = end.diff(start, ["hours", "minutes"]);
  return formatDuration(diffTime, t, false, abbreviate);
}
/**
 * Formatted duration string, e.g. 2 days, 1 hour, 30 minutes
 * @param duration Duration object
 * @param withSeconds boolean, whether to include seconds in the output
 * @param abbreviate abbreviate boolean, whether to abbreviate the unit
 * @returns string formatted duration string
 */
export function formatDuration(
  base: Duration | number,
  t: UseTranslateResult["t"],
  withSeconds = false,
  abbreviate = true,
  withHours = true,
): string {
  const duration = Duration.isDuration(base)
    ? base
    : Duration.fromObject({ minutes: base }).rescale();

  const diffDays = withHours ? Math.round(Math.abs(duration.days)) : 0;
  const diffHours = Math.round(
    Math.abs(duration.hours) + (withHours ? 0 : Math.abs(duration.days * 24)),
  );
  const diffMinutes = Math.round(Math.abs(duration.minutes));

  if (withSeconds) {
    const seconds = Math.abs(duration.seconds);
    const fixedSeconds = seconds.toFixed(0);
    const paddedSeconds = seconds < 10 ? `0${fixedSeconds}` : fixedSeconds;
    const paddedMinutes =
      Math.abs(duration.minutes) < 10 ? `0${diffMinutes}` : diffMinutes;
    return `${paddedMinutes}:${paddedSeconds}`;
  }
  if (diffDays > 0) {
    return `${getDateValueWithUnit(
      diffDays,
      t,
      "d",
      abbreviate,
    )} ${getDateValueWithUnit(
      diffHours,
      t,
      "h",
      abbreviate,
    )} ${getDateValueWithUnit(diffMinutes, t, "m", abbreviate)}`;
  } else if (diffHours > 0) {
    return diffMinutes > 0
      ? `${getDateValueWithUnit(
          diffHours,
          t,
          "h",
          abbreviate,
        )} ${getDateValueWithUnit(diffMinutes, t, "m", abbreviate)}`
      : `${getDateValueWithUnit(diffHours, t, "h", abbreviate)}`;
  } else {
    return `${getDateValueWithUnit(diffMinutes, t, "m", abbreviate)}`;
  }
}

function formatDecimal(
  value: string,
  decimalSeparator?: DecimalSeparator,
): string {
  return decimalSeparator === DecimalSeparator.Comma
    ? value.replace(".", ",")
    : value;
}

export function formatDurationToDecimals(
  base: Duration | number,
  decimalSeparator?: DecimalSeparator,
  inMinutes = false,
): string {
  const duration = Duration.isDuration(base)
    ? base
    : Duration.fromObject({ minutes: base });

  if (inMinutes) {
    return `${duration.minutes}`;
  }

  const totalHours = duration.as("hours");
  const roundedHours = Math.floor(totalHours);
  const minutesPart = duration.minutes % 60;

  if (minutesPart === 0) {
    return `${roundedHours}`;
  } else {
    const decimalHours = (totalHours % 1).toFixed(1).substring(1);
    return formatDecimal(`${roundedHours}${decimalHours}`, decimalSeparator);
  }
}

/**
 * Gets a localized human readable string given a number value and a unit
 * @param value number value, amount of time for unit
 * @param unit duration unit, e.g. d: days, h: hours, m: minutes, s: seconds
 * @param abbreviate boolean, whether to abbreviate the unit
 * @returns string localized human readable string, e.g 2 days
 */
export function getDateValueWithUnit(
  value: number,
  t: UseTranslateResult["t"],
  unit: "d" | "h" | "m" | "s",
  abbreviate = true,
): string {
  switch (unit) {
    case "d":
      return `${value}${abbreviate ? "d" : t("day", { value, count: value })}`;
    case "h":
      return `${value}${abbreviate ? "h" : t("hour", { value, count: value })}`;
    case "m":
      return `${value}${
        abbreviate ? "m" : t("minute", { value, count: value })
      }`;
    case "s":
      return `${value}${
        abbreviate ? "s" : t("second", { value, count: value })
      }`;
  }
}

export function calculateAge(dob: string): number {
  const diff_ms = Date.now() - new Date(dob).getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const getMinutesBetweenDates = (
  start: DateTime | string,
  end: DateTime | string,
) => {
  const sd = DateTime.isDateTime(start) ? start : DateTime.fromISO(start);
  const ed = DateTime.isDateTime(end) ? end : DateTime.fromISO(end);
  return ed.diff(sd).toMillis() / (1000 * 60);
};
/**
 * Formats an iso string to dd/MM/yy
 * If the bool time is true it will add hh:mm:ss a at the end
 * @param date string
 * @param time boolean, determins if it adds hours and minutes to the formatted string
 * @param meridiem boolean, determins wheather the time will be 24hrs or AM/PM
 * @param lowercase boolean, determins the casing of the AM/PM
 * @returns string
 */
const formatToFullDate = (
  date: string | DateTime,
  format: string,
  time?: boolean,
  meridiem?: boolean,
  lowercase = false,
): string => {
  const start = DateTime.isDateTime(date) ? date : DateTime.fromISO(date);
  return `${start.toFormat(format)}${
    time ? ` ${formatToSimpleTime(start, meridiem, lowercase)}` : ""
  }`;
};

export const getDateFromStartAndDuration = (
  date: string | DateTime,
  minutes: number | Duration,
): DateTime => {
  const start = DateTime.isDateTime(date) ? date : DateTime.fromISO(date);

  const duration = Duration.isDuration(minutes)
    ? minutes
    : Duration.fromObject({ minutes });

  return start.plus(duration);
};

export const isAllWeekDays = (week: Array<Weekday>) => {
  const weekDays = [
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
  ];
  const weekend = [Weekday.Sunday, Weekday.Saturday];

  const hasAllWeekDays = weekDays.every((day) => week.includes(day));
  const hasWeekend = weekend.some((day) => week.includes(day));
  return hasAllWeekDays && !hasWeekend;
};

/**
 * Formats an iso string to hh:mm a or HH:mm.
 * @param date string or DateTime
 * @param meridiem boolean, determins wheather the time will be 24hrs or AM/PM
 * @param lowercase boolean, determins the casing of the AM/PM
 * @returns string
 * */
const formatToSimpleTime = (
  date: string | DateTime,
  meridiem = true,
  lowercase = true,
) => {
  const dt = DateTime.isDateTime(date) ? date : DateTime.fromISO(date);
  const format = meridiem ? "hh:mm a" : "HH:mm";
  const formatted = dt.toFormat(format);
  return lowercase ? formatted.toLowerCase() : formatted;
};

export const relativeDateInternal = (
  date: string | DateTime,
  now: DateTime,
): {
  translationString: string;
  param?: number;
} => {
  const dt = DateTime.isDateTime(date) ? date : DateTime.fromISO(date);
  const diff = Math.round((now.toMillis() - dt.toMillis()) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = month * 12;

  if (diff < 30) {
    return {
      translationString: "relativeTime.justNow",
    };
  } else if (diff < minute) {
    return {
      translationString: "relativeTime.secondsAgo",
      param: diff,
    };
  } else if (diff < 2 * minute) {
    return {
      translationString: "relativeTime.aMinuteAgo",
    };
  } else if (diff < hour) {
    return {
      translationString: "relativeTime.minutesAgo",
      param: Math.floor(diff / minute),
    };
  } else if (Math.floor(diff / hour) === 1) {
    return {
      translationString: "relativeTime.1hourAgo",
    };
  } else if (diff < day) {
    return {
      translationString: "relativeTime.hoursAgo",
      param: Math.floor(diff / hour),
    };
  } else if (diff < day * 2) {
    return { translationString: "relativeTime.yesterday" };
  } else if (diff < week) {
    return {
      translationString: "relativeTime.daysAgo",
      param: Math.floor(diff / day),
    };
  } else if (diff < month) {
    return {
      translationString: "relativeTime.weeksAgo",
      param: Math.floor(diff / week),
    };
  } else if (diff < year) {
    return {
      translationString: "relativeTime.monthsAgo",
      param: Math.floor(diff / month),
    };
  } else {
    return {
      translationString: "relativeTime.yearsAgo",
      param: Math.floor(diff / year),
    };
  }
};

const relativeDate = (date: string | DateTime, t: UseTranslateResult["t"]) => {
  const { translationString, param } = relativeDateInternal(
    date,
    DateTime.now(),
  );
  return t(translationString, { param });
};

export type DateSettings =
  | Pick<TenantSettings, "country" | "timezone" | "dateFormat" | "timeFormat">
  | undefined;

export const useDateFormatter = (dateSettings: DateSettings) => {
  const { t } = useTranslate();

  return useMemo(() => {
    const timezone = dateSettings?.timezone ?? "UTC";

    // Default to am/pm if no tenant settings
    const meridiem = dateSettings?.timeFormat !== TimeFormat.TwentyFourHour;

    const timeFormat = meridiem ? "h:mm a" : "HH:mm";

    const dateFormat = match(dateSettings?.dateFormat)
      .with(P.nullish, () => "MM/dd/yyyy")
      .with(DateFormat.DdMmYyyy, () => "d/M/yyyy")
      .with(DateFormat.MmDdYyyy, () => "M/d/yyyy")
      .exhaustive();

    const formatTime = (date: string | DateTime, lowercase?: boolean) =>
      formatToSimpleTime(date, meridiem, lowercase);

    const formatDate = (date: string | DateTime, time?: boolean) =>
      formatToFullDate(date, dateFormat, time, meridiem, false);

    const formatRelativeTime = (date: string | DateTime) =>
      relativeDate(date, t);

    return {
      formatTime,
      formatDate,
      formatRelativeTime,
      timeFormat,
      meridiem,
      timezone,
    };
  }, [dateSettings, t]);
};

export const getNotificationGroupTimeInternal = (
  from: DateTime,
  to: string | DateTime,
): {
  translationString?: string;
  param?: number;
  fullDate?: string;
} => {
  const dt = DateTime.isDateTime(to) ? to : DateTime.fromISO(to);
  const diff = from.diff(dt, ["weeks", "days", "hours", "minutes"]);

  const verifyYesterday = (otherwise: {
    translationString?: string;
    param?: number;
  }) =>
    !from.hasSame(dt, "day")
      ? { translationString: "relativeTime.yesterday" }
      : otherwise;

  // Groupings should be:
  // 0-15min: Just now
  // 15-30min: 15 minutes ago
  // 30-60min: 30 minutes ago
  // 1-23:59hr : x hour(s) ago
  // 1 day: Yesterday
  // 1-6 days: x day(s) ago
  // 1-4 weeks: x week(s) ago
  // else: dd/MM/yyyy

  const res = match(diff)
    .with({ weeks: 0, days: 0, hours: 0, minutes: P.when((m) => m < 15) }, () =>
      verifyYesterday({ translationString: "relativeTime.justNow" }),
    )
    .with({ weeks: 0, days: 0, hours: 0, minutes: P.when((m) => m < 30) }, () =>
      verifyYesterday({
        translationString: "relativeTime.minutesAgo",
        param: 15,
      }),
    )
    .with({ weeks: 0, days: 0, hours: 0, minutes: P.when((m) => m < 60) }, () =>
      verifyYesterday({
        translationString: "relativeTime.minutesAgo",
        param: 30,
      }),
    )
    .with({ weeks: 0, days: 0, hours: P.when((h) => h === 1) }, () =>
      verifyYesterday({
        translationString: "relativeTime.1hourAgo",
      }),
    )
    .with({ weeks: 0, days: 0, hours: P.when((h) => h < 24) }, ({ hours }) =>
      verifyYesterday({
        translationString: "relativeTime.hoursAgo",
        param: hours,
      }),
    )
    .with({ weeks: 0, days: P.when((d) => d >= 1 && d < 7) }, () => {
      const fromDay = from.startOf("day");
      const toDay = dt.startOf("day");

      const days = fromDay.diff(toDay, "days").days;

      if (days === 1) {
        return { translationString: "relativeTime.yesterday" };
      }

      return {
        translationString: "relativeTime.daysAgo",
        param: days,
      };
    })
    .with({ weeks: 1 }, () => ({
      translationString: "relativeTime.aWeekAgo",
    }))
    .with({ weeks: P.when((w) => w < 5) }, ({ weeks }) => ({
      translationString: "relativeTime.weeksAgo",
      param: weeks,
    }))
    .otherwise(() => ({ fullDate: dt.toFormat("MM/dd/yyyy") }));
  return res;
};

export const getNotificationGroupTime = (
  date: string | DateTime,
  t: UseTranslateResult["t"],
): string => {
  const { translationString, param, fullDate } =
    getNotificationGroupTimeInternal(DateTime.now(), date);

  if (fullDate) {
    return fullDate;
  }

  return t(translationString ?? "", { param });
};
