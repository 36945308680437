import { Paragraph } from "@frontend/lyng/typography";
import { useNumberFormatter } from "../../../utils/hooks/useNumberFormatter";
import classNames from "classnames";
import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type Props = {
  id?: string;
  placeholder?: string;
  value?: number;
  invalid?: boolean;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  alignRight?: boolean;
  editView?: boolean;
};

export const DistanceInput = forwardRef<
  HTMLInputElement,
  Props & UseFormRegisterReturn<string>
>(function CurrencyInput(props, ref) {
  const { invalid, editView = true, alignRight, ...rest } = props;

  const { distanceUnitShort } = useNumberFormatter();

  return (
    <div
      className={classNames("sm:mx-w-xs relative mx-1 max-w-lg rounded-xl", {
        "shadow-sm": editView,
      })}
    >
      <input
        {...rest}
        ref={ref}
        type="text"
        disabled={!editView}
        className={classNames(
          "flex h-12 w-full items-center rounded-xl pl-4 pr-11 text-lg placeholder:text-gray-400",
          {
            "border-gray-300 focus:border-primary-500 focus:ring-primary-500":
              editView && !invalid,
            "border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
              editView && invalid,
            "hover:border:transparent border-transparent bg-transparent":
              !editView,
            "text-right": !!alignRight,
          },
        )}
      />
      {distanceUnitShort && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
          <Paragraph size="m">{distanceUnitShort}</Paragraph>
        </div>
      )}
    </div>
  );
});
