import { useTranslate } from "@tolgee/react";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { ContactForCareRecipient } from "../../../api/generated/graphql";
import { relationshipTypeToString } from "../../../utils/translationUtils";
import { SmallPill } from "@frontend/lyng/pill";
import { Dropdown } from "@frontend/lyng/forms/dropdown/Dropdown";
import { More } from "@frontend/lyng/assets/icons/12/outline/";

type Option = {
  id: string;
  label: string;
  onClick: (contact: ContactForCareRecipient) => void;
};

type Props = {
  contact: ContactForCareRecipient;
  options?: Option[];
};

const ContactInfo = ({ contact, options }: Props) => {
  const { t } = useTranslate();

  const firstLine = `${contact.firstName} ${contact.lastName} - ${t(
    relationshipTypeToString(contact.relationshipType),
  )}`;

  const getOptions = () =>
    options?.map(({ id, label, onClick }: Option) => ({
      id,
      label,
      onClick: () => onClick(contact),
    })) ?? [];

  return (
    <li className="divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex flex-1 flex-col gap-1.5 truncate">
          <div className="mb-1.5 flex gap-1.5">
            {contact.deactivatedAt && (
              <SmallPill text={t("inactive")} color="gray" />
            )}
            {contact.isPrimary && (
              <SmallPill text={t("contacts.primary")} color="green" />
            )}
            {contact.isPayer && (
              <SmallPill text={t("contacts.payer")} color="green" />
            )}
            {contact.hasPowerOfAttorney && (
              <SmallPill text={t("contacts.attorney")} color="yellow" />
            )}
          </div>

          <div className="flex items-center">
            <h3 className="truncate text-sm font-medium text-gray-900">
              {firstLine}
            </h3>
          </div>

          <div className="mt-1 text-sm text-gray-500">
            {contact.phone && (
              <>
                <a
                  href={`tel:${contact.phone}`}
                  className="truncate text-sm text-gray-500 hover:text-gray-600"
                >
                  {`Tel: ${contact.phone}`}
                </a>
                <span>{" | "}</span>
              </>
            )}
            {contact.email && (
              <a
                href={`mailto:${contact.email}`}
                className="truncate text-sm text-gray-500 hover:text-gray-600"
              >
                {`Email: ${contact.email}`}
              </a>
            )}
          </div>
        </div>
        <div>
          <Dropdown
            options={getOptions()}
            keepOpenOnClick={false}
            horizontalPosition="end"
            verticalPosition="bottom"
            icon={More}
            iconPosition="only"
          />
        </div>
      </div>

      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px flex w-0 flex-1">
            {contact.phone && (
              <a
                href={`tel:${contact.phone}`}
                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-700"
              >
                <PhoneIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
                {t("call")}
              </a>
            )}
          </div>

          <div className={`flex w-0 ${contact.phone ? "flex-1" : "w-full"}`}>
            {contact.email && (
              <a
                href={`mailto:${contact.email}`}
                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-700"
              >
                <EnvelopeIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
                {t("email")}
              </a>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ContactInfo;
