import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useRevalidator } from "react-router-dom";
import {
  RoleType,
  useCaregiverDeleteMutation,
  useCaregiverReactivateMutation,
} from "../../../api/generated/graphql";
import { useCareContext } from "../../../providers";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { SimpleModal } from "../../../components/common";
import { useDateFormatter } from "../../../utils/dateUtils";
import { Button } from "@frontend/lyng/button/Button";

type Props = {
  id: string;
  officeId: string;
  name: string;
  deactivatedAt: string;
};
export const DeactivatedCaregiver = ({
  id,
  officeId,
  name,
  deactivatedAt,
}: Props) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const { t } = useTranslate();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const {
    state: { viewer },
  } = useCareContext();

  const { formatDate } = useDateFormatter();

  const [caregiverReactivate, { loading: reactivateLoading }] =
    useCaregiverReactivateMutation();

  const [caregiverDelete, { loading: deleteLoading }] =
    useCaregiverDeleteMutation();

  const handleReactivate = () => {
    const promise = caregiverReactivate({
      variables: { id: id, officeId },
    }).then(() => {
      revalidator.revalidate();
    });

    toast.promise(promise, {
      loading: t("caregiverForm.reactivating", { name }),
      success: () => t("caregiverForm.reactivated", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleDelete = () => {
    const promise = caregiverDelete({
      variables: { id, officeId },
    }).then(() => {
      navigate("..");
    });

    toast.promise(promise, {
      loading: t("caregiverForm.deleting", { name }),
      success: () => t("caregiverForm.deleted", { name }),
      error: (err) => errorToToastMessage(err),
    });

    setDeleteModal(false);
  };

  const loading = reactivateLoading || deleteLoading;
  const isAdmin = viewer?.roles.some(
    ({ deactivateAt, roleType }) =>
      !deactivateAt && roleType === RoleType.Admin,
  );

  return (
    <>
      <div className="mt-16 flex flex-col items-center gap-2">
        <h2 className="text-2xl font-bold text-gray-800">
          {t("caregiverDetails.deactivatedCaregiver.heading", {
            name: name,
          })}
        </h2>
        <p className="text-gray-500">
          {t("caregiverDetails.deactivatedCaregiver.subHeading", {
            name: name,
            deactivatedAt: formatDate(deactivatedAt),
          })}
        </p>

        <div className="flex flex-row gap-2">
          {isAdmin && (
            <>
              <Button
                className="mt-4"
                variant="critical"
                size="lg"
                onClick={() => setDeleteModal(true)}
                text={t("caregiverDetails.deactivatedCaregiver.delete") ?? ""}
                disabled={loading}
              />
              <Button
                className="mt-4"
                variant="primary"
                size="lg"
                onClick={handleReactivate}
                text={t("reactivate") ?? ""}
                disabled={loading}
              />
            </>
          )}
        </div>
      </div>
      <SimpleModal
        show={deleteModal}
        title={t("caregiverDetails.deactivatedCaregiver.deleteTitle") ?? ""}
        onClose={() => setDeleteModal(false)}
        onAccept={handleDelete}
        acceptText={
          t("caregiverDetails.deactivatedCaregiver.deleteConfirm") ?? ""
        }
        closeText={
          t("caregiverDetails.deactivatedCaregiver.deleteCancel") ?? ""
        }
        type="critical"
      >
        <p>
          {t("caregiverDetails.deactivatedCaregiver.deleteDescription") ?? ""}
        </p>
      </SimpleModal>
    </>
  );
};
