import { useMemo, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import {
  CareGiversQuery,
  useCareGiversQuery,
} from "../../api/generated/graphql";
import { Table } from "../../components/common";
import {
  Headline,
  HeadlineContainer,
  Paragraph,
} from "@frontend/lyng/typography";

import { ColumnDef } from "@tanstack/react-table";
import { Caregiver, CaregiverFormSlideOver } from "./CaregiverFormSlideOver";
import { useSortingOptions } from "../../utils/hooks/useSortingOptions";
import { OfficeFilter } from "../../components/common/officeFilter/OfficeFilter";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import { match } from "ts-pattern";
import { Tabs } from "@frontend/lyng/tabs";
import { useCareContext } from "../../providers";
import { Button } from "@frontend/lyng/button/Button";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import { Caregivers } from "./Caregivers";

type CaregiverRow = CareGiversQuery["careGivers"][number];

type RoleActiveTab = "active" | "deactivated" | "all";

const CaregiverListOld = () => {
  const {
    state: { selectedTenant },
  } = useCareContext();
  const { t } = useTranslate();
  const { collator, nameOrderFn } = useSortingOptions();

  const [roleActiveTab, setRoleActiveTab] = useState<RoleActiveTab>("active");
  const [caregiverFormModal, setCaregiverFormModal] = useState<Partial<
    Caregiver & { id: string }
  > | null>(null);
  const [selectedOfficeId, setSelectedOfficeId] = useLocalStorage<
    string | null
  >(`office-filter-${selectedTenant?.id}`, null);

  const { data, loading, error } = useCareGiversQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      filter: {
        officeIds: selectedOfficeId ? [selectedOfficeId] : [],
      },
    },
  });

  const columns = useMemo<ColumnDef<CaregiverRow>[]>(() => {
    return [
      {
        id: "name",
        header: t("caregivers.name"),
        accessorFn: (row) => nameOrderFn(row),
        cell: (row) => (
          <Link to={row.row.original.id}>{row.getValue<string>()}</Link>
        ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        header: t("caregivers.office"),
        cell: (row) =>
          row.row.original.caregiverRoles.map((role) => {
            return (
              <div className="flex items-center gap-2" key={role.office.id}>
                <div
                  className={`h-2.5 w-2.5 m-2.5 rounded-full ${
                    !role.deactivatedAt
                      ? "bg-secondary-400"
                      : "bg-greyscale-200"
                  }`}
                />
                <Paragraph
                  size="s"
                  type={!role.deactivatedAt ? "primary" : "secondary"}
                >
                  {role.office.name}
                </Paragraph>
              </div>
            );
          }),
      },
    ];
  }, [t, collator, nameOrderFn]);

  const rows = useMemo(() => {
    return (
      match(roleActiveTab)
        .with("active", () =>
          data?.careGivers.filter((cr) => !cr.deactivatedAt),
        )
        .with("deactivated", () =>
          data?.careGivers.filter((cr) => cr.deactivatedAt),
        )
        .with("all", () => data?.careGivers)
        .exhaustive() ?? []
    );
  }, [data, roleActiveTab]);

  const roleActiveTabs: { id: RoleActiveTab; label: string }[] = useMemo(
    () => [
      {
        id: "active",
        label: t("caregivers.active"),
      },
      {
        id: "deactivated",
        label: t("caregivers.deactivated"),
      },
      {
        id: "all",
        label: t("caregivers.all"),
      },
    ],
    [t],
  );

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("caregivers.title")}</Headline>
        <Button
          variant="primary"
          text={t("caregivers.add").toString()}
          onClick={() => {
            setCaregiverFormModal({});
          }}
        />
      </HeadlineContainer>

      <div className="px-8 pb-4 flex flex-row gap-2">
        <OfficeFilter
          selectedOfficeId={selectedOfficeId}
          setSelectedOfficeId={(officeId) => {
            setSelectedOfficeId(officeId);
          }}
          showAllOption={true}
        />
        <Tabs
          tabs={roleActiveTabs}
          currentTab={roleActiveTab}
          onChange={setRoleActiveTab}
        />
      </div>

      <Table
        columns={columns}
        data={rows}
        defaultSorting={[{ id: "name", desc: false }]}
        loading={loading && !data}
      />

      {caregiverFormModal && (
        <CaregiverFormSlideOver
          caregiver={caregiverFormModal}
          onClose={() => {
            setCaregiverFormModal(null);
          }}
        />
      )}
    </div>
  );
};
export const CaregiverList = () => {
  const usersIndexFF = useFeatureFlag("UsersSearchIndex");

  return usersIndexFF ? <Caregivers /> : <CaregiverListOld />;
};
