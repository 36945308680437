import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ActivitySchedule, Priority } from "../../../api/generated/graphql";
import { PartialBy } from "../../../utils/typeUtils";
import {
  Form,
  FormSection,
  TextAreaWide,
} from "../../../components/formfields";
import {
  DatePicker,
  FormGroup,
  Input,
  Select,
  TimePicker,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { useCareContext } from "../../../../src/providers";
import { Button } from "@frontend/lyng/button/Button";
import { useFeatureFlag } from "../../../providers/FeatureFlags";
import { scheduleSchema } from "./types";
import { ScheduleSelect } from "./scheduleSelect";
import { Paragraph } from "@frontend/lyng/typography";
import { useState } from "react";
import { Activity } from "../../../types/index";
import { match } from "ts-pattern";

type PriorityOption = {
  label: string;
  value: Priority;
};

const validationSchema = z.object({
  title: z
    .string({ required_error: "Activity must have a title" })
    .min(1, "Activity must have a title"),
  description: z
    .string({ required_error: "Activity must have a description" })
    .min(1, "Activity must have a description"),
  timeOfDayStart: z.object(timeSchemaShape).nullable(),
  priority: z.object({
    label: z.string(),
    value: z.nativeEnum(Priority),
  }),
  schedule: scheduleSchema.nullable(),
});
export type ActivityFormInput = z.infer<typeof validationSchema>;

const priorityLabels = {
  [Priority.High]: "activityDetailsModal.priorities.high",
  [Priority.Medium]: "activityDetailsModal.priorities.medium",
  [Priority.Low]: "activityDetailsModal.priorities.low",
  [Priority.Optional]: "activityDetailsModal.priorities.optional",
};

const getPriorityOption = (priority: Priority | null) => {
  if (!priority) return null;

  const key = priorityLabels[priority.toUpperCase() as Priority];
  if (!key) return null;

  return { label: key, value: priority };
};

const getScheduleOption = (schedule: ActivitySchedule | null) => {
  const scheduleOption = match(schedule)
    .with({ __typename: "ActivityScheduleOneOff" }, (oneOff) => ({
      oneOff: {
        date: DateTime.fromISO(oneOff.date),
      },
    }))
    .with({ __typename: "ActivityScheduleEveryNDays" }, (everyNDays) => ({
      everyNDays,
    }))
    .with({ __typename: "ActivityScheduleEveryVisit" }, () => ({
      everyVisit: { everyDay: true },
    }))
    .with({ __typename: undefined }, () => null)
    .with(null, () => null)
    .exhaustive();
  return scheduleOption;
};

type Props = {
  activity: PartialBy<Activity, "id">;
  onSubmit: (values: ActivityFormInput) => Promise<void>;
  onDelete?: (() => void) | null;
  onCancel: () => void;
  loading: boolean;
  enableSchedule?: boolean;
};

export const ActivityForm = ({
  activity,
  onSubmit,
  onDelete,
  loading,
  enableSchedule = true,
  onCancel,
}: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ActivityFormInput>({
    resolver: zodResolver(validationSchema),
    shouldUnregister: true,
    defaultValues: {
      title: activity.title,
      description: activity.description,
      timeOfDayStart: activity.timeOfDayStart
        ? (({ hour, minute }) => ({ hour, minute }))(
            DateTime.fromFormat(activity.timeOfDayStart, "HH:mm").toObject(),
          )
        : null,
      priority: getPriorityOption(activity.priority) || undefined,
      schedule: getScheduleOption(activity.schedule),
    },
  });

  const priorityOptions: PriorityOption[] = Object.values(Priority).map(
    (priority) => ({
      label: priorityLabels[priority],
      value: priority,
    }),
  );

  const [dayString, setDayString] = useState(t("activityForm.days"));
  const watchSchedule = watch("schedule");
  const ff_activities = useFeatureFlag("Activities");
  const saveButtonLabel = activity.id ? t("update") : t("create");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label={t("title")} labelFor="title">
        <Input
          {...register("title")}
          placeholder={t("placeholder.title").toString()}
          type="text"
          errorMessage={errors.title?.message}
        />
      </FormGroup>

      <FormGroup label={t("description")} labelFor="description">
        <TextAreaWide
          control={control}
          name="description"
          id="description"
          placeholder={t("placeholder.description").toString()}
          aria-labelledby="description-label"
        />
      </FormGroup>

      <FormGroup label={t("activities.time")} labelFor="timeOfDayStart">
        <Controller
          control={control}
          name={"timeOfDayStart"}
          render={({ field }) => (
            <TimePicker
              dateSettings={viewer?.tenantSettings}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          )}
        />
      </FormGroup>

      {ff_activities && enableSchedule ? (
        <FormGroup label={t("activityForm.schedule")} labelFor="schedule">
          <Controller
            control={control}
            name={"schedule"}
            render={({ field }) => (
              <ScheduleSelect
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
              />
            )}
          />
        </FormGroup>
      ) : undefined}

      {watchSchedule?.oneOff && (
        <FormGroup label={t("activityForm.date")} labelFor="oneOff">
          <Controller
            control={control}
            name="schedule.oneOff.date"
            render={({ field }) => (
              <DatePicker
                dateSettings={viewer?.tenantSettings}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                aria-label="scheduled date"
              />
            )}
          />
        </FormGroup>
      )}

      {watchSchedule?.everyNDays && (
        <FormGroup label={t("activityForm.repeatsEvery")} labelFor="interval">
          <div className="flex flex-row">
            <Input
              type="number"
              min="0"
              {...register("schedule.everyNDays.days", { valueAsNumber: true })}
              placeholder={t("placeholder.number")}
              className="my-1.5"
              errorMessage={errors.schedule?.everyNDays?.message}
              onChange={async (event) => {
                const daysValue = event.target.value;
                <FormSection.Label htmlFor="priority">
                  {t("priority")}
                </FormSection.Label>;
                if (daysValue > 1) {
                  setDayString(t("activityForm.days"));
                } else {
                  setDayString(t("activityForm.day"));
                }
                return;
              }}
            />
            <Paragraph size="m" className="p-4">
              {dayString}
            </Paragraph>
          </div>
        </FormGroup>
      )}

      <FormGroup label={t("priority")} labelFor="priority">
        <Controller
          control={control}
          name={"priority"}
          render={({ field }) => (
            <Select
              name={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={field.onChange}
              options={priorityOptions}
              getOptionLabel={(option) => t(option.label)}
            />
          )}
        />
      </FormGroup>

      <Form.Footer className="border-t p-2">
        {onDelete ? (
          <Button
            variant="critical"
            onClick={() => onDelete()}
            text={t("delete")}
            disabled={loading}
          />
        ) : (
          <Button
            variant="secondary"
            onClick={() => onCancel()}
            text={t("cancel")}
            disabled={loading}
          />
        )}

        <Button
          variant="primary"
          type="submit"
          text={saveButtonLabel}
          disabled={loading}
        />
      </Form.Footer>
    </form>
  );
};
