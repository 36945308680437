import { Select } from "@frontend/lyng/forms";
import { useLabelsByOfficeIdsQuery } from "../../../api/generated/graphql";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
  officeId: string | null;
  onBlur: () => void;
  displayView?: boolean;
};

export const LabelsSelect = ({
  value,
  onChange,
  officeId,
  onBlur,
  displayView = false,
}: Props) => {
  const { collator } = useSortingOptions();

  const { data, loading, error } = useLabelsByOfficeIdsQuery({
    variables: { officeIds: officeId ? [officeId] : [] },
  });

  const sortedOptions = [...(data?.labelsByOfficeIds ?? [])].sort((a, b) =>
    collator.compare(a.name, b.name),
  );

  const selected =
    data?.labelsByOfficeIds.filter((label) => value.includes(label.id)) ?? [];

  return (
    <div className="sm:max-w-xs max-w-lg" data-testid="labelSelect">
      <Select
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        isMulti={true}
        options={sortedOptions ?? []}
        isLoading={loading}
        value={selected}
        onChange={(newValue) => {
          onChange(newValue.map((option) => option.id));
        }}
        id="labelSelect"
        onBlur={onBlur}
        displayView={displayView}
        errorMessage={error && error.message}
      />
    </div>
  );
};
