import { Paragraph } from "@frontend/lyng/typography";
import { useDateFormatter } from "../../../utils/dateUtils";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import { P, match } from "ts-pattern";
import { useExportModalState } from "./useExportModalState";
import { DateRangePicker } from "@frontend/lyng/forms";
import { useCareContext } from "../../../providers";
import { LoadingBounce } from "@frontend/lyng/loading";
import { Button } from "@frontend/lyng/button/Button";
import Modal from "@frontend/lyng/modal/Modal";

type Props = {
  show: boolean;
  from: DateTime;
  to: DateTime;
  onClose: () => void;
};
export const WiseExportModal = ({ show, from, to, onClose }: Props) => {
  const { t } = useTranslate();
  const { formatDate } = useDateFormatter();
  const {
    state: { viewer },
  } = useCareContext();

  const [dateRange, setDateRange] = useState({
    start: from,
    end: to,
  });

  const modalState = useExportModalState(dateRange.start, dateRange.end);

  const modalTitle = match(modalState)
    .with({ type: "mutationCalled", status: "inProgress" }, () =>
      t("processing"),
    )
    .with({ type: "mutationCalled", status: "failed" }, () => t("error"))
    .with({ type: "mutationCalled", status: "success" }, () => t("done"))
    .otherwise(() => "Sveitastjórinn");

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Title>{modalTitle}</Modal.Title>

      <div className="flex flex-col items-center">
        {match(modalState)
          .with({ type: "pending" }, () => <LoadingBounce size="m" />)
          .with(
            { type: "mutationCalled", status: P.not("failed") },
            ({ status }) => (
              <LoadingBounce size="m" done={status === "success"} />
            ),
          )
          .with({ type: "mutationCalled", status: "failed" }, () => (
            <Paragraph size="m" className="text-center">
              {t("reports.visitTypes.errorBody")}
            </Paragraph>
          ))
          .with(
            {
              type: "readyState",
              lastReportSent: P.nullish,
            },
            () => (
              <Paragraph size="m" className="pb-8">
                {t("reports.visitTypes.noReportSent")}
              </Paragraph>
            ),
          )
          .with(
            {
              type: "readyState",
              lastReportSent: P.not(P.nullish),
            },
            ({ lastReportSent }) => (
              <Paragraph size="m" className="pb-8">
                {t("reports.visitTypes.lastReportSent", {
                  to: formatDate(lastReportSent.to),
                  from: formatDate(lastReportSent.from),
                })}
              </Paragraph>
            ),
          )
          .exhaustive()}

        {modalState.type !== "mutationCalled" && (
          <DateRangePicker
            dateSettings={viewer?.tenantSettings}
            aria-label="Select date range"
            name="dateRange"
            value={dateRange}
            onChange={(value) => value && setDateRange(value)}
          />
        )}
      </div>

      {match(modalState)
        .with(
          P.union({ type: "pending" }, { type: "readyState" }),
          ({ sendReport }) => (
            <Modal.Footer>
              <Button
                variant="primary"
                text={t("reports.visitTypes.sendReports").toString()}
                className="sm:col-start-2"
                onClick={sendReport}
              />
              <Button
                variant="secondary"
                text={t("cancel").toString()}
                className="sm:col-start-1"
                onClick={onClose}
              />
            </Modal.Footer>
          ),
        )
        .with({ type: "mutationCalled", status: "failed" }, () => (
          <Modal.Footer>
            <div className="col-span-2 flex justify-center">
              <Button
                variant="secondary"
                text={t("close").toString()}
                onClick={onClose}
              />
            </div>
          </Modal.Footer>
        ))
        .otherwise(() => (
          <button className="sr-only" onClick={onClose}>
            Close
          </button>
        ))}
    </Modal>
  );
};
