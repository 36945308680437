import { useApolloClient } from "@apollo/client";
import { LogOut } from "@frontend/lyng/assets/icons/16/outline";
import { Empty } from "@frontend/lyng/assets/svg";
import { Button } from "@frontend/lyng/button";
import { Headline, Paragraph } from "@frontend/lyng/typography";
import { useTranslate } from "@tolgee/react";
import { signOut } from "aws-amplify/auth";

export const NoRoleHome = () => {
  const client = useApolloClient();

  const logOut = () => {
    client.resetStore();
    localStorage.removeItem("tenantId");
    signOut();
  };
  const { t } = useTranslate();

  return (
    <div className="h-full flex justify-center items-center">
      <Empty.D1 className="hidden md:block absolute top-10 left-12" />
      <div className="flex-col items-center gap-6 flex">
        <Headline size="xl" className="text-center">
          {t("noRoleHome.welcome")}
        </Headline>
        <Paragraph size="m" className="text-center max-w-80">
          {t("noRoleHome.pleaseContact")}
        </Paragraph>
        <Button
          className="mt-3"
          iconPosition="right"
          variant="secondary"
          text={t("noRoleHome.signOut")}
          icon={LogOut}
          onClick={logOut}
        />
      </div>
      <Empty.D2 className="w-[283px] h-[283px] md:w-[unset] md:h-[unset] absolute bottom-4 right-4" />
    </div>
  );
};
