import { Checkmark } from "../assets/icons/16/outline";
import { EyeClosed, EyeOpen } from "../assets/svg/Eye";
import { zodResolver } from "@hookform/resolvers/zod";
import { signIn } from "aws-amplify/auth";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { AuthenticatorCard } from "./AuthenticatorCard";
import { LoadingBounce } from "../loading";

const resetPasswordUrl = import.meta.env.VITE_DOMAIN + "/resetpassword";
const validateTokenUrl = import.meta.env.VITE_DOMAIN + "/validtoken";
const forgotPasswordUrl = import.meta.env.VITE_DOMAIN + "/forgotpassword";

const sendRequest = async (email: string) => {
  return fetch(forgotPasswordUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, app: "team" }),
  });
};

const PasswordRequirement = ({
  valid,
  isDirty,
  text,
}: {
  valid: boolean;
  isDirty: boolean;
  text: string;
}) => (
  <div
    className={classNames("flex flex-row gap-2 rounded-full", {
      "bg-secondary-100": isDirty && valid,
    })}
  >
    <div
      className={classNames(
        "flex h-6 w-6 items-center justify-center rounded-full ring-inset ring-greyscale-200",
        {
          "bg-white ring-1": !isDirty || !valid,
          "bg-secondary-600 ring-0": isDirty && valid,
        },
      )}
    >
      {isDirty && valid && <Checkmark className="text-white" />}
    </div>
    {text}
  </div>
);

const validationSchema = z.object({
  password: z
    .string()
    .min(8, { message: "min_length" })
    .regex(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, {
      message: "special_character",
    })
    .regex(/[A-Z]/, { message: "uppercase_letter" })
    .regex(/[a-z]/, { message: "lowercase_letter" })
    .regex(/[0-9]/, { message: "digit" }),
});

type FormValues = z.infer<typeof validationSchema>;

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isValidToken, setIsValidToken] = useState<boolean | null>(null);

  const { register, handleSubmit, watch, formState } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      password: "",
    },
  });

  const watchPassword = watch("password");

  useEffect(() => {
    if (!token || !email) {
      navigate("/");
      return;
    }
    const onValidateToken = async () => {
      await fetch(`${validateTokenUrl}?token=${token}`, {
        method: "GET",
      }).then((r) => {
        if (r.status !== 204) {
          console.error("Token is invalid");
          setIsValidToken(false);
        } else {
          setIsValidToken(true);
        }
      });
    };

    onValidateToken();
  }, [token, email, navigate, searchParams]);

  const onSubmit = async (data: FormValues) => {
    if (!token || !email) {
      return;
    }

    try {
      const response = await fetch(resetPasswordUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          token,
          password: data.password,
        }),
      });

      if (!response.ok) {
        setError("An error occurred. Please try again.");
      } else {
        await signIn({ username: email, password: data.password });
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
    }
  };

  // rhf + zod only returns the first error
  const validations = validationSchema.safeParse({
    password: watchPassword,
  });

  if (isValidToken === null) {
    // wordmark is centered so this behaves like a splash
    return null;
  }

  if (!isValidToken) {
    return <ResetPasswordInvalidToken />;
  }

  return (
    <AuthenticatorCard>
      <form
        className="space-y-6 p-4"
        action="#"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <label
            htmlFor="password"
            className="ml-4 block text-lg font-medium text-greyscale-900"
          >
            New password
          </label>
          <div className="relative mt-2">
            <input
              {...register("password")}
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              className={classNames(
                "flex h-12 w-full items-center rounded-xl border-0 py-2 text-lg text-gray-900 shadow-sm ring-1 ring-inset transition-colors focus:ring-2 focus:ring-inset bg-primary-0",
                {
                  "ring-greyscale-300 placeholder:text-greyscale-400 focus:ring-primary-600":
                    !formState.errors.password,
                  "ring-red-300 placeholder:text-red-300 focus:ring-red-600":
                    formState.errors.password,
                },
              )}
            />

            {formState.errors.password && (
              <div className="text-sm text-critical-800 ml-4 mt-3">
                {"The password must meet all requirements."}
              </div>
            )}

            <div className="absolute inset-y-0 right-0 top-3 items-center pr-3">
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="text-greyscale-600 hover:text-greyscale-400 active:text-greyscale-600"
              >
                {showPassword ? <EyeClosed /> : <EyeOpen />}
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 rounded-xl border border-greyscale-200 p-4">
          <PasswordRequirement
            isDirty={formState.isDirty}
            valid={
              validations.success ||
              !validations.error.issues.some(
                (issue) => issue.message === "min_length",
              )
            }
            text="At least 8 charactes"
          />
          <PasswordRequirement
            isDirty={formState.isDirty}
            valid={
              validations.success ||
              !validations.error.issues.some(
                (issue) => issue.message === "lowercase_letter",
              )
            }
            text="Lower case letters (a-z)"
          />
          <PasswordRequirement
            isDirty={formState.isDirty}
            valid={
              validations.success ||
              !validations.error.issues.some(
                (issue) => issue.message === "uppercase_letter",
              )
            }
            text="Upper case letters (A-Z)"
          />
          <PasswordRequirement
            isDirty={formState.isDirty}
            valid={
              validations.success ||
              !validations.error.issues.some(
                (issue) => issue.message === "digit",
              )
            }
            text="Numbers (0-9)"
          />
          <PasswordRequirement
            isDirty={formState.isDirty}
            valid={
              validations.success ||
              !validations.error.issues.some(
                (issue) => issue.message === "special_character",
              )
            }
            text="Special characters (e.g. !@#$%^&*)"
          />
        </div>

        {error && (
          <div className="px-4 text-red-600" role="alert">
            {error}
          </div>
        )}

        <button
          type="submit"
          className="flex w-full items-center justify-center rounded-lg bg-primary-600 px-8 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:bg-primary-800"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? <LoadingBounce /> : "Continue"}
        </button>
      </form>
    </AuthenticatorCard>
  );
};

const ResetPasswordInvalidToken = () => {
  const [searchParams] = useSearchParams(); // Step 2: Use useSearchParams hook
  const email = searchParams.get("email");

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (email) {
      setLoading(true);
      await sendRequest(email)
        .then((r) => {
          if (!r.ok) {
            setError("An error occurred. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setError("An error occurred. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <AuthenticatorCard>
      <div className="flex flex-col gap-8 px-4 pb-4 pt-8">
        <h2 className="self-center text-2xl font-semibold text-greyscale-900">
          Link expired
        </h2>
        <p className="px-4 text-greyscale-900">
          {`This link has expired, press below to receive a new link to your email inbox.`}
        </p>
        <div className="gap-2">
          <button
            type="submit"
            className="flex w-full items-center justify-center rounded-lg bg-white px-8 py-3 text-lg font-semibold leading-6 text-greyscale-900 shadow-sm ring-1 ring-inset ring-greyscale-200 hover:text-greyscale-500 focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 active:text-primary-700"
            onClick={() => onSubmit()}
            disabled={loading}
          >
            Resend link
          </button>
          {error && (
            <div className="ml-4 mt-3 text-sm text-critical-800">{error}</div>
          )}
        </div>
      </div>
    </AuthenticatorCard>
  );
};
