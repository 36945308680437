import { Fragment } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useTranslate } from "@tolgee/react";
import { Button, Variant } from "@frontend/lyng/button/Button";

type Type = "primary" | "critical";

type Props = {
  show: boolean;
  title: string;
  acceptText?: string;
  closeText?: string;
  onClose: () => void;
  onAccept: () => void;
  type?: Type;
  children?: React.ReactNode;
};

type VariantRes = {
  accept: Variant;
  close: Variant;
};

const getVariant = (style: Type = "primary"): VariantRes => {
  if (style === "critical") return { accept: "critical", close: "secondary" };
  return { accept: "primary", close: "secondary" };
};

const SimpleModal = ({
  show,
  title,
  closeText,
  acceptText,
  onClose,
  onAccept,
  type = "primary",
  children,
}: Props) => {
  const { t } = useTranslate();

  const variant = getVariant(type);

  return (
    <Transition show={show} appear as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative flex transform flex-col gap-8 overflow-hidden rounded-3xl bg-white px-5 py-8 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                <div className="flex flex-col gap-2">
                  <DialogTitle
                    as="h3"
                    className="text-2xl font-bold leading-tight tracking-tight text-greyscale-900"
                  >
                    {title}
                  </DialogTitle>

                  {children}
                </div>

                <div className="flex flex-row-reverse gap-2">
                  <Button
                    size="md"
                    variant={variant.accept}
                    className="flex-1"
                    text={acceptText || t("ok").toString()}
                    onClick={onAccept}
                  />

                  <Button
                    size="md"
                    variant={variant.close}
                    className="flex-1"
                    text={closeText || t("cancel").toString()}
                    onClick={onClose}
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SimpleModal;
