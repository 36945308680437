import { Calendar as CalendarIcon } from "../../assets/icons/16/filled";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
  CalendarDate,
  CalendarDateTime,
  createCalendar,
} from "@internationalized/date";
import { AriaButtonOptions, useButton } from "@react-aria/button";
import {
  AriaDateFieldOptions,
  AriaDateRangePickerProps,
  useDateField,
  useDatePicker,
  useDateRangePicker,
  useDateSegment,
} from "@react-aria/datepicker";
import { AriaDialogProps, useDialog } from "@react-aria/dialog";
import {
  AriaPopoverProps,
  DismissButton,
  Overlay,
  usePopover,
} from "@react-aria/overlays";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useRef } from "react";
import {
  DateFieldState,
  DatePickerStateOptions,
  DateSegment,
  OverlayTriggerState,
  useDateFieldState,
  useDatePickerState,
  useDateRangePickerState,
} from "react-stately";
import { P, match } from "ts-pattern";

import { Calendar, RangeCalendar } from "./Calendar";
import { DateFormat } from "../../api/generated/graphql";
import { Paragraph } from "../../typography";
import { DatePickerProps, WithOrWithoutTime } from "./types";
import { DateSettings, useDateFormatter } from "../../utils/dateUtils";

function dateTimeToCalendarDate<TWithTime extends boolean>(
  date: DateTime,
  withTime: TWithTime,
): WithOrWithoutTime<TWithTime> {
  if (withTime) {
    return new CalendarDateTime(
      date.year,
      date.month,
      date.day,
      date.hour,
      date.minute,
    ) as WithOrWithoutTime<TWithTime>;
  }
  return new CalendarDate(
    date.year,
    date.month,
    date.day,
  ) as WithOrWithoutTime<TWithTime>;
}
function calendarDateToDateTime<TWithTime extends boolean>(
  date: WithOrWithoutTime<TWithTime>,
): DateTime {
  if (date instanceof CalendarDateTime) {
    return DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: date.hour,
      minute: date.minute,
    });
  }
  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
  });
}

const getFieldWrapperClassName = (isDisabled: boolean, isInvalid: boolean) => {
  return match([isDisabled, isInvalid])
    .with([true, P._], () => "border-gray-200 bg-gray-50 text-gray-500")
    .with(
      [false, true],
      () =>
        "border-red-300 text-red-900 placeholder-red-300 group-focus-within:border-red-500 group-focus-within:outline-none group-focus-within:ring-red-500",
    )
    .with(
      [false, false],
      () =>
        "border-gray-300 group-hover:border-gray-400 group-focus-within:border-primary-600 group-focus-within:group-hover:border-primary-600",
    )
    .exhaustive();
};

export function DatePicker<TWithTime extends boolean = false>(
  props: DatePickerProps<TWithTime>,
) {
  const { meridiem } = useDateFormatter(props.dateSettings);

  const {
    value,
    onChange,
    minValue,
    maxValue,
    showCalendar = "showCalendarButton",
    editView = true,
    withTime = false as TWithTime,
    dateSettings,
    ...otherProps
  } = props;

  const innerValue = value
    ? dateTimeToCalendarDate<TWithTime>(value, withTime)
    : null;
  function innerOnChange<T extends boolean>(
    value: WithOrWithoutTime<T> | undefined,
  ) {
    if (value instanceof CalendarDateTime) {
      return onChange && onChange(value ? calendarDateToDateTime(value) : null);
    }
    return onChange && onChange(value ? calendarDateToDateTime(value) : null);
  }
  const innerMinValue = minValue
    ? dateTimeToCalendarDate(minValue, withTime)
    : undefined;
  const innerMaxValue = maxValue
    ? dateTimeToCalendarDate(maxValue, withTime)
    : undefined;

  const innerProps: DatePickerStateOptions<WithOrWithoutTime<TWithTime>> = {
    ...otherProps,
    value: innerValue,
    onChange: innerOnChange,
    minValue: innerMinValue,
    maxValue: innerMaxValue,
    isDisabled: props.isDisabled || !editView,
    hourCycle: meridiem ? 12 : 24,
  };
  const state = useDatePickerState(innerProps);
  const ref = useRef(null);
  const { groupProps, fieldProps, buttonProps, dialogProps, calendarProps } =
    useDatePicker(innerProps, state, ref);

  if (!editView && !value) {
    return (
      <Paragraph size="m" className="flex h-12 items-center px-5">
        -
      </Paragraph>
    );
  }

  // TODO: Change this to be on focus rather than on click
  const onClick = () => {
    if (props.showCalendar === "showCalendarOnFocus") {
      state.open();
    }
  };

  return (
    <div
      className={classNames(
        "relative flex max-w-lg rounded-xl  sm:max-w-xs",
        props.className,
      )}
    >
      <div {...groupProps} ref={ref} className="group flex w-full">
        <div
          className={classNames(
            "relative flex h-12 w-full items-center  rounded-l-xl border bg-white dark:bg-transparent pl-4 pr-8 text-sm transition-colors",
            {
              "border-gray-300 dark:border-greyscale-700 dark:text-greyscale-100 group-focus-within:border-primary-600 group-hover:border-gray-400 group-focus-within:group-hover:border-primary-600 dark:focus-within:border-primary-500 dark:focus-within:ring-primary-500 dark:active:border-primary-500 dark:active:ring-primary-500":
                !state.isInvalid && editView,
              "border-red-300 text-red-900 placeholder-red-300 focus-within:ring-red-500 group-focus-within:border-red-500 group-focus-within:outline-none":
                state.isInvalid && editView,
              "border-transparent bg-transparent placeholder-greyscale-900 hover:border-transparent":
                !editView,
              "bg-white": editView,
              "rounded-r-xl": showCalendar !== "showCalendarButton",
            },
          )}
          onClick={onClick}
        >
          <DateField
            {...fieldProps}
            editView={editView}
            onClick={onClick}
            dateSettings={dateSettings}
          />
          {state.isInvalid && (
            <ExclamationCircleIcon
              className="absolute right-1 h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
        {showCalendar === "showCalendarButton" && editView && (
          <DatePickerButton
            {...buttonProps}
            isDisabled={props.isDisabled}
            isPressed={state.isOpen}
            isInvalid={state.isInvalid}
          />
        )}
      </div>
      {state.isOpen && (
        <Popover triggerRef={ref} state={state} placement="bottom start">
          <Dialog {...dialogProps}>
            <Calendar
              {...calendarProps}
              country={props.dateSettings?.country ?? ""}
            />
          </Dialog>
        </Popover>
      )}
    </div>
  );
}

type Range = {
  start: DateTime;
  end: DateTime;
};

type DateRangePickerProps = Omit<
  AriaDateRangePickerProps<CalendarDate>,
  "value" | "onChange" | "minValue" | "maxValue"
> & {
  value?: Range;
  onChange?: (value: Range | undefined) => void;
  minValue?: DateTime;
  maxValue?: DateTime;
  name?: string;
  dateSettings?: DateSettings;
  onClick?: () => void;
};
export function DateRangePicker(props: DateRangePickerProps) {
  const { value, onChange, minValue, maxValue, dateSettings, ...otherProps } =
    props;

  const innerValue = value
    ? {
        start: dateTimeToCalendarDate(value.start, false),
        end: dateTimeToCalendarDate(value.end, false),
      }
    : undefined;
  const innerOnChange = (
    value: { start: CalendarDate; end: CalendarDate } | undefined,
  ) =>
    onChange &&
    onChange(
      value
        ? {
            start: calendarDateToDateTime(value.start),
            end: calendarDateToDateTime(value.end),
          }
        : undefined,
    );
  const innerMinValue = minValue
    ? dateTimeToCalendarDate(minValue, false)
    : undefined;
  const innerMaxValue = maxValue
    ? dateTimeToCalendarDate(maxValue, false)
    : undefined;

  const innerProps: AriaDateRangePickerProps<CalendarDate> = {
    ...otherProps,
    value: innerValue,
    onChange: innerOnChange,
    minValue: innerMinValue,
    maxValue: innerMaxValue,
  };

  const state = useDateRangePickerState(innerProps);
  const ref = useRef(null);
  const {
    groupProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(innerProps, state, ref);

  return (
    <div className="relative inline-flex max-w-lg rounded-xl text-left sm:max-w-md">
      <div {...groupProps} ref={ref} className="group flex w-full">
        <div
          className={classNames(
            "relative flex h-12 w-full items-center  rounded-l-xl border bg-white p-1  pl-4 pr-8 transition-colors",
            getFieldWrapperClassName(!!props.isDisabled, !!state.isInvalid),
          )}
        >
          <DateField
            {...startFieldProps}
            onClick={props.onClick}
            dateSettings={dateSettings}
          />
          <span aria-hidden="true" className="px-2">
            –
          </span>
          <DateField
            {...endFieldProps}
            onClick={props.onClick}
            dateSettings={dateSettings}
          />
          {state.isInvalid && (
            <ExclamationCircleIcon className="absolute right-1 h-5 w-5 text-red-500" />
          )}
        </div>
        <DatePickerButton
          {...buttonProps}
          isDisabled={props.isDisabled}
          isPressed={state.isOpen}
          isInvalid={state.isInvalid}
        />
      </div>
      {state.isOpen && (
        <Popover triggerRef={ref} state={state} placement="bottom start">
          <Dialog {...dialogProps}>
            <RangeCalendar
              {...calendarProps}
              country={props.dateSettings?.country ?? ""}
            />
          </Dialog>
        </Popover>
      )}
    </div>
  );
}

export const getLocale = (settings: DateSettings) => {
  if (!settings) {
    return "en-US";
  }

  switch (settings.dateFormat) {
    case DateFormat.DdMmYyyy:
      return "en-GB";
    case DateFormat.MmDdYyyy:
      return "en-US";
  }
};

export function DateField({
  editView = true,
  dateSettings,
  onClick,
  ...props
}: AriaDateFieldOptions<CalendarDate> & {
  editView?: boolean;
  dateSettings: DateSettings | undefined;
  onClick?: () => void;
}) {
  const locale = getLocale(dateSettings);

  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div {...fieldProps} ref={ref} className="flex">
      {state.segments.map((segment, i) => (
        <Segment
          key={i}
          segment={segment}
          state={state}
          editView={editView}
          onClick={onClick}
        />
      ))}
    </div>
  );
}

export function Segment({
  segment,
  state,
  editView = true,
  onClick,
}: {
  segment: DateSegment;
  state: DateFieldState;
  editView?: boolean;
  onClick?: () => void;
}) {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={segmentProps.style}
      onClick={onClick}
      className={classNames(
        "min group box-content rounded-sm px-0.5 text-right text-lg tabular-nums outline-none focus:bg-primary-500 focus:text-white",
        editView && (!segment.isEditable || state.isDisabled)
          ? "text-greyscale-500"
          : "text-greyscale-900 dark:text-greyscale-100",
        editView && segment.maxValue
          ? `min-w-[${String(segment.maxValue).length}ch]`
          : "",
      )}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. Hide placeholder in display view to preserve layout alignments */}
      {editView && (
        <span
          aria-hidden="true"
          className={classNames(
            "pointer-events-none block w-full text-center italic text-gray-500 group-focus:text-white dark:text-greyscale-200",
            {
              invisible: !segment.isPlaceholder,
              "h-0": !segment.isPlaceholder,
            },
          )}
          onClick={onClick}
        >
          {segment.placeholder}
        </span>
      )}
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  );
}

export function DatePickerButton(
  props: AriaButtonOptions<"button"> & {
    isPressed: boolean;
    isInvalid?: boolean;
  },
) {
  const ref = useRef(null);
  const { buttonProps, isPressed } = useButton(props, ref);

  const colorClassName = match([
    !!props.isDisabled,
    !!props.isInvalid,
    props.isPressed || isPressed,
  ])
    .with([true, P._, P._], () => "border-gray-200 bg-gray-50 text-gray-500")
    .with(
      [false, true, P._],
      () =>
        "border-red-300 text-red-900 group-focus-within:border-red-500 group-focus-within:outline-none group-focus-within:ring-red-500",
    )
    .with([false, false, true], () => "bg-primary-100 border-gray-400")
    .with(
      [false, false, false],
      () =>
        "bg-gray-50 border-gray-300 group-hover:border-gray-400 group-focus-within:border-primary-600 group-focus-within:group-hover:border-primary-600 ",
    )
    .exhaustive();

  const iconColorClassName = match([!!props.isDisabled, !!props.isInvalid])
    .with([true, P._], () => "text-gray-400")
    .with([false, true], () => "text-red-500")
    .with(
      [false, false],
      () => "text-gray-700 group-focus-within:text-primary-700",
    )
    .exhaustive();

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={classNames(
        "-ml-px h-full rounded-r-xl border px-2 outline-none transition-colors disabled:cursor-not-allowed",
        colorClassName,
      )}
    >
      <CalendarIcon className={classNames("h-5 w-5 ", iconColorClassName)} />
    </button>
  );
}

type DialogProps = AriaDialogProps & {
  children: React.ReactNode;
};

export function Dialog({ children, ...props }: DialogProps) {
  const ref = useRef(null);
  const { dialogProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref}>
      {children}
    </div>
  );
}
type PopoverProps = Omit<AriaPopoverProps, "popoverRef"> & {
  children: React.ReactNode;
  state: OverlayTriggerState;
};
export function Popover(props: PopoverProps) {
  const ref = useRef(null);
  const { state, children } = props;

  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef: ref,
    },
    state,
  );

  return (
    <Overlay>
      <div {...underlayProps} className="fixed inset-0" />
      <div
        {...popoverProps}
        ref={ref}
        className="absolute z-10 my-2 overflow-auto rounded-md border border-gray-300  bg-white p-8"
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}
