import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import toast from "react-hot-toast";
import { DateTime } from "luxon";
import { errorToToastMessage } from "../../utils/toastUtils";
import {
  useAbsenceByIdQuery,
  CaregiverAbsenceCreateInput,
  CareRecipientAbsenceCreateInput,
} from "../../api/generated/graphql";
import { SimpleModal } from "../../components/common";
import { useAbsenceMutations } from "./hook";
import AbsenceForm, { FormInput } from "./absenceForm";
import { TimeInput } from "@frontend/lyng/forms";
import { SlideOver } from "@frontend/lyng/slideOver";

type GetStartAndEnd = {
  start: DateTime;
  end?: DateTime;
  startTime: TimeInput | null;
  endTime: TimeInput | null;
  allDay?: boolean;
};

type HelperProps = {
  handleClose: () => void;
  handleSubmit: (values: FormInput) => void;
  isCareGiver: boolean;
};

const Update = ({
  handleClose,
  handleSubmit,
  handleDelete,
  isCareGiver,
}: HelperProps & { handleDelete: () => void }) => {
  const { id } = useParams();

  const { data, error, loading } = useAbsenceByIdQuery({
    variables: { id: id ?? "" },
  });
  if (loading) return <h3>Loading...</h3>;
  return (
    <>
      {error && errorToToastMessage(error)}
      <AbsenceForm
        absence={id ? data?.absenceById : undefined}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        isCareGiver={isCareGiver}
      />
    </>
  );
};

const Create = ({ handleClose, handleSubmit, isCareGiver }: HelperProps) => (
  <AbsenceForm
    onClose={handleClose}
    onSubmit={handleSubmit}
    isCareGiver={isCareGiver}
  />
);

const AbsenceSlideOver = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { careRecipientId, caregiverId, id } = useParams();

  const {
    createAbsenceForCaregiver,
    createAbsenceForCareRecipient,
    updateAbsenceForCaregiver,
    updateAbsenceForCareRecipient,
    deleteAbsence,
  } = useAbsenceMutations();

  const getStartAndEnd = ({
    start,
    end,
    startTime,
    endTime,
    allDay,
  }: GetStartAndEnd) => {
    if (allDay) {
      return [
        start.startOf("day").toISO() ?? "",
        (end || start).endOf("day").toISO() ?? "",
      ];
    }

    const startWithTime = start.set(startTime ?? {});
    let endWithTime = start.set(endTime ?? {});
    if (endWithTime && endWithTime < startWithTime) {
      endWithTime = endWithTime.plus({ days: 1 });
    }

    return [
      startWithTime.toUTC().toISO() ?? "",
      endWithTime?.toUTC().toISO() ?? "",
    ];
  };

  const handleClose = () => navigate("..");

  const updatePromise = (
    input: CareRecipientAbsenceCreateInput | CaregiverAbsenceCreateInput,
    isCareGiver: boolean,
  ) => {
    if (isCareGiver) {
      return updateAbsenceForCaregiver({
        variables: {
          id: id ?? "",
          input: input as CaregiverAbsenceCreateInput,
        },
      }).then(({ data }) => {
        handleClose();
        return data?.absenceUpdateForCaregiver;
      });
    } else {
      return updateAbsenceForCareRecipient({
        variables: {
          id: id ?? "",
          input: input as CareRecipientAbsenceCreateInput,
        },
      }).then(({ data }) => {
        handleClose();
        return data?.absenceUpdateForCareRecipient;
      });
    }
  };

  const createPromise = (
    input: CareRecipientAbsenceCreateInput | CaregiverAbsenceCreateInput,
    isCareGiver: boolean,
  ) => {
    if (isCareGiver) {
      return createAbsenceForCaregiver({
        variables: { input: input as CaregiverAbsenceCreateInput },
      }).then(({ data }) => {
        handleClose();
        return data?.absenceCreateForCaregiver;
      });
    } else {
      return createAbsenceForCareRecipient({
        variables: { input: input as CareRecipientAbsenceCreateInput },
      }).then(({ data }) => {
        handleClose();
        return data?.absenceCreateForCareRecipient;
      });
    }
  };

  const handleSubmit = (values: FormInput) => {
    const [start, end] = getStartAndEnd(values);
    const userId = careRecipientId || caregiverId || "";
    const isCareGiver = !!caregiverId;

    const input = {
      end,
      start,
      allDay: values.allDay,
      reason: values.reason?.value,
      reportedAt: DateTime.now().toUTC().toISO(),
    };

    const promise = id
      ? updatePromise(
          isCareGiver
            ? ({ ...input } as CaregiverAbsenceCreateInput)
            : ({ ...input } as CareRecipientAbsenceCreateInput),
          isCareGiver,
        )
      : createPromise(
          isCareGiver
            ? ({ ...input, userId } as CaregiverAbsenceCreateInput)
            : ({ ...input, userId } as CareRecipientAbsenceCreateInput),
          isCareGiver,
        );

    toast.promise(promise, {
      loading: t("absences.creating"),
      success: t("absences.successCreate"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleDelete = () => {
    const promise = deleteAbsence({ variables: { absenceId: id ?? "" } }).then(
      () => handleClose(),
    );
    toast.promise(promise, {
      loading: t("absences.deleting"),
      success: t("absences.successDelete"),
      error: (err) => errorToToastMessage(err),
    });
  };

  return (
    <SlideOver.WithIntercomHider onClose={handleClose} show={true}>
      <SlideOver.Title>
        {!id ? t("absences.register") : t("absences.update")}
      </SlideOver.Title>
      <SlideOver.DetailSection>
        {id ? (
          <Update
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleDelete={() => setShow(true)}
            isCareGiver={caregiverId !== undefined}
          />
        ) : (
          <Create
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isCareGiver={caregiverId !== undefined}
          />
        )}
      </SlideOver.DetailSection>

      <SimpleModal
        show={show}
        title={t("absences.delete")}
        onClose={() => setShow(false)}
        onAccept={handleDelete}
      />
    </SlideOver.WithIntercomHider>
  );
};

export default AbsenceSlideOver;
