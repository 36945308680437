import { DateTime } from "luxon";
import { match, P } from "ts-pattern";
import { COLORS } from "../constants/eventColors";
import { Frequency, Weekday } from "../api/generated/graphql";
import { UseTranslateResult } from "@tolgee/react";
import {
  datedayToWeekday,
  weekdaysArrayToString,
} from "@frontend/lyng/utils/dateUtils";

type EventColor = {
  text: string;
  background: string;
};

export const getAbsenceColor = () => COLORS.ABSENCE;
export type VisitStates =
  | "active"
  | "open"
  | "late-clockout"
  | "late-clockin"
  | "cancelled"
  | "scheduled"
  | "completed"
  | "missed";

export const getVisitColor = (visit: {
  start: string;
  durationMinutes: number;
  visitorIds: string[];
  clockInTime: string | null;
  clockOutTime: string | null;
  cancelledAt?: string | null;
}): EventColor => {
  const state = getVisitState(visit);
  return match(state)
    .with("cancelled", () => COLORS.CANCELED)
    .with("late-clockout", () => COLORS.LATE_CLOCK_OUT)
    .with("late-clockin", () => COLORS.LATE_CLOCK_IN)
    .with("completed", () => COLORS.COMPLETED)
    .with("open", () => COLORS.OPEN)
    .with("active", () => COLORS.ACTIVE)
    .with("scheduled", () => COLORS.SCHEDULED)
    .with("missed", () => COLORS.LATE_CLOCK_OUT)
    .exhaustive();
};

export const getVisitState = (visit: {
  start: string;
  durationMinutes: number;
  visitorIds: string[];
  clockInTime: string | null;
  clockOutTime: string | null;
  cancelledAt?: string | null;
}): VisitStates => {
  const now = DateTime.now();
  const startDate = DateTime.fromISO(visit.start);
  const endDate = startDate.plus({ minutes: visit.durationMinutes });

  if (visit.cancelledAt) return "cancelled";

  // Missed
  if (visit.clockInTime === null && endDate < now) {
    return "missed";
  }

  // Late clock-out
  if (endDate < now && visit.clockOutTime === null) {
    return "late-clockout";
  }

  // Completed
  if (endDate < now) {
    return "completed";
  }

  // Open
  if (visit.visitorIds.length === 0) {
    return "open";
  }

  // Late clock-in
  if (startDate < now && visit.clockInTime === null) {
    return "late-clockin";
  }

  // Active
  if ((startDate < now || visit.clockInTime) && endDate > now) {
    return "active";
  }

  // Scheduled
  return "scheduled";
};

export type Value = {
  frequency: Frequency | null;
  weekdays: Weekday[] | null;
  interval: number | null;
};

export type Props = {
  reccurence: Value | null;
};

type Selections =
  | ""
  | "DAILY"
  | "WEEKLY"
  | "EVERY_WEEKDAY"
  | "MONTHLY"
  | "SELECTED_CUSTOM"
  | "NEW_CUSTOM";

export const getSelectValueAndDescription = (
  value: Value | null,
  t: UseTranslateResult["t"],
): [Selections, string | null] => {
  const [selectValue, maybeCustomDescription]: [Selections, string | null] =
    match<Value | null, [Selections, string | null]>(value)
      .with(P.nullish, () => ["", null])
      .with({ frequency: P.nullish, weekdays: P._ }, () => ["", null])
      .with(
        {
          frequency: Frequency.Daily,
          interval: P.number.gt(1),
        },
        () => [
          "SELECTED_CUSTOM",
          t("visitRecurrencePicker.everyNDays", { interval: value?.interval }),
        ],
      )
      .with(
        {
          frequency: Frequency.Daily,
        },
        () => ["DAILY", null],
      )
      .with(
        {
          frequency: Frequency.Weekly,
          weekdays: [
            Weekday.Monday,
            Weekday.Tuesday,
            Weekday.Wednesday,
            Weekday.Thursday,
            Weekday.Friday,
          ],
          interval: P.union(P.nullish, P.number.lt(2)),
        },
        () => ["EVERY_WEEKDAY", null],
      )
      .with(
        {
          frequency: Frequency.Weekly,
          weekdays: P.union(P.nullish, []),
          interval: P.union(P.nullish, P.number.lt(2)),
        },
        () => ["WEEKLY", null],
      )
      .with(
        {
          frequency: Frequency.Monthly,
          interval: P.union(P.nullish, P.number.lt(2)),
        },
        () => ["MONTHLY", null],
      )
      .with(
        {
          frequency: Frequency.Weekly,
          weekdays: P.union([P._, ...P.array()], [...P.array(), P._]),
          interval: P.union(P.nullish, P.number.lt(2)),
        },
        ({ weekdays }) => [
          "SELECTED_CUSTOM",
          t("visitRecurrencePicker.weeklyOn", {
            day: weekdaysArrayToString(weekdays, t),
          }),
        ],
      )
      .with(
        {
          frequency: Frequency.Weekly,
          weekdays: P.union(P.nullish, []),
          // implicit: interval: P.number.gt(1)
        },
        ({ interval }) => [
          "SELECTED_CUSTOM",

          t("visitRecurrencePicker.everyNWeeks", { interval }),
        ],
      )
      .with(
        {
          frequency: Frequency.Monthly,
          // implicit: interval: P.number.gt(1)
        },
        ({ interval }) => [
          "SELECTED_CUSTOM",

          t("visitRecurrencePicker.everyNMonths", { interval }),
        ],
      )
      .with(
        {
          frequency: Frequency.Weekly,
          weekdays: P.union([P._, ...P.array()], [...P.array(), P._]),
          // implicit: interval: P.number.gt(1),
        },
        ({ weekdays, interval }) => [
          "SELECTED_CUSTOM",

          t("visitRecurrencePicker.everyNWeeksOn", {
            day: weekdaysArrayToString(weekdays, t),
            interval,
          }),
        ],
      )
      .exhaustive();
  return [selectValue, maybeCustomDescription];
};

export const getPremadeSelections = (
  baseDate: Date | null,
  t: UseTranslateResult["t"],
) => {
  const premadeSelections: Record<Selections, { description: string }> = {
    "": { description: t("visitRecurrencePicker.doesNotRepeat") },
    DAILY: { description: t("visitRecurrencePicker.daily") },
    WEEKLY: {
      description: baseDate
        ? t("visitRecurrencePicker.weeklyOn", {
            day: t(
              `visitRecurrencePicker.weeklyOnDays.${datedayToWeekday(baseDate.getDay())}`,
            ),
          })
        : t("visitRecurrencePicker.weekly"),
    },
    MONTHLY: {
      description: baseDate
        ? t("visitRecurrencePicker.monthlyOn", {
            day: baseDate.getDate(),
          })
        : t("visitRecurrencePicker.monthly"),
    },
    EVERY_WEEKDAY: {
      description: t("visitRecurrencePicker.everyWeekday"),
    },
    SELECTED_CUSTOM: { description: "" },
    NEW_CUSTOM: { description: t("visitRecurrencePicker.custom") },
  };
  return premadeSelections;
};

export const getHumanReadableRecurrence = (
  reccurence: Value | null,
  baseDate: Date | null,
  t: UseTranslateResult["t"],
): string => {
  const [selectValue, maybeCustomDescription] = getSelectValueAndDescription(
    reccurence,
    t,
  );

  const premadeSelections = getPremadeSelections(baseDate, t);
  const retString =
    selectValue === "SELECTED_CUSTOM" && maybeCustomDescription
      ? maybeCustomDescription
      : premadeSelections[selectValue].description;

  return retString;
};
