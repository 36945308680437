import { useMemo, useCallback } from "react";
import { useTranslate, useTolgee } from "@tolgee/react";
import { match } from "ts-pattern";
import { DateTime } from "luxon";

import FullCalendar from "@fullcalendar/react";
import { DateClickArg } from "@fullcalendar/interaction";
import {
  EventClickArg,
  DatesSetArg,
  EventChangeArg,
  DateSelectArg,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import luxon3plugin from "@fullcalendar/luxon3";
import { startOfWeek } from "@internationalized/date";
import {
  dateTimeToZonedDateTime,
  getShortDayFromDate,
} from "@frontend/lyng/utils/dateUtils";
import { useDateFormatter } from "../../../utils/dateUtils";
import { getTimeFormat } from "../../../utils/fullCalendarUtils";
import { getVisitColor, getAbsenceColor } from "../../../utils/visitUtils";

import {
  Event,
  Events,
  VisitExtendedProps,
  TopActions,
  FullCalendarEvent,
  VisitInstance,
  AbsenceInstance,
} from "./types";
import { Holiday } from "../../../utils/hooks/useGetHolidays";
import { useLocale } from "@react-aria/i18n";
import { LoadingBounce } from "@frontend/lyng/loading";
import { Button } from "@frontend/lyng/button/Button";

const transformWeekDay = (day: number) => (day === 7 ? 0 : day);

type Props = {
  events: Event[];
  holidays?: Holiday[];
  editable?: boolean;
  loading?: boolean;
  datesSet?: (arg: DatesSetArg) => void;
  eventClick?: (arg: EventClickArg) => void;
  eventChange?: (arg: EventChangeArg) => void;
  dateClick?: (arg: DateClickArg) => void;
  select?: (arg: DateSelectArg) => void;
  absenceClick?: (id: string) => void;
  getTitle?: (visit: VisitInstance) => string;
  topActions?: TopActions;
  timezone: string;
};

export const Schedule = ({
  events = [],
  holidays = [],
  editable = false,
  loading,
  datesSet,
  eventClick,
  eventChange,
  dateClick,
  getTitle,
  select,
  absenceClick,
  topActions,
  timezone,
}: Props) => {
  const { t } = useTranslate();
  const tolgee = useTolgee();
  const { meridiem } = useDateFormatter();
  const { locale } = useLocale();

  const createVisitEvent = useCallback(
    (visit: VisitInstance): FullCalendarEvent => {
      const now = DateTime.now();

      const start = DateTime.fromISO(visit.start);
      const end = start.plus({ minutes: visit.durationMinutes });
      const hasClockIn = visit.clockInTime ? true : false;

      const fullName = getTitle ? getTitle(visit) : "";

      const colors = getVisitColor(visit);

      const extendedProps: VisitExtendedProps = {
        careRecipientId: visit.careRecipientId,
        durationMinutes: visit.durationMinutes,
        visitorIds: visit.visitorIds,
        labelIds: visit.labelIds,
        recurrences: visit.recurrence?.rRule ?? null,
        recurrenceEnd: visit.recurrence?.end || null,
        exceptionId: visit.exceptionId,
        type: Events.Visit,
      };

      return {
        id: visit.id,
        classNames: `event-id-${visit.id}`,
        title: fullName === "" ? t("schedule.open").toString() : fullName,
        start: start.toISO() ?? "",
        end: end.toISO() ?? "",
        color: colors.background,
        borderColor: colors.background,
        textColor: colors.text,
        editable: editable && !loading && now < start && !hasClockIn,
        constraint: {
          daysOfWeek: [transformWeekDay(start.weekday)],
        },
        ...extendedProps,
      };
    },
    [editable, loading, t, getTitle],
  );

  const createAbsenceEvent = useCallback(
    (absence: AbsenceInstance): FullCalendarEvent => {
      const start = DateTime.fromISO(absence?.start ?? "");
      const end = DateTime.fromISO(absence?.end ?? "");
      const color = getAbsenceColor();
      return {
        id: absence.id ?? "",
        classNames: `absence-id-${absence.id}`,
        start: start.toISO() ?? "",
        end: end.toISO() ?? "",
        color: color.background,
        text: color.text,
        editable: false,
        title: t("absences.absent").toString(),
        display: "background",
        type: Events.Absence,
      };
    },
    [t],
  );

  const myEvents = useMemo(() => {
    const transformedEvents = events.reduce<FullCalendarEvent[]>(
      (acc, current) => {
        return match<Event, FullCalendarEvent[]>(current)
          .with({ __typename: Events.Visit }, (visit: VisitInstance) => [
            ...acc,
            createVisitEvent(visit),
          ])
          .with({ __typename: Events.Absence }, (absence: AbsenceInstance) => [
            ...acc,
            createAbsenceEvent(absence),
          ])
          .otherwise(() => acc);
      },
      [],
    );

    const transformedHolidays: FullCalendarEvent[] =
      holidays?.map((holiday) => ({
        id: holiday.id,
        start: holiday.date,
        title: holiday.localName,
        allDay: true,
        backgroundColor: "#e0f2fe",
        borderColor: "#e0f2fe",
        textColor: "#0369a1",
        type: "Holiday",
      })) ?? [];

    return [...transformedEvents, ...transformedHolidays];
  }, [events, holidays, createVisitEvent, createAbsenceEvent]);

  const getAbsence = (start: DateTime, end: DateTime) =>
    events.find((event) => {
      if (event.__typename !== Events.Absence) return false;

      const abs = event as AbsenceInstance;
      const eStart = abs.start && DateTime.fromISO(abs.start);
      const eEnd = abs.end && DateTime.fromISO(abs.end);
      return (
        eStart &&
        eEnd &&
        ((start >= eStart && start <= eEnd) || (end >= eStart && end <= eEnd))
      );
    })?.id;

  const handleDateClick = (e: DateClickArg) => {
    const startDT = DateTime.fromISO(e.dateStr);
    const endDT = startDT.plus({ minutes: 30 });
    const id = getAbsence(startDT, endDT);
    if (id) {
      absenceClick?.(id);
    } else {
      dateClick?.(e);
    }
  };

  const handleEventClick = (e: EventClickArg) => {
    const { type } = e.event.extendedProps;
    if (type === Events.Absence) return absenceClick?.(e.event.id);
    if (type === Events.Visit) return eventClick?.(e);
  };

  const timeFormat = getTimeFormat(meridiem);

  const now = DateTime.now().setZone(timezone);
  const firstDay = startOfWeek(dateTimeToZonedDateTime(now), locale);

  return (
    <div className="rounded bg-white px-3 pt-3 shadow sm:rounded-2xl">
      {topActions && (
        <div className="mb-3 flex justify-between border-b border-gray-200 pb-3">
          <div className="flex items-center">
            <h3 className="mr-3 text-lg font-semibold">{topActions.header}</h3>
            {loading && <LoadingBounce />}
          </div>
          <div className="flex gap-3">
            {topActions.actions?.map((action) => (
              <Button
                key={action.text}
                variant={action.variant || "secondary"}
                size="sm"
                onClick={action.onClick}
                text={action.text}
              />
            ))}
          </div>
        </div>
      )}

      <FullCalendar
        timeZone={timezone}
        eventTimeFormat={timeFormat}
        slotLabelFormat={timeFormat}
        locale={tolgee.getLanguage()}
        events={myEvents}
        editable={editable && !loading}
        selectable
        select={select}
        selectConstraint={{
          startTime: "00:00",
          endTime: "24:00",
        }}
        dayMaxEvents={true}
        datesSet={datesSet}
        eventClick={handleEventClick}
        eventChange={eventChange}
        dateClick={handleDateClick}
        headerToolbar={{
          left: "prev,next,today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        dayHeaderContent={({ date, view }) => {
          const dateDT = DateTime.fromJSDate(date);
          return (
            <>
              <div className="day-col-header-weekday">
                {getShortDayFromDate(dateDT, t)}
              </div>
              {view.type !== "dayGridMonth" && (
                <div className="day-col-header-date">{dateDT.day}</div>
              )}
            </>
          );
        }}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          luxon3plugin,
        ]}
        initialView="timeGridWeek"
        allDayText={""}
        allDaySlot={holidays?.length > 0}
        slotDuration="00:30:00"
        firstDay={firstDay.toDate().getDay()}
        buttonText={{
          today: t("calendar.today").toString(),
          month: t("calendar.month").toString(),
          week: t("calendar.week").toString(),
          day: t("calendar.day").toString(),
        }}
        eventOverlap={false}
        selectOverlap={(event) => event.display !== "background"}
        expandRows={true}
        displayEventTime={false}
        nowIndicator={true}
        scrollTime={now.minus({ minutes: 30 }).toISOTime()}
      />
    </div>
  );
};
