import { Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";

type Props = {
  children: React.ReactNode;
  show: boolean;
};

export const StickyFooter = ({ children, show }: Props) => {
  return (
    <Transition as={Fragment} show={show} appear>
      <TransitionChild
        as={Fragment}
        enter="transition ease-in-out duration-400"
        enterFrom="transform translate-y-full opacity-0"
        enterTo="transform translate-y-0 opacity-100"
        leave="transition ease-in-out duration-400"
        leaveFrom="transform translate-y-0 opacity-100"
        leaveTo="transform translate-y-full opacity-100"
      >
        <div className="fixed bottom-0 right-0 z-5 border-t h-[61px] border-greyscale-200 bg-white w-[calc(100%-257px)] ml-[257px]">
          <div className="flex justify-end gap-2 items-center max-w-[calc(100%-2rem)] mx-auto h-full">
            {children}
          </div>
        </div>
      </TransitionChild>
    </Transition>
  );
};
