import { useState } from "react";
import { FormOutput, LabelForm } from "./labelForm";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLabelDeleteMutation,
  useLabelUpdateMutation,
  useLabelsByOfficeIdsQuery,
} from "../../../../api/generated/graphql";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../../utils/toastUtils";
import { SlideOver } from "@frontend/lyng/slideOver";
import { useCareContext } from "../../../../providers";
import Modal from "@frontend/lyng/modal/Modal";
import { Button } from "@frontend/lyng/button";
import { Paragraph } from "@frontend/lyng/typography";
import { LoadingBounce } from "@frontend/lyng/loading";

export const LabelSlideOver = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const {
    state: { viewer },
  } = useCareContext();

  const handleClose = () => navigate("..");

  const { labelId } = useParams();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);

  const [LabelUpdate] = useLabelUpdateMutation();
  const [LabelDelete] = useLabelDeleteMutation();

  const { data, loading, error } = useLabelsByOfficeIdsQuery({
    variables: {
      officeIds: viewer?.offices.map((office) => office.id) ?? [],
    },
  });

  const handleDelete = (id: string) => {
    setSelectedLabelId(id);
    setShowDeleteModal(true);
  };

  const onConfirm = () => {
    if (!selectedLabelId) return;

    const promise = LabelDelete({
      variables: { labelId: selectedLabelId ?? "" },
      refetchQueries: ["labelsByOfficeIds"],
    }).then(() => {
      handleClose();
      setShowDeleteModal(false);
    });

    toast.promise(promise, {
      loading: t("labels.deleting"),
      success: t("labels.successDelete"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const onCancel = () => {
    setShowDeleteModal(false);
  };

  const handleSubmit = (values: FormOutput) => {
    const promise = LabelUpdate({
      variables: {
        input: {
          labelId: labelId ?? "",
          name: values.name,
        },
      },
      refetchQueries: ["labelsByOfficeIds"],
    });

    return toast.promise(promise, {
      loading: t("labels.updatingLabel"),
      success: () => t("labels.labelUpdated"),
      error: errorToToastMessage,
    });
  };

  const label = data?.labelsByOfficeIds.find((label) => label.id === labelId);

  return (
    <SlideOver.WithIntercomHider show={true} onClose={() => navigate("..")}>
      <SlideOver.Title>{t("labels.editLabel").toString()}</SlideOver.Title>
      {!data && loading && (
        <div className="flex h-full items-center justify-center">
          <LoadingBounce className="text-primary-600" />
        </div>
      )}
      {error && <p>{error.message}</p>}
      {data && (
        <LabelForm
          label={label}
          onSubmit={(values) => handleSubmit(values).then(() => navigate(".."))}
          onDelete={(id) => handleDelete(id)}
        />
      )}
      <Modal show={showDeleteModal} onClose={onCancel}>
        <Modal.Title>{t("labels.deleteLabel")}</Modal.Title>
        <Paragraph size="m">
          {t("labels.deleteDescription", {
            labelName: label?.name,
          })}
        </Paragraph>
        <Modal.Footer>
          <Button
            variant="critical"
            text={t("labels.deleteLabel").toString()}
            className="col-start-2"
            onClick={onConfirm}
          />
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            className="col-start-1"
            onClick={onCancel}
            disabled={loading}
          />
        </Modal.Footer>
      </Modal>
    </SlideOver.WithIntercomHider>
  );
};
