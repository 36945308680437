import classNames from "classnames";
import { useRef } from "react";
import {
  ChannelPreviewUIComponentProps,
  MessageDeliveryStatus,
  StreamMessage,
} from "stream-chat-react";
import { Checkmark } from "../assets/icons/16/outline";
import { Paragraph } from "../typography";

const getLastMessageTime = (lastMessage?: StreamMessage) => {
  if (!lastMessage) return null;

  if (lastMessage.created_at) {
    const date =
      typeof lastMessage.created_at === "string"
        ? new Date(lastMessage.created_at)
        : (lastMessage.created_at as Date);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  return null;
};

type MessageDeliveryStatusIndicator = {
  messageDeliveryStatus: MessageDeliveryStatus;
};

const MessageDeliveryStatusIndicator = ({
  messageDeliveryStatus,
}: MessageDeliveryStatusIndicator) => {
  // the last message is not an own message in the channel
  if (!messageDeliveryStatus) return null;

  return (
    <div>
      {messageDeliveryStatus === MessageDeliveryStatus.DELIVERED && (
        <Checkmark className="text-greyscale-400" />
      )}
      {messageDeliveryStatus === MessageDeliveryStatus.READ && (
        <div className="text-primary-500 flex flex-row">
          <Checkmark />
          <Checkmark className="-ml-5" />
        </div>
      )}
    </div>
  );
};

export const CommsChannelPreview = (props: ChannelPreviewUIComponentProps) => {
  const {
    channel,
    className: customClassName = "",
    displayImage,
    displayTitle,
    latestMessage,
    lastMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    watchers,
    unread,
  } = props;

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const avatarName =
    displayTitle ||
    channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  return (
    <div className="flex flex-col w-full bg-transparent">
      <button
        className={classNames(
          `flex mx-6 h-[84px] py-4 dark:px-5 pt-4 pb-5 cursor-pointer mb-2`,
          {
            "basis-0 bg-white dark:bg-greyscale-800 rounded-2xl shadow-[0_2px_6px_-1px_rgba(0,0,0,0.1)] flex-row hover:shadow-[0_2px_6px_-1px_rgba(0,0,0,0.2)] ":
              unread,
          },
          { "dark:border-greyscale-700 border-b": !unread },
          customClassName,
        )}
        data-testid="channel-preview-button"
        onClick={onSelectChannel}
        ref={channelPreviewButton}
      >
        <div className="px-2 flex flex-grow content-center">
          <Avatar image={displayImage} name={avatarName} />
        </div>
        <div className="flex flex-grow basis-1/2">
          <div className="flex flex-col justify-start items-start w-full">
            <div className="text-greyscale-100">
              <Paragraph
                size="s"
                className="text-greyscale-100 font-poppins truncate"
              >
                {displayTitle}
              </Paragraph>
            </div>
            <div className="text-greyscale-400 text-sm font-inclusive font-normal truncate">
              {latestMessage}
            </div>
          </div>
        </div>
        <div className="text-greyscale-400 flex flex-col items-end justify-end flex-grow basis-1/2 h-full py-1">
          {lastMessage && (
            <div className="flex flex-row flex-end justify-end items-end text-xs px-2 font-inclusive">
              <div className="px-1">
                <MessageDeliveryStatusIndicator
                  messageDeliveryStatus={
                    lastMessage?.unread
                      ? MessageDeliveryStatus.DELIVERED
                      : MessageDeliveryStatus.READ
                  }
                />
              </div>
              {getLastMessageTime(lastMessage)}
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

export const Avatar = ({ image, name }: { image?: string; name?: string }) => {
  if (!image) {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="h-10 w-10 bg-primary-100 dark:bg-primary-800 rounded-full flex items-center justify-center">
          <Paragraph size="s" className="text-greyscale-100">
            {name?.charAt(0).toUpperCase()}
          </Paragraph>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <img
        className="object-scale-down rounded-full h-10 w-10 "
        src={image}
        alt={name}
      />
    </div>
  );
};
