import { FormInput, UserForm } from "./UserForm";
import { useTranslate } from "@tolgee/react";
import { useNavigate, useParams } from "react-router-dom";
import { SlideOver } from "@frontend/lyng/slideOver";
import {
  useUserByIdQuery,
  ResourceType,
  RoleType,
  UserByIdQuery,
} from "../../../api/generated/graphql";
import { match, P } from "ts-pattern";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { roleTypeToCareTeamMemberRoleType } from "./utils";
import { useState } from "react";
import Modal from "@frontend/lyng/modal/Modal";
import { Paragraph } from "@frontend/lyng/typography";
import { Button } from "@frontend/lyng/button";
import { useUserMutationsWithCache } from "./hooks";
import { LoadingBounce } from "@frontend/lyng/loading";

type User = UserByIdQuery["userById"];
export const UserSlideOver = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [modalTitle, setModalTitle] = useState(t("users.addUser"));
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    userCreateMutation,
    createLoading,
    userUpdateMutation,
    updateLoading,
    userRoleReactivate,
    userRoleDeactivate,
    addCaregiverRole,
    addCareRecipientRole,
    addCareTeamMemberRole,
    userDelete,
  } = useUserMutationsWithCache();

  const { data, loading, error } = useUserByIdQuery({
    skip: userId === "new",
    fetchPolicy: "cache-and-network",
    variables: { id: userId ?? "" },
  });

  const user = userId !== "new" ? data?.userById : undefined;

  const handleClose = () => {
    navigate("..", { preventScrollReset: true });
  };

  const onConfirmDelete = () => {
    if (!user) return;

    const promise = userDelete({
      variables: { userId: user.id ?? "" },
      refetchQueries: ["loginUsers"],
    }).then(() => {
      handleClose();
      setShowDeleteModal(false);
    });

    toast.promise(promise, {
      loading: t("users.deleting"),
      success: t("users.successDelete"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleSubmit: (values: FormInput) => Promise<User> = async (values) => {
    if (!user) {
      const createUserPromise = userCreateMutation({
        variables: {
          input: {
            firstName: values.firstName || "",
            lastName: values.lastName || "",
            phone: values.phone || null,
            homePhone: values.homePhone || null,
            birthDate: values.birthDate || null,
            pid: values.pid || null,
            email: values.email || null,
          },
        },
      });

      const createUserResponse = await toast.promise(createUserPromise, {
        loading: t("users.creating"),
        success: () => {
          const { firstName, lastName } = values;
          return t("users.created", { name: `${firstName} ${lastName}` });
        },
        error: (err) => errorToToastMessage(err),
      });

      if (!createUserResponse.data?.userCreate.id) {
        throw new Error("Failed to create user.");
      }

      const userResult: User = createUserResponse.data.userCreate;
      return userResult;
    }

    const updateUserPromise = userUpdateMutation({
      variables: {
        id: user.id,
        input: {
          firstName: values.firstName || "",
          lastName: values.lastName || "",
          phone: values.phone || null,
          homePhone: values.homePhone || null,
          ...(user.email ? {} : { email: values.email || null }),
        },
      },
    }).then(async (updateUserResponse) => {
      if (!updateUserResponse.data?.userUpdate.id) {
        throw new Error("Failed to update user.");
      }

      const userId = updateUserResponse.data.userUpdate.id;

      const rolePromises = [
        ...values.careGiverRoles.map(async (role) => {
          const existingRole = user?.caregiverRoles?.find(
            (r) => r.office.id === role.office.id,
          );

          if (existingRole) {
            if (existingRole.deactivatedAt && role.status) {
              return userRoleReactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.Caregiver,
                  userId: userId,
                },
              });
            } else if (!existingRole.deactivatedAt && !role.status) {
              return userRoleDeactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.Caregiver,
                  userId: userId,
                },
              });
            }
          } else {
            return addCaregiverRole({
              variables: {
                userId: userId,
                input: { officeId: role.office.id },
              },
            });
          }
        }),

        ...values.careRecipientRoles.map(async (role) => {
          const existingRole = user?.careRecipientRoles?.find(
            (r) => r.office.id === role.office.id,
          );

          if (existingRole) {
            if (existingRole.deactivatedAt && role.status) {
              return userRoleReactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.CareRecipient,
                  userId: userId,
                },
              });
            } else if (!existingRole.deactivatedAt && !role.status) {
              return userRoleDeactivate({
                variables: {
                  resourceId: role.office.id,
                  resourceType: ResourceType.Office,
                  roleType: RoleType.CareRecipient,
                  userId: userId,
                },
              });
            }
          } else {
            return addCareRecipientRole({
              variables: {
                userId: userId,
                input: { officeId: role.office.id },
              },
            });
          }
        }),

        ...values.careTeamRoles.map(async (role) => {
          const existingRole = user?.careTeamMemberRoles?.find(
            (r) =>
              r.organizationUnit.id === role.organizationUnit?.id &&
              r.roleType === roleTypeToCareTeamMemberRoleType(role.role.value),
          );

          const careTeamRoleType = roleTypeToCareTeamMemberRoleType(
            role.role.value,
          );

          if (!careTeamRoleType) {
            throw new Error("Invalid Care Team Role Type.");
          }

          if (existingRole) {
            if (existingRole.deactivatedAt && role.status) {
              return userRoleReactivate({
                variables: {
                  resourceId: role.organizationUnit?.id ?? "",
                  resourceType:
                    role.organizationUnit?.type ?? ResourceType.Office,
                  roleType: role.role.value,
                  userId: userId,
                },
              });
            } else if (!existingRole.deactivatedAt && !role.status) {
              return userRoleDeactivate({
                variables: {
                  resourceId: role.organizationUnit?.id ?? "",
                  resourceType:
                    role.organizationUnit?.type ?? ResourceType.Office,
                  roleType: role.role.value,
                  userId: userId,
                },
              });
            }
          } else {
            return addCareTeamMemberRole({
              variables: {
                userId: userId,
                input: {
                  resourceType:
                    role.organizationUnit?.type ?? ResourceType.Office,
                  resourceId: role.organizationUnit?.id ?? "",
                  roleType: careTeamRoleType,
                },
              },
            });
          }
        }),
      ];

      await Promise.all(rolePromises);

      const updateUserResult: User = updateUserResponse.data.userUpdate;
      return updateUserResult;
    });
    return toast.promise(updateUserPromise, {
      loading: t("users.updating"),
      success: t("users.updated", {
        name: `${values.firstName} ${values.lastName}`,
      }),
      error: (err) => errorToToastMessage(err),
    });
  };

  return (
    <SlideOver.WithIntercomHider show={true} onClose={handleClose}>
      <SlideOver.Title
        pill={
          user?.isTenantOwner
            ? { color: "blue", text: t("users.workspaceOwner") }
            : undefined
        }
      >
        {modalTitle}
      </SlideOver.Title>
      {match({ loading, error })
        .with({ loading: true }, () => (
          <div className="flex h-full items-center justify-center">
            <LoadingBounce className="text-primary-600" />
          </div>
        ))
        .with({ error: P.not(P.nullish) }, () => <div>{error?.message}</div>)
        .otherwise(() => (
          <UserForm
            setModalTitle={setModalTitle}
            user={user}
            onDeleteUser={handleShowDeleteModal}
            onSubmit={(values) =>
              handleSubmit(values).then((result) => {
                if (!user) {
                  navigate(`../${result.id}`, { preventScrollReset: true });
                } else {
                  handleClose();
                }
              })
            }
            isUserSubmitting={createLoading || updateLoading}
          />
        ))}
      <Modal show={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <Modal.Title>{t("users.permanentlyDeleteUser")}</Modal.Title>
        <Paragraph className="text-center" size="m">
          {t("users.permanentlyDeleteUserDescription", {})}
        </Paragraph>
        <Modal.Footer>
          <Button
            variant="critical"
            text={t("users.permanentlyDelete").toString()}
            className="col-start-2"
            onClick={onConfirmDelete}
          />
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            className="col-start-1"
            onClick={() => setShowDeleteModal(false)}
            disabled={false}
          />
        </Modal.Footer>
      </Modal>
    </SlideOver.WithIntercomHider>
  );
};
