import { Plus, Settings } from "@frontend/lyng/assets/icons/16/outline";
import { ChevronRight as ChevronRight12 } from "@frontend/lyng/assets/icons/12/outline";
import {
  ChevronRight as ChevronRight24,
  ChevronLeft as ChevronLeft24,
} from "@frontend/lyng/assets/icons/24/outline";
import { useTranslate } from "@tolgee/react";
import { useCallback, useMemo, useState } from "react";
import { useCareContext, UserActions } from "../../../providers";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  useClose,
} from "@headlessui/react";
import classNames from "classnames";
import { Headline, Label } from "@frontend/lyng/typography";
import { ListItems } from "@frontend/lyng/listItems";
import { Button } from "@frontend/lyng/button";
import { AnimatePresence, easeOut, motion } from "framer-motion";
import { Link } from "react-router-dom";

type MenuItem = {
  name: string;
  href: string;
  icon: React.ElementType;
};

const TenantSwitcherPanel = () => {
  const { t } = useTranslate();
  const closePanel = useClose();

  const {
    state: { viewer, selectedTenant },
    dispatch,
  } = useCareContext();

  const [showList, setShowList] = useState(false);

  const menuItems: MenuItem[] = [
    {
      name: t("tenantSwitcher.addMember"),
      href: "/settings/users/new",
      icon: Plus,
    },
    {
      name: t("tenantSwitcher.workspaceSettings"),
      href: "/settings/workspace",
      icon: Settings,
    },
  ];

  const handleChangeTenant = useCallback(
    (tenantId: string) => {
      const tenant = viewer?.tenants?.find((tenant) => tenant.id === tenantId);
      if (!tenant) return;

      localStorage.setItem("tenantId", tenantId);
      dispatch(UserActions.setTenant(tenant));
      window.location.replace("/");
    },
    [dispatch, viewer?.tenants],
  );

  const tenantOptions = useMemo(
    () =>
      viewer?.tenants?.map((tenant) => ({
        value: tenant.id,
        label: tenant.name,
      })) ?? [],
    [viewer?.tenants],
  );
  return (
    <AnimatePresence mode="popLayout" initial={false}>
      {showList ? (
        <motion.div
          key="list"
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%", opacity: 0 }}
          transition={{ duration: 0.2, ease: easeOut }}
          className="w-full divide-y divide-greyscale-200"
        >
          <div className="p-1">
            <Button
              variant="tertiary"
              size="md"
              text={t("tenantSwitcher.organization")}
              icon={ChevronLeft24}
              iconPosition="left"
              onClick={() => setShowList(false)}
            />
          </div>
          <div className="p-1">
            <ListItems
              type="checkmark"
              value={selectedTenant?.id || null}
              items={tenantOptions}
              onChange={(value) => value && handleChangeTenant(value)}
              multi={false}
            />
          </div>
        </motion.div>
      ) : (
        <motion.div
          key="menu"
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-100%", opacity: 0 }}
          transition={{ duration: 0.2, ease: easeOut }}
          className="w-full divide-y divide-greyscale-200"
        >
          <div className="flex flex-row items-center justify-between gap-4 px-2 py-3">
            <div className="pl-3">
              <Headline size="m">{selectedTenant?.name}</Headline>
            </div>
            <Button
              variant="tertiary"
              size="md"
              text={t("tenantSwitcher.changeOrg")}
              icon={ChevronRight24}
              iconPosition="right"
              onClick={() => setShowList(true)}
            />
          </div>
          <div className="flex flex-col gap-1 p-1">
            {menuItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                onClick={closePanel}
                className={classNames(
                  "flex items-center gap-3 max-w-full px-4 py-3.5 rounded-lg bg-white",
                  "hover:bg-primary-50 focus:bg-primary-100 focus:outline-none",
                )}
              >
                <item.icon className="text-greyscale-900" />
                <Label
                  size="xs"
                  className="overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer"
                >
                  {item.name}
                </Label>
              </Link>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const TenantSwitcher = () => {
  const {
    state: { selectedTenant },
  } = useCareContext();

  return (
    <Popover className="relative">
      <PopoverButton
        className={classNames(
          "flex items-center gap-4 max-w-full px-2 py-2.5 rounded bg-primary-0",
          "hover:ring-1 hover:ring-greyscale-200",
          "focus:bg-primary-50 focus:outline-none",
        )}
      >
        <Label
          size="xs"
          className="overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer"
        >
          {selectedTenant?.name}
        </Label>
        <ChevronRight12 className="text-greyscale-900 min-w-3" />
      </PopoverButton>
      <PopoverPanel
        unmount
        anchor={{ to: "bottom start", gap: "6px" }}
        className="w-[364px] !overflow-hidden rounded-xl border border-greyscale-200 shadow-lg bg-white z-50"
      >
        <TenantSwitcherPanel />
      </PopoverPanel>
    </Popover>
  );
};
