import classNames from "classnames";
import { AriaAttributes, useEffect, useRef } from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { Critical } from "../../assets/icons/24/filled";
import { ErrorMessage } from "../errorMessage/ErrorMessage";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  displayView: boolean,
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      // 2 is added to account for the border
      const scrollHeight = textAreaRef.scrollHeight + 2;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      // Minimum value is the equivalent of 3 rows
      textAreaRef.style.height = Math.max(scrollHeight, 107) + "px";
    }
  }, [textAreaRef, value, displayView]);
};

type TextAreaProps<FormValues extends FieldValues> =
  UseControllerProps<FormValues> &
    AriaAttributes & {
      id?: string;
      placeholder?: string;
      displayView?: boolean;
      errorMessage?: string;
      errorMessageIcon?: boolean;
    };

export function TextArea<FormValues extends FieldValues>({
  id,
  name,
  control,
  placeholder,
  disabled,
  displayView = false,
  errorMessage,
  errorMessageIcon,
  ...ariaProps
}: TextAreaProps<FormValues>) {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const { field } = useController({
    name,
    control,
  });
  const { ref, value, ...rest } = field;

  useAutosizeTextArea(textAreaRef.current, value, displayView);

  return (
    <div className="flex flex-col">
      <div className="relative ">
        <textarea
          {...rest}
          ref={(e) => {
            ref(e);
            textAreaRef.current = e;
          }}
          id={id}
          value={value}
          placeholder={placeholder}
          disabled={disabled || displayView}
          rows={1}
          className={classNames(
            "w-full rounded-xl min-h-[107px] border px-4 py-2.5 transition-colors placeholder-greyscale-600 placeholder:font-inclusive text-greyscale-900 text-lg dark:text-greyscale-100 dark:placeholder-greyscale-300",
            {
              "border-greyscale-200 hover:border-greyscale-400 focus:border-primary-600 dark:border-greyscale-700 dark:hover:border-greyscale-500 dark:focus:border-primary-600":
                !errorMessage && !displayView,
              "border-critical-600 pr-10 focus:border-primary-600":
                errorMessage && !displayView,
              "border-transparent bg-transparent placeholder-transparent hover:border-transparent resize-none":
                displayView,
              "bg-greyscale-0 hover:bg-greyscale-0 focus:bg-greyscale-0 dark:bg-greyscale-800 dark:hover:bg-greyscale-900 dark:focus:bg-greyscale-900":
                !displayView,
            },
          )}
          // {...ariaProps}
          // aria-hidden={!editView}
          aria-invalid={errorMessage ? "true" : "false"}
          {...ariaProps}
        />
        {errorMessage && errorMessageIcon && !displayView && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex pt-3 pr-3">
            <Critical className="text-critical-800 dark:text-critical-400" />
          </div>
        )}
      </div>
      {errorMessage && !displayView && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </div>
  );
}
