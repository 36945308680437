import React, { useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import {
  OrganizationQuery,
  RoleType,
  useOrganizationQuery,
} from "../../../api/generated/graphql";
import { Checkbox } from "@frontend/lyng/forms";
import { Button } from "@frontend/lyng/button";
import { ChevronRight } from "@frontend/lyng/assets/icons/16/outline";
import { useFormContext, useWatch } from "react-hook-form";
import { useCareContext } from "../../../providers";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";

type DivisionType = NonNullable<
  OrganizationQuery["organization"]
>["divisions"][number];
type RegionType = NonNullable<DivisionType["regions"]>[number];

export const NestedOrganizationUnitSelector = () => {
  const { data: organizationData } = useOrganizationQuery();

  const [expandedDivisions, setExpandedDivisions] = useState<string[]>([]);
  const [expandedRegions, setExpandedRegions] = useState<{
    [divisionId: string]: string[];
  }>({});

  const {
    state: { viewer },
  } = useCareContext();
  const { collator } = useSortingOptions();

  const { setValue, control } = useFormContext();

  const sortedOrganizationData = organizationData
    ? {
        divisions: organizationData.organization.divisions
          .map((division) => ({
            ...division,
            regions: division.regions
              .map((region) => ({
                ...region,
                offices: region.offices
                  .slice()
                  .sort((a, b) => collator.compare(a.name, b.name)),
              }))
              .sort((a, b) => collator.compare(a.name, b.name)),
          }))
          .sort((a, b) => collator.compare(a.name, b.name)),
      }
    : undefined;

  const organizationUnits = useWatch({
    control,
    name: "organizationUnits",
  });

  const memoizedOrganizationUnits = useMemo(() => {
    return organizationUnits || [];
  }, [organizationUnits]);

  useEffect(() => {
    if (organizationData?.organization.divisions.length) {
      const isRegionChecked = (region: RegionType) => {
        if (memoizedOrganizationUnits.includes(region.id)) {
          return true;
        }
        return region.offices?.some((office) =>
          memoizedOrganizationUnits.includes(office.id),
        );
      };

      const isDivisionChecked = (division: DivisionType) => {
        if (memoizedOrganizationUnits.includes(division.id)) {
          return true;
        }
        return division.regions?.some((region) => isRegionChecked(region));
      };

      const expandedDivisionIds = organizationData.organization.divisions
        .filter((division) => isDivisionChecked(division))
        .map((division) => division.id);
      setExpandedDivisions(expandedDivisionIds);

      const newExpandedRegions: { [divisionId: string]: string[] } = {};
      organizationData.organization.divisions.forEach((division) => {
        const expandedRegionIds = division.regions
          ?.filter((region) => isRegionChecked(region))
          .map((region) => region.id);
        if (expandedRegionIds && expandedRegionIds.length > 0) {
          newExpandedRegions[division.id] = expandedRegionIds;
        }
      });
      setExpandedRegions(newExpandedRegions);
    }
  }, [organizationData, memoizedOrganizationUnits]);

  const toggleExpansion = (
    setExpandedList: React.Dispatch<React.SetStateAction<string[]>>,
    id: string,
  ) => {
    setExpandedList((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id],
    );
  };

  const toggleRegionExpansion = (divisionId: string, regionId: string) => {
    setExpandedRegions((prev) => {
      const divisionRegions = prev[divisionId] || [];
      const updatedRegions = divisionRegions.includes(regionId)
        ? divisionRegions.filter((id) => id !== regionId)
        : [...divisionRegions, regionId];
      return { ...prev, [divisionId]: updatedRegions };
    });
  };

  const isAdmin = (id: string, type: "division" | "region" | "office") => {
    switch (type) {
      case "division":
        return viewer?.tenantAccess.divisions?.some(
          (division) =>
            division.id === id &&
            division.roles.some((role) => role.roleType === RoleType.Admin),
        );
      case "region":
        return viewer?.tenantAccess.regions?.some(
          (region) =>
            region.id === id &&
            region.roles.some((role) => role.roleType === RoleType.Admin),
        );
      case "office":
        return viewer?.tenantAccess.offices?.some(
          (office) =>
            office.id === id &&
            office.roles.some((role) => role.roleType === RoleType.Admin),
        );
      default:
        return false;
    }
  };

  const handleChange = (checked: boolean, ids: string[]) => {
    let updatedUnits = [...organizationUnits];
    if (checked) {
      ids.forEach((id) => {
        if (!updatedUnits.includes(id)) updatedUnits.push(id);
      });
    } else {
      updatedUnits = updatedUnits.filter((id) => !ids.includes(id));
    }

    updatedUnits = updateParentSelection(updatedUnits);
    setValue("organizationUnits", updatedUnits);
  };

  const updateParentSelection = (updatedUnits: string[]) => {
    sortedOrganizationData?.divisions.forEach((division) => {
      let allRegionsSelected = true;
      let hasRegions = false;

      division.regions?.forEach((region) => {
        hasRegions = true;
        let allOfficesSelected = true;
        let hasOffices = false;

        region.offices?.forEach((office) => {
          hasOffices = true;
          if (!updatedUnits.includes(office.id)) {
            allOfficesSelected = false;
          }
        });

        if (hasOffices) {
          if (allOfficesSelected) {
            if (!updatedUnits.includes(region.id)) {
              updatedUnits.push(region.id);
            }
          } else {
            if (updatedUnits.includes(region.id)) {
              updatedUnits = updatedUnits.filter((id) => id !== region.id);
            }
            allRegionsSelected = false;
          }
        } else {
          if (!updatedUnits.includes(region.id)) {
            allRegionsSelected = false;
          }
        }
      });

      if (hasRegions) {
        if (allRegionsSelected) {
          if (!updatedUnits.includes(division.id)) {
            updatedUnits.push(division.id);
          }
        } else {
          if (updatedUnits.includes(division.id)) {
            updatedUnits = updatedUnits.filter((id) => id !== division.id);
          }
        }
      }
    });
    return updatedUnits;
  };

  const getDescendants = (
    division?: DivisionType,
    region?: RegionType,
  ): string[] => {
    const ids: string[] = [];
    if (division) {
      ids.push(division.id);
      division.regions?.forEach((region) => {
        ids.push(region.id);
        region.offices?.forEach((office) => ids.push(office.id));
      });
    } else if (region) {
      ids.push(region.id);
      region.offices?.forEach((office) => ids.push(office.id));
    }
    return ids;
  };

  const isIndeterminate = (ids: string[]) => {
    const selected = ids.filter((id) => organizationUnits.includes(id));
    return selected.length > 0 && selected.length < ids.length;
  };

  const renderUnit = ({
    id,
    label,
    checked,
    indeterminate,
    hasChildren,
    isExpanded,
    onToggle,
    onChange,
  }: {
    id: string;
    label: string;
    checked: boolean;
    indeterminate: boolean;
    hasChildren: boolean;
    isExpanded: boolean;
    onToggle?: () => void;
    onChange: (checked: boolean) => void;
  }) => (
    <div
      className={classNames("flex mx-2 my-1 rounded-lg justify-between", {
        "bg-primary-100": checked || indeterminate,
      })}
    >
      <Checkbox
        name={"organizationUnits"}
        onBlur={(value) => Promise.resolve(onChange(value.target.checked))}
        size="s"
        id={id}
        label={label}
        checked={checked}
        indeterminate={indeterminate}
        onChange={(e) => Promise.resolve(onChange(e.target.checked))}
      />
      {hasChildren && (
        <Button
          variant="tertiary"
          size="sm"
          icon={ChevronRight}
          iconPosition="only"
          onClick={onToggle}
          className={classNames(
            "self-center transform transition-transform duration-200",
            { "rotate-90": isExpanded },
          )}
        />
      )}
    </div>
  );

  return (
    <div className="rounded-2xl shadow-[0_2px_6px_-1px_rgba(0,0,0,0.1)]">
      {sortedOrganizationData?.divisions.map((division) => {
        if (!isAdmin(division.id, "division")) return null;

        const isDivisionExpanded = expandedDivisions.includes(division.id);
        const divisionDescendants = getDescendants(division);

        return (
          <div
            key={division.id}
            className={classNames({
              "border-b border-gray-300": isDivisionExpanded,
            })}
          >
            {renderUnit({
              id: `division-${division.id}`,
              label: division.name,
              checked: organizationUnits.includes(division.id),
              indeterminate: isIndeterminate(divisionDescendants),
              hasChildren: !!division.regions?.length,
              isExpanded: isDivisionExpanded,
              onToggle: () =>
                toggleExpansion(setExpandedDivisions, division.id),
              onChange: (checked) => handleChange(checked, divisionDescendants),
            })}

            <AnimatePresence initial={false}>
              {isDivisionExpanded && (
                <motion.div
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {division.regions?.map((region) => {
                    if (!isAdmin(region.id, "region")) return null;

                    const isRegionExpanded =
                      expandedRegions[division.id]?.includes(region.id) ||
                      false;
                    const regionDescendants = getDescendants(undefined, region);

                    return (
                      <div
                        key={region.id}
                        className={classNames(
                          "ml-12 border-b border-gray-300",
                          {
                            "border-b-0": !isRegionExpanded,
                            "last:border-b-0": isRegionExpanded,
                          },
                        )}
                      >
                        {renderUnit({
                          id: `region-${region.id}`,
                          label: region.name,
                          checked: organizationUnits.includes(region.id),
                          indeterminate: isIndeterminate(regionDescendants),
                          hasChildren: !!region.offices?.length,
                          isExpanded: isRegionExpanded,
                          onToggle: () =>
                            toggleRegionExpansion(division.id, region.id),
                          onChange: (checked) =>
                            handleChange(checked, regionDescendants),
                        })}

                        <AnimatePresence initial={false}>
                          {isRegionExpanded && (
                            <motion.div
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              {region.offices?.map((office) => {
                                if (!isAdmin(office.id, "office")) return null;

                                return (
                                  <div key={office.id} className="ml-12">
                                    {renderUnit({
                                      id: `office-${office.id}`,
                                      label: office.name,
                                      checked: organizationUnits.includes(
                                        office.id,
                                      ),
                                      indeterminate: false,
                                      hasChildren: false,
                                      isExpanded: false,
                                      onChange: (checked) =>
                                        handleChange(checked, [office.id]),
                                    })}
                                  </div>
                                );
                              })}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    );
                  })}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
};
