import {
  Channel as ChannelView,
  Window,
  MessageList,
  MessageInput,
  useChatContext,
  Thread,
  DateSeparatorProps,
  DefaultStreamChatGenerics,
} from "stream-chat-react";

import { useNavigate, useParams } from "react-router";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import { LoadingBounce } from "@frontend/lyng/loading";
import { useDateFormatter } from "@frontend/lyng/utils/dateUtils";
import { useCareContext } from "../../providers";
import { DateTime } from "luxon";
import { ChatList } from "./ChatList";
import { Avatar } from "@frontend/lyng/comms/CommsChannelPreview";
import { Channel, UserResponse } from "stream-chat";
import { Button } from "@frontend/lyng/button";
import { Phone } from "@frontend/lyng/assets/icons/24/filled";
import { VideoCameraIcon } from "@heroicons/react/20/solid";

const Loading = () => (
  <div className="flex justify-center dark:bg-greyscale-900 m-4">
    <LoadingBounce size="m" />
  </div>
);

const CustomDateSeparator = (props: DateSeparatorProps) => {
  const {
    state: { viewer },
  } = useCareContext();
  const { formatDate } = useDateFormatter(viewer?.tenantSettings);

  const dateTime = DateTime.fromJSDate(props.date);

  return (
    <div className="flex justify-center items-center py-2">
      <div className="px-3 py-px rounded-lg justify-start items-center gap-2.5 inline-flex">
        <div className="text-center text-xs font-normal font-inclusive leading-none">
          {formatDate(dateTime)}
        </div>
      </div>
    </div>
  );
};

type ChatHeaderProps = {
  displayImage?: string;
  displayTitle?: string;
  avatarName: string;
  messageStatus: string | null;
  startVideoFn: () => void;
  startAudioFn: () => void;
};

const ChatHeader = ({
  displayImage,
  avatarName,
  displayTitle,
  messageStatus,
  startVideoFn,
  startAudioFn,
}: ChatHeaderProps) => {
  return (
    <div className="h-[52px] px-2 py-1.5 bg-transparent border-b border-greyscale-200 justify-start items-center gap-4 inline-flex w-full">
      <div className="w-10 h-10 rounded-full justify-center items-center flex">
        <Avatar image={displayImage} name={avatarName} />
      </div>
      <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
        <div className="text-greyscale-900 text-sm font-medium font-poppins">
          {displayTitle}
        </div>
        {messageStatus && (
          <div className="self-stretch text-greyscale-600 text-xs font-inclusive">
            {messageStatus}
          </div>
        )}
      </div>
      <Button
        variant="primary"
        size="sm"
        icon={Phone}
        iconPosition="only"
        onClick={startAudioFn}
      />
      <Button
        variant="primary"
        size="sm"
        icon={VideoCameraIcon}
        iconPosition="only"
        onClick={startVideoFn}
      />
    </div>
  );
};

const getChannelDisplayInfo = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  info: "name" | "image",
  channel: Channel<StreamChatGenerics>,
  currentUser?: UserResponse<StreamChatGenerics>,
) => {
  if (channel.data?.[info]) return channel.data[info];
  const members = Object.values(channel.state.members);
  if (members.length !== 2) return;
  const otherMember = members.find(
    (member) => member.user?.id !== currentUser?.id,
  );
  return otherMember?.user?.[info];
};

export const Chat = () => {
  const { channelId } = useParams();
  const { client } = useChatContext();
  const navigate = useNavigate();
  const ff_messaging = useFeatureFlag("Messaging");

  if (!ff_messaging) {
    navigate("/");
    return <Loading />;
  }

  if (!channelId || !client.wsConnection) {
    return <Loading />;
  }

  const channel = client.channel("messaging", channelId);
  const displayImage = getChannelDisplayInfo("image", channel, client.user);
  const displayTitle = getChannelDisplayInfo("name", channel, client.user);
  const lastMessageTime = DateTime.fromISO(
    channel.data?.last_message_at as string,
  ).toRelative();
  //Find the user that does not match the viewer
  const startVideoCallFn = (audioOnly: boolean) => {
    console.log("Start video call", audioOnly);
  };

  return (
    <div className="h-full flex flex-col w-full">
      <div className="h-screen flex flex-row w-full">
        <div className="border-r border-primary-300 overflow-y-auto">
          <ChatList modal={false} />
        </div>
        <div className="flex-grow">
          <div className="flex flex-grow w-full sticky top-0">
            <ChatHeader
              displayImage={displayImage}
              avatarName={"A"}
              displayTitle={displayTitle}
              messageStatus={lastMessageTime}
              startVideoFn={() => startVideoCallFn(false)}
              startAudioFn={() => startVideoCallFn(true)}
            />
          </div>
          <ChannelView channel={channel} DateSeparator={CustomDateSeparator}>
            <Window>
              <div className="h-screen">
                <MessageList />
              </div>
              <div className="sticky bottom-0 bg-transparent">
                <MessageInput />
              </div>
              <Thread />
            </Window>
          </ChannelView>
        </div>
      </div>
    </div>
  );
};
