import {
  ActivitySchedule,
  ActivityStatus,
  Priority as PriorityType,
  useActivityScheduleMutation,
  useActivityUnscheduleMutation,
} from "../../../api/generated/graphql";
import { Activity } from "../../../types/index";
import { useTranslate, UseTranslateResult } from "@tolgee/react";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { useState } from "react";
import { SimpleModal } from "..";
import { Button } from "@frontend/lyng/button/Button";
import { CardList } from "@frontend/lyng/cardList/CardList";
import { Label, Paragraph } from "@frontend/lyng/typography";
import {
  Checkmark,
  Close,
  Edit,
  Plus,
} from "@frontend/lyng/assets/icons/16/outline";
import { useFeatureFlag } from "../../../providers/FeatureFlags";
import classNames from "classnames";
import { useDateFormatter } from "../../../utils/dateUtils";
import { DateTime } from "luxon";
import { getDayMonthYear } from "@frontend/lyng/utils/dateUtils";
import { match, P } from "ts-pattern";
import { PriorityIcon } from "@frontend/lyng/common/Priority";
import { useCareContext } from "../../../providers";
import { SmallPill } from "@frontend/lyng/pill/smallPill";

type Props = {
  activities: Activity[];
  visitDefinitionId?: string | null;
  loading: boolean;
  insideSlideOver?: boolean;
  onClickFunctions?: {
    editActivity?: (activity: Activity) => void;
    addActivity?: () => void;
    addActivityTemplate?: () => void;
  };
  unScheduledActivities?: Activity[];
  isVisitLog?: boolean;
  isVisitRecurring?: boolean;
};

export const ActivitiesTable = ({
  activities,
  loading,
  onClickFunctions,
  insideSlideOver = false,
  visitDefinitionId,
  isVisitLog = false,
  unScheduledActivities,
  isVisitRecurring = false,
}: Props) => {
  const { t } = useTranslate();
  const { formatTime, formatDate } = useDateFormatter();
  const ff_activities = useFeatureFlag("Activities");

  const sortActivitiesByStatus = (activities: Activity[]) => {
    const statusOrder: Record<ActivityStatus | "", number> = {
      [ActivityStatus.CouldNotComplete]: 0,
      [ActivityStatus.Completed]: 1,
      [ActivityStatus.NotStarted]: 2,
      "": 3,
    };
    return [...activities].sort((a, b) => {
      const indexA = statusOrder[a.status ?? ""];
      const indexB = statusOrder[b.status ?? ""];
      return indexA - indexB;
    });
  };

  const getTimeOfDayStart = (time?: string | null) => {
    if (!time) return "";
    return formatTime(DateTime.fromFormat(time, "HH:mm"));
  };

  const [activityUnscheduleMutation, { loading: activityUnscheduleLoading }] =
    useActivityUnscheduleMutation({
      refetchQueries: ["ActivitiesByCareRecipientId", "VisitById"],
    });

  const [activityScheduleMutation] = useActivityScheduleMutation({
    refetchQueries: ["ActivitiesByCareRecipientId", "VisitById"],
  });

  const {
    state: { viewer },
  } = useCareContext();

  const [confirmUnschedule, setConfirmUnschedule] = useState(false);
  const [loadingId, setLoadingId] = useState<string | null>(null);

  const handleUnschedule = (activity: Activity) => {
    if (!visitDefinitionId) {
      return;
    }

    const promise = activityUnscheduleMutation({
      variables: {
        visitDefinitionId: visitDefinitionId,
        activityId: activity.id,
      },
    }).then(() => {
      setConfirmUnschedule(false);
    });

    return toast.promise(promise, {
      loading: t("activities.removingActivitiesFromVisit"),
      success: t("activities.successRemoveActivityFromVisit"),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleSchedule = (activityId: string) => {
    if (!visitDefinitionId) {
      return;
    }
    setLoadingId(activityId);
    const promise = activityScheduleMutation({
      variables: {
        input: {
          visitDefinitionId: visitDefinitionId,
          activityId: activityId,
        },
      },
    });
    return toast
      .promise(promise, {
        loading: t("activities.assigningActivitiesToVisit"),
        success: t("activities.successAssignActivityToVisit"),
        error: (err) => errorToToastMessage(err),
      })
      .finally(() => {
        setLoadingId(null);
      });
  };

  const getDateOrTime = (
    time: string | null,
    schedule: ActivitySchedule | null,
  ) => {
    const dateTimeString = match({ time, schedule })
      .with({ time: null, schedule: null }, () => null)
      .with({ schedule: { __typename: undefined } }, () => null)
      .with({ time: P.not(P.nullish), schedule: null }, () =>
        getTimeOfDayStart(time),
      )
      .with(
        { time: null, schedule: { __typename: "ActivityScheduleOneOff" } },
        (e) =>
          getDayMonthYear(
            DateTime.fromISO(e.schedule.date),
            t,
            viewer?.tenantSettings,
          ),
      )
      .with(
        { time: null, schedule: { __typename: "ActivityScheduleEveryNDays" } },
        (e) =>
          t("activityForm.everyXDays", {
            days: e.schedule.days,
          }),
      )
      .with(
        { time: null, schedule: { __typename: "ActivityScheduleEveryVisit" } },
        () => t("activityForm.everyVisit"),
      )
      .with(
        {
          time: P.not(P.nullish),
          schedule: { __typename: "ActivityScheduleOneOff" },
        },
        (e) =>
          t("activityForm.oneOffWithTime", {
            date: getDayMonthYear(
              DateTime.fromISO(e.schedule.date),
              t,
              viewer?.tenantSettings,
            ),
            time: getTimeOfDayStart(time),
          }),
      )
      .with(
        {
          time: P.not(P.nullish),
          schedule: { __typename: "ActivityScheduleEveryNDays" },
        },
        (e) =>
          t("activityForm.everyXDaysWithTime", {
            everyXDays: t("activityForm.everyXDays", {
              days: e.schedule.days,
            }),
            time: time,
          }),
      )
      .with(
        {
          time: P.not(P.nullish),
          schedule: { __typename: "ActivityScheduleEveryVisit" },
        },
        () =>
          t("activityForm.everydayWithTime", { time: getTimeOfDayStart(time) }),
      )
      .with(
        {
          time: null,
          schedule: { __typename: "ActivityScheduleEveryNDays", days: 1 },
        },
        () => t("everyday"),
      )
      .exhaustive();

    if (!dateTimeString) return null;

    return (
      <Paragraph size="xxs" type="secondary">
        {dateTimeString}
      </Paragraph>
    );
  };

  const getPriority = (priorityLevel: PriorityType | null) => {
    if (priorityLevel && priorityLevel !== "OPTIONAL") {
      return (
        <div className="p-4">
          <PriorityIcon priority={priorityLevel} className="justify-end" />
        </div>
      );
    } else return undefined;
  };

  const getLastCompletedAt = (
    lastCompletedAt: string,
    t: UseTranslateResult["t"],
  ) => {
    const lastCompletedAtTime = DateTime.fromISO(lastCompletedAt);
    const today = DateTime.now().startOf("day");
    const isCompletedToday = lastCompletedAtTime.hasSame(today, "day");
    const isCompletedYesterday = lastCompletedAtTime.hasSame(
      today.minus({ days: 1 }),
      "day",
    );

    if (isCompletedToday) {
      return t("activitiesTable.lastCompletedAtToday", {
        time: getTimeOfDayStart(lastCompletedAtTime.toFormat("HH:mm")),
      });
    } else if (isCompletedYesterday) {
      return t("activitiesTable.lastCompletedAtYesterday");
    } else {
      return t("activitiesTable.lastCompletedAtDate", {
        date: formatDate(lastCompletedAtTime),
      });
    }
  };

  return (
    <CardList
      className={classNames(
        "rounded-2xl",
        insideSlideOver && "shadow-[0_2px_6px_rgba(0,0,0,0.1)]",
      )}
    >
      {!insideSlideOver && (
        <CardList.Title className={classNames("flex justify-between p-2 ")}>
          <Label className="pl-4">{t("activitiesTable.activities")}</Label>
          <div>
            <Button
              text={t("careRecipientDetails.addActivity")}
              size="sm"
              variant="tertiary"
              iconPosition="left"
              icon={Plus}
              onClick={onClickFunctions?.addActivity}
            />

            {ff_activities && (
              <Button
                text={t("activitiesTable.addFromTemplate")}
                size="sm"
                variant="tertiary"
                iconPosition="left"
                icon={Plus}
                onClick={onClickFunctions?.addActivityTemplate}
              />
            )}
          </div>
        </CardList.Title>
      )}
      {match({ loading, hasActivities: Boolean(activities.length) })
        .with({ loading: true }, () => (
          <CardList.Container>
            {[...Array(5)].map((_, i) => (
              <CardList.Rows key={i}>
                <Skeleton
                  containerTestId="skeleton-loader"
                  containerClassName="flex-1 p-5"
                />
              </CardList.Rows>
            ))}
          </CardList.Container>
        ))
        .with({ hasActivities: true }, () => (
          <CardList.Container>
            <>
              {sortActivitiesByStatus(activities).map((activity) => (
                <CardList.Rows className="flex flex-col py-2" key={activity.id}>
                  <div className="items-center flex justify-between flex-row">
                    <div
                      className={classNames(
                        "flex items-center",
                        !isVisitLog ? "pl-3" : undefined,
                      )}
                    >
                      {isVisitLog &&
                        activity.status !== ActivityStatus.NotStarted && (
                          <SmallPill
                            className="mx-3"
                            icon={
                              activity.status === ActivityStatus.Completed ? (
                                <Checkmark />
                              ) : (
                                <Close />
                              )
                            }
                            color={
                              activity.status === ActivityStatus.Completed
                                ? "green"
                                : "red"
                            }
                          />
                        )}
                      <Label size="xs" className="p-3.5 pl-1">
                        {activity.title}
                      </Label>
                    </div>
                    <div className="flex items-center">
                      {getDateOrTime(
                        activity.timeOfDayStart,
                        activity.schedule,
                      )}
                      {getPriority(activity.priority)}
                      {activity && !insideSlideOver ? (
                        <Button
                          icon={Edit}
                          className="p-2 m-1"
                          variant="tertiary"
                          iconPosition="only"
                          ariaLabel="edit-activity"
                          size="sm"
                          onClick={() =>
                            onClickFunctions?.editActivity &&
                            onClickFunctions?.editActivity(activity)
                          }
                        />
                      ) : activity &&
                        insideSlideOver &&
                        !activity.schedule &&
                        !isVisitLog ? (
                        <Button
                          iconPosition="only"
                          icon={Close}
                          className="p-1"
                          size="sm"
                          aria-label="Unschedule Activity"
                          onClick={() =>
                            isVisitRecurring
                              ? setConfirmUnschedule(true) // skip the modal
                              : handleUnschedule(activity)
                          }
                          variant="tertiary"
                          loading={activityUnscheduleLoading}
                        />
                      ) : undefined}
                    </div>
                    <SimpleModal
                      show={confirmUnschedule}
                      title={t(
                        "activities.confirmUnscheduleActivityFromVisitHeader",
                      )}
                      onAccept={() => handleUnschedule(activity)}
                      onClose={() => setConfirmUnschedule(false)}
                    >
                      {t("activities.confirmUnscheduleActivityFromVisit")}
                    </SimpleModal>
                  </div>
                  {isVisitLog &&
                  activity.status === ActivityStatus.CouldNotComplete &&
                  activity.comment ? (
                    <Paragraph size="xs" type="secondary" className="px-[12px]">
                      {activity.comment}
                    </Paragraph>
                  ) : undefined}
                </CardList.Rows>
              ))}
            </>
          </CardList.Container>
        ))
        .otherwise(() => null)}

      {unScheduledActivities?.length && insideSlideOver ? (
        <CardList.CollapsibleContainter
          title={t("Not in visit")}
          className=" bg-greyscale-50 rounded-b-2xl"
        >
          {unScheduledActivities.map((unscheduledActivity) => (
            <CardList.Rows
              key={unscheduledActivity.id}
              className="py-2 px-3 items-center justify-between "
            >
              <Label size="xs" className="p-3.5 pl-1">
                {unscheduledActivity.title}
              </Label>
              <div className="flex items-center">
                <div className="flex items-center pr-[12px] pl-2">
                  {unscheduledActivity.lastCompletedAt ? (
                    <Paragraph size="xxs" className="italic" type="secondary">
                      {getLastCompletedAt(
                        unscheduledActivity.lastCompletedAt,
                        t,
                      )}
                    </Paragraph>
                  ) : undefined}
                  {unscheduledActivity.lastCompletedAt &&
                  (unscheduledActivity.timeOfDayStart ||
                    unscheduledActivity.schedule) ? (
                    <Paragraph className="p-2" type="secondary">
                      •
                    </Paragraph>
                  ) : undefined}
                  {getDateOrTime(
                    unscheduledActivity.timeOfDayStart,
                    unscheduledActivity.schedule,
                  )}
                </div>
                {getPriority(unscheduledActivity.priority)}

                <Button
                  key={unscheduledActivity.id}
                  iconPosition="left"
                  icon={Plus}
                  text="Add"
                  variant="tertiary"
                  onClick={() => handleSchedule(unscheduledActivity.id)}
                  loading={loadingId === unscheduledActivity.id}
                />
              </div>
            </CardList.Rows>
          ))}
        </CardList.CollapsibleContainter>
      ) : undefined}
    </CardList>
  );
};
