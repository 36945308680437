import { useTranslate } from "@tolgee/react";
import { useCareContext } from "../../../providers";
import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { signOut } from "aws-amplify/auth";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { DalaLeaves } from "@frontend/lyng/assets/svg";
import { Label } from "@frontend/lyng/typography";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";

const arrowAnimation = {
  initial: { opacity: 0, scale: 0.95, transform: "translate(-50%, -50%)" },
  animate: { opacity: 1, scale: 1, transform: "translate(-50%, -50%)" },
  exit: { opacity: 0, scale: 0.95, transform: "translate(-50%, -50%)" },
};

const ProfileMenuArrow = () => {
  return (
    <motion.svg
      {...arrowAnimation}
      className="absolute top-[2px] left-6 transform -translate-x-1/2 -translate-y-1/2 z-[999]"
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.73103 5.5L11 13.3121L19.269 5.5H2.73103Z"
        fill="white"
        stroke="#E2E2E4"
      />
      <path
        d="M11.1373 12.2455L11 12.3752L10.8627 12.2455L1.33637 3.24548L0.970796 2.9001H1.47372H20.5263H21.0292L20.6636 3.24548L11.1373 12.2455Z"
        fill="white"
        stroke="white"
        stroke-width="0.4"
      />
    </motion.svg>
  );
};

export const ProfileMenu = () => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();

  const client = useApolloClient();

  const logOut = useCallback(async () => {
    localStorage.removeItem("tenantId");
    await signOut();
    await client.clearStore();
  }, [client]);

  const itemClassName =
    "block w-full px-4 py-3.5 data-[focus]:bg-primary-50 text-left rounded";

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton
            className={classNames("relative w-full pt-1 pb-3 px-1 ", {
              "bg-white": !open,
              "bg-primary-0": open,
            })}
          >
            <div className="flex items-center gap-3 px-3 py-3.5">
              <DalaLeaves />
              <Label
                size="xs"
                className="overflow-ellipsis text-nowrap overflow-hidden"
              >
                {`${viewer?.firstName} ${viewer?.lastName}`}
              </Label>
            </div>
            <AnimatePresence>{open && <ProfileMenuArrow />}</AnimatePresence>
          </MenuButton>
          <AnimatePresence>
            {open && (
              <MenuItems
                static
                as={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                anchor={{ to: "top start", gap: "1px" }}
                className="w-[var(--button-width)] flex flex-col items-start gap-1 p-1 bg-white border-t  border-greyscale-200 z-50"
              >
                <MenuItem>
                  <a
                    className={itemClassName}
                    href="https://changelog.dala.care"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Label size="xs" className="cursor-pointer">
                      {t("profileMenu.changelog")}
                    </Label>
                  </a>
                </MenuItem>
                <MenuItem>
                  <button className={itemClassName} onClick={logOut}>
                    <Label size="xs" className="cursor-pointer">
                      {t("profileMenu.signOut")}
                    </Label>
                  </button>
                </MenuItem>
              </MenuItems>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
};
