import { useTranslate } from "@tolgee/react";
import { useCallback, useMemo } from "react";
import { Select } from "@frontend/lyng/forms/select/Select";
import { ScheduleSchema } from "./types";
import { match, P } from "ts-pattern";

type Selections = "" | "EVERY_N_DAYS" | "EVERY_VISIT" | "ONE_OFF";
type Option = { id: Selections; label: string };

type Props = {
  name: string;
  value: ScheduleSchema | null;
  onChange: (value: ScheduleSchema | null) => void;
  onBlur?: () => void;
};

const options: Record<Selections, Option> = {
  "": { id: "", label: "activityForm.none" },
  EVERY_VISIT: { id: "EVERY_VISIT", label: "activityForm.everyVisit" },
  EVERY_N_DAYS: { id: "EVERY_N_DAYS", label: "activityForm.everyNDays" },
  ONE_OFF: { id: "ONE_OFF", label: "activityForm.oneOff" },
};

export const ScheduleSelect = ({ name, value, onChange, onBlur }: Props) => {
  const { t } = useTranslate();

  const optionsList = useMemo(() => Object.values(options), []);

  const selected = match(value)
    .with(P.nullish, () => options[""])
    .with({ oneOff: P.not(P.nullish) }, () => options["ONE_OFF"])
    .with({ everyVisit: P.not(P.nullish) }, () => options["EVERY_VISIT"])
    .with({ everyNDays: P.not(P.nullish) }, () => options["EVERY_N_DAYS"])
    .otherwise(() => options[""]);

  const handleSelectOption = useCallback(
    (val: Option | null) => {
      const newValue = match<Selections | null, ScheduleSchema | null>(
        val?.id ?? null,
      )
        .with("", () => undefined)
        .with(null, () => undefined)
        .with("ONE_OFF", () => ({
          oneOff: {
            date: value?.oneOff?.date ?? null,
          },
        }))
        .with("EVERY_N_DAYS", () => ({
          everyNDays: {
            days: value?.everyNDays?.days ?? null,
          },
        }))
        .with("EVERY_VISIT", () => ({
          everyVisit: {
            everyDay: true,
          },
        }))
        .exhaustive();

      onChange(newValue);
    },
    [onChange, value],
  );

  return (
    <Select
      name={name}
      value={selected}
      onChange={(e) => handleSelectOption(e)}
      onBlur={onBlur}
      options={optionsList}
      isMulti={false}
      getOptionLabel={(option) => t(option.label)}
      getOptionValue={(option) => option.id}
    />
  );
};
