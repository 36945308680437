import { useTranslate } from "@tolgee/react";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useHits, useInstantSearch, useSortBy } from "react-instantsearch";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { match } from "ts-pattern";
import {
  NameOrder,
  useUserInvitationResendMutation,
} from "../../../api/generated/graphql";
import { Link, Paragraph } from "@frontend/lyng/typography";
import { Table } from "../table/table";
import { useDateFormatter } from "../../../utils/dateUtils";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { Button } from "@frontend/lyng/button";
import { Pagination } from "./search";
import { useLocation } from "react-router-dom";
import { Empty } from "@frontend/lyng/assets/svg";

type CareTeamMemberRole = {
  role_type: string;
  resource_id: string;
  resource_name: string;
  resource_type: string;
  deactivated_at: string | null;
};
type CaregiverRole = {
  office_id: string;
  office_name: string;
  deactivated_at: string | null;
};
type CareRecipientRole = {
  office_id: string;
  office_name: string;
  deactivated_at: string | null;
};
type ContactRole = {
  care_recipient_id: string;
  care_recipient_first_name: string;
  care_recipient_last_name: string;
  deactivated_at: string | null;
};

type Hit = {
  id: string;
  pid: string;
  first_name: string;
  last_name: string;
  phone?: string;
  email?: string;
  address?:
    | {
        address_line1: string;
        city: string;
        zip_code: string;
      }
    | undefined;
  last_login?: string;
  caregiver_roles?: CaregiverRole[];
  care_recipient_roles?: CareRecipientRole[];
  care_team_member_roles?: CareTeamMemberRole[];
  contact_roles?: ContactRole[];
  is_tenant_owner?: boolean;
  role_types?: string[];
};

const sortOptions = {
  firstLastAsc: "users/sort/first_name_sortable:asc,last_name_sortable:asc",
  firstLastDesc: "users/sort/first_name_sortable:desc,last_name_sortable:desc",
  lastFirstAsc: "users/sort/last_name_sortable:asc,first_name_sortable:asc",
  lastFirstDesc: "users/sort/last_name_sortable:desc,first_name_sortable:desc",
};

const renderOffice = (role: {
  office_id: string;
  office_name: string;
  deactivated_at: string | null;
}) => {
  return (
    <div className="flex items-center gap-2" key={role.office_id}>
      <div
        className={`h-2.5 w-2.5 m-2.5 rounded-full ${
          !role.deactivated_at ? "bg-secondary-400" : "bg-greyscale-200"
        }`}
      />
      <Paragraph size="s" type={!role.deactivated_at ? "primary" : "secondary"}>
        {role.office_name}
      </Paragraph>
    </div>
  );
};

type Columns =
  | "name"
  | "pid"
  | "phone"
  | "email"
  | "roles"
  | "address"
  | "lastLogin"
  | "careRecipientOffices"
  | "caregiverOffices"
  | "edit";

const defaultColumnVisibility: Record<Columns, boolean> = {
  name: false,
  phone: false,
  pid: false,
  email: false,
  roles: false,
  address: false,
  lastLogin: false,
  careRecipientOffices: false,
  caregiverOffices: false,
  edit: false,
};

type Props = {
  columnVisibility: Partial<Record<Columns, boolean>>;
  onEditClick?: (id: string) => void;
  emptyMessage?: string;
};

export function UsersSearchTable({
  columnVisibility,
  onEditClick,
  emptyMessage,
}: Props) {
  const { t } = useTranslate();
  const location = useLocation();
  const { nameOrderFn, nameOrder } = useSortingOptions();
  const { formatRelativeTime } = useDateFormatter();

  const [userInvitationMutation] = useUserInvitationResendMutation();

  const { items } = useHits<Hit>();
  const { status, refresh } = useInstantSearch();

  const [tableSorting, setTableSorting] = useState<SortingState>([
    { id: "name", desc: false },
  ]);
  const { refine } = useSortBy({
    items: [
      {
        label: "",
        value: "users",
      },
      {
        label: "name asc",
        value: "users/sort/first_name_sortable:asc,last_name_sortable:asc",
      },
      {
        label: "name desc",
        value: "users/sort/first_name_sortable:desc,last_name_sortable:desc",
      },
    ],
  });

  // Refresh page when modal closes
  useEffect(() => {
    const timeout = setTimeout(() => {
      refresh();
    }, 100);
    return () => clearInterval(timeout);
  }, [location, refresh]);

  useEffect(() => {
    if (tableSorting.length === 0) {
      return;
    }
    const [sort] = tableSorting;

    const refineOpt = match({ id: sort.id, desc: sort.desc, nameOrder })
      .with(
        { id: "name", desc: true, nameOrder: NameOrder.FirstLast },
        () => sortOptions.firstLastDesc,
      )
      .with(
        { id: "name", desc: false, nameOrder: NameOrder.FirstLast },
        () => sortOptions.firstLastAsc,
      )
      .with(
        { id: "name", desc: true, nameOrder: NameOrder.LastFirst },
        () => sortOptions.lastFirstDesc,
      )
      .with(
        { id: "name", desc: false, nameOrder: NameOrder.LastFirst },
        () => sortOptions.lastFirstAsc,
      )
      .otherwise(() => sortOptions.firstLastAsc);

    refine(refineOpt);
  }, [refine, nameOrder, tableSorting]);

  const columns = useMemo<ColumnDef<Hit>[]>(() => {
    return [
      {
        id: "name",
        header: t("caregivers.name") ?? "",
        accessorFn: (row) =>
          nameOrderFn({ firstName: row.first_name, lastName: row.last_name }),
        cell: (row) => (
          <Link size="xs" to={row.row.original.id}>
            {row.getValue<string>()}
          </Link>
        ),
      },
      {
        id: "email",
        header: t("email") ?? "",
        accessorFn: (row) => row.email,
        cell: (row) => (
          <Link size="xs" to={`tel:${row.getValue<string>()}`}>
            {row.getValue<string>()}
          </Link>
        ),
      },
      {
        id: "pid",
        header: t("careRecipients.ssn"),
        accessorFn: (row) => row.pid,
      },
      {
        id: "address",
        header: t("careRecipients.address"),
        accessorFn: (row) => row.address,
        cell: (row) =>
          [
            row.row.original.address?.address_line1,
            row.row.original.address?.city,
            row.row.original.address?.zip_code,
          ]
            .filter((x) => !!x)
            .join(", "),
      },
      {
        id: "roles",
        header: t("rolesPlural") ?? "",
        enableSorting: false,
        cell: (row) => {
          const roles = [
            ...(row.row.original.care_recipient_roles?.map((role) => ({
              ...role,
              role_type: t("roleType.CARE_RECIPIENT"),
              resource_type: null,
              resource_name: role.office_name,
            })) ?? []),
            ...(row.row.original.caregiver_roles?.map((role) => ({
              ...role,
              role_type: t("roleType.CAREGIVER"),
              resource_type: null,
              resource_name: role.office_name,
            })) ?? []),
            ...(row.row.original.care_team_member_roles?.map((role) => ({
              ...role,
              role_type: t(`roleType.${role.role_type}`),
              resource_type: t(`resourceType.${role.resource_type}`),
              resource_name: role.resource_name,
            })) ?? []),
            ...(row.row.original.contact_roles?.map((role) => ({
              ...role,
              role_type: t("roleType.CONTACT"),
              resource_type: null,
              resource_name: `${role.care_recipient_first_name} ${role.care_recipient_last_name}`,
            })) ?? []),
          ];

          const deactivatedCount = roles.filter(
            (role) => role.deactivated_at,
          ).length;

          return (
            <ul>
              {row.row.original.is_tenant_owner && (
                <li>
                  <Paragraph size="s" className="flex">
                    <span className="h-2 w-2 rounded-full bg-primary-300 mt-2 mr-3" />
                    {t("users.workspaceOwner")}
                  </Paragraph>
                </li>
              )}
              {roles
                .filter((role) => !role.deactivated_at)
                .map((role, index) => (
                  <li key={index}>
                    <Paragraph size="s" className="flex">
                      <span className="h-2 w-2 rounded-full bg-secondary-500 mt-2 mr-3" />
                      {`${role.role_type} • ${role.resource_name}`}
                      {role.resource_type && ` • ${role.resource_type}`}
                    </Paragraph>
                  </li>
                ))}

              {deactivatedCount > 0 && (
                <Paragraph size="s" className="flex">
                  <span className="h-2 w-2 rounded-full bg-greyscale-200 self-center mr-3" />
                  {`${deactivatedCount} ${t("users.inactiveRoles")}`}
                </Paragraph>
              )}
            </ul>
          );
        },
      },
      {
        id: "phone",
        header: t("phone") ?? "",
        accessorFn: (row) => row.phone,
        cell: (row) => (
          <Link size="xs" to={`tel:${row.getValue<string>()}`}>
            {row.getValue<string>()}
          </Link>
        ),
      },
      {
        id: "lastLogin",
        header: t("users.lastLogin") ?? "",
        cell: (row) => {
          if (row.row.original.last_login) {
            return (
              <Paragraph size="s">
                {formatRelativeTime(row.row.original.last_login)}
              </Paragraph>
            );
          }
          const hasActiveCaregiverRole = row.row.original.caregiver_roles?.some(
            (role) => !role.deactivated_at,
          );
          const hasActiveCareRecipientRole =
            row.row.original.care_recipient_roles?.some(
              (role) => !role.deactivated_at,
            );
          const hasActiveContactRole = row.row.original.contact_roles?.some(
            (role) => !role.deactivated_at,
          );
          const hasActiveCareTeamMemberRole =
            row.row.original.care_team_member_roles?.some(
              (role) => !role.deactivated_at,
            );

          if (
            !hasActiveCaregiverRole &&
            !hasActiveCareRecipientRole &&
            !hasActiveContactRole &&
            !hasActiveCareTeamMemberRole
          ) {
            return null;
          }
          return (
            <Button
              className="-ml-6"
              type="button"
              size="md"
              variant="tertiary"
              text={t("users.resendInvite") ?? ""}
              onClick={() => {
                const toastSuccess = (email: string) => {
                  return t("users.inviteSent", { email });
                };

                const promise = userInvitationMutation({
                  variables: {
                    userId: row.row.original.id,
                  },
                });

                toast.promise(promise, {
                  loading: t("users.sendingInvite"),
                  success: () => toastSuccess(row.row.original.email ?? ""),
                  error: (err) => errorToToastMessage(err),
                });
              }}
            />
          );
        },
      },
      {
        id: "edit",
        header: "",
        accessorFn: (row) => row.id,
        enableSorting: false,
        cell: (row) => (
          <div className="flex justify-end">
            <Button
              className="ml-auto"
              type="button"
              variant="tertiary"
              text={
                row.row.original.is_tenant_owner ? t("view") ?? "" : t("edit")
              }
              onClick={() => onEditClick?.(row.row.original.id)}
            />
          </div>
        ),
      },
      {
        id: "caregiverOffices",
        header: t("users.office") ?? "",
        cell: (row) => row.row.original?.caregiver_roles?.map(renderOffice),
      },
      {
        id: "careRecipientOffices",
        header: t("users.office") ?? "",
        cell: (row) =>
          row.row.original?.care_recipient_roles?.map(renderOffice),
      },
    ];
  }, [t, nameOrderFn, onEditClick, formatRelativeTime, userInvitationMutation]);

  if (status === "error") {
    return <Paragraph size="s">{t("users.errorLoadingUsers")}</Paragraph>;
  }

  if (items.length === 0 && status === "idle") {
    return (
      <div className="flex flex-col gap-6 items-center h-full pt-14 md:pt-60">
        <Empty.C1 />
        <Paragraph size="m">{emptyMessage}</Paragraph>
      </div>
    );
  }

  return (
    <>
      <Table
        columns={columns}
        data={items}
        columnVisibility={Object.assign(
          {},
          defaultColumnVisibility,
          columnVisibility,
        )}
        manualSorting={{
          setSorting: setTableSorting,
          state: tableSorting,
        }}
        loading={
          (status === "loading" || status === "stalled") && items.length === 0
        }
      />
      <Pagination />
    </>
  );
}
