import { DateTime } from "luxon";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import {
  useActivityCreateMutation,
  useActivityDeleteMutation,
  useActivityUpdateMutation,
} from "../../../api/generated/graphql";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { PartialBy } from "../../../utils/typeUtils";
import { ConfirmDeleteModal } from "..";
import { ActivityForm, ActivityFormInput } from "../activity-form/ActivityForm";
import { SlideOver } from "@frontend/lyng/slideOver";
import { Activity } from "../../../types";

type Props = {
  activity: PartialBy<Activity, "id">;
  onClose: () => void;
};

export const ActivityDetailsSlideOver = ({ activity, onClose }: Props) => {
  const { t } = useTranslate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [activityCreateMutation, { loading }] = useActivityCreateMutation({
    refetchQueries: ["ActivitiesByCareRecipientId"],
  });

  const [activityUpdateMutation] = useActivityUpdateMutation({
    refetchQueries: ["ActivitiesByCareRecipientId"],
  });

  const [activityDeleteMutation, { loading: deleteLoading }] =
    useActivityDeleteMutation({
      refetchQueries: ["ActivitiesByCareRecipientId"],
    });

  const onSubmit = async (values: ActivityFormInput): Promise<void> => {
    if (activity.id) {
      const activityUpdatePromise = activityUpdateMutation({
        variables: {
          activityId: activity.id ?? "",
          input: {
            ...values,
            schedule: {
              ...(values.schedule?.everyVisit?.everyDay && {
                everyVisit: {
                  everyDay: values.schedule?.everyVisit?.everyDay,
                },
              }),
              ...(values.schedule?.everyNDays?.days && {
                everyNDays: {
                  days: values.schedule?.everyNDays?.days ?? 0,
                },
              }),
              ...(values.schedule?.oneOff?.date && {
                oneOff: {
                  date:
                    values.schedule?.oneOff?.date?.toFormat("yyyy-MM-dd") ?? "",
                },
              }),
            },
            priority: values.priority.value,
            timeOfDayStart: values.timeOfDayStart
              ? DateTime.fromObject(values.timeOfDayStart).toFormat("HH:mm")
              : null,
          },
        },
      }).then((data) => {
        onClose();
        return data;
      });

      toast.promise(activityUpdatePromise, {
        loading: t("activityDetailsModal.updating"),
        success: (data) =>
          t("saved", {
            name: data.data?.activityUpdate.title ?? t("activity"),
          }),
        error: (err) => errorToToastMessage(err),
      });
      return;
    }

    const activityCreatePromise = activityCreateMutation({
      variables: {
        input: {
          ...values,
          schedule: {
            ...(values.schedule?.everyVisit?.everyDay && {
              everyVisit: {
                everyDay: values.schedule?.everyVisit?.everyDay,
              },
            }),
            ...(values.schedule?.everyNDays?.days && {
              everyNDays: {
                days: values.schedule?.everyNDays?.days ?? 0,
              },
            }),
            ...(values.schedule?.oneOff?.date && {
              oneOff: {
                date:
                  values.schedule?.oneOff?.date?.toFormat("yyyy-MM-dd") ?? "",
              },
            }),
          },
          priority: values.priority.value,
          careRecipientId: activity.careRecipientId,
          timeOfDayStart: values.timeOfDayStart
            ? DateTime.fromObject(values.timeOfDayStart).toFormat("HH:mm")
            : null,
        },
      },
    }).then((data) => {
      onClose();
      return data;
    });

    await toast.promise(activityCreatePromise, {
      loading: t("activityDetailsModal.creating"),
      success: (data) =>
        t("saved", {
          name: data.data?.activityCreate.title ?? t("activity"),
        }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const deleteActivity = () => {
    const activityDeletePromise = activityDeleteMutation({
      variables: {
        activityId: activity.id ?? "",
      },
    }).then((data) => {
      onClose();
      return data;
    });

    toast.promise(activityDeletePromise, {
      loading: t("activityDetailsModal.deleting"),
      success: () => t("deletedSuccessfully"),
      error: (err) => errorToToastMessage(err),
    });
    return;
  };
  return (
    <SlideOver.WithIntercomHider show={true} onClose={onClose}>
      <SlideOver.Title>
        {`${activity.id ? t("activities.edit") : t("activities.add")} `}
      </SlideOver.Title>
      <SlideOver.DetailSection>
        <ActivityForm
          onSubmit={onSubmit}
          activity={activity}
          onDelete={activity.id ? () => setShowDeleteModal(true) : null}
          onCancel={onClose}
          loading={loading}
        />
      </SlideOver.DetailSection>
      <ConfirmDeleteModal
        show={!!showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onConfirm={() => {
          deleteActivity();
          setShowDeleteModal(false);
        }}
        loading={!!deleteLoading}
        description={t(
          "activityDetailsModal.removeActivitiesFromVisitsConfirmation",
        ).toString()}
      />
    </SlideOver.WithIntercomHider>
  );
};
