import classNames from "classnames";
import { forwardRef, useState } from "react";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFormRegisterReturn,
  useController,
} from "react-hook-form";

type CheckboxGroupProps = {
  children: React.ReactNode;
};
const CheckboxGroup = ({ children }: CheckboxGroupProps) => {
  return <div className="max-w-lg space-y-4">{children}</div>;
};

type Props = {
  id?: string;
  label?: string;
  type: "checkbox" | "radio";
  checked?: boolean;
  value?: string;
  defaultChecked?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
};
const Checkbox = forwardRef<
  HTMLInputElement,
  Props & UseFormRegisterReturn<string>
>(function Checkbox(
  {
    id,
    name,
    label,
    type,
    checked,
    value,
    defaultChecked,
    onChange,
    onBlur,
    ariaLabel,
    disabled,
  },
  ref,
) {
  return (
    <div className="relative flex items-center">
      <div className="flex items-center">
        <input
          ref={ref}
          id={id}
          name={name}
          type={type}
          checked={checked}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          defaultChecked={defaultChecked}
          className={classNames(
            type === "checkbox" ? "rounded" : "",
            "h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500",
          )}
          aria-label={ariaLabel}
          disabled={disabled}
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {label}
          </label>
        </div>
      )}
    </div>
  );
});

type CheckboxesProps = {
  options: { key: string; label: string; value: string }[];
};

function Checkboxes<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: UseControllerProps<TFieldValues, TName> & CheckboxesProps) {
  const { field } = useController(props);
  const [value, setValue] = useState<string[]>(field.value || []);

  return (
    <>
      {props.options.map((option) => (
        <Checkbox
          id={`${field.name}-${option.key}`}
          name={field.name}
          onChange={(e) => {
            const valueCopy = new Set(value);

            if (e.target.checked) {
              valueCopy.add(option.value);
            } else {
              valueCopy.delete(option.value);
            }
            const valueCopyArr = Array.from(valueCopy);
            field.onChange(valueCopyArr);

            // update local state
            setValue(valueCopyArr);
            return Promise.resolve();
          }}
          onBlur={() => Promise.resolve(field.onBlur())}
          key={option.key}
          type="checkbox"
          checked={value.includes(option.value)}
          label={option.label}
          value={option.value}
        />
      ))}
    </>
  );
}

export { Checkbox, Checkboxes, CheckboxGroup };
