import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useMatches,
} from "react-router-dom";
import { Breadcrumbs } from "./Breadcrumbs/Breadcrumbs";
import { useEffect } from "react";
import { analytics } from "../../analytics";
import typewriter from "../../typewriter/segment";
import { PushNotificationClicked } from "@frontend/lyng/notifications/types";
import { SidebarMenu } from "./SidebarMenu/SidebarMenu";

export type Navigator = (data: unknown) => { hideCrumbs: boolean } | null;
type HandleWithNavigator = {
  navigator: Navigator;
};

function isHandleWithNavigator(
  handle: unknown,
): handle is { navigator: Navigator } {
  return (
    typeof handle === "object" &&
    !!handle &&
    "navigator" in handle &&
    !!handle.navigator
  );
}

const Layout = () => {
  const location = useLocation();
  const matches = useMatches();
  const navigator = matches
    .filter((match) => Boolean(isHandleWithNavigator(match.handle)))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => (match.handle as HandleWithNavigator).navigator(match.data))
    .pop();

  useEffect(() => {
    analytics.page();

    // if location contains "push" as a parameter we need to track it
    if (location.search.includes("push")) {
      // push has structure of { Count: number, Type: string }
      const push = new URLSearchParams(location.search).get("push");
      const pushObj: PushNotificationClicked = push
        ? JSON.parse(push)
        : { Count: 0, Type: "Visit" };

      typewriter.pushNotificationClicked(pushObj);
    }
  }, [location]);

  return (
    <div className="h-screen w-screen">
      <SidebarMenu />

      <div className="flex h-full flex-1 flex-col lg:pl-56">
        <main className="flex flex-1">
          <div className="mx-auto flex w-full flex-1 flex-col px-2 sm:px-4">
            {!navigator?.hideCrumbs && (
              <div className="hidden lg:block">
                <Breadcrumbs />
              </div>
            )}
            {/* This element renders the the matche route from the Router */}
            <Outlet />
          </div>
        </main>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default Layout;
