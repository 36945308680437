import { DateTime } from "luxon";
import { z } from "zod";

export const scheduleSchema = z
  .object({
    everyNDays: z.object({ days: z.number().positive().nullable() }).optional(),
    everyVisit: z.object({ everyDay: z.boolean().nullable() }).optional(),
    oneOff: z
      .object({
        date: z
          .custom<DateTime>((value) => DateTime.isDateTime(value))
          .nullable(),
      })
      .optional(),
  })
  .optional();

export type ScheduleSchema = z.infer<typeof scheduleSchema>;
