import { Field, Label, Switch } from "@headlessui/react";
import classNames from "classnames";
import { Paragraph } from "../../typography";
import { match } from "ts-pattern";

type ToggleWithLabelProps = {
  checked: boolean;
  onToggle: () => void;
  disabled?: boolean;
  text?: string | undefined;
  size?: "s" | "l";
};

export const Toggle = ({
  text,
  checked,
  onToggle,
  disabled,
  size = "s",
}: ToggleWithLabelProps) => {
  return (
    <Field as="div" className="group inline-flex gap-4 items-center px-3 py-2">
      <Switch
        checked={checked}
        onChange={() => onToggle()}
        className={classNames(
          {
            "bg-secondary-600 border-transparent dark:bg-secondary-400":
              !disabled && checked,
            "bg-greyscale-200 border-greyscale-400 dark:bg-greyscale-700 dark:border-greyscale-500":
              !disabled && !checked,
            "bg-greyscale-200 border-transparent dark:bg-greyscale-700":
              disabled && checked,
            "bg-greyscale-100 border-transparent dark:bg-greyscale-700":
              disabled && !checked,
            "cursor-pointer": !disabled,
          },
          "relative inline-flex items-center flex-shrink-0 rounded-full border transition-colors duration-200 ease-in-out focus:outline-none",
          match(size)
            .with("s", () => "h-6 w-10")
            .with("l", () => "h-8 w-13")
            .exhaustive(),
        )}
        disabled={disabled}
      >
        <span
          aria-hidden="true"
          className={classNames(
            // checked ? "translate-x-[0.9rem]" : "-translate-x-[0.4rem]",
            match([checked, size])
              .with([true, "l"], () => "translate-x-[0.9rem] h-10 w-10")
              .with([false, "l"], () => "-translate-x-[0.4rem] h-10 w-10")
              .with([true, "s"], () => "translate-x-[0.7rem] h-8 w-8")
              .with([false, "s"], () => "-translate-x-[0.4rem] h-8 w-8")
              .exhaustive(),
            "flex items-center justify-center pointer-events-none transform rounded-full transition duration-200 ease-in-out",

            {
              "group-focus-within:bg-primary-400 group-focus-within:bg-opacity-20  group-hover:bg-gradient-radial-50% dark:group-focus-within:!bg-primary-400 dark:group-focus-within:!bg-opacity-20 dark:group-hover:bg-none dark:group-hover:bg-accent-200 dark:group-hover:bg-opacity-20 from-[rgba(255,255,192,0.8)] via-[rgba(255,231,154,0.4)] to-[rgba(200,211,254,0)]":
                !disabled && checked,
              "group-focus-within:!bg-primary-400 group-focus-within:!bg-opacity-40 group-hover:bg-greyscale-500 group-hover:bg-opacity-20":
                !disabled && !checked,
            },
          )}
        >
          <span
            className={classNames(
              "inline-block rounded-full transition duration-200 ease-in-out ",

              checked ? "scale-100" : "scale-[0.666]",
              match(size)
                .with("l", () => "h-6 w-6")
                .with("s", () => "h-5 w-5")
                .exhaustive(),

              {
                "bg-white dark:bg-secondary-900": checked && !disabled,
                "bg-white dark:bg-greyscale-900": checked && disabled,
                "bg-greyscale-700 dark:bg-greyscale-200": !checked && !disabled,
                "bg-greyscale-400 bg-opacity-40 dark:bg-greyscale-500 dark:bg-opacity-40":
                  !checked && disabled,
              },
            )}
          />
        </span>
      </Switch>
      {text && (
        <Label as={Paragraph} size="m">
          {text}
        </Label>
      )}
    </Field>
  );
};
